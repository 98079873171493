

export default class WebSocketWorker {

    constructor(login,building_uuid, callback_function_message, setActiveUsers, setReceivedMessages, setSentMessages) {
        
        this.url = process.env.WEBSOCKET;
        this.socket = null;
        this.token = login?.Authorization?.replace("Bearer ", "");
        this.userId = login?.id;
        this.building_uuid = building_uuid;
        this.callback_function_toast = callback_function_message;
        this.setActiveUsersCallback = setActiveUsers;
        this.setReceivedMessages = setReceivedMessages;
        this.setSentMessages = setSentMessages;

        if(this.token && this.building_uuid && this.url)this.connect()
    }
  
    connect() {
      this.building_socket = new WebSocket(this.url+'building/'+this.building_uuid+'/?token='+this.token);
      // this.privatesocket = new WebSocket(this.url+'?token='+this.token);
      // Event-Listener: Wenn die Verbindung geöffnet wird
      this.building_socket.onopen = () => {
        console.log('WebSocket connection opened.');
      };
  
      // Event-Listener: Wenn eine Nachricht empfangen wird
      this.building_socket.onmessage = (event) => {
        try {
            let parsedData = JSON.parse(event.data);

            if (typeof parsedData === "object" && parsedData !== null) {

              if(Object.keys(parsedData).includes("active_users")){
                if (this.setActiveUsersCallback) {
                  this.setActiveUsersCallback(prevState => {
                    const filteredData = parsedData['active_users'].filter(user => user?.id !== this.userId);
                    const isEqual = JSON.stringify(prevState) === JSON.stringify(filteredData);
                    if (isEqual) {
                      return prevState; 
                    }
                    return filteredData; 
                  });
                }
              }

              else if(Object.keys(parsedData).includes("message")){

                if (parsedData.sender === this.userId) {
                  this.setSentMessages(prevState => {
                    const updatedState = { ...prevState };
                    for (const key in updatedState) {
                      const item = updatedState[key];
                      if (!item.id && item.message === parsedData.message) {
                        updatedState[key] = { 
                          ...parsedData, 
                          date: new Date().toISOString(),
                          sent: true,
                        };
                      }
                    }
                  
                    return updatedState;
                  });
                } else if(this.setReceivedMessages){
                  this.setReceivedMessages(prevState => {
                    let updatedState = { ...prevState };
                    updatedState[parsedData['id']]={...parsedData,date: new Date().toISOString()}; 
                    return updatedState;
                  })
                }
                this.callback_function_message && this.callback_function_message(parsedData)

              }
              // else{
              //     console.log("Received a dictionary (object):", parsedData);
              // }
              
            }
          } catch (error) {
            // console.log("Received non-dict message:", event.data);
          }
      };
  
      // Event-Listener: Wenn ein Fehler auftritt
      this.building_socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      // Event-Listener: Wenn die Verbindung geschlossen wird
      this.building_socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };

    }
  
    // Schicke eine Nachricht über den WebSocket
    async sendMessage(message, onSentCallback) {
      await this.waitForSocketOpen();
      try {
        this.building_socket.send(JSON.stringify({ "type": "chat.message", "message": message.message }));

        if (onSentCallback) {
          onSentCallback({success: true}); 
        }

      } catch (error) {
        console.error('Error sending message:', error);
        if (onSentCallback) {
          onSentCallback({success: false });
        }
      }
    }
      
    // Ungelesene Nachrichten auf "gelesen" setzen
    async readMessage(message, onSentCallback) {
      await this.waitForSocketOpen();
      try {
        this.building_socket.send(JSON.stringify({ "type": "chat.read", "message_uuids": message }));

        if (onSentCallback) {
          onSentCallback();
        }
      } catch (error) {
        console.error('Error message:', error);
      }
    }
    
    waitForSocketOpen() {
      return new Promise((resolve, reject) => {
        if (this.building_socket && this.building_socket.readyState === WebSocket.OPEN) {
          resolve(); 
        } else {
          const onOpenHandler = () => {
            this.building_socket.removeEventListener('open', onOpenHandler);
              resolve(); 
          };
          this.building_socket.addEventListener('open', onOpenHandler);
        }
      });
    }
  
    // Schließe die WebSocket-Verbindung
    disconnect() {
      if (this.socket) {
        this.building_socket.close();
      }
    }
  }
  