import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MainLayout from '../components/MainLayout';
import Background from "../assets/background_3_3.png";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import Collapse from "../components/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faDownload, faEdit, faList, faListCheck, faSearch, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { useToast } from '../NotificationsContent';
import { Button, Form, OverlayTrigger, Table, Tooltip, Placeholder } from "react-bootstrap";
import Pagination from '../components/Pagination';
import { usePermissions } from '../context/PermissionsContext';
import AddEditCounter from './AddEditCounter';
import AddEditCounterReadingOrConsumption from './AddEditCounterReadingOrConsumption';
import BuildingLineChart from './BuildingLineChart';
import { useBuilding } from '../context/BuildingContext';
import DjangoPagination from '../components/DjangoPagination';

const BuildingCounters = ({comeFromEnergyReports, buildingID}) => {
    const _buildingGuid = useParams()['buildingGuid'] || buildingID;
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    const [show, setShow] = useState(false);
    const [counters, setCounters] = useState(undefined);
    const [counter, setCounter] = useState(false);

    const [reload, setReload] = useState(false);

    const { allowEdit } = usePermissions();
    const { currentBuilding } = useBuilding();
    const [isStable, setIsStable] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const mainFunctions = [
        { label: t('add'), onClick: () => { setShow('addCounter'); setCounter(undefined); }, key: 'add', icon: faAdd, disabled: !allowEdit},
    ]

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        },
        {
            col: t('name'),
            type: 'label'
        },
        {
            col: t('is_main_counter'),
            type: 'label'
        },
        {
            col: t('type'),
            type: 'label'
        },
        {
            col: t('hpccomponent'),
            type: 'label'
        },
        {
            col: t('created'),
            type: 'label'
        },
        {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const getAllCounters = useCallback(async () => {
        setCounters(undefined);

        if (!client) return;
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_datasource_list({
                building_uuid: _buildingGuid,
                page: currentPage
            });

            if (response.status === 204) {
                setCounters({results: []});
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setCounters(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t, currentPage])

    const deleteCounter = useCallback(async (counterId) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_datasource_destroy({
                building_uuid: _buildingGuid,
                datasource_uuid: counter?.id
            });

            if (response.status >= 200 && response.status < 300) {
                setCounter(undefined);
                setShow(false)
                addToast(t("success"), t("deleteCounterSuccess"), "success");

                if (counters.results?.length === 1 && currentPage !== 1) {
                    setCurrentPage(currentPage - 1)
                } else {
                    getAllCounters();
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setCounter(undefined);
            setShow(false)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, counter, getAllCounters, login.Authorization, t, counters, currentPage])

    const onClose = () => {
        setShow(false);
        setCounter(undefined);
        getAllCounters();
        setReload(true)
    }

    useEffect(() => {
        if (!currentBuilding && !comeFromEnergyReports) return;
        if (comeFromEnergyReports) setIsStable(true)
        setIsStable(true)
    }, [comeFromEnergyReports, currentBuilding])
    
    useEffect(() => {
        if(!isStable) return
        getAllCounters()
    }, [getAllCounters, currentPage, isStable])

    return (
        <>
            {comeFromEnergyReports ? (
                <>
                    {(counters === undefined) ? (
                        <Card {...{ heading: `${t('counters')}`, mainFunctions, search: true }}>
                            <TablePlaceholder {...{ structure: tableStructure }} />
                        </Card>
                    ) : (!Boolean(counters?.results.length)) ? (
                        <>
                            <Card {...{ heading: `${t('counters')}`, mainFunctions, search: true }}>
                                <p className="m-0">{t("noBuildingCounters")}</p>
                            </Card>
                        </>
                    ) : (
                        <Card {...{ heading: `${t('counters')}`, mainFunctions, search: true }}>

                            <Table responsive>
                                <thead>
                                    <tr>
                                        {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                            <span>{ts.col}</span>
                                        </th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {counters?.results.map((counterRow) => <CounterRow key={`counter-key-${counterRow.id}`} {...{ counterRow, setCounter, counter, setShow, show, reload, setReload, buildingID }} />)}
                                </tbody>
                            </Table>
                            {/* <Pagination
                                currentPage={currentPage}
                                pageSize={pageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                                pageCount={pageCount}
                            /> */}

                            <DjangoPagination {...{ total_pages: counters?.total_pages, currentPage, setCurrentPage }} />
                        </Card>
                    )}

                    <ContentModal {...{ show: show === 'addCounter' || show === 'editCounter', onHide: () => {setShow(false)}, title: (show === 'editCounter' ? t('editCounter') : t('addCounter')), content: <AddEditCounter {...{ editCounter: counter, onClose, buildingID }} /> }} />
                    <ContentModal {...{ show: show === 'addCounterReadingOrConsumption', onHide: () => {setShow(false); setCounter(false)}, title: counter?.counter_datasource_type === 1000 ? t('addCounterReading') : t('addConsumption'), size:'lg', content: <AddEditCounterReadingOrConsumption {...{ counter, buildingID, onClose: () => {setShow(false); setCounter(false); setReload(true)}}} /> }} />
                    <SecurityActionModal {...{ show: show === "deleteCounter", onHide: () => { setCounter(undefined); setShow(false) }, title: t('deleteCounter'), question: t('deleteCounterQuestion'), action: () => deleteCounter(counter.id) }} />
                </>


            ) : (
                <MainLayout {...{ background: Background }}>
                    <div className="sidebar__padding">
                        {(counters === undefined) ? (
                            // <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}>
                            <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${currentBuilding?.name}`, mainFunctions, search: true }}>
                                <TablePlaceholder {...{ structure: tableStructure }} />
                            </Card>
                        ) : (!Boolean(counters?.results.length)) ? (
                            <>
                                {/* <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}> */}
                                <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${currentBuilding?.name}`, mainFunctions, search: true }}>
                                    <p className="m-0">{t("noBuildingCounters")}</p>
                                </Card>
                            </>
                        ) : (<>
                            {/* <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}> */}
                            <Card {...{ heading: `${t('counters')} ${t('for_the')} ${t('building')}: ${currentBuilding?.name}`, mainFunctions, search: true }}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                                <span>{ts.col}</span>
                                            </th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {counters?.results.map((counterRow) => <CounterRow key={`counter-key-${counterRow.id}`} {...{ counterRow, setCounter, counter, setShow, show, reload, setReload }} />)}
                                    </tbody>
                                </Table>
                                {/* <Pagination
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                    pageCount={pageCount}
                                /> */}
                                <DjangoPagination {...{ total_pages: counters?.total_pages, currentPage, setCurrentPage }} />
                            </Card>
                        </>)}

                        {/* {loading && (
                            <Loading/>
                        )} */}
                        <ContentModal {...{ show: show === 'addCounter' || show === 'editCounter', onHide: () => {setShow(false)}, title: (show === 'editCounter' ? t('editCounter') : t('addCounter')), content: <AddEditCounter {...{ editCounter: counter, onClose }} /> }} />
                        <ContentModal {...{ show: show === 'addCounterReadingOrConsumption', onHide: () => {setShow(false); setCounter(false)}, title: counter?.counter_datasource_type === 1000 ? t('addCounterReading') : t('addConsumption'), size:'lg', content: <AddEditCounterReadingOrConsumption {...{ counter, onClose: () => {setShow(false); setCounter(false); setReload(true)}}} /> }} />
                        <SecurityActionModal {...{ show: show === "deleteCounter", onHide: () => { setCounter(undefined); setShow(false) }, title: t('deleteCounter'), question: t('deleteCounterQuestion'), action: () => deleteCounter(counter.id) }} />
                    </div>
                </MainLayout>
            )}
        </>
    )
}

const CounterRow = ({ counterRow, setCounter, setShow, reload, setReload, buildingID }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    const { allowEdit } = usePermissions();
    // const _buildingGuid = useParams()['buildingGuid'];
    // const client = useSwagger();
    // const { login } = useAuth();

    const isoString = counterRow.created_at

    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('de-DE');
    const formattedTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return (
        <>
            <tr>
                <td className="align-middle">
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td className="align-middle">
                    <span>{counterRow.heatpart_name}</span>
                </td>
                <td className="align-middle">
                    <span>{counterRow.is_main_counter ? <FontAwesomeIcon icon={faCheck} /> : ""}</span>
                </td>
                <td className="align-middle">
                    <span>{counterRow.counter_datasource_type === 1000 ? t('counterReading') : t('consumption')}</span>
                </td>
                <td className="align-middle">
                    <span>{counterRow.component_name}</span>
                </td>
                <td className="align-middle">
                    <span>{formattedDateTime}</span>
                </td>
                <td className="align-middle">
                    <div className='actions__buttonbar grid__three'>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{counterRow?.counter_datasource_type === 1000 ? t('addCounterReading') : t('addConsumption')}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setCounter(counterRow); setShow("addCounterReadingOrConsumption") }}>
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("editCounter")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setCounter(counterRow); setShow("editCounter") }}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("deleteCounter")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setCounter(counterRow); setShow("deleteCounter") }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
            {display && (
                <tr>
                    <td colSpan="100%">
                        <div className="cards-container" style={{minHeight: "500px"}}>
                            <Card className='card' {...{ heading: t('overview'), active: true, className: "mt-3 mb-3" }}>
                                <BuildingLineChart {...{counter: counterRow, reload, setReload, buildingID}} />
                            </Card>

                            <Card className='card' {...{ heading: counterRow?.counter_datasource_type === 1000 ? t('counterReadings') : t('consumptions'), active: true, className: "mt-3 mb-3" }}>
                                <CounterReadingsOrConsumptions {...{counterRow, reload, setReload, buildingID}} />
                            </Card>
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

const CounterReadingsOrConsumptions = ({ counterRow, reload, setReload, buildingID}) => {
    // building_consumption_consumption_monthly_list
    const _buildingGuid = useParams()['buildingGuid'] || buildingID;
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { allowEdit } = usePermissions();

    const [counterReadingsOrConsumptions, setCounterReadingsOrConsumptions] = useState(undefined);
    const [counterReadingOrConsumption, setCounterReadingOrConsumption] = useState(undefined);
    const [shw, setShw] = useState(false);

    const tableStructure_counterReadingsOrConsumption = useMemo(() => [
        {col: counterRow.counter_datasource_type === 2000 ? t("from_1") : t("date"),
            type: 'label'
        }, counterRow.counter_datasource_type === 2000 && {
            col: t("to_1"),
            type: 'label'
        }, {
            col: t('value'),
            type: 'label'
        }, {
            col: t('value_MWh'),
            type: 'buttons'
        }, {
            col: t('actions'),
            type: 'buttons'
        },
    ], [t])

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    // const pageSize = 10;
    // const [pageCount, setPageCount] = useState(Math.ceil((counterReadingsOrConsumptions?.length || 0) / pageSize));

    // useEffect(() => {
    //     setPageCount(Math.ceil((counterReadingsOrConsumptions?.length || 0) / pageSize));
    // }, [counterReadingsOrConsumptions, pageSize]);
    
    // const indexOfLastItem = (currentPage + 1) * pageSize;
    // const indexOfFirstItem = indexOfLastItem - pageSize;
    // const currentItems = counterReadingsOrConsumptions?.slice(indexOfFirstItem, indexOfLastItem) || [];
    
    const getCounterReadingsOrConsumptions = useCallback(async() => {
        setCounterReadingsOrConsumptions(undefined)
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_datasource_consumptions_list({building_uuid: _buildingGuid, datasource_uuid: counterRow.id, page: currentPage, ordering: "to_datetime" });

            if (response.status >= 200 && response.status < 300) {
                // const sortedData = response.obj?.results.sort((a, b) => new Date(a.to_datetime) - new Date(b.to_datetime));
                // setCounterReadingsOrConsumptions({
                //     ...response.obj, 
                //     results: sortedData, 
                // });

                setCounterReadingsOrConsumptions(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
            
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, counterRow, login.Authorization, currentPage]);

    const deleteCounterReadingOrConsumption = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_datasource_consumptions_destroy({building_uuid: _buildingGuid, datasource_uuid: counterRow.id, id: counterReadingOrConsumption.id});

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), counterRow?.counter_datasource_type === 1000 ? t("deleteCounterReadingSuccess") :  t("deleteConsumptionSuccess") , "success");
                setShw(false);
                setCounterReadingOrConsumption(undefined);
                setReload(true)

                if (counterReadingsOrConsumptions.results?.length === 1 && currentPage !== 1) {
                    setCurrentPage(currentPage - 1)
                } else {
                    getCounterReadingsOrConsumptions()
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
            
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, counterRow, counterReadingOrConsumption, login.Authorization, addToast, t, setReload, currentPage, counterReadingsOrConsumptions]);

    const formatDateWithTime = (datetime) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Stellt sicher, dass das 24-Stunden-Format verwendet wird
        };
        return new Date(datetime).toLocaleString('de-DE', options);
    };

    const onClose = () => {
        setShw(false);
        setCounterReadingOrConsumption(undefined);
        getCounterReadingsOrConsumptions()
        setReload(true)
    }

    useEffect(() => {
        getCounterReadingsOrConsumptions()
    }, [currentPage])

    useEffect(() => {
        if (reload) {
            getCounterReadingsOrConsumptions()
            setReload(false)
        }
    }, [getCounterReadingsOrConsumptions, reload, setReload])

    return (
        <>
            {(counterReadingsOrConsumptions === undefined) ? (
                <TablePlaceholder {...{ structure: tableStructure_counterReadingsOrConsumption }} />
            ) : (!Boolean(counterReadingsOrConsumptions?.results.length)) ? (
                <p className="m-0">{counterRow?.counter_datasource_type === 1000 ? t('noCounterReading') : t('noConsumption')}</p>
            ) : (
                <>
                    <Table responsive className="mb-3">
                        <thead>
                            <tr>
                                {tableStructure_counterReadingsOrConsumption.map(th => <th key={`log-header-${th.col}`}>{th.col}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {/* {currentItems?.map((row, i) => <tr key={`log-row-${row?.id}`}> */}
                            {counterReadingsOrConsumptions?.results.map((row, i) => <tr key={`log-row-${row?.id}`}>
                                <td className="align-middle">{row?.from_datetime ? formatDateWithTime(row?.from_datetime) : formatDateWithTime(row?.to_datetime)}</td>
                                <td className="align-middle">{row?.from_datetime && formatDateWithTime(row?.to_datetime)}</td>
                                <td className="align-middle">{row?.counter_value || row?.value_raw || 0 }</td>
                                <td className="align-middle">{row?.value_MWh || 0 }</td>
                                <td className="align-middle">
                                    <div className='actions__buttonbar grid__two'>
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>{t('edit')}</Tooltip>
                                            }>
                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {
                                                setShw("editCounterReadingOrConsumption");
                                                setCounterReadingOrConsumption(row);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>{t('delete')}</Tooltip>
                                            }>
                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {
                                                setShw("deleteCounterReadingOrConsumption");
                                                setCounterReadingOrConsumption(row);
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                    {/* <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                        pageCount={pageCount}
                    /> */}

                    <DjangoPagination {...{ total_pages: counterReadingsOrConsumptions?.total_pages, currentPage, setCurrentPage }} />

                    <ContentModal {...{ show: shw === 'editCounterReadingOrConsumption', onHide: () => { setShw(false); setCounterReadingOrConsumption(false)}, title: counterRow?.counter_datasource_type === 1000 ? t('editCounterReading') : t('editConsumption'), size:'lg', content: <AddEditCounterReadingOrConsumption {...{ counter: counterRow, editCounterReadingOrConsumption: counterReadingOrConsumption, editConsumptionDatasourceType: counterRow?.counter_datasource_type, buildingID, onClose }} /> }} />
                    <SecurityActionModal {...{ show: shw === "deleteCounterReadingOrConsumption", onHide: () => { setShw(false); setCounterReadingOrConsumption(false) }, title: counterRow?.counter_datasource_type === 1000 ? t('deleteCounterReading') : t('deleteConsumption') , question: counterRow?.counter_datasource_type === 1000 ? t('deleteCounterReadingQuestion') : t('deleteConsumptionQuestion'), action: () => deleteCounterReadingOrConsumption() }} />
                </>
            )}
        </>
    )


}

export default BuildingCounters