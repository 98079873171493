import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronLeft, faChevronRight, faLock } from "@fortawesome/free-solid-svg-icons";
import { useBuilding } from "../context/BuildingContext";
import { useTranslation } from "react-i18next";
import ContentModal from "../components/ContentModal"
import { BuyLicence } from "../companyProfile/CompanyUsers";
import { Button } from "react-bootstrap";

const SidebarElement = ({ icon, label, to, type, index, currentVariantNode, variantHistoryStack, goToParent, openChild }) => {
    if (type === 'TreeItem') {
        return (
            <TreeSidebar
                key={`tree-item-${index}`}
                {...{ currentVariantNode, variantHistoryStack, goToParent, openChild }}
            />
        );
    }

    if (type === 'Link') {
        return (
            <NavLink
                key={`sidebar-nav-key-${to}`}
                className='sidebar__item'
                to={to}
                state={true}
            >
                <FontAwesomeIcon icon={icon} />
                <span>{label}</span>
            </NavLink>
        );
    }
};


const TreeSidebar = ({ currentVariantNode, variantHistoryStack, goToParent, openChild }) => {
    const { t } = useTranslation();

    return (
        <div className="tree-sidebar">
            {/* Navigation */}
            <div className="navigation-tree">
                <button onClick={goToParent} disabled={variantHistoryStack.length === 0}>
                    <FontAwesomeIcon icon={faChevronLeft} color="#fff" />
                </button>
                <span>{currentVariantNode?.name ? currentVariantNode?.name : t('noVariant')}</span>
                <div className="dropdown-tree-container">
                    <button disabled={!currentVariantNode?.children?.length}>
                        <FontAwesomeIcon icon={faChevronRight} color="#fff" />
                    </button>
                    {/* Children Dropdown */}
                    {currentVariantNode?.children?.length > 0 && (
                        <div className="dropdown-tree">
                            {currentVariantNode?.children.map((child) => (
                                <div key={`dropdown-tree-${child.id}`} onClick={() => openChild(child)} className="dropdown-tree-item">{child.name}</div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default function Sidebar({ sidebarArray }) {
    // const [collapsed, setCollapsed] = useState({});
    const location = useLocation();
    const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
    const { currentVariantNode, variantHistoryStack, goToParent, openChild, currentBuilding } = useBuilding();
    const { t } = useTranslation();
    const [show, setShow] = useState(false)

    // const toggleCollapse = (boolean, index) => {
    //     setCollapsed(prevState => ({
    //         ...prevState,
    //         [index]: boolean
    //     }));
    // };

    const scrollEffect = useCallback(() => {
        const sidebar = document.querySelector('.sidebar__wrapper');
        var currentScrollPos = window.pageYOffset;
        sidebar.style.maxHeight = `${prevScrollpos > currentScrollPos || !Boolean(currentScrollPos) ? 'calc(100% - 60px)' : '100%'}`;
        sidebar.style.top = `${prevScrollpos > currentScrollPos || !Boolean(currentScrollPos) ? '60' : '0'}px`;
        setPrevScrollpos(currentScrollPos);
    }, [prevScrollpos])

    useEffect(() => {
        if (!Boolean(sidebarArray.length)) return;
        const navbar = document.querySelector('.navigation');
        const sidebar = document.querySelector('.sidebar__wrapper');

        sidebar.addEventListener('pointerenter', () => {
            navbar.classList.add('hovered');
        });
      
        sidebar.addEventListener('pointerleave', () => {
            if (window.scrollY < 60)
                navbar.classList.remove('hovered');
        });

        window.addEventListener('scroll', scrollEffect)
        return () => window.removeEventListener('scroll', scrollEffect)
    }, [scrollEffect, sidebarArray.length])

    useEffect(() => {
        if (!Boolean(sidebarArray.length)) return;
        const sidebar = document?.querySelector('.sidebar__wrapper');
        sidebar.style.maxHeight = 'calc(100% - 60px)';
        sidebar.style.top = '60px';
    }, [location, sidebarArray.length])

    const isModuleActive = useCallback((module) => {
        if (currentBuilding === undefined) return
        return currentBuilding[module] || module === "other"
    }, [currentBuilding])

    if (!Boolean(sidebarArray.length)) return <></>

    return (
        <>
            <div className='sidebar__wrapper'>
                <div className="sidebar__overflow">
                    <div className='sidebar__container'>
                        {
                            sidebarArray.map((elem, index) => {
                                if (elem.type === "groupedLinks" && elem.items.length) {
                                    return (
                                        <div key={`grouped_links_wrapper-${index}`} className="grouped_links_wrapper">
                                            {!isModuleActive(elem.module) && (
                                                <div className="lock_icon_div" key={`lock_icon_div-${index}`} >
                                                    <FontAwesomeIcon icon={faLock} className="lock_icon"/>
                                                    {/* <Button variant="outline-primary" className="p-10 mt-4 lock_button" onClick={() => setShow("buyLicence")}>
                                                        <FontAwesomeIcon
                                                            icon={faArrowRight}
                                                        />
                                                    </Button> */}
                                                </div>
                                            )}
                                            {elem.items.map((item, i) => {
                                                return (
                                                    <div
                                                        key={`sidebar-grouped-element-${i}`}
                                                        className={!isModuleActive(elem.module) ? "blured_links" : ""}
                                                    >
                                                        <SidebarElement
                                                            icon={item.icon}
                                                            label={item.label}
                                                            to={item.to}
                                                            type={item.type}
                                                            index={item.index}
                                                            currentVariantNode={currentVariantNode}
                                                            variantHistoryStack={variantHistoryStack}
                                                            goToParent={goToParent}
                                                            openChild={openChild}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                }
                          
                                if (elem.type === 'Divider') return <div key={`subnav-key-${index}`} className={`sidebar__divider${elem?.hidden ? ' d-none' : ''}`} />

                                // if (elem.type === 'Category') return <React.Fragment key={`subnav-category-${index}`}><div className='sidebar__category__item'>
                                //         <div className='sidebar__item'>
                                //             <FontAwesomeIcon icon={elem.icon} />
                                //             <span>{elem.label}</span>
                                //         </div>
                                //         {elem.button}
                                //     </div>
                                //     {elem.array.map(el => SidebarElement(el))}
                                // </React.Fragment>
                                // if (elem.type === 'Array') return <React.Fragment key={`subnav-array-${index}`}>
                                //     {elem.array.map(el => SidebarElement(el))}
                                // </React.Fragment>
                                // if (elem.type === 'Collapsable' && Boolean(elem.array.length)) return <React.Fragment key={`subnav-collaspable-${index}`}>
                                //     <div className='sidebar__item'>
                                //         <Collapse {...{boolean: collapsed[index], onClick: () => toggleCollapse(!collapsed[index], index), index}} />
                                //         {(elem.icon!=undefined)?<FontAwesomeIcon icon={elem.icon} />:<></>}
                                //         <span>{elem.label}</span>
                                //     </div>
                                //     <div className={`${collapsed[index] ? 'd-block' : 'd-none'}`}>
                                //         {elem.array.map(el => SidebarElement(el))}
                                //     </div>
                                // </React.Fragment>
                            })
                        }
                    </div>
                </div>
            </div>;
            <ContentModal {...{ show: show === "buyLicence", onHide: () => {setShow(false)}, title: t('addSWPackage'), content: <BuyLicence {...{setShow, comesFromSidebar: true}}/> }} />
        </>
    )
}