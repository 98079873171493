import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useToast } from "../NotificationsContent";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { faAdd, faEdit, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import { useSwagger } from "../context/SwaggerContext";

export const StrandReference = ({ variantID }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [strandReferences, setStrandReferences] = useState(undefined)
    const [show, setShow] = useState(false);
    const [strandRef, setStrandRef] = useState(undefined);
    const client = useSwagger();

    const mainFunctions = [
        { label: t('add'), onClick: () => {onClick('add/edit', undefined); setStrandRef({more_attributes:{"sigma_z": 0.4, "mbar_per_m": 1.5, "offset_mbar": 30.0, "max_distance": 50.0, "offset_boiler_room": 0}})}, key: 'add', icon: faAdd }
    ]

    const tableStructure = [
        {
            col: "Name",
            type: 'label'
        },  {
            col: t("heatpart_count"),
            type: 'label'
        }, {
            col: t("power"),
            type: 'label'
        },{
            col: t("maxDistance"),
            type: 'label'
        }, {
            col: t("mass_flow"),
            type: 'label'
        },{
            col: t("targetPressureMBAR"),
            type: 'label'
        },{
            col: t("sigma_z"),
            type: 'label'
        },{
            col: t("mBar per m"),
            type: 'label'
        },{
            col: t("OffsetGeneric"),
            type: 'label'
        }, {
            col: t("OffsetBoilerRoom"),
            type: 'label'
        }, {
            col: t("actions"),
            type: 'buttons'
        }
    ];

    const onClick = (show, ref) => {
        setShow(show);
        setStrandRef(ref);
        if(ref==undefined && show == false)fetchSR();
    }

    const fetchSR = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variantID}/strandreference/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return response.json()
        // })
        // .then(data => {
        //     //meta wird als object übergeben
        //     //Umwandlung in ein array 
    
        //     const newData = data.results.map(item => {
        //         return {
        //             ...item,
        //             meta: Array.isArray(item.meta) ? item.meta : [item.meta]
        //         };
        //     });

        //     setStrandReferences(newData)
        //     onClick();
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_strandreference_list({building_uuid: _buildingGuid, variant_uuid: variantID});

            if (response.status >= 200 && response.status < 300) {
                let objs = [...response.obj];
                objs.map(o=>{
                    if(o.more_attributes.sigma_z==undefined)o.more_attributes.sigma_z=0.4;
                    if(o.more_attributes.mbar_per_m==undefined)o.more_attributes.mbar_per_m=1.5;
                    if(o.more_attributes.offset_mbar==undefined)o.more_attributes.offset_mbar=30;
                    if(o.more_attributes.offset_boiler_room==undefined)o.more_attributes.offset_boiler_room=0;
                })
                setStrandReferences([...objs])
                onClick();
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast])

    const strandRefHandler = useCallback((e) => {
        
        const { id, value } = e.target;        
        
        setStrandRef(prev => {
            const newPrev = {...prev}
            switch(id){
                case "OffsetGeneric":
                    newPrev.more_attributes.offset_mbar = Number(value);
                    break;
                case "sigma_z":
                    newPrev.more_attributes.sigma_z = Number(value);
                    break;
                case "mBarperm":
                    newPrev.more_attributes.mbar_per_m = Number(value);
                    break;
                case "OffsetBoilerRoom":
                    newPrev.more_attributes.offset_boiler_room = Number(value);
                    break;
                case "name":
                    newPrev.name = value;
            }

            return newPrev
        })
    }, [strandRef])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        let sR = {...strandRef}

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(sR);
                return req;
            };

            const response = sR?.id ? await client.apis["building"].building_variant_strandreference_partial_update({building_uuid: _buildingGuid, variant_uuid: variantID, strandreference_uuid: strandRef?.id}) : await client.apis["building"].building_variant_strandreference_create({building_uuid: _buildingGuid, variant_uuid: variantID});

            if (response.status >= 200 && response.status < 300) {
                fetchSR();
                addToast(t("success"), t(strandRef?.id ? "strandReferenceEdited" : "strandReferenceAdded"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast, strandRef])

    const onDelete = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variantID}/strandreference/${strandRef?.id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE",
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return {}
        // })
        // .then(_ => {
        //     fetchSR();
        //     addToast(t("success"), t("strandReferenceDeleted"), "success");
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(strandRef);
                return req;
            };

            const response = await client.apis["building"].building_variant_strandreference_destroy({building_uuid: _buildingGuid, variant_uuid: variantID, strandreference_uuid: strandRef?.id});

            if (response.status >= 200 && response.status < 300) {
                fetchSR();
                addToast(t("success"), t("strandReferenceDeleted"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast, strandRef])

    useEffect(() => {
        fetchSR();
    }, [])

    return <>
        <Card {...{ mainFunctions }}>
            {(strandReferences === undefined) ? (
                <TablePlaceholder {...{ structure: tableStructure }} />
            ) : (!Boolean(strandReferences?.length)) ? (
                <p className="m-0">{t("noStrandReferences")}</p>
            ) : (
                <Table responsive>
                    <thead>
                        <tr>
                            {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                
                                {ts.col==t("OffsetGeneric") ? (
                                    <><span>{ts.col} </span><OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t("info_OffsetGeneric")}</Tooltip>}>
                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" color="#blue" style={{marginRight: "5px"}} />
                                    </OverlayTrigger></>
                                ):(
                                    <span>{ts.col}</span>
                                )}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {strandReferences?.map((sR) => <SRRow key={`strandref-key-${sR.id}`} {...{ sR, onClick: (show, ref) => onClick(show, ref), variantID }} />)}
                    </tbody>
                </Table>
            )}
        </Card> 
        <ContentModal {...{ show: show === "add/edit", onHide: () => onClick(false, undefined), title: t(strandRef?.id ? "editStrandReference" : "addStrandReference"), content: <AddEditStrandReference {...{ strandRef, strandRefHandler, onSubmit, setStrandRef, strandReferences, setStrandReferences}} />, topMost: true }} />
        <SecurityActionModal {...{ show: show === "delete", onHide: () => onClick(false, undefined), title: t("deleteStrandReference"), question: t("deleteStrandReferenceQuestion"), action: () => onDelete() }} />
    </>
}

const SRRow = ({ sR, onClick, variantID }) => {
    const { t } = useTranslation();

    let out = sR.more_attributes[variantID]
    console.log(out)

    return <tr>
        <td className="align-middle">
            <span>{sR?.name}</span>
        </td>
        <td className="align-middle">
            <span>{out?.heatpart_count}</span>
        </td>
        
        <td className="align-middle">
            <span>{(sR?.target_thermal_power_to_ambient/1000 || 0).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
        </td>
        <td className="align-middle">
            <span>{(sR?.more_attributes?.max_distance||0).toLocaleString(undefined, { maximumFractionDigits: 2 })} m</span>
        </td>
        <td className="align-middle">
            <span>{(sR?.target_mass_flow||0).toLocaleString(undefined, { maximumFractionDigits: 2 })} kg/h</span>
        </td>
        <td className="align-middle">
            <span>{(Math.max(sR?.target_pressure_mbar,0)||0).toLocaleString(undefined, { maximumFractionDigits: 2 })} mBar</span>
        </td>
        <td className="align-middle">
            <span>{sR?.more_attributes?.sigma_z.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
        </td>
        <td className="align-middle">
            <span>{sR?.more_attributes?.mbar_per_m.toLocaleString(undefined, { maximumFractionDigits: 2 })} mBar</span>
        </td>
        <td className="align-middle">
            <span>{sR?.more_attributes?.offset_mbar.toLocaleString(undefined, { maximumFractionDigits: 2 })} mBar</span>
        </td>
        <td className="align-middle">
            <span>{sR?.more_attributes?.offset_boiler_room.toLocaleString(undefined, { maximumFractionDigits: 2 })} mBar</span>
        </td>

        <td className="align-middle">
            <div className='actions__buttonbar grid__two'>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("edit")}</Tooltip>
                    }>
                    <Button variant="outline-primary" className="p-10" onClick={() => onClick("add/edit", sR)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("delete")}</Tooltip>
                    }>
                    <Button variant="outline-primary" className="p-10" onClick={() => onClick("delete", sR)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            </div>
        </td>
    </tr>
};

const AddEditStrandReference = ({ strandRef, strandRefHandler, onSubmit, setStrandRef }) => {
    const { t } = useTranslation();

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="name" value={strandRef?.name || ""} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="sigma_z">
            <Form.Label>{t("sigma_z")}</Form.Label>
            <Form.Control type="number" value={strandRef?.more_attributes?.sigma_z || 0.4} step={0.1} max={0.99} min={0} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="mBarperm">
            <Form.Label>{t("mBar per m")}</Form.Label>
            <Form.Control type="number" value={strandRef?.more_attributes?.mbar_per_m || 1.5} step={0.1} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="OffsetGeneric">
            <Form.Label>{t("OffsetGeneric")+" (in mBar) "} 
                <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t("info_OffsetGeneric")}</Tooltip>}>
                    <FontAwesomeIcon icon={faInfoCircle} size="sm" color="#blue" style={{marginRight: "5px"}} />
                </OverlayTrigger>
            </Form.Label>
            <Form.Control type="number" value={strandRef?.more_attributes?.offset_mbar || 0} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="OffsetBoilerRoom">
            <Form.Label>{t("OffsetBoilerRoom")} (in mBar)</Form.Label>
            <Form.Control type="number" value={strandRef?.more_attributes?.offset_boiler_room || 0} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Button variant="outline-primary" className="w-100" type="submit">{t('submit')}</Button>
    </Form>
}

export default StrandReference;