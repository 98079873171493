import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useSwagger } from "../context/SwaggerContext";
import { useBuilding } from "../context/BuildingContext";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePermissions } from "../context/PermissionsContext";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import AddEditAlarm from "./AddEditAlarm";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";

export const BuildingAlarms = () => {

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <AlarmTable />
        </div>
    </MainLayout>
}

const AlarmTable = () => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const client = useSwagger();
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();
    const { currentBuilding } = useBuilding();
    
    const [alarms, setAlarms] = useState(undefined);
    const [alarm, setAlarm] = useState(undefined);
    const [types, setTypes] = useState(undefined);
    const [show, setShow] = useState(false);
    const [isStable, setIsStable] = useState(false);

    const mainFunctions = [
        { label: t("addAlarm"), onClick: () => setShow("add/edit-alarm"), key: "add/edit-alarm", icon: faAdd }
    ];

    const tableStructure = [
        {
            col: t("device"),
            type: "label",
        }, {
            col: t("measurement"),
            type: "label",
        }, {
            col: t("threshhold"),
            type: "label",
        }, {
            col: t("alarmType"),
            type: "label",
        }, {
            col: t("lastExecuted"),
            type: "label",
        }, {
            col: t("actions"),
            type: "buttons",
        }
    ];

    const loadAlarms = useCallback(async() => {
        if (!client || !currentBuilding?.id) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_measurement_alarm_list({ building_uuid: currentBuilding?.id });
        
            if (response.status >= 200 && response.status < 300) {
                setAlarms(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, t]);

    const getTypes = useCallback(async () => {
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["alarmtypes"].alarmtypes_list();
    
            if (response.status >= 200 && response.status < 300) {
                setTypes(response.status === 204 ? [] : response.obj)
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const deleteAlarm = useCallback(async() => {  
        if (!client || !currentBuilding?.id) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_measurement_alarm_destroy({ building_uuid: currentBuilding.id, alarm_uuid: alarm.id });
        
            if (response.status >= 200 && response.status < 300) {
                loadAlarms();
                addToast(t("success"), t("alarmDeleted"), "success");
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, alarm, loadAlarms, addToast, t])

    const onClickHandler = (alarm, show, refresh = false) => {
        setAlarm(alarm);
        setShow(show);
        if (refresh) loadAlarms();
    };

    useEffect(() => {
        if (!currentBuilding) return
        setIsStable(true)
    }, [currentBuilding]);

    useEffect(() => {
        if (!isStable) return
        loadAlarms()
    }, [isStable])
    
    useEffect(() => {
        getTypes();
    }, [])

    return (
        <>
            <Card {...{ heading: `${t('alarms')} ${t('inThe')} ${t('building')}: ${isStable ? currentBuilding?.name : t('loading')}`, ...(allowEdit && { mainFunctions }) }}>
                {(alarms === undefined) ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : (alarms !== undefined && !Boolean(alarms?.length)) ? (
                    <p className="m-0">{t("noAlarms")}</p>
                ) : (
                    <>
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{(ts.type == "label") ? t(ts.col) : ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {alarms.map((alarm) => <AlarmRow key={`floor-${alarm.id}`} {...{ alarm, types, onClickHandler }} />)}
                            </tbody>
                        </Table>
                    </>
                )}
            </Card>

            <ContentModal {...{ show: show === "add/edit-alarm", onHide: () => onClickHandler(undefined, false, false), title: t(alarm ? "editAlarm" : "addAlarm"), content: <AddEditAlarm {...{ alarm, types, onClose: (alarm, show, refresh) => onClickHandler(alarm, show, refresh) }} /> }} />
            <SecurityActionModal {...{ show: show === "delete-alarm", onHide: () => onClickHandler(undefined, false, false), title: t("deleteAlarm"), question: t("deleteAlarmQuestion"), action: () => deleteAlarm() }} />
        </>
    )
}

const AlarmRow = ({ alarm, types, onClickHandler }) => {
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();

    return (
        <tr>
            <td className="align-middle">
                <span>{alarm.device_name}</span>
            </td>
            <td className="align-middle">
                <span>{alarm.measurement_name}</span>
            </td>
            <td className="align-middle">
                <span>{alarm.value}</span>
            </td>
            <td className="align-middle">
                <span>{types?.find(type => type.value == alarm.alarm_type).label}</span>
            </td>
            <td className="align-middle">
                <span>{alarm.last_executed}</span>
            </td>
            <td className="align-middle">
                <div className="actions__buttonbar grid__two">
                    <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("editAlarm")}</Tooltip>}>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(alarm, "add/edit-alarm", false)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("deleteAlarm")}</Tooltip>}>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(alarm, "delete-alarm", false)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    )
}

export default BuildingAlarms;