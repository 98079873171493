import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { Button, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "../components/Card"
import TablePlaceholder from '../components/Placeholders/TablePlaceholder';
import ContentModal from "../components/ContentModal"
import SecurityActionModal from "../components/SecurityActionModal"
import { timeReadableFormat } from "../util/helpers"
import { useSwagger } from "../context/SwaggerContext";
import AddEditViewLog from '../BuildingPage/AddEditViewLog';
import Pagination from '../components/Pagination';
import { usePermissions } from "../context/PermissionsContext.js"

const EditHP = ({ selectedComponent, onClose, canvasClassRef }) => {

    // wenn hp undefined ist, dürfen das nicht bearbeiten
    // es ist undefined, weil das ausgewählte Element nicht zu HP gehört
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const [hp, setHP] = useState({});
    const [rooms, setRooms] = useState([]);
    const [valves, setValves ] = useState([]);
    const [valve, setValve ] = useState(undefined);
    const [catalogues, setCatalogues] = useState([]);
    const [catalog, setCatalog] = useState(undefined);
    const [classifications, setClassifications] = useState();
    const [show, setShow] = useState({ type: undefined, classification: undefined });

    const _buildingGuid = useParams()['buildingGuid'];
    const _variantUUID = useParams()['variantID'];
    const _schemaUUID = useParams()['schemaID'];

    const [entry, setEntry] = useState({ title: "", content: "", id: undefined });
    const [logs, setLogs] = useState(undefined);
    const [log, setLog] = useState(undefined);
    const [type, setType] = useState([])
    const [connectionporttypes, setConnectionporttypes] = useState([])
    const [measurementPoints, setMeasurementPoints] = useState(undefined)
    const [devices, setDevices] = useState([])
    const [measurementPointsLoaded, setMeasurementPointsLoaded] = useState(false)
    const { allowEdit } = usePermissions();

    const mainFunctions = [
        {
            label: t("addClassification"),
            onClick: () => setShow({ type: "add/edit", classification: undefined }),
            key: "add_classification",
            icon: faAdd,
        }
    ];

    const logFunctions = [
        {
            label: t("addLogEntry"),
            onClick: () => setShow({ type: "add/edit_log", classification: undefined}),
            key: "add_log",
            icon: faAdd,
        }
    ];

    const measurementPointsFunctions = [
        {
            label: t("addMeasurementPoints"),
            onClick: () => setShow({ type: "add_measurementPoint", classification: undefined}),
            key: "add_log",
            icon: faAdd,
        }
    ];

    const tableStructure = useMemo(() => [
        {
            col: t("type"),
            type: 'label'
        }, {
        //     col: t('measurementPoint'),
        //     type: 'label'
        // }, 
            col: t('connection_type'),
            type: 'label'
        }, {
            col: t('group_id'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('updatedAt'),
            type: 'label'
        }, {
            col: t('editDelete'),
            type: 'buttons'
        }
    ], [t])

    const tableStructureMeasurementPoints = useMemo(() => [
        {
            col: t("devices"),
            type: 'label'
        }, {
            col: t('measurementPoint'),
            type: 'label'
        }, {
            col: t('editDelete'),
            type: 'buttons'
        }
    ], [t])

    const tableStructure_log = useMemo(() => [
        {
            col: t("title"),
            type: 'label'
        }, {
            col: t('author'),
            type: 'label'
        }, {
            col: t('lastUpdate'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('editDelete'),
            type: 'buttons'
        }
    ], [t])
    
    const types = [
        {
            value: 1,
            name: t("heatCircuit"),
        }, {
            value: 2,
            name: t("hotWaterPreparation"),
        }, {
            value: 3,
            name: t("energyConsumption"),
        }, {
            value: 4,
            name: t("heatSource"),
        }, {
            value: 5,
            name: t("heatStorage"),
        }, {
            value: 6,
            name: t("pump"),
        }
    ]

    const getTypes = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatingparttypes"].heatingparttypes_list();

            if (response.status >= 200 && response.status < 300) {
                const transformedHpt = response.obj?.map(h => ({
                    ...h, 
                    label: h.label.toLowerCase().replace(/[\s-]+/g, '_')
                }));

                const type = transformedHpt.filter((type) => type.value === parseInt(hp?.type))
                setType(type);
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, hp?.type, login.Authorization]);

    
    const getConnectionporttypes = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["connectionporttypes"].connectionporttypes_retrieve();

            if (response.status >= 200 && response.status < 300) {
                setConnectionporttypes(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, t, addToast]);

    const getDeviceInfo = useCallback(async(measurementPoint) => {
        if (!client || !Boolean(measurementPoint?.length) || measurementPoint[0].device === null) {
            setMeasurementPoints([])
            setMeasurementPointsLoaded(true)
            return;
        }

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_device_retrieve({ building_uuid: _buildingGuid, building_device_uuid: measurementPoint[0].device });

            if (response.status >= 200 && response.status < 300) {

                const measurement = response.obj.measurements.filter(measurement => measurement.uuid === measurementPoint[0].measurement);

                const tmp = {
                    id: measurementPoint[0].id,
                    device: measurementPoint[0].device,
                    measurement: measurementPoint[0].measurement,
                    device_name: response.obj?.name ? response.obj.name : "",
                    measurement_name: measurement?.[0]?.name ? measurement[0].name : ""
                }

                setMeasurementPoints([tmp])

                setMeasurementPointsLoaded(true)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, _buildingGuid, measurementPoints, login.Authorization, addToast, t]);

    const getMeasurmentPoints = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_heatpart_sensor_list({building_uuid: _buildingGuid, variant_uuid: _variantUUID, heatpart_uuid: selectedComponent.part});
            
            if (response.status >= 200 && response.status < 300) {
                getDeviceInfo(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, _buildingGuid, _variantUUID, login.Authorization, addToast, t, getDeviceInfo, selectedComponent]);

    const getHP = useCallback(async() => {
        if (!client || selectedComponent === undefined) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_building_heatpart_retrieve({building_uuid: _buildingGuid, variant_uuid: _variantUUID, hp_uuid: selectedComponent.part})

            const uuidv4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

            if (response.status >= 200 && response.status < 300) {
                setHP({});
                if (response.status === 204) return

                if (response.obj.standard_reference == 'fc3396b4-5d35-490a-bc81-6fa938380df1') {
                    // wenn das element ein strang ist
                    let o = {...response.obj};
                    if (o.more_attributes.sigma_z==undefined) o.more_attributes.sigma_z=0.4;
                    if (o.more_attributes.mbar_per_m==undefined) o.more_attributes.mbar_per_m=1.5;
                    if (o.more_attributes.offset_mbar==undefined) o.more_attributes.offset_mbar=30;
                    if (o.more_attributes.offset_boiler_room==undefined) o.more_attributes.offset_boiler_room=0;

                    Object.keys(o.more_attributes).map(key => {
                        if (uuidv4Pattern.test(key) || key == 'out') delete o.more_attributes[key];
                    })
                    setHP({...o})

                } else {
                    setHP(response.obj)
                }
                
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, _buildingGuid, login.Authorization, t, addToast]);

    const getHPC = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_list({org_uuid: login.currentOrganisation.id})

            if (response.status >= 200 && response.status < 300) {
                setCatalogues([]);
                setCatalog(undefined)
                if (response.status === 204) return

                let updatedCatalogues = [...response.obj];

                // Überprüfen, ob hp.radiator_throttle.catalog existiert
                if (hp?.radiator_throttle_catalog) {
                    const catalogId = hp.radiator_throttle_catalog;
                    const matchingCatalog = updatedCatalogues.find(catalog => catalog.id === catalogId);

                    if (matchingCatalog) {
                        // Entfernen des Objekts aus der aktuellen Position
                        updatedCatalogues = updatedCatalogues.filter(catalog => catalog.id !== catalogId);

                        // Das Objekt an die erste Stelle verschieben
                        updatedCatalogues.unshift(matchingCatalog);
                    }
                }

                setCatalogues(updatedCatalogues);
                setCatalog(updatedCatalogues?.length > 0 ? updatedCatalogues[0]?.id : undefined)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, login.currentOrganisation.id, login.Authorization, t, addToast, hp]);

    const getRadiatorValve = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_list({org_uuid: login.currentOrganisation.id, heatpart_catalog_uuid: catalog, type: 8})

            if (response.status >= 200 && response.status < 300) {
                setValves([]);
                if (response.status === 204) return

                setValves(response.obj)
                
                if (hp?.radiator_throttle) {
                    setValve(response.obj?.find(item => item.id === hp?.radiator_throttle));
                } else if (response.obj?.length > 0) {
                    setValve(response.obj[0])
                } else {
                    setValve(undefined)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, addToast, login.Authorization, login.currentOrganisation.id, t, catalog]);
    

    const loadRooms = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_room_list({building_uuid: _buildingGuid, variant_uuid: _variantUUID})

            if (response.status === 204) {
                setRooms([]);
                return
            }

            if (response.status >= 200 && response.status < 300) {
                setRooms(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, login.Authorization, _buildingGuid])

    const getClassifications = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_scheme_part_classification_list({
                building_uuid: _buildingGuid,
                variant_uuid: _variantUUID,
                scheme_uuid: _schemaUUID,
                scheme_part_uuid: selectedComponent.id
            })

            if (response.status >= 200 && response.status < 300) {
                setClassifications([]);
                if (response.status === 204) return
                setClassifications(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.currentOrganisation.id, login.Authorization, t, addToast]);

    const deleteClassification = useCallback(async(deleteHeatpart) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_scheme_part_classification_destroy({
                building_uuid: _buildingGuid,
                variant_uuid: _variantUUID,
                scheme_uuid: _schemaUUID,
                scheme_part_uuid: selectedComponent.id,
                scheme_part_classification_uuid: show?.classification?.id
            })

            if (response.status >= 200 && response.status < 300) {
                getClassifications();
                setShow({ type: undefined, classification: undefined })
                addToast(t("success"), t("deleteClassificationSuccessful"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t, show, getClassifications]);

    const deleteMeasurementPoint = useCallback(async(measurementID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_heatpart_sensor_destroy({
                building_uuid: _buildingGuid,
                variant_uuid: _variantUUID,
                heatpart_uuid: selectedComponent.part,
                sensor_meas_uuid: measurementID
            })

            if (response.status >= 200 && response.status < 300) {
                getMeasurmentPoints();
                setShow({ type: undefined, classification: undefined })
                addToast(t("success"), t("deleteMeasurementPointSuccessful"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, _variantUUID, login.Authorization, getMeasurmentPoints, addToast, t]);

    const objToArray = (obj) => {
        const arr = [];
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                arr.push(obj[key]);
            }
        }
        return arr;
    }

    const changeHandler = useCallback((e, selectedValve) => {
        const { id, value } = e.target;

        if (id === "catalog") {
            setCatalog(value)
            return
        } else if (id === "radiator_throttle") {
            setValve(selectedValve)
        } else {
            setHP((prevState) => ({
                ...prevState,
                [id]: value,
            }));
        }
    }, [hp])

    const handleInputChange = useCallback((e, colIndex) => {
        let {value, id} = e.target;
        setHP(prev => {
            const newPrev = {...prev}
            if (colIndex=="value") switch(id) {
                case "offset_mbar":
                    newPrev.more_attributes.offset_mbar = Number(value);
                    break;
                case "sigma_z":
                    newPrev.more_attributes.sigma_z = Number(value);
                    break;
                case "mbar_per_m":
                    newPrev.more_attributes.mbar_per_m = Number(value);
                    break;
                case "offset_boiler_room":
                    newPrev.more_attributes.offset_boiler_room = Number(value);
                    break;
                default:
                    newPrev.more_attributes[id]=value;
            }

            else {
                newPrev.more_attributes[value] = newPrev.more_attributes[id];
                delete newPrev.more_attributes[id];
            }
            return ({ ...newPrev })
        });
    }, [hp])

    const deleteRow = useCallback((key) => {
        
        setHP(prev => {
            const newPrev = {...prev}
            delete newPrev.more_attributes[key];
            return ({ ...newPrev })
    });
    }, [hp])

    const addRow = useCallback(() => {
        let tmpData = {...hp.more_attributes}
        tmpData[""]="";
        setHP(prev => ({ ...prev, more_attributes: tmpData }));
    }, [hp])

    const onSubmit = useCallback(async(e) => {


        // falls die Raum Zuweisung noch nicht erfüllt ist
        if(hp.room === t("belongsToNoRoom")) {
            hp.room = null
        }

        const hpData = {
            id: hp.id,
            name: hp.name,
            comment: hp.comment,
            multiples_standard_ref_power: hp.multiples_standard_ref_power,
            distance_overbuilt: hp.distance_overbuilt,
            room: hp.room,
            // radiator_throttle: radiatorThrottle?.id || undefined,
            radiator_throttle: valve?.id ? valve?.id : null,
            more_attributes: hp.more_attributes,
        };

        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(hpData);
                return req;
            };

            const response = await client.apis["building"].building_variant_building_heatpart_partial_update({building_uuid: _buildingGuid, variant_uuid: _variantUUID, hp_uuid: selectedComponent?.part})

            if (response.status >= 200 && response.status < 300) {
                addToast(t('HeatingParts'), t('heatingPartPatch'), "success");
                let allComponents = canvasClassRef.current?.getComponents();
                allComponents.find(c => c.part === selectedComponent.part).name = hp.name
                selectedComponent.canvasDraw();
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('responseError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, _buildingGuid, addToast, hp, login.Authorization, onClose, selectedComponent?.part, t, _variantUUID, valve])

    const getLogs = useCallback(async() => {
        if (!client || !hp?.id) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_log_list({ building_uuid: _buildingGuid, relation_model_uuid: hp?.id })

            if (response.status >= 200 && response.status < 300) {
                const sortedLogs = response.obj.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setLogs(sortedLogs);
                setEntry({ title: "", content: "", id: undefined });
            } else {
                setLogs([]);
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, hp])

    const deleteLog = useCallback(async(id) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_log_destroy({ building_uuid: _buildingGuid, log_uuid: id })
    
            if (response.status >= 200 && response.status < 300) {
                getLogs();
                setLog(undefined)
                addToast(t("hpLog"), t("hpLogDeletedSuccessfully"), "success");
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, getLogs, addToast, t])

    const getDevices = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_device_list({ building_uuid: _buildingGuid, filter_measurements: false })
    
            if (response.status >= 200 && response.status < 300) {
                setDevices(response.obj)
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("ERROR: ", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid])

    // pagination
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;
    const [pageCount, setPageCount] = useState(Math.ceil((logs?.length || 0) / pageSize));

    useEffect(() => {
        setPageCount(Math.ceil((logs?.length || 0) / pageSize));
    }, [logs, pageSize]);

    const indexOfLastItem = (currentPage + 1) * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = logs?.slice(indexOfFirstItem, indexOfLastItem) || [];

    useEffect(()=> {
        if (selectedComponent?.hpcc) return
        getHP()
        loadRooms()
        // getHPC()
        getClassifications()
        getConnectionporttypes()
        getMeasurmentPoints()
        getDevices()
    }, [])

    useEffect(()=> {
        if (selectedComponent?.hpcc) return
        catalog && getRadiatorValve()
    }, [catalog])

    useEffect(() => {
        getHPC()
        getLogs()
        getTypes()
    }, [hp.id])

    useEffect(() => {
        getDeviceInfo()
    }, [])

    return (
        <div>
            {selectedComponent?.hpcc ? <div>{t("noEdit")}</div> :
                <Form onSubmit={(e) => onSubmit(e)}>
                    <Form.Group className='mb-3'>
                        <Form.Label>{t('name')}</Form.Label>
                        <Form.Control id='name' type='text' value={hp?.name || " "} onChange={(e) => changeHandler(e)} />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>{t('catalog')}</Form.Label>
                        <Form.Select className="w-100" id="catalog" value={catalog} onChange={(e) => changeHandler(e)}>
                            {catalogues.map(cat => <option key={`catalog-key-${cat?.id}`} value={cat?.id}>{cat?.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                  
                    <Form.Group className='mb-3'>
                        <Form.Label>{t('radiator_throttle')}</Form.Label>
                        <Form.Select id="radiator_throttle" value={valve?.id|| ''} onChange={(e) => {
                            const selectedValve = valves.find(val => val.id === e.target.value);
                            changeHandler(e, selectedValve); 
                        }}>
                            {valves?.length === 0 && <option key={`valves-key-undefined`} value={undefined}>{t('noValves')}</option>}
                            {valves?.map(val => <option key={`valve-type-key-${val.id}`} value={val.id}>{val?.name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    {hp.type === 2 && (
                            <>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('multiples_standard_ref_power')}</Form.Label>
                                    <Form.Control id='multiples_standard_ref_power' required type='number' min={0.1} step={0.1} value={hp?.multiples_standard_ref_power || " "} onChange={(e) => changeHandler(e)} />
                                </Form.Group>
        

                                <Form.Group className='mb-3'>
                                    <Form.Label>{t('distance_overbuilt')}</Form.Label>
                                    <Form.Control id='distance_overbuilt' type='number' value={hp?.distance_overbuilt || ""} min={0} max={20} step={1} onChange={(e) => changeHandler(e)} />
                                </Form.Group>
                            </>
                        )
                    }                  

                    <Form.Group className='mb-3'>
                        <Form.Label>{t('room')}</Form.Label>
                        <Form.Select className="w-100" id="room" value={hp?.room} onChange={(e) => changeHandler(e)}>
                            {hp.room == null && <option key={`room-key-undefined`} value={undefined}>{t('belongsToNoRoom')}</option>}
                            {rooms.map(room => <option key={`room-key-${room?.id}`} value={room?.id}>{room?.name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="attributes">
                        <Form.Label>{t("attributes")}</Form.Label>
                        <Table responsive className="mb-3">
                            <thead>
                                <tr>
                                    <th>{t("key")}</th>
                                    <th>{t("value")}</th>
                                    <th><FontAwesomeIcon icon={faTrash} size='sm' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {hp?.more_attributes && Object.keys(hp?.more_attributes)?.sort((a, b) => {
                                    // Wenn beide Werte Zahlen sind, sortiere sie numerisch
                                    if (typeof a === 'number' && typeof b === 'number') {
                                        return a - b;
                                    }
                                    
                                    // Wenn beide Werte Strings sind, sortiere sie alphabetisch
                                    if (typeof a === 'string' && typeof b === 'string') {
                                        return a.localeCompare(b);
                                    }

                                    // Wenn einer der Werte eine Zahl und der andere ein String ist, sortiere die Zahl vor den String
                                    return typeof a === 'number' ? -1 : 1;
                                    }).map((key, rowIndex) => { */}

                                    {hp?.more_attributes && Object.keys(hp?.more_attributes).map((key, rowIndex) => {

                                    let value = hp.more_attributes[key];

                                    let disabled_keys = ["sigma_z",'offset_mbar',"offset_boiler_room","mbar_per_m","max_distance"]
                                    let disabled_values = ["max_distance"]
                                    let lookup_names = ["sigma_z",'OffsetGeneric',"OffsetBoilerRoom","mBarperm","maxDistance"]

                                    let disabled_key = false;
                                    let disabled_value = false;
                                    let old_key = key;

                                    if(disabled_values.includes(key))disabled_value=true;

                                    if(disabled_keys.includes(key)){
                                        disabled_key=true;

                                        key = t(lookup_names[disabled_keys.indexOf(key)]) ||key
                                    }

                                    return (<tr key={`selected-row-${rowIndex}`}>
                                    <td className="align-middle">
                                        <input className="form-control" type="text" disabled={disabled_key} value={key}  id={old_key} onChange={(e) => handleInputChange(e, "key")} onBlu/>
                                    </td>
                                    <td className="align-middle">
                                        <input className="form-control" type={disabled_key?"number":"text"} disabled={disabled_value}  id={old_key} step={0.1} value={value} onChange={(e) => handleInputChange(e, "value")} />
                                    </td>
                                    <td className="align-middle">
                                        <Button disabled={!allowEdit || disabled_key} variant="outline-primary" className="p-10" style={{ width: "38px" }} onClick={() => deleteRow(old_key)}>
                                            <FontAwesomeIcon icon={faTrash} size='sm' />
                                        </Button>
                                    </td>
                                </tr>)
                                })}
                            </tbody>
                        </Table>
                        <Button disabled={!allowEdit} className="w-100 btn-icon" variant="outline-primary" onClick={() => addRow()}><FontAwesomeIcon icon={faAdd} size='sm' /></Button>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>{t('comment')}</Form.Label>
                        <Form.Control id='comment' type='text' value={hp?.comment || " "} onChange={(e) => changeHandler(e)} />
                    </Form.Group>

                    {/* HP INFO */}
                    <Card {...{ heading: t("heatpart"), className: "mb-3"}}>
                        <Form.Group className='mb-3'>
                            <Form.Label>{t('catalog')}</Form.Label>
                            <Form.Control id='catalog' type='text' value={hp?.catalog || " "} readOnly disabled />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>{t('type')}</Form.Label>
                            <Form.Control id='type' type='text' value={t(type[0]?.label) || " "} readOnly disabled />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>{t('thermal_power')}</Form.Label>
                            <Form.Control id='thermal_power_of_heatpart' type='text' value={hp?.thermal_power_of_heatpart || " "} readOnly disabled />
                        </Form.Group>
                    </Card>

                    {hp.type === 12 && (
                        <>
                            {/* MEASUREMENT POINT */}
                            <Card {...{ heading: t("measurementPoint"), className: "mb-3", ...(allowEdit && { mainFunctions: measurementPointsFunctions })  }}>
                                {(measurementPointsLoaded === false ) ? (
                                    <TablePlaceholder {...{ structure: tableStructureMeasurementPoints }} />
                                ) : (!Boolean(measurementPoints?.length)) ? (
                                    <p className="m-0">{t('noMeasurementPoints')}</p>
                                ) : (
                                    <Table responsive className="mb-3">
                                        <thead>
                                            <tr>
                                                {tableStructureMeasurementPoints.map(th => <th key={`measurementPoint-header-${th.col}`}>{th.col}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {measurementPoints.map(row => <tr key={`measurementPoint-row-${row?.id}`}>
                                                <td className="align-middle">{row?.device_name}</td> 
                                                <td className="align-middle">{row?.measurement_name}</td>
                                                <td className="align-middle">
                                                    <div className='actions__buttonbar grid__two'>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            overlay={
                                                                <Tooltip>{t('edit')}</Tooltip>
                                                            }>
                                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => setShow({ type: "edit_measurementPoint", classification: row })}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            overlay={
                                                                <Tooltip>{t('delete')}</Tooltip>
                                                            }>
                                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => setShow({ type: "delete_measurementPoint", classification: row })}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                )}
                            </Card>

                            {/* CLASSIFICATIONS */}
                            <Card {...{ heading: t("classifications"), className: "mb-3", ...(allowEdit && { mainFunctions }) }}>
                                {(classifications === undefined) ? (
                                    <TablePlaceholder {...{ structure: tableStructure }} />
                                ) : (!Boolean(classifications?.length)) ? (
                                    <p className="m-0">{t('noClassifications')}</p>
                                ) : (
                                    <Table responsive className="mb-3">
                                        <thead>
                                            <tr>
                                                {tableStructure.map(th => <th key={`classification-header-${th.col}`}>{th.col}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {classifications.map(row => <tr key={`classification-row-${row?.id}`}>
                                                <td className="align-middle">{types?.filter((item) => item.value === row?.type)[0]?.name}</td>
                                                <td className="align-middle">{connectionporttypes?.filter((item) => item.id === row?.subtype)[0]?.name}</td>
                                                <td className="align-middle">{row?.group_id}</td>
                                                <td className="align-middle">{timeReadableFormat(row?.created_at)}</td>
                                                <td className="align-middle">{timeReadableFormat(row?.updated_at)}</td>
                                                <td className="align-middle">
                                                    <div className='actions__buttonbar grid__two'>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            overlay={
                                                                <Tooltip>{t('edit')}</Tooltip>
                                                            }>
                                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => setShow({ type: "add/edit", classification: row })}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            overlay={
                                                                <Tooltip>{t('delete')}</Tooltip>
                                                            }>
                                                            <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => setShow({ type: "delete", classification: row })}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                )}
                            </Card>
                        </>
                    )}

                    {/* LOGS */}
                    <Card {...{ heading: t("hpLog"), className: "mb-3", ...(allowEdit && { mainFunctions: logFunctions })  }}>

                        {(logs?.length === undefined) ? (
                            <TablePlaceholder {...{ structure: tableStructure_log }} />
                        ) : (!Boolean(logs?.length)) ? (
                            <p className="m-0">{t('noHPLogs')}</p>
                        ) : (
                            <>
                                <Table responsive className="mb-3">
                                    <thead>
                                        <tr>
                                            {tableStructure_log.map(th => <th key={`log-header-${th.col}`}>{th.col}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems?.map((row, i) => <tr key={`log-row-${row?.id}`}>
                                            <td className="align-middle">{row?.title}</td>
                                            <td className="align-middle">{row?.prename} {row?.name}</td>
                                            <td className="align-middle">{timeReadableFormat(row?.updated_at)}</td>
                                            <td className="align-middle">{timeReadableFormat(row?.created_at)}</td>
                                            <td className="align-middle">
                                                <div className='actions__buttonbar grid__two'>
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        overlay={
                                                            <Tooltip>{t('edit')}</Tooltip>
                                                        }>
                                                        <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {
                                                            setShow({ type: "add/edit_log"});
                                                            setLog(row);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </OverlayTrigger>

                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        overlay={
                                                            <Tooltip>{t('delete')}</Tooltip>
                                                        }>
                                                        <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {
                                                            setShow({ type: "delete_log"});
                                                            setLog(row);
                                                        }}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                                <Pagination
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                    pageCount={pageCount}
                                />
                            </>
                        )}

                    </Card>
                    <Button disabled={!allowEdit} variant="outline-primary" className='w-100' type='submit'>{t('save')}</Button>
                </Form>
            }

            {/* Logs */}
            <ContentModal {...{ show: show?.type === "add/edit_log", onHide: () => {setShow(false); setLog(undefined)}, title: t("addEditHPLog"), content: <AddEditViewLog {...{ close: () => {setShow(false); setLog(undefined)}, item: entry, log: log, hp_id: hp.id, reload: () => getLogs(), autoSaved: false }} />, size: 'xl', onTop: true }} />
            <SecurityActionModal {...{ show: show?.type === "delete_log", onHide: () => {setShow(false); setLog(undefined)}, title: t("deleteHPLog"), question: t("deleteHPLogQuestion"), action: () => deleteLog(log?.id)}} />
            
            {/* Classifications */}
            <ContentModal {...{ show: show?.type === "add/edit", onHide: () => setShow(false), title: t("addEditClassification"), content: <ClassificationsForm {...{ schemaPart: selectedComponent, classific: show?.classification, connectionporttypes, types, onClose: () => {setShow({ type: undefined, classification: undefined }); getClassifications()} }} />, onTop: true }} />
            <SecurityActionModal {...{ show: show?.type === "delete", onHide: () => setShow(false), title: t("deleteClassification"), question: t("deleteClassificationQuestion"), action: () => deleteClassification() }} />

            {/* MeasurementPoint */}
            <ContentModal {...{ show: show?.type === "edit_measurementPoint" || show?.type === "add_measurementPoint", onHide: () => setShow(false), title: t("addEditMeasurementPoint"), content: <MeasurementPointsForm {...{ shw: show.type, hpId: hp.id, exists: Boolean(measurementPoints?.length), measurement: show?.classification, devices, onClose: () => {setShow({ type: undefined, classification: undefined }); getMeasurmentPoints()} }} />, onTop: true }} />
            <SecurityActionModal {...{ show: show?.type === "delete_measurementPoint", onHide: () => setShow(false), title: t("deleteMeasurementPoint"), question: t("deleteMeasurementPointQuestion"), action: () => deleteMeasurementPoint(show?.classification.id) }} />
        </div>
    )
}

const ClassificationsForm = ({ schemaPart, classific, connectionporttypes, types, onClose }) => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const _buildingGuid = useParams()['buildingGuid'];
    const _variantUUID = useParams()['variantID'];
    const _schemaUUID = useParams()['schemaID'];
    const { allowEdit } = usePermissions();

    const [classification, setClassification] = useState(classific || {
        type: 1,
        // measurement: "",
        group_id: "",
        subtype: 1
    }) 

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setClassification((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    }, [classification])

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(classification)
                return req;
            };

            const response = classification?.id ? await client.apis["building"].building_variant_scheme_part_classification_partial_update({building_uuid: _buildingGuid, variant_uuid: _variantUUID, scheme_uuid: _schemaUUID, scheme_part_uuid: schemaPart?.id, scheme_part_classification_uuid: classification?.id}) : await client.apis["building"].building_variant_scheme_part_classification_create({building_uuid: _buildingGuid, variant_uuid: _variantUUID, scheme_uuid: _schemaUUID, scheme_part_uuid: schemaPart?.id })

            if (response.status >= 200 && response.status < 300) {
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, t, addToast, classification]);

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>{t('type')}</Form.Label>
            <Form.Select className="w-100" id="type" value={classification?.type} onChange={(e) => changeHandler(e)}>
                {types.map(type => <option key={`type-key-${type?.value}`} value={type?.value}>{type?.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('connection_type')}</Form.Label>
            <Form.Select className="w-100" id="subtype" value={classification?.subtype} onChange={(e) => changeHandler(e)}>
                {connectionporttypes.map(connectionport_type => <option key={`connectionport_type-key-${connectionport_type?.id}`} value={connectionport_type?.id}>{connectionport_type?.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('group_id')}</Form.Label>
            <Form.Control id='group_id' type='text' value={classification?.group_id} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Button disabled={!allowEdit} variant="outline-primary" className='w-100' type='submit'>{t('save')}</Button>
    </Form>
}

const MeasurementPointsForm = ({ shw, hpId, exists, measurement, devices , onClose }) => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const _buildingGuid = useParams()['buildingGuid'];
    const _variantUUID = useParams()['variantID'];
    const { allowEdit } = usePermissions();
    
    const [measurementpoint, setMeasurementpoint] = useState({
        heatpart: hpId,
        device_id: measurement?.device || devices[0]?.id,
        measurement: measurement?.measurement || devices[0]?.measurements[0]?.uuid
    })

    const [measurements, setMeasurements] = useState(devices[0]?.measurements)

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setMeasurementpoint((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    }, [])

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        let result = {...measurementpoint}

        delete result.device_id

        if (measurement !== undefined) {
            delete result.heatpart
        }

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(result)
                return req;
            };

            const response = measurement !== undefined ? await client.apis["building"].building_variant_heatpart_sensor_partial_update({building_uuid: _buildingGuid, variant_uuid: _variantUUID, heatpart_uuid: hpId, sensor_meas_uuid: measurement.id}) : await client.apis["building"].building_variant_heatpart_sensor_create({building_uuid: _buildingGuid, variant_uuid: _variantUUID, heatpart_uuid: hpId});

            if (response.status >= 200 && response.status < 300) {
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, measurementpoint, measurement, _buildingGuid, _variantUUID, login.Authorization, onClose, addToast, t]);

    useEffect(() => {
        setMeasurements(devices.find(d => d.id === measurementpoint?.device_id)?.measurements)
        setMeasurementpoint(prev => ({
            ...prev,
            device_id: (measurement?.device || devices[0]?.id),
            measurement: measurement?.measurement || devices.find(d => d.id === (devices[0]?.id))?.measurements?.[0]?.uuid
        }))
    }, [measurementpoint.device_id])

    if (!Boolean(devices?.length)) return <p>{t('noDevices')}</p>

    return (
        <>
            {(shw === "add_measurementPoint" && exists) ? (
                <p>{t('measurementPointAlreadyExists')}</p>
            ) : (
                <Form onSubmit={(e) => onSubmit(e)}>
                    <Form.Group className='mb-3'>
                        <Form.Label>{t('devices')}</Form.Label>
                        <Form.Select className="w-100" id="device_id" value={measurementpoint?.device_id} onChange={(e) => changeHandler(e)}>
                            {devices.map(device => <option key={`device-key-${device?.id}`} value={device?.id}>{device?.name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>{t('measurementPoint')}</Form.Label>
                        <Form.Select className="w-100" id="measurement" value={measurementpoint?.measurement} onChange={(e) => changeHandler(e)}>
                            {measurements?.map(measurement => <option key={`measurement-key-${measurement?.uuid}`} value={measurement?.uuid}>{measurement?.name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <Button disabled={!allowEdit} variant="outline-primary" className='w-100' type='submit'>{t('save')}</Button>
                </Form>
            )}
        </>
    ) 
}

export default EditHP