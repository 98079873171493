import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useSwagger } from '../context/SwaggerContext';
import { PricingTableWrapper } from '../components/PricingTable';
import { Form, Button } from "react-bootstrap";
import PaymentMethods, { PaymentComponent } from './PaymentMethods';
import Loading from '../components/Loading';

// const PaymentMethod = ({ _orgGuid, planID, paymentMethod, redirect }) => {
//     const { login } = useAuth()
//     const { t } = useTranslation()
//     const { addToast } = useToast()
//     const [paymentMethods, setPaymentMethods] = useState(undefined);
//     const [defaultPM, setDefaultPM] = useState(paymentMethod)
//     const [disabled, setDisabled] = useState(false)
//     const client = useSwagger();

//     const softwareID = planID?.software_id
//     const getAllPaymentMethods = useCallback(async () => {
//         if (!client) return;

//         const originalRequestInterceptor = client.http.requestInterceptor;

//         try {
//             client.requestInterceptor = (req) => {
//                 req.headers["Content-Type"] = "application/json";
//                 req.headers["Authorization"] = login.Authorization;
//                 return req;
//             };

//             const response = await client.apis[
//                 "payment"
//             ].payment_org_paymentmethods_list({
//                 org_uuid: _orgGuid,
//             });

//             if (response.status >= 200 && response.status < 300) {
//                 console.log("response PAYMENTS", response)
//                 !Boolean(response.obj.results.length) ? addToast(t("error"), t("noPaymentMethodConfiguration"), "error") : setPaymentMethods(response.obj.results)
//                 if (Boolean(response.obj.results.length) && (defaultPM === null || defaultPM === undefined)) setDefaultPM(response.obj.results[0].id)
//             }

//             client.http.requestInterceptor = originalRequestInterceptor;
//         } catch (error) {
//             addToast(t("error"), t("responseError"), "error");
//             client.http.requestInterceptor = originalRequestInterceptor;
//         }
//     }, [client, _orgGuid, login.Authorization, addToast, t, defaultPM]);

//     const onSubmit = useCallback(async (e) => {
//         e.preventDefault();
//         setDisabled(true)
//         if (!client) return;

//         const originalRequestInterceptor = client.http.requestInterceptor;

//         try {
//             client.requestInterceptor = (req) => {
//                 req.headers["Content-Type"] = "application/json";
//                 req.headers["Authorization"] = login.Authorization;
//                 req.body = JSON.stringify({ software: softwareID });
//                 return req;
//             };

//             const response = await client.apis["org"].org_user_assignclicence_create({ org_uuid: _orgGuid, user_uuid: login.id });

//             if (response.status >= 200 && response.status < 300) {
//                 // redirect();
//                 setDisabled(false)
//             }

//             client.http.requestInterceptor = originalRequestInterceptor;
//         } catch (error) {
//             addToast(t("error"), t("responseError"), "error");
//             setDisabled(false)

//             client.http.requestInterceptor = originalRequestInterceptor;
//         }
//     }, [_orgGuid, addToast, client, login.Authorization, login.id, softwareID, t])

//     useEffect(() => {
//         getAllPaymentMethods()
//     }, [getAllPaymentMethods])

//     return <Form onSubmit={(e) => onSubmit(e)}>
//         <Form.Group controlId="organisation-pm" className="mb-3">
//             <Form.Select onChange={(e) => setDefaultPM(e.target.value)} value={defaultPM}>
//                 {paymentMethods?.map(pM => <option value={pM.id} key={`payment-methods-${pM.id}`}>{pM.name}</option>)}
//             </Form.Select>
//         </Form.Group>

//         <div className="d-flex justify-content-end">
//             <Button variant="outline-primary" disabled={disabled} type="submit">{t("submit")}</Button>
//         </div>
//     </Form>
// }

const AddLicence = ({onHide, wizardStep, plan, showOnlySummery, toBuy, increaseQuantity}) => {
    const _orgGuid = useParams()['orgGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const [wizard, setWizard] = useState(showOnlySummery ? 99 : wizardStep ? wizardStep : 0);
    const [plans, setPlans] = useState(undefined);
    const [planID, setPlanID] = useState(plan || undefined)
    const [prices, setPrices] = useState(undefined);
    const client = useSwagger();
    const [selectedLicense, setSelectedLicense] = useState(undefined)
    const [orgHasPaymentMethod, setOrgHasPaymentMethod] = useState(false)

    const getAllLicences = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["org"].org_sw_products_retrieve({
            const response = await client.apis["org"].getBuildingProduct_3({
                org_uuid: _orgGuid,
            });

            if (response.status >= 200 && response.status < 300) {
                setPrices(response.obj?.map((p) => p.price));
                let prices = response.obj.map((p) => ({ ...p, quantity: 1 }));
                setPlans(prices);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("Error:", error);

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization]);

    const getOrganisationPaymentMethods = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["payment"].payment_org_paymentmethods_list({org_uuid: _orgGuid});

            if (response.status >= 200 && response.status < 300) {
                // if (!Boolean(response.obj.results.length)) {
                if (!Boolean(response.obj.length)) {
                    setOrgHasPaymentMethod(false)
                } else {
                    setOrgHasPaymentMethod(true)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization, addToast, t])

    const redirect = useCallback(() => {
        onHide();
    }, [])

    useEffect(() => {
        getAllLicences();
        getOrganisationPaymentMethods();
    }, [])

    return <div>
        {plans === undefined ? (
            <Loading/>
        ) : (
            <>
                {(showOnlySummery) && <BuyOrIncreaseAmountOrderSummary {...{ toBuy, increaseQuantity, onHide }} />}
                {wizard === 0 && <PricingTableWrapper {...{ plans, setPlans, setPlanID, setWizard, setSelectedLicense, redirect: () => redirect(), prices, setPrices }} />}
                {((wizard === 1 && orgHasPaymentMethod) || wizard === 2 ) && <OrderSummary {...{ planID, redirect: () => redirect() }} />}
                {/* {(wizard === 1 && !orgHasPaymentMethod) && <PaymentComponent {...{ cameFromAddLicence: true, planID, onHide: () => redirect() }} />} */}
                {(wizard === 1 && !orgHasPaymentMethod) && <PaymentMethods {...{ cameFromAddLicence: true, planID, onHide: () => redirect() }} />}
            </>

        )}
        {/* {wizard === 0 && <PricingTableWrapper {...{ plans, setPlans, setPlanID, setWizard, setSelectedLicense, redirect: () => redirect(), prices, setPrices }} />}
        {((wizard === 1 && orgHasPaymentMethod) || wizard === 2 ) && <OrderSummary {...{ planID, redirect: () => redirect() }} />}
        {/* {(wizard === 1 && !orgHasPaymentMethod) && <PaymentComponent {...{ cameFromAddLicence: true, planID, onHide: () => redirect() }} />} */}
        {/* {(wizard === 1 && !orgHasPaymentMethod) && <PaymentMethods {...{ cameFromAddLicence: true, planID, onHide: () => redirect() }} />}  */}
    </div>
}

const OrderSummary = ({ planID, redirect }) => {
    const client = useSwagger();
    const { login } = useAuth();
    const _orgGuid = useParams()['orgGuid'];
    const { t } = useTranslation()
    const { addToast } = useToast()

    const softwareID = planID?.software_id
    const quantity = planID.quantity

    const [disabled, setDisabled] = useState(false)

    const onSubmit = useCallback(async (e) => {
        setDisabled(true)
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        const order = {
            software: softwareID,
            count: quantity
        }

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(order);
                return req;
            };

            const response = await client.apis["org"].org_software_post_create({ org_uuid: _orgGuid });

            if (response.status >= 200 && response.status < 300) {
                redirect();
                setDisabled(false)
                addToast(t("success"), t("successSWPackage"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("responseError"), "error");
            setDisabled(false)

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_orgGuid, addToast, client, login.Authorization, quantity, redirect, softwareID, t])

    return (
        <>
          {planID &&
            <>
                <div className="order__summary__wrapper mb-3">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("product")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("quantity")}</th>
                                <th>{t("totalAmount")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-middle">{planID.title} - Software</td>
                                <td className="align-middle">{planID.price} €</td>
                                <td className="align-middle">{planID.quantity}</td>
                                <td className="align-middle">{planID.price * planID.quantity} €</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div className="order__total">
                        <span>Total</span>
                        <span>{planID.price * planID.quantity} €</span>
                    </div>
                
                </div>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" disabled={disabled} onClick={() => onSubmit()} type="submit">{t("submit")}</Button>
                </div>
            </>
        }
      </>
    )
}

const BuyOrIncreaseAmountOrderSummary = ({ toBuy, increaseQuantity, onHide }) => {
    const { login } = useAuth();
    const _orgGuid = useParams()['orgGuid'];
    const client = useSwagger();
    const { t } = useTranslation()
    const { addToast } = useToast()
    const [disabled, setDisabled] = useState(false)

    const buyLicence = useCallback(async () => {
        if (toBuy.length === 0) return 
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        const order = toBuy?.map((item) => ({
            count: item.amount,   
            software: item.software_id     
        }));

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(order);
                return req;
            };

            const response = await client.apis["org"].org_software_post_create({ org_uuid: _orgGuid });

            // if (response.status >= 200 && response.status < 300) {
            //     // redirect();
            //     addToast(t("success"), t("successSWPackage"), "success");
            // }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_orgGuid, addToast, client, login.Authorization, t, toBuy])

    const increaseAmountLicence = useCallback(async () => {
        if (increaseQuantity.length === 0) return 
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        const order = increaseQuantity.map((item) => ({
            quantity: item.amount,  
            software_id: item.software_id     
        }));

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(order);
                return req;
            };

            const response = await client.apis["org"].org_updatelicences_partial_update({ org_uuid: _orgGuid });

            // if (response.status >= 200 && response.status < 300) {
            //     // addToast(t("success"), t("successSWPackage"), "success");
            // }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_orgGuid, addToast, client, increaseQuantity, login.Authorization, t])

    const onSubmit = async () => {
        setDisabled(true);
        
        try {
            await buyLicence();
            await increaseAmountLicence();
            addToast(t("success"), t("successSWPackage"), "success");
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
        } finally {
            try {
                await onHide({submitLicenses: true});
            } catch (error) {
                addToast(t("error"), t("responseError"), "error");
            }
            setDisabled(false);
        }
    };
    
    return (
        <>
            <div className="order__summary__wrapper mb-3">
                <table>
                    <thead>
                        <tr>
                            <th>{t("product")}</th>
                            <th>{t("amount")}</th>
                            <th>{t("price")}</th>
                            <th>{t("totalAmount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {toBuy?.map((item) => {
                            return (
                                <tr key={`tr_toBuy_${item.title}`}>
                                    <td className="align-middle">{item.title} - Software</td>
                                    <td className="align-middle">{item.quantity}</td>
                                    <td className="align-middle">{item.price} €</td>
                                    <td className="align-middle">{item.price * item.quantity} €</td>
                                </tr>
                            )
                        })}

                        {increaseQuantity?.map((item) => {
                            return (
                                <tr key={`tr_increaseQuantity_${item.title}`}>
                                    <td className="align-middle">{item.title} - Software</td>
                                    <td className="align-middle">{item.quantity}</td>
                                    <td className="align-middle">{item.price} €</td>
                                    <td className="align-middle">{item.price * item.quantity} €</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <hr />
            </div>
            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" disabled={disabled} onClick={() => onSubmit()} type="submit">{t("submit")}</Button>
            </div>
      </>
    )
}

export default AddLicence
