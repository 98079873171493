import { useState, useCallback, useEffect, memo } from "react";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import { useParams } from 'react-router-dom';
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";
import { evaluateMathExpression, getChangedFields, isEmpty } from "../util/helpers";
import { useBuilding } from "../context/BuildingContext";

const RadiatorThrottle = ({ heatpart, changeHandler, changeFilterHandler, filters, diameterNominal, defaultFiltersValue }) => {
    const { t } = useTranslation();

    return <>
        <Form.Group className='mb-3'>
            <Form.Label>{t('heatingPartCataloges')}</Form.Label>
            <Form.Select id="throttlecatalog" value={defaultFiltersValue?.throttlecatalog ?? ''} onChange={(e) => changeFilterHandler(e)}>
                {(filters.cataloges ?? [])?.map(h => <option key={`cataloges-${h.id}`} value={h.id}>{h.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t("radiator_valve")}</Form.Label>
            <Form.Select id="radiator_throttle" value={heatpart['radiator_throttle'] ?? ''} onChange={(e) => changeHandler(e)}>
                <option value="unknown">{t('unknown')}</option>
                {filters.throttles?.map(t => <option key={`throttle-key-${t.id}`} value={t.id}>{t.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('architecturalForm')}</Form.Label>
            <Form.Select id="architectural_form" value={heatpart['architectural_form']} onChange={(e) => changeHandler(e)}>
                {(filters.architecturalForms ?? [])?.map(form => <option key={`architectural-form-key-${form.value}`} value={form.value}>{t(form.label?.toLowerCase().replace(/\s+/g, ''))}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('diameterNominal')}</Form.Label>
            <Form.Select id="diameter_nominal" value={heatpart['diameter_nominal']} onChange={(e) => changeHandler(e)}>
                {diameterNominal?.map(DN => <option key={`diameterNominal-${DN.value}`} value={DN.value}>{DN.label}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('distance_to_pump')} (in m)</Form.Label>
            <Form.Control id='distance_to_pump' required type='text' min={0} max={99} step={0.1} value={heatpart['distance_to_pump'] ?? ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>
    </>
}

const HeatingPart = ({ heatpart, setHeatpart, changeHandler, changeFilterHandler, defaultFiltersValue, applyFilter, filters, filteredHeatparts }) => {
    const { t } = useTranslation();

    return <>
        <Form.Group className='mb-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control id='name' required type='text' value={heatpart['name'] ?? ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('heatingPartCataloges')}</Form.Label>
            <Form.Select id="catalog" value={defaultFiltersValue.catalog ?? ''} onChange={(e) => changeFilterHandler(e)}>
                {filters.cataloges?.map(h => <option key={`heatpart-catalog-${h.id}`} value={h.id}>{h.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>Type</Form.Label>
            <Form.Select id="type" value={defaultFiltersValue.type ?? ''} onChange={(e) => changeFilterHandler(e)}>
                {filters.types?.filter(type => (type.value == 2 || type.value == 15)).map(b => <option key={`type-${b.value}`} value={b.value}>{t(b.label)}</option>)}
            </Form.Select>
        </Form.Group>

        {defaultFiltersValue.type == 2 && (
            <>
                <Form.Group className='mb-3'>
                    <Form.Label>{t("subtypes")}</Form.Label>
                    <Form.Select id="subtype" value={defaultFiltersValue.subtype ?? ''} onChange={(e) => { changeFilterHandler(e); applyFilter(e.target.value, defaultFiltersValue.productseries, defaultFiltersValue.flat_panel_type, defaultFiltersValue.depth, defaultFiltersValue.height) }}>
                        {filters.subtypes?.map(type => {
                            return <option key={`subtype-${type.value}`} value={type.value}>{t(`${type.name}`)}</option>
                        })}
                    </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>{t("productseries")}</Form.Label>
                    <Form.Select id="productseries" value={defaultFiltersValue.productseries ?? ''} onChange={(e) => {changeFilterHandler(e); applyFilter(defaultFiltersValue.subtype, e.target.value, defaultFiltersValue.flat_panel_type, defaultFiltersValue.depth, defaultFiltersValue.height)}}>
                        {filters.productseries?.map(serie => {
                            return <option key={`productseries-${serie}-${serie}`} value={serie}>{t(`${serie}`)}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </>
        )}

        {defaultFiltersValue.type == 2 && (defaultFiltersValue.subtype != 1) && (
            <Form.Group className='mb-3'>
                <Form.Label>{t("depth")}</Form.Label>
                <Form.Select id="depth" value={defaultFiltersValue.depth ?? ''} onChange={(e) => {changeFilterHandler(e); applyFilter(defaultFiltersValue.subtype, defaultFiltersValue.productseries, defaultFiltersValue.flat_panel_type, e.target.value, defaultFiltersValue.height)}}>
                    {filters.depths?.map(d => {
                        return <option key={`depth-${d}}`} value={d}>{d}</option>
                    })}
                </Form.Select>
            </Form.Group>
        )}

        {defaultFiltersValue.type == 2 && (defaultFiltersValue.subtype == 1 || defaultFiltersValue.subtype == 2) && (
            <Form.Group className='mb-3'>
                <Form.Label>{t(defaultFiltersValue.subtype == 1 ? "panelRadiatorType" : "length")}</Form.Label>
                <Form.Select id="flat_panel_type" value={defaultFiltersValue.flat_panel_type ?? ''} onChange={(e) => {changeFilterHandler(e); applyFilter(defaultFiltersValue.subtype, defaultFiltersValue.productseries, e.target.value, defaultFiltersValue.depth, defaultFiltersValue.height)}}>
                    {filters.flat_panel_types?.map(f => {
                        return <option key={`flat_panel_type-${f}`} value={f}>{f}</option>
                    })}
                </Form.Select>
            </Form.Group>
        )}

        {defaultFiltersValue.type == 2 && (
            <Form.Group className='mb-3'>
                <Form.Label>{t("height")}</Form.Label>
                <Form.Select id="height" value={defaultFiltersValue.height ?? ''} onChange={(e) => {changeFilterHandler(e); applyFilter(defaultFiltersValue.subtype, defaultFiltersValue.productseries, defaultFiltersValue.flat_panel_type, defaultFiltersValue.depth, e.target.value)}}>
                    {filters.heights?.map(h => {
                        return <option key={`height-${h}`} value={h}>{h}</option>
                    })}
                </Form.Select>
            </Form.Group>
        )}

        <Form.Group className='mb-3'>
            <Form.Label>{t("reference")}</Form.Label>
            <Form.Select id="standard_reference" value={heatpart['standard_reference'] ?? ''} onChange={(e) => changeHandler(e)}>
                {filteredHeatparts?.map(el => {
                    return <option key={`filtered-heatpart-${el.id}`} value={el.id}>{el.name}</option>
                })} 
            </Form.Select>
        </Form.Group>

        {/* {defaultFiltersValue.type === 2 && ( */}
        {(defaultFiltersValue.type == 2 && defaultFiltersValue.subtype != 2) && (
            <Form.Group className='mb-3'>
                {/* <Form.Label>{[2, 3, 4].includes(parseInt(defaultFiltersValue.subtype)) ? t('multiples_standard_ref_power') : t('multiples_standard_ref_power_meter')}</Form.Label> */}
                <Form.Label>{[2, 3, 4].includes(parseInt(defaultFiltersValue.subtype)) ? t('multiples_standard_ref_power') : t('multiples_standard_ref_power_meter')}</Form.Label>
                <Form.Control id='multiples_standard_ref_power' required type='text' step={0.01} value={heatpart['multiples_standard_ref_power'] ?? ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>
        )}

        <Form.Group className='mb-3'>
            <Form.Check id='overbuilt' type='checkbox' checked={heatpart['overbuilt'] ?? false} onChange={(e) => setHeatpart(prev => ({...prev, overbuilt: e.target.checked}))} label={`${t("overbuilt")}?`}/>
            <Form.Label>{t('distance_overbuilt')}</Form.Label>
            <Form.Control id='distance_overbuilt' disabled={!heatpart['overbuilt']} type='number' min={0} max={20} step={1} value={heatpart['distance_overbuilt'] ?? ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('comment')}</Form.Label>
            <Form.Control id='comment' type='textarea' value={heatpart['comment'] ?? ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>
    </>
}


const Strand = ({ heatpart, changeHandler,strands }) => {
    const { t } = useTranslation();

    return <Form.Group className='mb-3'>
        <Form.Label>{t('strand')}</Form.Label>
        <Form.Select id="strand" value={heatpart.strand} onChange={(e) => changeHandler(e)}>
            <option key={`strand-undefined`} value={"unknown"}>{t('noStrandSelected')}</option>
            {(strands ?? [])?.map(h => <option key={`strand-${h.id}`} value={h.id}>{h.name}</option>)}
        </Form.Select>
    </Form.Group>
}

export const AddEditHeatingPart = ({ onClose, room, editHP }) => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()["buildingGuid"];
    const { currentVariantNode } = useBuilding();

    const diameterNominal = [
        {label: 'DN 10 (3/8")', value: 10},
        {label: 'DN 15 (1/2")', value: 15},
        {label: 'DN 20 (3/4")', value: 20},
        {label: 'DN 25 (1")', value: 25},
        {label: 'DN 32 (1 1/4")', value: 32}
    ];

    const initialHeatpart = editHP ? 
        {
            name: editHP.name,
            overbuilt: editHP.distance_overbuilt > 0 ? true : false,
            distance_overbuilt: editHP.distance_overbuilt > 0 ? editHP.distance_overbuilt : '',
            comment: editHP.comment,
            multiples_standard_ref_power: editHP.multiples_standard_ref_power,
            diameter_nominal: editHP.diameter_nominal,
            radiator_throttle: editHP.radiator_throttle?.id,
            standard_reference: editHP.standard_reference?.id,
            depth: editHP.standard_reference?.depth,
            flat_panel_type: editHP.standard_reference?.flat_panel_type,
            height: editHP.standard_reference?.height,
            distance_to_pump: editHP.distance_to_pump,
            architectural_form: editHP.architectural_form,
            strand: editHP.strand?.id,
        } : {
            name: '',
            overbuilt: false,
            distance_overbuilt: '',
            comment: '',
            multiples_standard_ref_power: 1,
            diameter_nominal: diameterNominal[0].value,
            radiator_throttle: '',
            standard_reference: '',
            depth: '',
            flat_panel_type: '',
            height: '',
            distance_to_pump: 5,
            architectural_form: '',
            strand: '',
        }

    const [heatpart, setHeatpart] = useState(initialHeatpart)

    const [manualName, setManualName] = useState(false)
    const [heatparts, setHeatparts] = useState(undefined);
    const [strands, setStrands] = useState([]);
    const [filteredHeatparts, setFilteredHeatparts] = useState(undefined);

    const [filters, setFilters] = useState({
        cataloges: [],
        types: [],
        subtypes: [],
        productseries: [],
        combinations: [],
        depths: [],
        flat_panel_types: [],
        heights: [],
        architecturalForms: [],
        throttles: []
    });

    const [defaultFiltersValue, setDefaultFiltersValue] = useState({
        catalog: editHP ? editHP.catalog : '',
        type: editHP ? editHP.type : '',
        subtype: editHP && editHP.standard_reference ? editHP.standard_reference.id : '',
        productseries: editHP && editHP.standard_reference ? editHP.standard_reference.product_series : '',
        depth: editHP && editHP.standard_reference ? editHP.standard_reference.depth : '',
        flat_panel_type: editHP && editHP.standard_reference ? editHP.standard_reference.flat_panel_type : '',
        height: editHP && editHP.standard_reference ? editHP.standard_reference.height : '',
        throttlecatalog: editHP && editHP.radiator_throttle ? editHP.radiator_throttle.catalog : '',
    })

    const changeFilterHandler = useCallback((e) => {
        const { id, value } = e.target;
        setDefaultFiltersValue(prev => {
            const newState = { ...prev, [id]: value };
            return newState;
        });
    }, [])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setHeatpart(prev => ({ ...prev, [id]: value }));
        if (id === "name")
            setManualName(true)
    }, [heatpart]);

    const getStrands = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_strandreference_list({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                setStrands(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            // addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, login.currentOrganisation.id]);

    const getHPCataloges = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_list({ org_uuid: login.currentOrganisation.id });

            if (response.status >= 200 && response.status < 300) {
                setFilters(prev => ({ ...prev, cataloges: response.obj }));
                setDefaultFiltersValue(prev => ({
                    ...prev,
                    catalog: editHP ? editHP.catalog : "b8d2b1aa-1362-4c9b-a8dc-45a3e98c940a",
                    throttlecatalog: editHP?.radiator_throttle?.catalog || "b8d2b1aa-1362-4c9b-a8dc-45a3e98c940a"
                }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), "THERE WAS AN ISSUE FETCHING THE HP CATALOGES", "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, login.currentOrganisation.id]);

    const getFilterCombinations = useCallback(async(heatparts) => {
        if (!client || !defaultFiltersValue.catalog) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_depth_height_list({
                org_uuid: login.currentOrganisation.id,
                heatpart_catalog_uuid: defaultFiltersValue.catalog
            });

            if (response.status >= 200 && response.status < 300) {
                const uniqueTypesMap = new Map();
                response.obj.forEach(item => {
                    const key = `${item.subtype_info.name}-${item.subtype_info.value}`;
                    if (!uniqueTypesMap.has(key))
                        uniqueTypesMap.set(key, item.subtype_info);
                });
                const uniqueTypes = Array.from(uniqueTypesMap.values());

                const subtype = editHP && editHP.standard_reference ? editHP.standard_reference.subtype : uniqueTypes?.[0]?.value

                const filteredItems = response.obj.filter(item =>
                    item.subtype_info.value == subtype
                );

                const uniqueProductSeries = [...new Set(filteredItems.map(item => item.product_series))];
                const productSeries = editHP && editHP.standard_reference && editHP?.standard_reference?.catalog === defaultFiltersValue.catalog ? editHP.standard_reference.product_series : uniqueProductSeries?.[0]

                const uniqueFlatPanelTypes = [...new Set(filteredItems.filter(f => f.product_series == productSeries).map(item => item.flat_panel_type))];
                const flat_panel_type = editHP && editHP.standard_reference && editHP?.standard_reference?.catalog === defaultFiltersValue.catalog ? editHP.standard_reference.flat_panel_type : uniqueFlatPanelTypes?.[0]

                const uniqueDepths = [...new Set(filteredItems.filter(f => f.product_series == productSeries).map(item => item.depth))];
                const depth = editHP && editHP.standard_reference && editHP.standard_reference?.catalog === defaultFiltersValue.catalog ? editHP.standard_reference.depth : uniqueDepths?.[0]

                const uniqueHeight = (subtype == 1 || subtype == 2) ? [...new Set(filteredItems.filter(f => f.product_series == productSeries && f.flat_panel_type == flat_panel_type).map(item => item.height))] : [...new Set(filteredItems.filter(f => f.product_series == productSeries && f.depth == depth).map(item => item.height))];
                const height = editHP && editHP.standard_reference && editHP.standard_reference?.catalog === defaultFiltersValue.catalog ? editHP.standard_reference.height : uniqueHeight?.[0]

                setFilters(prev => ({
                    ...prev,
                    combinations: response.obj,
                    subtypes: uniqueTypes,
                    productseries: uniqueProductSeries,
                    flat_panel_types: uniqueFlatPanelTypes,
                    depths: uniqueDepths,
                    heights: uniqueHeight
                }));

                setDefaultFiltersValue(prev => ({
                    ...prev,
                    subtype: subtype,
                    productseries: productSeries || '',
                    flat_panel_type: flat_panel_type,
                    depth: depth,
                    height: height
                }));

                const result = heatparts?.find(item => {
                    const flatPanelType = item.flat_panel_type == flat_panel_type;
                    const subtypeMatches = item.subtype == subtype;
                    const depthMatches = item.depth == depth;
                    const heightMatches = item.height == height;
                    return (subtype == 1 || subtype == 2) ? flatPanelType && subtypeMatches && heightMatches : subtypeMatches && depthMatches && heightMatches;
                }) || [];

                setHeatpart(prev => {
                    const newState = { ...prev, standard_reference: result?.id };
                    if (!manualName)
                        newState['name'] = result?.name
                    return newState;
                });
        
                setFilteredHeatparts([result])
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), "THERE WAS AN ISSUE FETCHING THE HP FILTER COMBINATIONS", "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, login.currentOrganisation.id, defaultFiltersValue, editHP, manualName])

    const getAllHP = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_list({
                org_uuid: login.currentOrganisation.id,
                heatpart_catalog_uuid: defaultFiltersValue.catalog
            });

            if (response.status >= 200 && response.status < 300) {
                console.log("RESPONSE: ", response.obj)
                setHeatparts(response.obj)
                getFilterCombinations(response.obj);

                setHeatpart(prev => ({
                    ...prev,
                    depth: response.obj?.[0]?.depth,
                    flat_panel_type: response.obj?.[0]?.flat_panel_type,
                    height: response.obj?.[0]?.height
                }))

            }

            client.http.requestInterceptor = originalRequestInterceptor;
            
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, login.currentOrganisation.id, defaultFiltersValue.catalog, getFilterCombinations]);

    const getTypes = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatingparttypes"].heatingparttypes_list();

            if (response.status >= 200 && response.status < 300) {
                setFilters(prev => ({ ...prev, types: response.obj }));
                setDefaultFiltersValue(prev => ({ ...prev, type: editHP?.type || response.obj?.[1]?.value }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), "THERE WAS AN ISSUE FETCHING THE HP TYPES", "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, login.currentOrganisation.id]);

    const getRadiatorValves = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_list({org_uuid: login.currentOrganisation.id, heatpart_catalog_uuid: defaultFiltersValue.throttlecatalog, type: 8});

            if (response.status >= 200 && response.status < 300) {
                setFilters(prev => ({ ...prev, throttles: response.obj }));
                if (!editHP) setHeatpart(prev => ({ ...prev, radiator_throttle: response.obj?.[0]?.id || 'unknown' }))
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, login.currentOrganisation.id, t, addToast, defaultFiltersValue, editHP]);

    const heatpartValveArchitecturalForm = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartvalvearchitecturalform"].heatpartvalvearchitecturalform_list();

            if (response.status >= 200 && response.status < 300) {
                setFilters(prev => ({ ...prev, architecturalForms: response.obj }));
                if (!editHP)
                    setHeatpart(prev => ({ ...prev, architectural_form: response.obj?.[0].value }))
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), "COUDNT FETCH HP VALVE ARCHITEKTURAL FORM", "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, heatpart, editHP])

    const applyFilter = useCallback((defaultSubtype, defaultProductseries, defaultFlatpaneltype, defaultDepth, defaultHeight) => {
        if (!Boolean(filters.combinations.length)) return

        if (defaultFiltersValue.type == 2) {
            let defaultSubtypeValue = defaultSubtype || 1

            const filteredItems = filters.combinations.filter(item =>
                item.subtype_info.value == defaultSubtypeValue
            );

            const uniqueProductSeries = [...new Set(filteredItems.map(item => item.product_series))];
            const productSeries = uniqueProductSeries.includes(defaultProductseries) ? defaultProductseries : uniqueProductSeries?.[0]

            const uniqueFlatPanelTypes = [...new Set(filteredItems.filter(f => f.product_series == productSeries).map(item => item.flat_panel_type))];
            const flat_panel_type = uniqueFlatPanelTypes.includes(parseInt(defaultFlatpaneltype)) ? defaultFlatpaneltype : uniqueFlatPanelTypes?.[0]

            const uniqueDepths = [...new Set(filteredItems.filter(f => f.product_series == productSeries).map(item => item.depth))];
            const depth = uniqueDepths.includes(parseInt(defaultDepth)) ? defaultDepth : uniqueDepths?.[0]

            const uniqueHeight = (defaultSubtypeValue == 1 || defaultSubtypeValue == 2) ? [...new Set(filteredItems.filter(f => f.product_series == productSeries && f.flat_panel_type == flat_panel_type).map(item => item.height))] : [...new Set(filteredItems.filter(f => f.product_series == productSeries && f.depth == depth).map(item => item.height))];
            const height = uniqueHeight.includes(parseInt(defaultHeight)) ? defaultHeight : uniqueHeight?.[0]

            setFilters(prev => ({
                ...prev,
                productseries: uniqueProductSeries,
                flat_panel_types: uniqueFlatPanelTypes,
                depths: uniqueDepths,
                heights: uniqueHeight
            }));

            setDefaultFiltersValue(prev => ({
                ...prev,
                subtype: defaultSubtypeValue,
                productseries: productSeries || '',
                flat_panel_type: flat_panel_type,
                depth: depth,
                height: height
            }));

            console.log(defaultSubtypeValue)
            console.log(productSeries)
            console.log(flat_panel_type)
            console.log(depth)
            console.log(height)

            const result = heatparts?.find(item => {
                const flatPanelType = item.flat_panel_type == flat_panel_type;
                const subtypeMatches = item.subtype == defaultSubtypeValue;
                const depthMatches = item.depth == depth;
                const heightMatches = item.height == height;

                return (defaultSubtypeValue == 1 || defaultSubtypeValue == 2) ? flatPanelType && subtypeMatches && heightMatches : subtypeMatches && depthMatches && heightMatches;
            }) || [];

            setHeatpart(prev => {
                const newState = { ...prev, standard_reference: result?.id };
                if (!manualName)
                    newState['name'] = `${result?.name}${(result?.subtype !== 1 && result?.flat_panel_type !== 0) ? ", " + t('length') + ": " + result?.flat_panel_type : ""}`
                return newState;
            });

            setFilteredHeatparts([result])
        } else {
            const result = heatparts.find(item => item.type == defaultFiltersValue.type) || [];

            setHeatpart(prev => {
                const newState = { ...prev, standard_reference: result?.id };
                if (!manualName)
                    newState['name'] = `${result?.name}`
                return newState;
            });

            setFilteredHeatparts([result])
        }

    }, [filters.combinations, heatparts, manualName, defaultFiltersValue])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        let copyHP = {...heatpart}

        if (copyHP.strand === "unknown")
            copyHP.strand = null

        if (copyHP.radiator_throttle === "unknown")
            copyHP.radiator_throttle = null

        copyHP.multiples_standard_ref_power = evaluateMathExpression(copyHP.multiples_standard_ref_power)
        copyHP.distance_to_pump = evaluateMathExpression(copyHP.distance_to_pump)

        if (copyHP.distance_overbuilt === '' || !copyHP.distance_overbuilt)
            delete copyHP.distance_overbuilt

        if (!copyHP.multiples_standard_ref_power)
            delete copyHP.multiples_standard_ref_power

        const changedFields = getChangedFields(copyHP, initialHeatpart);
        if (editHP !== undefined && isEmpty(changedFields)) onClose()

        if (!client || (editHP !== undefined && isEmpty(changedFields))) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(editHP ? changedFields : copyHP);
                return req;
            };

            const response = editHP ? await client.apis["building"].building_variant_room_heatpart_partial_update({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room.id, room_heatpart_uuid: editHP?.id }) : await client.apis["building"].building_variant_room_heatpart_create({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room.id });

            if (response.status >= 200 && response.status < 300)
                onClose();

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            if (error.status === 423 && editHP) {
                addToast(t("error"), t("lockedErrorEditCopy"), "error");
            } else if (error.status === 423 && !editHP) {
                addToast(t("error"), t("lockedErrorPost"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast, onClose, editHP, heatpart, currentVariantNode])


    useEffect(() => {
        getHPCataloges();
        getTypes();
        heatpartValveArchitecturalForm();
        getStrands();
    }, [])

    useEffect(() => {
        if (defaultFiltersValue.catalog === '') return
        getAllHP();
    }, [defaultFiltersValue.catalog])

    useEffect(() => {
        if (defaultFiltersValue.type === '') return
        applyFilter();
    }, [defaultFiltersValue.type])

    useEffect(() => {
        if (defaultFiltersValue.throttlecatalog === '') return
        getRadiatorValves()
    }, [defaultFiltersValue.throttlecatalog])

    return <div className="tabs__wrapper">
        <Form onSubmit={(e) => onSubmit(e)}>
            <Tabs defaultActiveKey="heatingparts" className="mb-3">
                <Tab eventKey="heatingparts" title={t(`HeatingPart`)}>
                    <HeatingPart {...{ heatpart, setHeatpart, changeHandler, changeFilterHandler, defaultFiltersValue, applyFilter, filters, filteredHeatparts }} />
                </Tab>

                <Tab eventKey="radiator_valve" title={t("radiator_valve")}>
                    <RadiatorThrottle {...{ heatpart, changeHandler, changeFilterHandler, filters, diameterNominal, defaultFiltersValue }} />
                </Tab>

                <Tab eventKey="strand" title={t("strand")}>
                    <Strand {...{ heatpart, changeHandler,strands}} />
                </Tab>
            </Tabs>
            <Button variant="outline-primary" className='w-100' type='submit'>{ editHP ? t('save') : t('add')}</Button>
        </Form>
    </div>
}

export default AddEditHeatingPart;