import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useAuth } from "../Context";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BuildingProfile from "../dashboard/Widgets/BuildingProfile";
import { useBuilding } from "../context/BuildingContext";
import Events from "../dashboard/Widgets/Events";
import EnergySaving from "../dashboard/Widgets/EnergySaving";
import Consumption from "../dashboard/Widgets/Consumption";
import Climate from "../dashboard/Widgets/Climate";
import BuildingsMetrics from "../dashboard/Widgets/BuildingsMetrics";
import CO2 from "../dashboard/Widgets/CO2";
import Goal from "../dashboard/Widgets/Goal";
import Map from "../dashboard/Widgets/Map";
import { faHardDrive, faGear, faClipboard, faDoorClosed, faHotel, faHouse, faImage, faPerson, faCarBattery, faRectangleList, faSitemap, faFile, faMoneyBill, faHandHoldingDollar, faToolbox, faHouseCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import MainLayout from "../components/MainLayout";
import '../dashboard/style.css'
import Background from "../assets/background_3_3.png";
import { DIN276_main } from "./AddEditPlant"
import { useToast } from "../NotificationsContent";

export const GetSideBarArray = () => {
    const { reload } = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const [sidebarItems, setSidebarItems] = useState([]);
    const { t, i18n } = useTranslation();
    const din276_main = DIN276_main();
    const { variants } = useBuilding();

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    const loadSidebar = useCallback(() => {
        setSidebarItems([
            {
                module: 'other',
                type: 'groupedLinks',
                items: [
                    {
                        type: 'Link',
                        label: t("overview"),
                        icon: faHouse,
                        to: `/building/${_buildingGuid}/dashboard`
                    },
                ]
            },

            {
                type: 'Divider'
            },

            { 
                module: 'module_monitoring_optimization',
                type: 'groupedLinks',
                items: [
                   
                    {
                        type: 'Link',
                        label: t("devices"),
                        icon: faHardDrive,
                        to: `/building/${_buildingGuid}/devices`
                    },
                    {
                        type: 'Link',
                        label: t("alarms"),
                        icon: faTriangleExclamation,
                        to: `/building/${_buildingGuid}/alarms`
                    },
                    {
                        type: 'Link',
                        label: t("counters"),
                        icon: faToolbox,
                        to: `/building/${_buildingGuid}/counters`
                    },
                    {
                        type: 'Link',
                        label: t("energyFlow"),
                        icon: faCarBattery,
                        to: `/building/${_buildingGuid}/energyflow`
                    },
                    {
                        type: 'Link',
                        label: t("buildingLogBook"),
                        icon: faClipboard,
                        to: `/building/${_buildingGuid}/log`
                    },
                ]
            },

            {
                type: 'Divider'
            },

            {
                module: 'module_hydraulic_balance',
                type: 'groupedLinks',
                items: [
                    {
                        type: 'Link',
                        label: t("variants"),
                        icon: faRectangleList,
                        to: `/building/${_buildingGuid}/variants`
                    },
                    {
                        type: 'TreeItem',
                        icon: faHouseCircleCheck,
                    },
                    {
                        type: 'Link',
                        label: t("usageUnits"),
                        icon: faDoorClosed,
                        to: `/building/${_buildingGuid}/units`,
                        disabled: isEmptyObject(variants)
                    },
                    {
                        type: 'Link',
                        label: t("buildingParts"),
                        icon: faHotel,
                        to: `/building/${_buildingGuid}/level`,
                        disabled: isEmptyObject(variants)
                    },
                    {
                        type: 'Link',
                        label: t("heatparts"),
                        icon: faSitemap,
                        to: `/building/${_buildingGuid}/heatparts`,
                        disabled: isEmptyObject(variants)
                    },
                    {
                        type: 'Link',
                        label: `${t('subsidiesCatalog')}`,
                        icon: faMoneyBill,
                        to: `/building/${_buildingGuid}/subsidiescatalog`,
                    }, {
                        type: 'Link',
                        label: t('building_actions'),
                        icon: faHandHoldingDollar,
                        to: `/building/${_buildingGuid}/actions`,
                    }
                ]
            },

            {
                type: 'Divider'
            },

            {
                module: 'other',
                type: 'groupedLinks',
                items: [   
                    {
                        type: 'Link',
                        label: t("contactPerson"),
                        icon: faPerson,
                        to: `/building/${_buildingGuid}/contacts`
                    },
                    {
                        type: 'Link',
                        label: t("documents"),
                        icon: faFile,
                        to: `/building/${_buildingGuid}/documents`
                    },
                    {
                        type: 'Link',
                        label: t("gallery"),
                        icon: faImage,
                        to: `/building/${_buildingGuid}/pictures`
                    },
                ]
            },

            {
                type: 'Divider'
            },
            
            {
                module: 'other',
                type: 'groupedLinks',
                items: [
                    {
                        type: 'Link',
                        label: t("settings"),
                        icon: faGear,
                        to: `/building/${_buildingGuid}/settings`
                    },
                ]
            }
        ])
    }, [setSidebarItems, _buildingGuid, t, din276_main, variants]);

    useEffect(() => {
    if (variants === undefined) return
        loadSidebar()
    }, [_buildingGuid, i18n.language, reload, variants])

    return _buildingGuid ? sidebarItems : [];
}

export const BuildingDashboard = () => {
    const location = useLocation();
    const { addToast } = useToast();
    const { t } = useTranslation();

    // const moduleLocked = useMemo(() => {
    //     return location.state?.moduleLocked || false;
    // }, [location.state?.moduleLocked]);
    
    // // Debouncing
    // const toastTimeoutRef = useRef(null);
    // const [toastShown, setToastShown] = useState(false);

    // useEffect(() => {
    //     console.log("moduleLocked", moduleLocked)
    //     console.log("toastShown", toastShown)
    //     if (moduleLocked && !toastShown) {
    //         if (toastTimeoutRef.current) {
    //             clearTimeout(toastTimeoutRef.current);
    //         }
    
    //         toastTimeoutRef.current = setTimeout(() => {
    //             addToast(t("error"), t("error"), "error");
    //             setToastShown(true); 
    //         }, 200); 
    //     } else if (!moduleLocked) {
    //         setToastShown(false);
    //     }
    //     return () => {
    //         if (toastTimeoutRef.current) {
    //             clearTimeout(toastTimeoutRef.current);
    //         }
    //     };
    // }, [addToast, moduleLocked, t, toastShown]);
    
    return <MainLayout {...{ background: Background }}>
        <div className="main__content sidebar__padding">
            <div className="grid__column">
                <BuildingProfile />
                {/* <Events {...{ icon: faMaximize, heading: 'Climate Target For 2030', descriptiton: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliau' }} /> */}
                {/* <Goal /> */}
            </div>

            <div className="grid__column">
                <Consumption />
                {/* <Climate {...{ direction: 45, color: '#decdef' }} /> */}
            </div>

            <div className="grid__column">
                <Map />
                {/* <CO2 /> */}
                {/* <EnergySaving /> */}
                {/* <BuildingsMetrics /> */}
            </div>
        </div>
    </MainLayout>
}

export default BuildingDashboard;