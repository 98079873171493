import React, { useState, useEffect, useRef, useCallback } from "react";
import { useToast } from "../../NotificationsContent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleCheck, faPaperPlane, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import WebSocketWorker from '../../websocket/WebsocketWorker';
import { useAuth } from '../../Context';
import { useParams } from 'react-router-dom';
import { useSwagger } from "../../context/SwaggerContext";
import { useTranslation } from 'react-i18next';
import SecurityActionModal from "../SecurityActionModal";
import useWindowSize from "../../hooks/useWindowSize";
import { extractInitials, specificColorGenerator } from "../../util/helpers";
import chatbot from "../../assets/chat-bot.png"
import Loading from "../Loading";

const { v4: uuidv4 } = require('uuid');

const Chat = ({users}) => {
    const client = useSwagger();
    const {addToast} = useToast();
    const {login} = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const chatBoxRef = useRef(null);
    const { t } = useTranslation();
    const [show, setShow] = useState(undefined)
    const [showDeleteMessage, setShowDeleteMessage] = useState(false)

    const [sentMessages, setSentMessages] = useState([]);
    const [receivedMessages, setReceivedMessages] = useState({});
    const [pendingMessage, setPendingMessage] = useState({})
    const [unreadMessages, setUnreadMessages] = useState([])

    // sort
    const allMessages = [...Object.keys(sentMessages)?.map(msg_id => ({ ...sentMessages[msg_id], type: 'sent' })), ...Object.keys(receivedMessages)?.map(msg_id => ({ ...receivedMessages[msg_id], type: 'received' }))];
    const sortedMessages = allMessages.sort((a, b) => new Date(a.date) - new Date(b.date));
    const [newMessage, setNewMessage] = useState('');
    const [userCache, setUserCache] = useState({});
    const [loader, setLoader] = useState(true);

    const formatDateWithoutSeconds = (dateString) => {
        return new Date(dateString).toLocaleString(undefined, { 
            hour: '2-digit', 
            minute: '2-digit', 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit' 
        });
    };

    // long press click
    const [isLongPressed, setIsLongPressed] = useState(false);
    const pressTimer = useRef(null); // Timer-Referenz
    const { width } = useWindowSize();

    // empfangege Nachrichten, welche ins viewport kommen
    const [visibleMessageIds, setVisibleMessageIds] = useState([]);
    const observerRefs = useRef(new Map()); 

    const loadChat = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_chat_list({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                let rMsg = {};
                let sMsg = {};

                response.obj?.map(entry => {
                    if(entry.sender == login.id){
                        sMsg[entry.id]={...entry,date:entry.created_at,sent:true};
                    }
                    else{
                        rMsg[entry.id]={...entry,date:entry.created_at};
                    }
                })

                setReceivedMessages({...rMsg});
                setSentMessages({...sMsg});

                // images
                const uniqueUserIds = new Set();
                response.obj.forEach((entry) => {
                    if (entry.sender && entry.sender !== login.id) uniqueUserIds.add(entry.sender);
                });

                for (let userId of uniqueUserIds) {
                    await profileImage(userId);
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            // addToast(t("error"), t("networkError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setLoader(false)
        }
    }, [client, _buildingGuid, login.Authorization, t])

    const getUnreadMessages = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_chat_unread_list({
                building_uuid: _buildingGuid,
            });


            if (response.status === 204) {
                setUnreadMessages([])
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setUnreadMessages(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, _buildingGuid, login.Authorization, t])

    useEffect(()=> {
        if (loader === false) return
        loadChat()
        getUnreadMessages()
    }, [loader])
    
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            let newsentMessages = {...sentMessages};
            let message = {
                date: new Date().toISOString(),
                message: newMessage, 
                sent: false,
            }
            let id = uuidv4();
            newsentMessages[id]=message;
            setSentMessages({...newsentMessages});
            setPendingMessage(prevMsg => {
                let newmsg = {...prevMsg};
                newmsg[id]=message;
                return newmsg;
            });
            setNewMessage('');
            setShowDeleteMessage(null);
        }
    };

    const handleDeleteMessage  = useCallback(async(messageId) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_message_destroy({
                building_uuid: _buildingGuid,
                message_uuid: messageId
            });

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("deleteMessageSuccessfully"), "success");
                loadChat()
                setShow(undefined)
                setShowDeleteMessage(null);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, addToast, t, loadChat])

    // wird nach langem Drücken ausgeführt
    const handleLongPress = (id, type) => {
        setIsLongPressed(true);
        if (type === "sent") setShowDeleteMessage(id)
    };

    // Start Timer (mousedown)
    const startPressTimer = (id, type) => {
        if (width <= 1024) {
            pressTimer.current = setTimeout(() => handleLongPress(id, type), 800);
        }
    };

    // cancal Timer (onMouseUp oder onMouseLeave)
    const cancelPressTimer = () => {
        clearTimeout(pressTimer.current);
        setIsLongPressed(false);
    };

    // sender img
    const loadProfileImage = useCallback(async(userID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: userID, size: 48 })

            if (response.status >= 200 && response.status < 300 && response.status !== 204)
                // setUserCache((prevCache) => ({ ...prevCache, [userID]: URL.createObjectURL(response.data) }));

            setUserCache((prevCache) => ({
                ...prevCache,
                [userID]: {
                    ...prevCache[userID], 
                    url: URL.createObjectURL(response.data), 
                },
            }));
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization])

    const getUserName = useCallback(async(userID) => {
        if (!client || !login.currentOrganisation) return;
        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["org"].org_user_retrieve({ org_uuid: login.currentOrganisation.id, user_uuid: userID })

            if (response.status >= 200 && response.status < 300 && response.status !== 204)
                setUserCache((prevCache) => ({
                    ...prevCache,
                    [userID]: {
                      ...prevCache[userID],
                      prename: response.obj.prename,
                      name: response.obj.name
                    },
                }));

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            if (error.status === 403) {
                setUserCache((prevCache) => ({
                    ...prevCache,
                    [userID]: {
                      ...prevCache[userID],
                      prename: "",
                      name: ""
                    },
                }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login])

    const profileImage = useCallback(async(userID) => {
        if (!userCache[userID])
            await getUserName(userID);
            await loadProfileImage(userID);
        return userCache[userID];
    }, [userCache])

    useEffect(() => {
        const wsWorker = new WebSocketWorker(login, _buildingGuid, (title, msg, type) => {}, undefined, setReceivedMessages, setSentMessages);
        Object.keys(pendingMessage)?.map(msg_id => {
            let latestMessage = pendingMessage[msg_id];
            if(latestMessage)wsWorker.sendMessage(latestMessage, (response) => {
                // if (response.success) {
                //     loadChat();
                // }
                setPendingMessage(prevMsg => {
                    delete prevMsg[msg_id];
                    return prevMsg;
                })
            });
        });
        
        return () => {
            wsWorker.disconnect();
        };
    }, [pendingMessage]);

    useEffect(() => {
        if (unreadMessages.length === 0 || visibleMessageIds.length === 0) return;
        const matchingMessages = unreadMessages.filter((msg) => visibleMessageIds.includes(msg.message)).map((msg) => msg.message);
        if (matchingMessages.length === 0) return;

        const wsWorker = new WebSocketWorker(login, _buildingGuid, (title, msg, type) => {}, undefined, setReceivedMessages, setSentMessages);

        wsWorker.readMessage(matchingMessages, () => {
            setVisibleMessageIds([])
            setUnreadMessages(unreadMessages.filter((msg) => !visibleMessageIds.includes(msg.message)))
        })
        
        return () => {
            wsWorker.disconnect();
        };
    }, [unreadMessages, visibleMessageIds]);
  
    const observerCallback = useCallback((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const { id, type } = entry.target.dataset;
          if (type === "received" && id && !visibleMessageIds.includes(id)) {
            setVisibleMessageIds((prev) => [...prev, id]);
          }
        }
      });
    }, [visibleMessageIds]);

    // wenn empfangene Nachrichten in den sichtbaren Bereich kommen 
    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, {
            root: null, // Beobachte den Viewport
            rootMargin: "0px",
            threshold: 0.1, // Element muss zu 10% sichtbar sein
      });
  
        // Fügt alle refs hinzu
        observerRefs.current?.forEach((ref) => {
            if (ref instanceof Element) {
                observer.observe(ref);
            } 
        });
        
        return () => {
            observer.disconnect();
        };
    }, [observerCallback, sortedMessages]);

    useEffect(() => {
        // Scrollt nach unten, wenn sich die Nachrichten ändern oder beim ersten Laden
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [sentMessages, receivedMessages, loader]);
    
    return (
        <>
            <div className="chat_container">
                {loader ? (
                    <div className={users ? 'chat_box' : 'chat_box chat_box_no_active_users'} ref={chatBoxRef}>
                        <Loading {...{chat:true}}/>
                    </div>
                ): (
                    <>
                    {((showDeleteMessage && showDeleteMessage?.content !== "onlyOverlay") && (
                        <div className="delete_overlay_container">
                            <div className="delete_overlay_header" key={`delete_overlay_${showDeleteMessage?.id}`}>
                                <div>
                                    <span>
                                        <FontAwesomeIcon style={{fontSize:"15px"}} icon={faTrash} />
                                    </span> 
                                    <span>{t('deleteMessage')}?</span>
                                    <button className="delete_button" onClick={() => setShow({content: "deleteMessage", messageID: showDeleteMessage})}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </button>

                                    <button className="close_button" onClick={(e) => {
                                        e.stopPropagation(); // verhindert, dass der Klick auf das "X" das Overlay erneut öffnet
                                        setShowDeleteMessage(null);
                                    }}>

                                        <FontAwesomeIcon icon={faXmark} /> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={users ? 'chat_box' : 'chat_box chat_box_no_active_users'} ref={chatBoxRef}>
                        {sortedMessages.map((msg, index) => (
                            <div className={"chat_message_sent_wrapper"} key={`chat_message_sent_${index}`}>
                                {/* delete sent message */}
                                {msg.type === 'sent' && (
                                    <div className={"chat_message_delete_div"}><FontAwesomeIcon icon={faTrash} className={"chat_message_delete_icon"} onClick={() => {setShow({content: "deleteMessage", messageID: msg.id}); setShowDeleteMessage({id: msg.id, content: "onlyOverlay"})}} /></div>
                                )}

                                {msg.type == "received" && Object.keys(userCache).length > 0 && msg?.sender && 
                                    <div className="d-flex align-items-end" style={{marginBottom: "10px"}}>
                                        {/* Bild soll nur auftauchen, wenn der Sender nicht bei den nächsten Nachricht gleich ist */}
                                        {userCache[msg?.sender]?.url && sortedMessages[index + 1]?.sender !== msg?.sender && <img className="author__img" src={(userCache[msg?.sender].url)} alt='' />}
                                        {/* Wenn kein Bild vorhanden, dann sollen die initials genommen werden */}
                                        {userCache[msg?.sender]?.url === undefined && sortedMessages[index + 1]?.sender !== msg?.sender && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(userCache[msg?.sender]?.prename + ' ' + userCache[msg?.sender]?.name, true) }}>
                                            <span className="initials" style={{ color: specificColorGenerator(userCache[msg?.sender]?.prename + ' ' + userCache[msg?.sender]?.name, false) }}>{extractInitials(userCache[msg?.sender]?.prename + ' ' + userCache[msg?.sender]?.name)}</span>
                                        </div>}

                                        {/* Das Bild soll nicht wiederholt auftauchen, wenn die nächste Nachricht vom gleichen Absender ist, aber es soll als div den Platz einnehmen */}
                                        {sortedMessages[index + 1]?.sender === msg?.sender && <div className="log__profile">
                                            <span className="initials"/>
                                        </div>}
                                    </div>
                                }

                                {/* autoamtische Nachrichten */}
                                {/* Bild soll nur auftauchen, wenn der Sender nicht bei den nächsten Nachricht gleich ist */}
                                {msg.type == "received" && msg.sender === null && sortedMessages[index + 1]?.sender !== msg?.sender && 
                                    <div className="d-flex align-items-end" style={{marginBottom: "10px"}}>
                                        {<img className="author__img" src={chatbot} alt='' />}
                                    </div>
                                }
                                
                                {/* Das Bild soll nicht wiederholt auftauchen, wenn die nächste Nachricht vom gleichen Absender ist, aber es soll als div den Platz einnehmen */}
                                {msg.type == "received" && msg.sender === null && sortedMessages[index + 1]?.sender === msg?.sender && 
                                    <div className="log__profile">
                                        <span className="initials"/>
                                    </div>
                                }
                                {/* ------------------------- */}

                                <div key={index} ref={(el) => observerRefs.current.set(index, el)} data-id={msg.id} data-type={msg.type} className={`chat_message ${msg.type === 'sent' ? 'sent' : 'received'}`} onMouseDown={() => startPressTimer(msg.id, msg.type)} onMouseUp={cancelPressTimer} onMouseLeave={cancelPressTimer} onTouchStart={() => startPressTimer(msg.id, msg.type)} onTouchEnd={cancelPressTimer}>
                                    {msg.type == "received" && msg?.sender && 
                                        <small className="align-self-start">{`${userCache[msg?.sender]?.prename} ${userCache[msg?.sender]?.name}`}</small>
                                    }

                                    <span>{msg.message}</span>
                                    <small>{formatDateWithoutSeconds(msg.date)} {msg.sent ? <FontAwesomeIcon icon={faCircleCheck} /> : ""}</small>
                                    {((msg.id && (showDeleteMessage === msg.id || showDeleteMessage?.id === msg.id ) && msg.type === "sent") && (
                                        <div className="delete_overlay"/>
                                    ))}
                                </div> 
                            </div>          
                        ))}
                    </div>
                    <form className="chat_input" onSubmit={handleSendMessage}>
                        <input 
                            type="text" 
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder={t('messagePlaceholder')}
                            className={users ? 'input_with_active_users' : ''}
                        />
                        <button type="submit"><FontAwesomeIcon className='chat_input_icon' icon={faPaperPlane} /></button>
                    </form>
                    </>
                )}
            </div>
            <SecurityActionModal {...{ show: show?.content === "deleteMessage", onHide: () => {setShow(undefined); showDeleteMessage?.id && setShowDeleteMessage(undefined)}, title: t("deleteMessage"), question: t("deleteMessageQuestion"), action: () => handleDeleteMessage(show.messageID) }} />
        </>
      );
    };

export default Chat