import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faInfo, faInfoCircle, faUser } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../Context';
import { useSwagger } from '../../context/SwaggerContext';
import { extractInitials, specificColorGenerator } from "../../util/helpers";
import { useTranslation } from 'react-i18next';
import Chat from '../Messaging/Chat';
import WebSocketWorker from '../../websocket/WebsocketWorker';

const ActiveUsers = ({ buildingGuid }) => {
    const { login, image48, updateProfileImage } = useAuth();
    const wsRef = useRef(undefined)
    const [activeUsers, setActiveUsers] = useState([]);

    const client = useSwagger();
    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleBox = () => {
        setIsExpanded(!isExpanded);
    };

    const loadProfileImage = async(size) => {
        const sizes = [48];
        if (login === null || !sizes.includes(size)) return

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const params = { user_uuid_path: login.id};

            if (size) {
                params.size = size;
            }

            const response = await client.apis["User"].getUserProfilePicture(params);

            if (response.status >= 200 && response.status < 300) {
                if(response.data.size>0)updateProfileImage(size, URL.createObjectURL(response.data))
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (wsRef.current) return
        wsRef.current = new WebSocketWorker(login, buildingGuid, (title, msg, type) => {}, setActiveUsers)
        return () => wsRef.current?.disconnect();
    }, [buildingGuid, login]);

    useEffect(() => {
        if (image48 === false) loadProfileImage(48)
    }, [image48])
    
    return (
        <div className={`active_users_container ${isExpanded ? 'active_users_container_expanded' : ''}`}>
            <div className={`active_users_container_header ${isExpanded ? 'active_users_container_header_expanded' : ''}`} onClick={toggleBox}>
                <div className='profile__img__wrapper author__img' style={{ marginRight: 0, backgroundColor: specificColorGenerator(login?.prename + ' ' + login?.name, true) }}>
                    {image48 && <img className="author__img"  src={image48} />}
                    {image48 === false && <span className="initials" style={{ color: specificColorGenerator(login?.prename + ' ' + login?.name, false) }}>{extractInitials(login?.prename + ' ' + login?.name)}</span>}               
                    <div className='active__div'/>
                </div>
                <span>{t('building_lifecycle_chat')}</span>
                <FontAwesomeIcon className='active_users_container_header_icon' style={{fontSize:"15px"}} icon={isExpanded ? faAngleDown : faAngleUp} />
            </div>
            {isExpanded && (
                activeUsers.length === 0 ? (
                    <div>
                        <div className='users_container users_container_no_active_users'>
                            <div className='users_div'>
                                <span className='no_users_online'>
                                    <FontAwesomeIcon className='no_users_online_icon' style={{fontSize:"15px"}} icon={faUser} />
                                    {t('noUsersOnline')}
                                </span> 
                            </div>
                        </div>
                        <Chat/>
                    </div>
                ) : (
                    <div className={`active_users_container_body`}>
                        <div className='users_container'>
                            {activeUsers.map((user, index) => {
                                return (
                                    <div key={`user_${index}`} className='users_div'>
                                        <span className='active_users_names'>{user.prename} {user.name}</span> 
                                        <div className='profile__img__wrapper author__img author__img__active__users' style={{ marginRight: 0, backgroundColor: specificColorGenerator(user?.prename + ' ' + user?.name, true) }}>
                                            {<span className="initials initials_active_users" style={{ color: specificColorGenerator(user?.prename + ' ' + user?.name, false) }}>{extractInitials(user?.prename + ' ' + user?.name)}</span>}               
                                            <div className='active__div'/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <Chat users={true}/>
                    </div>
                )
            )}
        </div>
    );
}

export default ActiveUsers