import { useState, useRef, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5flow from "@amcharts/amcharts5/flow";
// Import German Language
import am5locales_de_DE from "@amcharts/amcharts5/locales/de_DE";
import { generateRandomId, generateDistinctColors, getMinMax, findNearestObject } from '../util/helpers';
import ContentModal from './ContentModal'
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import VanillaContextMenu from 'vanilla-context-menu';
const LICENCE = process.env.REACT_APP_AMCHART5;

export const ColumnChart = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const visibleScrollbar = props.visibleSB;
    const chartRef = useRef(null);
    const seriesRef = useRef([]);
    const scrollbarSeriesRef = useRef([]);
    const xAxisRef = useRef(null);
    const xAxisSBRef = useRef(null);
    const yAxisRef = useRef([]);
    const yAxisSBRef = useRef([]);
    const intersectedRef = useRef(false);

    const timeUnit = props.interval.timeUnit;
    const count = props.interval.count;
    const timeRange = props.time;
    const colorScheme = props.theme || 'light';
    const colorsFromUser = props.colors;

    const chartID = props.id || generateRandomId(10);
    const data = props.data || [];
    const firstLoad = props.firstLoad;
    const load = props.load;
    const dataToShow = props.dataToShow;

    // Indicator
    let indicatorRef = useRef(null);
    let indicatorLabelRef = useRef(null);
    let hourglassRef = useRef(null);

    let root;
    useLayoutEffect(() => {
        am5.addLicense(LICENCE)

        am5.ready(function () {
            chartRef.current = null;
            seriesRef.current = [];
            scrollbarSeriesRef.current = [];
            xAxisRef.current = null;
            xAxisSBRef.current = null;
            yAxisRef.current = [];
            yAxisSBRef.current = [];
            intersectedRef.current = false;
            
            root = am5.Root.new(chartID, {
                useSafeResolution: false
            });

            // German Language As Default Language
            root.locale = am5locales_de_DE;

            // root.utc = true;

            // Custom Chart Scheme
            const animatedTheme = am5.Theme.new(root);

            // Labels Color, Font Size
            animatedTheme.rule("Label").setAll({
                fill: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                fontSize: "14px"
            });

            // Grid Color, Stroke width
            animatedTheme.rule("Grid").setAll({
                stroke: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                strokeWidth: 1,
                minGridDistance: 50
            });

            root.setThemes([am5themes_Animated.new(root), animatedTheme]);

            // Create the chart
            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false, // Default to false; only enable with Ctrl
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    pinchZoomX: true,
                    layout: root.verticalLayout,
                })
            );
              
            // Set up the cursor for zooming behavior
            let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                    behavior: "zoomXY", // Default behavior is zoom
                })
            );
              
            // Style the cursor lines
            cursor.lineX.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));
            cursor.lineY.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));
              
            // Overlay for instructions when not holding Ctrl
            let overlay = root.container.children.push(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    layer: 100,
                    visible: false,
                })
            );
              
            // Curtain
            overlay.children.push(
                am5.Rectangle.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    fill: am5.color(0x000000),
                    fillOpacity: 0.3,
                })
            );
              
            // Message
            overlay.children.push(
                am5.Label.new(root, {
                    text: t("ctrlScrollZoom"),
                    fontSize: 15,
                    x: am5.p50,
                    y: am5.p50,
                    centerX: am5.p50,
                    centerY: am5.p50,
                })
            );
              
            // Handle wheel events for zooming/panning
            chart.plotContainer.events.on("wheel", function (ev) {
                if (ev.originalEvent.ctrlKey) {
                    ev.originalEvent.preventDefault();
                    chart.set("wheelX", "panX");
                    chart.set("wheelY", "zoomX");
                } else {
                    chart.set("wheelX", "none");
                    chart.set("wheelY", "none");
                    overlay.show();
                    overlay.setTimeout(function () {
                        overlay.hide();
                    }, 800);
                }
            });
              
            // Handle pointerdown to differentiate panning and area selection
            chart.plotContainer.events.on("pointerdown", function (ev) {
                if (ev.originalEvent.ctrlKey) {
                    ev.originalEvent.preventDefault();
                    chart.set("panX", true); // Enable panning on X-axis
                    chart.set("panY", false); // Adjust as needed
                    cursor.set("behavior", "none"); // Disable zoom selection while panning
                    cursor.set("visible", false); // Hide the cursor to prevent selection
                } else {
                    chart.set("panX", false); // Disable panning
                    cursor.set("behavior", "zoomXY"); // Enable area selection for zoom
                    cursor.set("visible", true); // Show the cursor for zooming
                }
            });
              
            // Prevent context menu when using Ctrl + click
            document.addEventListener("contextmenu", function (ev) {
                if (ev.ctrlKey) {
                    ev.preventDefault(); // Prevent the context menu from opening
                }
            });
              
            // Handle pointerup to reset behavior
            chart.plotContainer.events.on("pointerup", function () {
                chart.set("panX", false); // Reset panning after mouse action
                cursor.set("behavior", "zoomXY"); // Reset to default zoom selection
                cursor.set("visible", true); // Ensure cursor is visible
            });
              
            // Listen for Ctrl key to adjust panning dynamically
            document.addEventListener("keydown", function (ev) {
                if (ev.key === "Control") {
                    chart.set("panX", true); // Enable panning when Ctrl is pressed
                    cursor.set("behavior", "none"); // Disable zoom selection
                    cursor.set("visible", false); // Hide the cursor during panning
                }
            });
              
            document.addEventListener("keyup", function (ev) {
                if (ev.key === "Control") {
                    chart.set("panX", false); // Disable panning when Ctrl is released
                    cursor.set("behavior", "zoomXY"); // Enable zoom selection
                    cursor.set("visible", true); // Show the cursor for selection
                }
            });

            // Indicator
            indicatorRef.current = root.container.children.push(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    layer: 1000,
                    background: am5.Rectangle.new(root, {
                        fill: am5.color(0xffffff),
                        fillOpacity: 0.7
                    })
                })
            );

            indicatorLabelRef.current = am5.Label.new(root, {
                text: t("loading"),
                fontSize: 25,
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50
            })

            indicatorRef.current.children.push(indicatorLabelRef.current);

            let hourglass = indicatorRef.current.children.push(am5.Graphics.new(root, {
                width: 32,
                height: 32,
                fill: am5.color(0x000000),
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50,
                dy: -45,
                svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
            }));

            hourglassRef.current = hourglass.animate({
                key: "rotation",
                to: 180,
                loops: Infinity,
                duration: 2000,
                easing: am5.ease.inOut(am5.ease.cubic)
            });

            // Create X-Axis
            let xAxis = chart.xAxes.push(
                am5xy.GaplessDateAxis.new(root, {
                    baseInterval: {
                        timeUnit: timeUnit,
                        count: count
                    },
                    min: timeRange?.from,
                    max: timeRange?.to,
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {})
                })
            );

            xAxis.children.push(
                am5.Label.new(root, {
                    text: t('date'),
                    x: am5.p50,
                    centerX: am5.p50
                })
            );

            xAxis.get("dateFormats")["day"] = "dd-MMM-yyyy";

            xAxisRef.current = xAxis;

            // Scrollbar Chart
            let scrollbar;
            let sbDateAxis;
            let sbValueAxis;
            if (visibleScrollbar) {
                scrollbar = chart.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
                    background: am5.Rectangle.new(root, {
                        fill: am5.color(0x171717),
                        fillOpacity: 0.2
                    }),
                    orientation: "horizontal",
                    height: 60
                }));

                sbDateAxis = scrollbar.chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        baseInterval: {
                            timeUnit: timeUnit,
                            count: count
                        },
                        min: timeRange?.from,
                        max: timeRange?.to,
                        renderer: am5xy.AxisRendererX.new(root, {})
                    })
                );

                sbValueAxis = scrollbar.chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );

                xAxisSBRef.current = sbDateAxis;
            }

            const createScrollbarSeries = (dimension, data, type) => {
                let sbSeries;
                if (type === 'Line') {
                    sbSeries = scrollbar.chart.series.push(
                        am5xy.LineSeries.new(root, {
                            valueYField: dimension,
                            valueXField: "date",
                            xAxis: sbDateAxis,
                            yAxis: sbValueAxis
                        })
                    );

                    // Line Width
                    sbSeries.strokes.template.setAll({
                        strokeWidth: 3
                    });
                } else {
                    sbSeries = scrollbar.chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            valueYField: dimension,
                            valueXField: "date",
                            xAxis: sbDateAxis,
                            yAxis: sbValueAxis
                        })
                    );
                }
                sbSeries.data.setAll(data);
                sbSeries.hide();
                scrollbarSeriesRef.current.push(sbSeries);
            }

            // Create Y-axis
            let yAxis;
            const createYAxis = (dimension, min, max) => {
                yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {}),
                    min: min,
                    max: max
                }));

                yAxis.children.unshift(
                    am5.Label.new(root, {
                        rotation: -90,
                        text: dimension,
                        y: am5.p50,
                        centerX: am5.p50,
                    })
                );
            }

            const createSeries = (name, dimension, data, color, unit, chartType) => {
                let series;

                if (chartType === 'Line') {
                    series = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: dimension,
                            valueXField: "date",
                            connect: true,
                            tooltip: am5.Tooltip.new(root, {
                                labelText: `{valueY} ${unit} {name}`
                            }),
                            minBulletDistance: 50,
                            stroke: am5.color(color),
                            fill: am5.color(color)
                        })
                    );

                    // Bullets
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Circle.new(root, {
                                radius: 5,
                                fill: series.get("fill")
                            })
                        });
                    });

                    // Line Width
                    series.strokes.template.setAll({
                        strokeWidth: 1
                    });
                } else {
                    series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: dimension,
                            valueXField: "date",
                            connect: true,
                            tooltip: am5.Tooltip.new(root, {
                                labelText: `{valueY} ${unit} {name}`
                            }),
                            minBulletDistance: 50,
                            stroke: am5.color(color),
                            fill: am5.color(color)
                        })
                    );
                }

                if (visibleScrollbar) createScrollbarSeries(dimension, data, chartType);

                series.on("visible", function (visible, target) {
                    scrollbarSeriesRef.current.forEach(item => {
                        if (item._settings.valueYField === dimension) {
                            !visible ? item.hide() : item.show()
                        }
                    })

                    for (let i = 0; i < yAxisRef.current.length; i++) {
                        let usedQuantities = {
                            min: Number.MAX_VALUE,
                            max: Number.MIN_VALUE
                        };

                        let seriesPerAxis = yAxisRef.current[i].series;

                        for (let j = 0; j <= seriesPerAxis.length; j++) {

                            if (j === seriesPerAxis.length && j !== 0 && usedQuantities.min !== Number.MAX_VALUE) {
                                yAxisRef.current[i].setAll({
                                    min: usedQuantities.min,
                                    max: usedQuantities.max
                                })
                                if (visibleScrollbar) {
                                    yAxisSBRef.current[i].setAll({
                                        min: usedQuantities.min,
                                        max: usedQuantities.max
                                    })
                                }
                                usedQuantities.min = Number.MAX_VALUE;
                                usedQuantities.max = Number.MIN_VALUE;
                            }

                            if (j === seriesPerAxis.length) continue;

                            if (seriesPerAxis[j].isVisible() && seriesPerAxis[j]._dataItems[0]?.dataContext) {
                                let keys = Object.keys(seriesPerAxis[j]._dataItems[0]?.dataContext);
                                let array = seriesPerAxis[j]._dataItems.map(p => p?.dataContext[keys[1]]);

                                usedQuantities.min = Math.min(...[usedQuantities.min, ...array])
                                usedQuantities.max = Math.max(...[usedQuantities.max, ...array])
                            }
                        }

                        let visible = false;
                        seriesPerAxis.forEach(series => {
                            if (series.isVisible()) visible = true;
                        });

                        yAxisRef.current[i].setAll({
                            visible: visible,
                            tooltip: visible ? am5.Tooltip.new(root, {}) : undefined
                        })
                    }
                });

                series.data.setAll(data);
                series.hide();
                seriesRef.current.push(series);
            }

            const totalDataCount = data?.reduce((count, obj) => count + obj.data.length, 0);
            const colors = colorsFromUser ? colorsFromUser : generateDistinctColors(totalDataCount);
            let usedQuantities = [];
            let counter = 0;

            data.forEach(d => {
                if (usedQuantities.indexOf(d["dimension"]) === -1) {
                    createYAxis(d["dimension"], d["min"], d["max"]);
                    usedQuantities.push(d["dimension"]);
                    yAxisRef.current.push(yAxis);
                }

                for (var j = 0; j < d["name"].length; j++) {
                    let name = d["name"][j];
                    let keyPoint = name.replace(/\s+/g, '').toLowerCase();
                    createSeries(name, keyPoint, d.data[j], colors[counter], d["unit"], d.type[j]);
                    counter++;
                }
            })

            // Add legend
            let legend = chart.children.push(
                am5.Legend.new(root, {
                    x: am5.percent(50),
                    centerX: am5.percent(50),
                    strokeField: am5.color(0xffffff),
                    layout: am5.GridLayout.new(root, {
                        maxColumns: 4,
                        fixedWidthGrid: true
                    })
                })
            );

            legend.data.setAll(chart.series.values);

            // Create cursor
            // let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            //     behavior: "zoomXY"
            // }));
            // cursor.lineX.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));
            // cursor.lineY.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));

            // Pointer Move Event
            // cursor.events.on("cursormoved", function(ev) {
            //     let x = ev.target.getPrivate("positionX");
            //     let dateX = xAxis.positionToDate(x);
            //     data.forEach(elem => {
            //         elem.data.forEach(dataSet => {
            //             console.log(findNearestObject(dataSet, Date.parse(dateX)))
            //         })
            //     })
            // });

            // Hide Zoom Out Button
            // chart.zoomOutButton.set("forceHidden", true);

            // Chart Animation on Intersection
            chartRef.current = chart;
            chart.appear();
        });

        return () => root.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstLoad]);

    useEffect(() => {
        // Animate Series

        if (load) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !intersectedRef.current) {
                        intersectedRef.current = true;
                        seriesRef.current.forEach((item) => {
                            item.show()
                            item.appear(1000, 500)
                        });
                        scrollbarSeriesRef.current.forEach((item) => {
                            item.show()
                            item.appear(1000, 500)
                        });
                    }
                });
            }, {
                root: null,
                threshold: 0.5,
            });
            observer.observe(document.querySelector(`#${chartID}`));
        }

        // Indicator
        if (indicatorRef.current !== null) {
            if (dataToShow) {
                indicatorRef.current.hide();
            } else {
                indicatorLabelRef.current.set("text", load ? t("noDataAvailable") : t("loading"))
                indicatorRef.current.show();
                load ? hourglassRef.current.stop() : hourglassRef.current.play()
                // Clean Series from previous data
                let totalIndex = 0;
                data.forEach((obj, index) => {
                    const dataArr = obj.data;
                    dataArr.forEach((item, itemIndex) => {
                        seriesRef.current[totalIndex].data.setAll([])
                        if (visibleScrollbar) scrollbarSeriesRef.current[totalIndex].data.setAll([])
                        totalIndex++;
                    });
                });
            }
        }

        if (dataToShow) {
            let totalIndex = 0;

            data.forEach((obj, index) => {
                const dataArr = obj.data;
                dataArr.forEach((item, itemIndex) => {
                    seriesRef.current[totalIndex]?.data.setAll(item)
                    if (visibleScrollbar) scrollbarSeriesRef.current[totalIndex].data.setAll(item)
                    totalIndex++;
                });
            });

            xAxisRef.current.setAll({
                baseInterval: {
                    timeUnit: timeUnit,
                    count: count
                },
                min: timeRange?.from,
                max: timeRange?.to
            });

            if (visibleScrollbar) {
                xAxisSBRef.current.setAll({
                    baseInterval: {
                        timeUnit: timeUnit,
                        count: count
                    },
                    min: timeRange?.from,
                    max: timeRange?.to
                });
            }

            // Automatically set [min, max] yAxes
            for (let i = 0; i < yAxisRef.current.length; i++) {
                let usedQuantities = {
                    min: Number.MAX_VALUE,
                    max: Number.MIN_VALUE
                };

                let seriesPerAxis = yAxisRef.current[i].series;

                for (let j = 0; j <= seriesPerAxis.length; j++) {

                    if (j === seriesPerAxis.length && j !== 0 && usedQuantities.min !== Number.MAX_VALUE) {
                        yAxisRef.current[i].setAll({
                            min: usedQuantities.min,
                            max: usedQuantities.max
                        })
                        if (visibleScrollbar) {
                            yAxisSBRef.current[i].setAll({
                                min: usedQuantities.min,
                                max: usedQuantities.max
                            })
                        }
                        usedQuantities.min = Number.MAX_VALUE;
                        usedQuantities.max = Number.MIN_VALUE;
                    }

                    if (j === seriesPerAxis.length) continue;

                    if (seriesPerAxis[j].isVisible() && seriesPerAxis[j]._dataItems[0]?.dataContext) {
                        let keys = Object.keys(seriesPerAxis[j]._dataItems[0]?.dataContext);
                        let array = seriesPerAxis[j]._dataItems.map(p => p?.dataContext[keys[1]]);

                        usedQuantities.min = Math.min(...[usedQuantities.min, ...array])
                        usedQuantities.max = Math.max(...[usedQuantities.max, ...array])
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load, data, dataToShow])
    

    

    useImperativeHandle(ref, () => ({
        zoomInit() {
            chartRef.current.zoomOut()
        }
    }));

    return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>
})

export const ScatterPlotChart = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const visibleScrollbar = props.visibleSB;
    const chartRef = useRef(null);
    const seriesRef = useRef([]);
    const llSeriesRef = useRef([])
    const scrollbarSeriesRef = useRef([]);
    const scrollbarLLSeriesRef = useRef([])
    const xAxisRef = useRef(null);
    const yAxisRef = useRef([]);
    const legendRef = useRef(null);

    const colorScheme = props.theme || 'light';

    const chartID = props.id || generateRandomId(10);
    const data = props.data || {};
    const firstLoad = props.firstLoad;
    const load = props.load;
    const dataToShow = props.dataToShow;

    // Indicator
    let indicatorRef = useRef(null);
    let indicatorLabelRef = useRef(null);
    let hourglassRef = useRef(null);


    useLayoutEffect(() => {
        let root;

        seriesRef.current = [];
        llSeriesRef.current = [];
        scrollbarSeriesRef.current = [];
        scrollbarLLSeriesRef.current = [];

        am5.addLicense(LICENCE)

        am5.ready(function () {
            
            root = am5.Root.new(chartID, {
                useSafeResolution: false
            });

            // German Language As Default Language
            root.locale = am5locales_de_DE;

            root.utc = true;

            // Custom Chart Scheme
            const animatedTheme = am5.Theme.new(root);

            // Labels Color, Font Size
            animatedTheme.rule("Label").setAll({
                fill: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                fontSize: "14px"
            });

            // Grid Color, Stroke width
            animatedTheme.rule("Grid").setAll({
                stroke: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                strokeWidth: 1,
                minGridDistance: 50
            });

            root.setThemes([am5themes_Animated.new(root), animatedTheme]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                    layout: root.verticalLayout
                })
            );

            // Zoom In & Out using CTRL + Wheel
            let overlay = root.container.children.push(am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                layer: 100,
                visible: false
            }));

            // Curtain
            overlay.children.push(am5.Rectangle.new(root, {
                width: am5.p100,
                height: am5.p100,
                fill: am5.color(0x000000),
                fillOpacity: 0.3
            }));

            // Message
            overlay.children.push(am5.Label.new(root, {
                text: t('ctrlScrollZoom'),
                fontSize: 15,
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50
            }));

            chart.plotContainer.events.on("wheel", function (ev) {
                if (ev.originalEvent.ctrlKey) {
                    ev.originalEvent.preventDefault();
                    chart.set("wheelX", "panX");
                    chart.set("wheelY", "zoomX");
                } else {
                    chart.set("wheelX", "none");
                    chart.set("wheelY", "none");
                    overlay.show();
                    overlay.setTimeout(function () {
                        overlay.hide()
                    }, 800);
                }
            });

            // Indicator
            indicatorRef.current = root.container.children.push(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    layer: 1000,
                    background: am5.Rectangle.new(root, {
                        fill: am5.color(0xffffff),
                        fillOpacity: 0.7
                    })
                })
            );

            indicatorLabelRef.current = am5.Label.new(root, {
                text: t("loading"),
                fontSize: 25,
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50
            })

            indicatorRef.current.children.push(indicatorLabelRef.current);

            let hourglass = indicatorRef.current.children.push(am5.Graphics.new(root, {
                width: 32,
                height: 32,
                fill: am5.color(0x000000),
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50,
                dy: -45,
                svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
            }));

            hourglassRef.current = hourglass.animate({
                key: "rotation",
                to: 180,
                loops: Infinity,
                duration: 2000,
                easing: am5.ease.inOut(am5.ease.cubic)
            });

            // Create X-Axis
            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {}),
                    min: -12,
                    max: 20,
                })
            );

            let xAxisLabel = am5.Label.new(root, {
                text: data?.axes[0],
                x: am5.p50,
                centerX: am5.p50
            })

            xAxis.children.push(
                xAxisLabel
            );

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            }));

            let yAxisLabel = am5.Label.new(root, {
                rotation: -90,
                text: data?.axes[1],
                y: am5.p50,
                centerX: am5.p50
            })

            yAxis.children.unshift(
                yAxisLabel
            );

            xAxisRef.current = xAxisLabel;
            yAxisRef.current = yAxisLabel;

            let scrollbar;
            let sbXAxis;
            let sbYAxis;
            if (visibleScrollbar) {
                scrollbar = chart.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
                    orientation: "horizontal",
                    height: 60
                }));

                sbXAxis = scrollbar.chart.xAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        min: -12,
                        max: 20,
                    })
                );

                sbYAxis = scrollbar.chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );
            }

            function createScatterSBSeries(field, data) {
                var sbSeries = scrollbar.chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: field,
                        valueYField: "y",
                        valueXField: "x",
                        xAxis: sbXAxis,
                        yAxis: sbYAxis,
                        connect: false
                    })
                );

                // Bullets
                sbSeries.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        sprite: am5.Circle.new(root, {
                            radius: 1,
                            fill: sbSeries.get("fill")
                        })
                    });
                });

                // Hide Lines
                sbSeries.strokes.template.set("strokeOpacity", 0);
                sbSeries.data.setAll(data);
                scrollbarSeriesRef.current.push(sbSeries);
            }

            function createScatterSeries(name, data, color) {
                var series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}"
                        }),
                        stroke: am5.color(color),
                        fill: am5.color(color)
                    })
                );

                // Bullets
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        sprite: am5.Circle.new(root, {
                            radius: 2,
                            fill: series.get("fill")
                        })
                    });
                });

                // Hide Lines
                series.strokes.template.set("strokeOpacity", 0);

                // Create and manage the series on scrollbar
                if (visibleScrollbar) createScatterSBSeries("scatter", data);
                series.on("visible", function (visible, target) {
                    var length = scrollbarSeriesRef.current.length;
                    for (var i = 0; i < length; i++) {
                        if (scrollbarSeriesRef.current[i]._settings.name === "scatter") {
                            scrollbarSeriesRef.current[i].setAll({
                                forceHidden: visible ? false : true
                            });
                            break;
                        }
                    }
                });
                series.data.setAll(data);
                seriesRef.current.push(series);
            }

            function createLLSSBSeries(field, data) {
                let trendSeries = scrollbar.chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: field,
                        valueYField: "y",
                        valueXField: "x",
                        xAxis: sbXAxis,
                        yAxis: sbYAxis,
                    })
                );
                trendSeries.data.setAll(data);
                scrollbarLLSeriesRef.current.push(trendSeries);
            }

            function createLLSSeries(name, data, color) {
                let trendSeries = chart.series.push(am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    stroke: am5.color(color),
                    fill: am5.color(color)
                }));

                // Create an manage the series on scrollbar
                if (visibleScrollbar) createLLSSBSeries("lls", data);
                trendSeries.on("visible", function (visible, target) {
                    var length = scrollbarLLSeriesRef.current.length;
                    for (var i = 0; i < length; i++) {
                        if (scrollbarLLSeriesRef.current[i]._settings.valueYField === "lls") {
                            scrollbarLLSeriesRef.current[i].setAll({
                                forceHidden: visible ? false : true
                            });
                            break;
                        }
                    }
                });
                trendSeries.data.setAll(data);
                llSeriesRef.current.push(trendSeries);
            }

            const colors = generateDistinctColors(2);

            createScatterSeries(t('scatterPlotData'), data.data, colors[0]);
            createLLSSeries(t('derivedFunction'), data.line["data"], colors[1]);

            let a = Boolean(data.line["func"].length) ? parseFloat(((parseFloat(data.line["func"][0]["a"]) * 100) / 100).toFixed(2)) : 0;
            let b = Boolean(data.line["func"].length) ? parseFloat(((parseFloat(data.line["func"][0]["b"]) * 100) / 100).toFixed(2)) : 0;
            let negTwelve = parseFloat((-12 * a + b).toFixed(2));
            let negTen = parseFloat((-10 * a + b).toFixed(2));
            let zero = parseFloat((0 * a + b).toFixed(2));
            let ten = parseFloat((12 * a + b).toFixed(2));
            let twenty = parseFloat((20 * a + b).toFixed(2));
            twenty = b >= 20 ? (twenty - 20).toFixed(2) : twenty;
            let desc = `${t('level')}: ${b}\n${t('slope')}: ${a}\n-12°C: ${negTwelve}\n-10°C: ${negTen}\n0°C: ${zero}\n10°C: ${ten}\n20°C: ${twenty}`;


            let tooltip = am5.Tooltip.new(root, {});
            tooltip.get("background").setAll({
                fill: am5.color(0xeeeeee)
            });


            let legend = chart.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                useDefaultMarker: true,
                layout: am5.GridLayout.new(root, {
                    maxColumns: 2,
                    fixedWidthGrid: true
                })
            }));

            legend.markers.template.setup = function (marker) {
                let check = am5.Graphics.new(root, {
                    fill: am5.color(0xFFFFFF),
                    fillOpacity: 1,
                    width: 20,
                    height: 20,
                    x: 1,
                    y: 1,
                    layer: 50,
                    svgPath: "M7.5 3.83A.67.67 0 1 0 7.5 5.17.67.67 0 1 0 7.5 3.83zM8 10L8 6 6.329 6 6.329 7 7 7 7 10 6 10 6 11 9 11 9 10z"
                });
                marker.children.push(check);
            }

            legend.data.setAll(chart.series.values);


            legend.set("tooltip", tooltip);
            legend.itemContainers._values[1].set("tooltipText", desc);

            legendRef.current = legend;

            // Create cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomXY"
            }));
            cursor.lineX.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));
            cursor.lineY.set("stroke", am5.color(colorScheme === 'light' ? 0xffffff : 0x171717));

            // Hide Zoom Out Button
            // chart.zoomOutButton.set("forceHidden", true);

            // Chart Animation on Intersection
            chartRef.current = chart;
            chart.appear();
        });

        

        return () => root.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstLoad]);

    useEffect(() => {
        if (indicatorRef.current !== null) {
            if (dataToShow) {
                indicatorRef.current.hide();
            } else {
                indicatorLabelRef.current.set("text", load ? t("noDataAvailable") : t("loading"))
                indicatorRef.current.show();
                load ? hourglassRef.current.stop() : hourglassRef.current.play()
            }
        }

        if (!firstLoad || !dataToShow) return;

        console.log(seriesRef.current);

        seriesRef.current[0]?.data.setAll(data.data);
        if (visibleScrollbar) scrollbarSeriesRef.current[0]?.data.setAll(data.data);
        llSeriesRef.current[0]?.data.setAll(data.line.data);
        scrollbarLLSeriesRef.current[0]?.data.setAll(data.line.data);

        let a = Boolean(data.line["func"].length) ? parseFloat(((parseFloat(data.line["func"][0]["a"]) * 100) / 100).toFixed(2)) : 0;
        let b = Boolean(data.line["func"].length) ? parseFloat(((parseFloat(data.line["func"][0]["b"]) * 100) / 100).toFixed(2)) : 0;
        let negTwelve = parseFloat((-12 * a + b).toFixed(2));
        let negTen = parseFloat((-10 * a + b).toFixed(2));
        let zero = parseFloat((0 * a + b).toFixed(2));
        let ten = parseFloat((12 * a + b).toFixed(2));
        let twenty = parseFloat((20 * a + b).toFixed(2));
        let desc = `${t('level')}: ${b}\n${t('slope')}: ${a}\n-12°C: ${negTwelve}\n-10°C: ${negTen}\n0°C: ${zero}\n10°C: ${ten}\n20°C: ${twenty}`;
        legendRef.current?.itemContainers._values[1].set("tooltipText", desc);

        xAxisRef.current?.set("text", data.axes[0])
        yAxisRef.current?.set("text", data.axes[1])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load, data, dataToShow, firstLoad])

    useImperativeHandle(ref, () => ({
        zoomInit() {
            chartRef.current.zoomOut()
        }
    }));

    

    return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>
})


export const MarkerAmChart = (props) => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [range, setRange] = useState({
        x: {
            start: undefined,
            end: undefined
        },
        y: {
            start: undefined,
            end: undefined
        }
    })

    useLayoutEffect(() => {
        am5.addLicense(LICENCE)
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("marker-chart");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.utc = true;

        root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
        });

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelY: "zoomXY",
            pinchZoomX: true,
            pinchZoomY: true
        }));

        chart.get("colors").set("step", 2);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: "day", count: 1 },
            renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "ay",
            valueXField: "date"
        }));

        // Hide strokes
        // series.strokes.template.set("forceHidden", true);

        series.bullets.push(function () {
            var circleTemplate = am5.Template.new({
                radius: 6,
                templateField: "bulletSettings",
                fill: series.get("fill"),
                strokeWidth: 2,
                stroke: root.interfaceColors.get("background")
            })

            var circle = am5.Circle.new(root, {}, circleTemplate);

            return am5.Bullet.new(root, {
                sprite: circle,
                locationX: 0
            });
        });


        // Add bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
        // series.bullets.push(function(root) {
        //     return am5.Bullet.new(root, {
        //         sprite: am5.Circle.new(root, {
        //             fill: series.get("fill")
        //         })
        //     });
        // });

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "selectXY"
        }));

        cursor.selection.setAll({
            fill: am5.color(0xffddee),
            fillOpacity: 0.75
        });

        // function getMousePos(elem, e, contextMenu) {
        //     const rect = elem.getBoundingClientRect();
        //     const contextMenuRect = contextMenu.getBoundingClientRect();
        //     const mouseX = e.clientX - rect.left + contextMenuRect.width;
        //     const mouseY = e.clientY - rect.top + contextMenuRect.height;

        //     // Adjust the context menu position to avoid overflow beyond the viewport
        //     const menuX = Math.min(mouseX, rect.width + contextMenuRect.width);
        //     const menuY = Math.min(mouseY, rect.height - contextMenuRect.height);

        //     return {
        //         x: mouseX,
        //         y: mouseY
        //     };
        // }

        // am5.utils.addEventListener(root.dom, "contextmenu", function(event) {
        //     event.preventDefault();
        //     const contextMenu = document.getElementById('contextMenu');
        //     const contextMenuRect = contextMenu.getBoundingClientRect();
        //     const clickX = event.clientX;
        //     const clickY = event.clientY;
        //     const maxX = window.innerWidth - contextMenuRect.width;
        //     const maxY = window.innerHeight - contextMenuRect.height;

        //     const positionX = Math.min(clickX, maxX);
        //     const positionY = Math.min(clickY, maxY);

        //     contextMenu.style.display = 'block';
        //     contextMenu.style.top = `${positionY}px`;
        //     contextMenu.style.left = `${positionX}px`;
        // });

        // Hide the context menu when clicking anywhere on the document
        // document.addEventListener('click', function () {
        //     document.getElementById('contextMenu').style.display = 'none';
        // });

        cursor.events.on("selectended", function (ev) {

            function swapIfBigger(x1, x2, y1, y2) {
                if (x1 > x2) {
                    const tempX = x1;
                    x1 = x2;
                    x2 = tempX;
                }

                if (y1 > y2) {
                    const tempY = y1;
                    y1 = y2;
                    y2 = tempY;
                }

                return { x1, x2, y1, y2 };
            }

            // Get actors
            var cursor = ev.target;

            // Get selection boundaries
            var xx1 = xAxis.positionToDate(xAxis.toAxisPosition(cursor.getPrivate("downPositionX"))).getTime();
            var xx2 = xAxis.positionToDate(xAxis.toAxisPosition(cursor.getPrivate("positionX"))).getTime();
            var yy1 = yAxis.positionToValue(yAxis.toAxisPosition(cursor.getPrivate("downPositionY")));
            var yy2 = yAxis.positionToValue(yAxis.toAxisPosition(cursor.getPrivate("positionY")));

            const { x1, x2, y1, y2 } = swapIfBigger(xx1, xx2, yy1, yy2);
            setRange({
                x: {
                    start: x1,
                    end: x2
                },
                y: {
                    start: y1,
                    end: y2
                }
            })

            let modData = [...series.data._values];
            let count = 0;
            modData.forEach(item => {
                var date = item.date;
                var ay = item.ay;

                // Check if the data point falls within the selected range
                if (date >= x1 && date <= x2 && ay >= y1 && ay <= y2) {
                    // Set the color of the selected point
                    item.bulletSettings = { fill: am5.color("#970505") }
                    count++
                } else {
                    // Reset the color for points outside the selection
                    if (item.hasOwnProperty('bulletSettings')) {
                        delete item['bulletSettings'];
                    }
                    console.log(item)
                }
            })

            series.data.setAll(modData);
            console.log(`${count / modData.length * 100}%`);
            cursor.selection.hide();
        });

        var data = [
            { date: new Date(2015, 1, 1).getTime(), ay: 6.5 },
            { date: new Date(2015, 1, 2).getTime(), ay: 12.3 },
            { date: new Date(2015, 1, 3).getTime(), ay: 12.3 },
            { date: new Date(2015, 1, 4).getTime(), ay: 2.8 },
            { date: new Date(2015, 1, 5).getTime(), ay: 3.5 },
            { date: new Date(2015, 1, 6).getTime(), ay: 5.1 },
            { date: new Date(2015, 1, 7).getTime(), ay: 6.7 },
            { date: new Date(2015, 1, 8).getTime(), ay: 8 },
            { date: new Date(2015, 1, 9).getTime(), ay: 8.9 },
            { date: new Date(2015, 1, 10).getTime(), ay: 9.7 },
            { date: new Date(2015, 1, 11).getTime(), ay: 10.4 },
            { date: new Date(2015, 1, 12).getTime(), ay: 1.7 }
        ];

        series.data.setAll(data);


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);

        

        return () => root.dispose();
    }, [])

    useEffect(() => {
        // Context Menu
        new VanillaContextMenu({
            scope: document.querySelector('#marker-chart'),
            menuItems: [
                {
                    label: 'Label Selected Points',
                    callback: () => setShow(true),
                    preventCloseOnClick: false,
                    // iconClass: 'fa fa-scissors',
                }
            ],
        });
    }, [])

    const SelectionContent = () => {
        const [label, setLabel] = useState('')

        const submit = (e) => {
            e.preventDefault();
            setShow(false);
        }

        const formatUNIXTime = (unixtime) => {
            const a = new Date(unixtime);
            var year = a.getFullYear();
            var month = a.getMonth() + 1;
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            return `${date}.${month}.${year} ${hour}:${min}:${sec}`;
        }

        return (
            <Form onSubmit={submit}>
                <Form.Group className='mb-3'>
                    <Form.Label>Label</Form.Label>
                    <Form.Control type='text' value={label} onChange={(e) => setLabel(e.target.value)} />
                </Form.Group>
                <Row className='mb-3'>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t('xAxisStart')}</Form.Label>
                            <Form.Control type='text' value={formatUNIXTime(range.x.start)} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t('xAxisEnd')}</Form.Label>
                            <Form.Control type='text' value={formatUNIXTime(range.x.end)} readOnly />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t('yAxisStart')}</Form.Label>
                            <Form.Control type='text' value={parseFloat(range.y.start).toFixed(2)} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t('yAxisEnd')}</Form.Label>
                            <Form.Control type='text' value={parseFloat(range.y.end).toFixed(2)} readOnly />
                        </Form.Group>
                    </Col>
                </Row>
                <div className='d-flex flex-end'>
                    <Button variant='outline-primary' type='submit'>{t('submit')}</Button>
                </div>
            </Form>
        )
    }

    return <>
        <div id="marker-chart" style={{ width: "100%", height: "100%" }}></div>
        <ContentModal {...{ show, onHide: () => setShow(false), title: t('selectLabelDataset'), content: <SelectionContent /> }} />
    </>
}

export const DemographicDiagramm = ({ chartID, live = false }) => {

    function aggregateData(list) {
        let maleTotal = 0;
        let femaleTotal = 0;

        for (var i = 0; i < list.length; i++) {
            let row = list[i];
            maleTotal += row.male;
            femaleTotal += row.female;
        }

        for (var i = 0; i < list.length; i++) {
            let row = list[i];
            row.malePercent = -1 * Math.round((row.male / maleTotal) * 10000) / 100;
            row.femalePercent = Math.round((row.female / femaleTotal) * 10000) / 100;
        }

        return list;
    }

    const [temperatures, setTemperatures] = useState(
        [
                {
                  "flow_temperature": "40-44°C",
                  "flow_percentage": -25.93,
                  "return_temperature": "20-24°C",
                  "return_percentage": 27.82
                },
                {
                  "flow_temperature": "45-49°C",
                  "flow_percentage": -5.35,
                  "return_temperature": "25-29°C",
                  "return_percentage": 12.90
                },
                {
                  "flow_temperature": "50-54°C",
                  "flow_percentage": -22.35,
                  "return_temperature": "30-34°C",
                  "return_percentage": 0.22
                },
                {
                  "flow_temperature": "55-59°C",
                  "flow_percentage": -17.90,
                  "return_temperature": "35-39°C",
                  "return_percentage": 38.67
                },
                {
                  "flow_temperature": "60-64°C",
                  "flow_percentage": -1.34,
                  "return_temperature": "40-44°C",
                  "return_percentage": 0.75
                },
                {
                  "flow_temperature": "65-69°C",
                  "flow_percentage": -0.26,
                  "return_temperature": "45-49°C",
                  "return_percentage": 4.17
                },
                {
                  "flow_temperature": "70-74°C",
                  "flow_percentage": -22.57,
                  "return_temperature": "50-54°C",
                  "return_percentage": 4.51
                },
                {
                  "flow_temperature": "75-79°C",
                  "flow_percentage": -4.30,
                  "return_temperature": "55-59°C",
                  "return_percentage": 10.98
                }
              ]

    );




    useLayoutEffect(() => {
        let root;
        am5.addLicense(LICENCE)
        am5.ready(function () {
            
            root = am5.Root.new(chartID, {
                useSafeResolution: false
            });

            // German Language As Default Language
            root.locale = am5locales_de_DE;

            root.utc = true;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            // Create wrapper container
            let container = root.container.children.push(am5.Container.new(root, {
                layout: root.horizontalLayout,
                width: am5.p100,
                height: am5.p100
            }))

            // Set up formats
            root.numberFormatter.setAll({
                numberFormat: "#.##as"
            });


            // ===========================================================
            // XY chart
            // ===========================================================

            // Create chart
            let chart = container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout,
                width: am5.percent(60)
            }));

            // Create axes
            let yAxis1 = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "flow_temperature",
                renderer: am5xy.AxisRendererY.new(root, {
                    minorGridEnabled: true,
                    minGridDistance: 15
                })
            }));
            yAxis1.get("renderer").grid.template.set("location", 1);
            yAxis1.get("renderer").labels.template.set("fontSize", 12);
            yAxis1.data.setAll(temperatures);

            let yAxis2 = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "return_temperature",
                renderer: am5xy.AxisRendererY.new(root, {
                    opposite: true
                })
            }));
            yAxis2.get("renderer").labels.template.set("fontSize", 12);
            yAxis2.data.setAll(temperatures);

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                min: -50,
                max: 50,
                numberFormat: "#.s'%'",
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 40
                })
            }));

            // Create series
            let maleSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Males",
                xAxis: xAxis,
                yAxis: yAxis1,
                valueXField: "flow_percentage",
                categoryYField: "flow_temperature",
                clustered: false
            }));


            maleSeries.columns.template.setAll({
                tooltipText: "{categoryY} °C: {flow_percentage.formatNumber('#.0s')}%)",
                tooltipX: am5.p100,
                fill: am5.color(0xFFA500), // Orange
                stroke: am5.color(0xFFA500) // Umrandung ebenfalls Orange, optional
            });

            maleSeries.data.setAll(temperatures);

            let femaleSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Return",
                xAxis: xAxis,
                yAxis: yAxis2,
                valueXField: "return_percentage",
                categoryYField: "return_temperature",
                clustered: false
            }));

            femaleSeries.columns.template.setAll({
                tooltipText: "{categoryY}: {return_percentage.formatNumber('#.0s')}%",
                tooltipX: am5.p100
            });

            femaleSeries.data.setAll(temperatures);

        })

        

        return () => root.dispose();
    }, [])


    return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>
}

export const SankeyDiagram = ({ chartID, live = false }) => {

    const [calcData, setCalcData] = useState([
        { to: "WE EG", from: "Haus A", value: 10 },
        { to: "WE 1. OG", from: "Haus A", value: 8 },
        { to: "WE 1. OG", from: "Haus B", value: 4 },
        { to: "WE 2. OG", from: "Haus B", value: 3 },
        { to: "Haus A", from: "Heizkreisverteiler", value: 5 },
        { to: "Haus A", from: "Solarthermie", value: 2 },
        { to: "Haus A", from: "Warmwasserbereitung", value: 3 },
        { to: "Haus B", from: "Warmwasserbereitung", value: 6 },
        { to: "Heizkreisverteiler", from: "Brennwertgerät", value: 5 },
        { to: "Solarthermie", from: "Brennwertgerät", value: 1 },
        { to: "Warmwasserbereitung", from: "Brennwertgerät", value: 9 }
    ]);
    const [liveData, setLiveData] = useState([
        { to: "WE EG", from: "Haus A", value: 50 },
        { to: "WE 1. OG", from: "Haus A", value: 8 },
        { to: "WE 1. OG", from: "Haus B", value: 4 },
        { to: "WE 2. OG", from: "Haus B", value: 3 },
        { to: "Haus A", from: "Heizkreisverteiler", value: 5 },
        { to: "Haus A", from: "Solarthermie", value: 2 },
        { to: "Haus A", from: "Warmwasserbereitung", value: 3 },
        { to: "Haus B", from: "Warmwasserbereitung", value: 6 },
        { to: "Heizkreisverteiler", from: "Brennwertgerät", value: 5 },
        { to: "Solarthermie", from: "Brennwertgerät", value: 1 },
        { to: "Warmwasserbereitung", from: "Brennwertgerät", value: 9 }
    ]);

    useLayoutEffect(() => {
        let root;
        am5.addLicense(LICENCE)
        am5.ready(function () {
            
            root = am5.Root.new(chartID, {
                useSafeResolution: false
            });

            // German Language As Default Language
            root.locale = am5locales_de_DE;

            root.utc = true;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let series = root.container.children.push(am5flow.Sankey.new(root, {
                sourceIdField: "from",
                targetIdField: "to",
                valueField: "value",
                paddingRight: 50
            }));

            series.nodes.get("colors").set("step", 2);

            series.data.setAll(live ? liveData : calcData);

            series.appear(1000, 100);
        })

        

        return () => root.dispose();
    }, [])


    return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>
}

export const ColumnLineMix = ({ chartID, data, x_label = "{name} bei {categoryX} °C: {valueY}", x_axis_title = 'xAxis title', y_label = "kW", y2_label = "days" }) => {

    let colorScheme = 'light'

    useLayoutEffect(() => {
        if (data === undefined) return
        let root;
        am5.addLicense(LICENCE)
        am5.ready(function () {
            root = am5.Root.new(chartID, {
                useSafeResolution: false
            });

            root.locale = am5locales_de_DE;

            root.utc = true;

            const animatedTheme = am5.Theme.new(root);

            animatedTheme.rule("Label").setAll({
                fill: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                fontSize: "14px"
            });

            animatedTheme.rule("Grid").setAll({
                stroke: am5.color(colorScheme === 'light' ? 0xffffff : 0x171717),
                strokeWidth: 1,
                minGridDistance: 50
            });

            root.setThemes([am5themes_Animated.new(root), animatedTheme]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    paddingLeft: 0,
                    layout: root.verticalLayout
                })
            );

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 60
            });


            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "x",
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                })
            );

            xAxis.children.push(am5.Label.new(root, {
                text: x_axis_title,
                textAlign: 'center',
                x: am5.p50,
                // fontWeight: 'bold'
            }));

            xRenderer.grid.template.setAll({
                location: 1
            })

            xAxis.data.setAll(data?.data);

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    extraMax: 0.1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.1
                    })
                })
            );

            yAxis.children.unshift(am5.Label.new(root, {
                text: y_label,
                textAlign: 'center',
                y: am5.p50,
                rotation: -90,
                // fontWeight: 'bold'
            }));


            let seriesCol = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: data['name'],
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    categoryXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: x_label + " " + y_label
                    })
                })
            );


            seriesCol.columns.template.setAll({
                fill: am5.color(0xFFC300), // Rote Farbe für die Füllung
                stroke: am5.color(0xFFC300), // Rote Farbe für die Umrandung
            });

            seriesCol.data.setAll(data.data);

            if (data.data[0]) {
                if ('y_2' in data?.data[0]) {
                    let yAxis2 = chart.yAxes.push(
                        am5xy.ValueAxis.new(root, {
                            min: 0,
                            extraMax: 0.1,
                            renderer: am5xy.AxisRendererY.new(root, {
                                strokeOpacity: 0.1
                            })
                        })
                    );
    
                    yAxis2.children.unshift(am5.Label.new(root, {
                        text: y2_label,
                        textAlign: 'center',
                        y: am5.p50,
                        rotation: -90,
                        // fontWeight: 'bold'
                    }));
    
                    let seriesLine = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            name: data['name2'],
                            xAxis: xAxis,
                            yAxis: yAxis2,
                            valueYField: "y_2",
                            categoryXField: "x",
                            tooltip: am5.Tooltip.new(root, {
                                pointerOrientation: "horizontal",
                                labelText: x_label + " " + y2_label
                            })
                        })
                    );
    
                    seriesLine.data.setAll(data.data);
    
                    seriesLine.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Circle.new(root, {
                                strokeWidth: 3,
                                stroke: seriesLine.get("stroke"),
                                radius: 5,
                                fill: root.interfaceColors.get("background")
                            })
                        });
                    });
                }
            }

            chart.set("cursor", am5xy.XYCursor.new(root, {}));

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                })
            );

            legend.data.setAll(chart.series.values);

            chart.appear(1000, 100);
        })

        

        return () => root.dispose();
    }, [data])

    return <div id={chartID} style={{ width: "100%", height: "100%" }}></div>
}