import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowRight, faBell, faChevronLeft, faChevronRight, faLock } from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/SigmaHeatLogo_lang.svg'
import ToggleIcon from '../assets/Einstellungen_Account_Icon.svg'
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '../components/Collapse'
import NotificationWebsocketWorker from '../websocket/NotificationWebsocketWorker';
import { deleteCookie, extractInitials, specificColorGenerator } from "../util/helpers";
import { useToast } from '../NotificationsContent';
import { useSwagger } from '../context/SwaggerContext';
import { useBuilding } from '../context/BuildingContext';
import ContentModal from '../components/ContentModal';
import { BuyLicence } from '../companyProfile/CompanyUsers';

const GroupedLinksItems = ({ groupedLinks, menuToggle }) => {
    const [show, setShow] = useState(false)
    const { t } = useTranslation();
    const { currentBuilding } = useBuilding();

    const isModuleActive = useCallback((module) => {
        if (currentBuilding === undefined) return
        return currentBuilding[module] || module === "other"
    }, [currentBuilding])

    const { currentVariantNode, variantHistoryStack, goToParent, openChild } = useBuilding();

    return (
        <>
            <div className="grouped_links_wrapper grouped_links_wrapper_mobile">
                {!isModuleActive(groupedLinks.module) && (
                    <div className="lock_icon_div" >
                        <FontAwesomeIcon icon={faLock} className="lock_icon lock_icon_mobile"/>
                        {/* <Button variant="outline-primary" className="p-10 mt-4 lock_button lock_button_mobile" onClick={() => setShow("buyLicence")}>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                            />
                        </Button> */}
                    </div>
                )}
                {groupedLinks.items.map((item, i) => {
                    if (item.type === 'Link') {
                        return (
                            <div
                                key={`sidebar-grouped-element-${i}`}
                                className={!isModuleActive(groupedLinks.module) ? "blured_links" : ""}
                            >
                                <li key={`subnavlink-link-${item.to}`}><Link className='navigation__submenu__item' to={item.to} onClick={() => menuToggle(false)}><FontAwesomeIcon icon={item.icon} /><span>{item.label}</span></Link></li>
                            </div>
                        );
                    }

                    if (item.type === 'TreeItem') {
                        return (
                            <div
                                key={`sidebar-grouped-element-${i}`}
                                className={!isModuleActive(groupedLinks.module) ? "blured_links" : ""}
                            >
                            <React.Fragment key={`rotating-item-${i}`}>
                                <TreeSidebar key={`tree-item-${i}`} {...{ currentVariantNode, variantHistoryStack, goToParent, openChild }} />
                            </React.Fragment>
                            </div>
                        )
                    }
                })}
            </div>
            <ContentModal {...{ show: show === "buyLicence", onHide: () => {setShow(false)}, title: t('addSWPackage'), content: <BuyLicence {...{setShow, comesFromSidebar: true, menuToggle}}/> }} />
        </>
    )
}

// const CollapsableItem = ({ item, menuToggle, contentHeight }) => {
//     const [display, setDisplay] = useState(false);

//     useEffect(() => {
//         contentHeight(true);
//     }, [display, contentHeight])

//     return (
//         <>
//             <li className='navigation__submenu__item'>
//                 <Collapse {...{boolean: display, onClick: () => setDisplay(!display)}} />
//                 <span>{item.label}</span>
//             </li>
//             <li className={`${display ? 'd-block' : 'd-none'}`}>
//                 <ul className='navigation__submenu__list'>
//                     {item.array.map(it => {
//                         return (
//                             <li key={`collapsableitem-${Math.random()}`}>
//                                 <Link className='navigation__submenu__item' to={it.to} onClick={menuToggle}><FontAwesomeIcon icon={it.icon} /><span>{it.label}</span></Link>
//                             </li>
//                         )
//                     })}
//                 </ul>
//             </li>
//         </>
//     )
// }

const TreeSidebar = ({ currentVariantNode, variantHistoryStack, goToParent, openChild }) => {
    const { t } = useTranslation();

    return (
        <div className="tree-sidebar">
            {/* Navigation */}
            <div className="navigation-tree">
                <button onClick={goToParent} disabled={variantHistoryStack.length === 0}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <span>{currentVariantNode?.name ? currentVariantNode?.name : t('noVariant')}</span>
                <div className="dropdown-tree-container">
                    <button disabled={!currentVariantNode?.children?.length}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    {/* Children Dropdown */}
                    {currentVariantNode?.children?.length > 0 && (
                        <div className="dropdown-tree">
                            {currentVariantNode?.children.map((child) => (
                                <div key={`dropdown-tree-${child.id}`} onClick={() => openChild(child)} className="dropdown-tree-item">{child.name}</div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function Accordion({ link, menuToggle }) {
    const [toggle, setToggle] = useState(false);
    const listRef = useRef(null);
    const listWrapperRef = useRef(null);

    const contentHeight = useCallback((toggle) => {
        let margin = parseInt(window.getComputedStyle(listWrapperRef.current).marginTop);
        let height = `${listRef.current.clientHeight + margin}px`;
        listWrapperRef.current.style.setProperty('--height', height);
        if (toggle === undefined) setToggle(window.screen.width > 1024)
        if (window.screen.width < 1024) setToggle(true)
    }, [])

    const customEvent = useCallback(() => {
        const event = new CustomEvent('footerResize', { detail: { message: 'Hello!' } });
        window.dispatchEvent(event);
    }, []);

    useEffect(() => {
        const refListWrapper = listWrapperRef.current;
        contentHeight()
        window.addEventListener('resize', contentHeight)

        refListWrapper.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'min-height') customEvent();
        });

        return () => {
            window.removeEventListener('resize', contentHeight)
            refListWrapper.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'min-height') customEvent();
            });
        }
    }, [customEvent, contentHeight, link])

    return (
        <li>
            <div className='list__extend__wrapper'>
            <NavLink className='navigation__menu__item' to={link.to} onClick={() => menuToggle(false)}>
                    <FontAwesomeIcon icon={link.icon} />
                    <span>{link.label}</span>
                </NavLink>
                {Boolean(link.array.length) && <FontAwesomeIcon className={`${toggle ? ' open' : ''}`} icon={faArrowDown} onClick={() => setToggle(!toggle)} />}
            </div>
            <div className={`navigation__submenu__wrapper${toggle ? ' open' : ''}`} ref={listWrapperRef}>
                <ul className='navigation__submenu__list' ref={listRef}>
                    {
                        // eslint-disable-next-line array-callback-return
                        link.array.map((sublink, index) => {
                            // if (sublink.type === 'Link') return <li key={`subnavlink-link-${sublink.to}`}><Link className={`navigation__submenu__item ${sublink.disabled ? 'navigation__submenu__item__disabled' : ''}`} to={!sublink.disabled ? sublink.to : '#'} onClick={e => (sublink.disabled ? e.preventDefault() : menuToggle(false))}><FontAwesomeIcon className={`${sublink.disabled ? 'navigation__icon__disabled' : 'navigation__icon'}`} icon={sublink.icon} /><span>{sublink.label}</span></Link></li>
                            // if (sublink.type === 'Category') return <React.Fragment key={`subnavlink-category-${index}`}><li key={`subnavlink-categorylink-${index}`}><Link className='navigation__submenu__item'>{sublink.label}</Link></li> {sublink.array.map(subitem => <li key={`subsubnavlink-key-${subitem.to}`}><Link className='navigation__submenu__item' to={subitem.to} onClick={() => menuToggle(false)}><FontAwesomeIcon icon={subitem.icon} /><span>{subitem.label}</span></Link></li>)}</React.Fragment>
                            if (sublink.type === 'Divider') return <div key={`subnavlink-divider-${index}`} className='sidebar__divider' />
                            // if (sublink.type === 'Collapsable') return <CollapsableItem key={`collapsable-category-${index}`} {...{item: sublink, menuToggle: () => menuToggle(false), contentHeight}} />
                            // if (sublink.type === 'TreeItem') {
                            //     return (
                            //         <React.Fragment key={`rotating-item-${index}`}>
                            //             <TreeSidebar key={`tree-item-${index}`} {...{ currentVariantNode, variantHistoryStack, goToParent, openChild }} />
                            //         </React.Fragment>
                            //     )
                            // }
                            if (sublink.type === 'groupedLinks') return <GroupedLinksItems key={`collapsable-grouped-links-${index}`} {...{groupedLinks: sublink, menuToggle: () => menuToggle(false)}} />
                        })
                    }
                </ul>
            </div>
        </li>
    )
}

function Navbar({ toggle, setToggle, linksArray, showNotifications, setShowNotifications }) {
    const { addToast } = useToast();
    const {login, doSetLogin, image48, updateProfileImage} = useAuth();
    const _orgGuid = useParams()['orgGuid'];
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const client = useSwagger();
    
    const location = useLocation();
    const dropdownRef = useRef(null);
    const [receivedNotifications, setReceivedNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [visibleMessageIds, setVisibleMessageIds] = useState([]);
    const observerRefs = useRef(new Map()); 
    const wsRef = useRef(undefined)

    const logout = async() => {
        doSetLogin(null);
        navigate('/login');
        deleteCookie('sigmaheat_user', undefined, '.sigmaheat.de');
        localStorage.setItem("logout", true);
        localStorage.removeItem('currentVariant');
    }

    const organisations = login.organisations;

    const changeOrganisation = useCallback(async(e) => {
        e.preventDefault();
       
        if (location.pathname === '/') {
            navigate(0);
        } else {
            let loginCopy = cloneDeep(login)
            delete loginCopy.currentOrganisation
            await doSetLogin(loginCopy)
            navigate('/', { replace: true });
        }
    }, [login, doSetLogin])

    const loadProfileImage = async(size) => {
        const sizes = [48];
        if (login === null || !sizes.includes(size)) return
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const params = { user_uuid_path: login.id};

            if (size) {
                params.size = size;
            }

            const response = await client.apis["User"].getUserProfilePicture(params);

            if (response.status >= 200 && response.status < 300) {
                if(response.data.size>0)updateProfileImage(size, URL.createObjectURL(response.data))
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('error'), t("profileImageLoadingError"), "error");
        }
    }

    const loadNotifications = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["user"].user_notification_list({user_uuid_path: login.id});

            if (response.status === 204) {
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setReceivedNotifications(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, login])

    const loadUnreadNotifications = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["user"].user_notification_unread_list({user_uuid_path: login.id});
            if (response.status === 204) {
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setUnreadNotifications(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, login])

    useEffect(() => {
        if (image48 === false) loadProfileImage(48)
    }, [image48])

    useEffect(() => {
        function waitUntilFontsLoadedAndExecute() {
            document.fonts.ready.then(() => {
                const getAverageCharWidth = () => {
                    const tempElement = document.createElement('span');
                    tempElement.style.position = 'absolute';
                    tempElement.style.visibility = 'hidden';
                    tempElement.innerText = '\u00A0';
                    document.body.appendChild(tempElement);
                    const averageCharWidth = tempElement.offsetWidth;
                    document.body.removeChild(tempElement);
                    return averageCharWidth;
                }

                const autoGap = () => {
                    const marquee = document.querySelector('.marquee').clientWidth;
                    const span = document.querySelector('.marquee span');
                    span.innerText = login?.currentOrganisation?.name || login.mail;

                    return marquee > span.clientWidth ? marquee - span.clientWidth : 10;
                }

                const backspacesForWidth = () => {
                    const averageCharWidth = getAverageCharWidth();
                    const backspaces = Math.ceil(autoGap() / averageCharWidth);
                    return backspaces;
                }

                const addHTMLBackspacesToInnerText = (element, count) => {
                    const htmlBackspaces = Array(count).fill('\u00A0').join('');
                    element.innerText = (login?.currentOrganisation?.name || login.mail) + htmlBackspaces;
                }

                const bs = backspacesForWidth();
                Array.from(document.querySelectorAll('.marquee span')).forEach(sp => {
                    addHTMLBackspacesToInnerText(sp, bs);
                })
            });
        }

        // Marquee
        const findLeftMostVisibleItem = () => {
            const marqueeContainer = document.querySelector('.marquee');
            const marqueeTexts = Array.from(document.querySelectorAll('.marquee span'));
            const containerLeft = marqueeContainer.getBoundingClientRect().left;
            let leftMostVisibleItem = null;

            for (const textItem of marqueeTexts) {
                const textItemRect = textItem.getBoundingClientRect();
                if (textItemRect.right >= containerLeft) {
                    leftMostVisibleItem = textItem;
                    break;
                }
            }

            return leftMostVisibleItem;
        }

        const findVisibleWidth = (element) => {
            const marqueeContainer = document.querySelector('.marquee');
            const containerRect = marqueeContainer.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            if (elementRect.left >= containerRect.left && elementRect.right <= containerRect.right) {
                return elementRect.width;
            } else if (elementRect.left < containerRect.left && elementRect.right > containerRect.left) {
                return elementRect.right - containerRect.left;
            } else if (elementRect.left < containerRect.right && elementRect.right > containerRect.right) {
                return containerRect.right - elementRect.left;
            }

            return 0;
        }

        const marqueeContainer = document.querySelector('.marquee');
        const marqueeText = [...document.querySelectorAll('.marquee span')];

        let animationSpeed = 2;

        const startMarqueeAnimation = () => {
            marqueeText.forEach(text => text.style.animation = `marquee ${animationSpeed}s linear infinite running`)
        }

        const stopMarqueeAnimation = () => {
            const leftElem = findLeftMostVisibleItem();
            const vpLeftElem = findVisibleWidth(leftElem);
            const itemWidth = marqueeText[0].offsetWidth;
            const timeToTravel = ((vpLeftElem * animationSpeed) / itemWidth).toFixed(2);

            setTimeout(function () {
                marqueeText.forEach(text => text.style.animation = `marquee ${animationSpeed}s linear infinite paused`)
            }, timeToTravel * 1000);
        }

        window.addEventListener('resize', waitUntilFontsLoadedAndExecute());
        marqueeContainer.addEventListener('mouseover', startMarqueeAnimation);
        marqueeContainer.addEventListener('mouseleave', stopMarqueeAnimation);

        // Navigation Event
        var prevScrollpos = window.pageYOffset;
        window.addEventListener('scroll', function() {
            var currentScrollPos = window.pageYOffset;
            // if (window.pageYOffset <= 0) currentScrollPos = 0
            // document.querySelector('.navigation').style.transform = `translateY(${prevScrollpos >= currentScrollPos ? '0' : '-60'}px)`;
            // if (window.innerWidth <= 1024) document.querySelector('.navigation__mobile').style.minHeight = prevScrollpos >= currentScrollPos ? 'calc(100vh - 60px)' : '100vh';
            // else document.querySelector('.navigation__mobile').style.minHeight = null;
            if (window.pageYOffset <= 0) currentScrollPos = 0;
            const navigationElement = document.querySelector('.navigation');
            const mobileNavigationElement = document.querySelector('.navigation__mobile');

            if (navigationElement) {
                navigationElement.style.transform = `translateY(${prevScrollpos >= currentScrollPos ? '0' : '-60'}px)`;
                if (prevScrollpos >= currentScrollPos) {
                    if (window.pageYOffset >= 60) navigationElement.classList.add('hovered');
                    if (window.pageYOffset <  60) navigationElement.classList.remove('hovered');
                }
            }

            if (mobileNavigationElement) {
                if (window.innerWidth <= 1024) {
                    mobileNavigationElement.style.minHeight = prevScrollpos >= currentScrollPos ? 'calc(100vh - 60px)' : '100vh';
                } else {
                    mobileNavigationElement.style.minHeight = null;
                }
            }
            prevScrollpos = currentScrollPos;
        })

        return () => {
            marqueeContainer.removeEventListener('mouseover', startMarqueeAnimation);
            marqueeContainer.removeEventListener('mouseleave', stopMarqueeAnimation);
        }
    }, [login?.currentOrganisation?.name])

    useEffect(() => {
        loadNotifications()
        loadUnreadNotifications()
    }, [])

    // Schließen der Notifications Dropdown
    useEffect(() => {
        if (!login.currentOrganisation) return
     
        const handleOutsideClickOrTouch = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowNotifications(false); 
            }
        };

        document.addEventListener('mousedown', handleOutsideClickOrTouch);
        document.addEventListener('touchstart', handleOutsideClickOrTouch);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClickOrTouch);
            document.removeEventListener('touchstart', handleOutsideClickOrTouch);
        };
    }, [login.currentOrganisation, receivedNotifications, setShowNotifications, showNotifications]);
    
    useEffect(() => {
        if (wsRef.current) return
        wsRef.current = new NotificationWebsocketWorker(login, (title, msg, type) => {}, setReceivedNotifications, setUnreadNotifications);
        return () => {
            wsRef.current.disconnect();
        };
    }, [login]);

    // Unreaad Notifications
    useEffect(() => {
        if (unreadNotifications.length === 0 || visibleMessageIds.length === 0 || wsRef.current === undefined) return;
        const matchingMessages = unreadNotifications.filter((msg) => visibleMessageIds.includes(msg.message)).map((msg) => msg.message);
        if (matchingMessages.length === 0) return;

        wsRef.current.readMessage(matchingMessages, () => {
            setVisibleMessageIds([])
            setUnreadNotifications(unreadNotifications.filter((msg) => !visibleMessageIds.includes(msg.message)))
        })
    }, [unreadNotifications, visibleMessageIds]);

    const observerCallback = useCallback((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const { id } = entry.target.dataset;
          if (id && !visibleMessageIds.includes(id)) {
            setVisibleMessageIds((prev) => [...prev, id]);
          }
        }
      });
    }, [visibleMessageIds]);

    // wenn empfangene Nachrichten in den sichtbaren Bereich kommen 
    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, {
            root: null, // Beobachte den Viewport
            rootMargin: "0px",
            threshold: 0.1, // Element muss zu 10% sichtbar sein
        });

        // Fügt alle refs hinzu
        observerRefs.current?.forEach((ref) => {
            if (ref instanceof Element) {
                observer.observe(ref);
            } 
        });
        
        return () => {
            observer.disconnect();
        };
    }, [observerCallback, showNotifications]);

    return (
        <nav className={`navigation${toggle ? ' hovered': ''}`}>
            <section className='navigation__main__menu'>
                <div className='menu__button__logo__wrapper'>
                    {/* dont show burger menu in change organisation */}
                    {(_orgGuid || _buildingGuid) &&
                        <button className={`navigation__trigger ${toggle ? ' open' : ''}`} onClick={() => setToggle(!toggle)}>
                            <span className='hamburger__line' />
                            <span className='hamburger__line' />
                            <span className='hamburger__line' />
                        </button>
                    }
                    <Link to='/'>
                        <img className='img__logo' src={Logo} alt='' />
                    </Link>
                </div>

                <div className={`navigation__mobile ${toggle ? ' open' : ''}`}>
                    <ul className='navigation__menu__list'>
                        {linksArray.map(link => link.array ? <Accordion key={`navlink-keys-${link.to}`} {...{link, menuToggle: setToggle}} /> : <li key={`navlink-key-${link.to}`}><NavLink className='navigation__menu__item' to={link.to} onClick={() => setToggle(false)}><FontAwesomeIcon icon={link.icon} /><span>{link.label}</span></NavLink></li>)}
                    </ul>
                </div>

                <div className='dropdown__main__container' ref={dropdownRef}>
                    {login.currentOrganisation && (
                        <div className='dropdown__bell' onClick={() => { setShowNotifications(!showNotifications) }}>
                            <FontAwesomeIcon icon={faBell} className='dropdown__bell__icon flex-shrink-0' />
                            {(Boolean(unreadNotifications.length)) && <div className='number__notifications'>{unreadNotifications?.length}</div>}
                        </div>
                    )}

                    {showNotifications && (
                        <div className='notifications__container'>
                            <ListGroup>
                                {receivedNotifications?.map((item, index) => (
                                    <ListGroup.Item ref={(el) => observerRefs.current.set(index, el)} data-id={item.id} data-type={item.type} key={index}>{item.message}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    )}

                    <Dropdown>
                        <button className='dropdown__main__button dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false' onClick={() => login.currentOrganisation && setShowNotifications(false)}>
                            <div className='dropdown__column'>
                                <div className='profile__img__wrapper author__img' style={{ backgroundColor: specificColorGenerator(login?.prename + ' ' + login?.name, true) }}>
                                    {image48 && <img className="author__img" src={image48} />}
                                    {image48 === false && <span className="initials" style={{ color: specificColorGenerator(login?.prename + ' ' + login?.name, false) }}>{extractInitials(login?.prename + ' ' + login?.name)}</span>}
                                </div>

                                <div className='profile__info__wrapper'>
                                    <p>{`${login.prename} ${login.name}`}</p>

                                    <div className='marquee'>
                                        {login.hasOwnProperty('currentOrganisation') ? (
                                            <>
                                                <span>{login?.currentOrganisation?.name}</span>
                                                <span>{login?.currentOrganisation?.name}</span>
                                                <span>{login?.currentOrganisation?.name}</span>
                                                <span>{login?.currentOrganisation?.name}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span>{login?.mail}</span>
                                                <span>{login?.mail}</span>
                                                <span>{login?.mail}</span>
                                                <span>{login?.mail}</span>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className='dropdown__toggle'>
                                <img src={ToggleIcon} alt='' />
                            </div>
                        </button>

                        <ul className='dropdown-menu dropdown__content'>
                            {Boolean(organisations.length) && <>
                                <Dropdown.Header>{t('organisations')}</Dropdown.Header>
                                <Dropdown.Item href='/#' onClick={(e) => changeOrganisation(e)}>{t('changeOrganisation')}</Dropdown.Item>
                                {(_orgGuid || login?.currentOrganisation?.id && login?.currentOrganisation?.in_onboarding === 3) &&<Link className='dropdown-item' to={`/${_orgGuid || login?.currentOrganisation?.id}/orgprofile`}>{t('organisationProfile')}</Link>}
                                <Dropdown.Divider />
                            </>}
                            <Link className='dropdown-item' to='/settings'>{t('settings')}</Link>
                            <Dropdown.Item onClick={logout}>{t('logout')}</Dropdown.Item>
                        </ul>
                    </Dropdown>
                </div>
            </section>
        </nav>
    )
}

export default Navbar