import React, { useCallback, useState } from 'react'
import { Button } from "react-bootstrap";
import { useSwagger } from '../context/SwaggerContext';
import { useToast } from '../NotificationsContent';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faRotate } from '@fortawesome/free-solid-svg-icons';

const SearchFile = ({setFiles, setShow, setFoundItem, fromSubsidies, setSubsidies, setLoading, showSearchbarOnBigDevice, setSearch}) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const { login } = useAuth();
    const { addToast } = useToast();
    const client = useSwagger();

    const [searchWord, setSearchWord] = useState({string: ""})

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setSearchWord(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const clearInput = () => {
        setSearchWord({ string: '' });
    };

    const getAllFiles = useCallback(async (result) => {
        if (!client) return;
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const requestData = {
                building_uuid: _buildingGuid,
            };
              
            // result === undefined, wenn bei der Searchbar auf reset gedrückt wird
            // dann sollen die files paginated angezeigt werden
            if (result === undefined) {
                requestData.page = 1;
            }

            const response = await client.apis["building"].building_file_list(requestData);

            if (response.status >= 200 && response.status < 300) {

                // result === undefined, wenn bei der Searchbar auf reset gedrückt wird
                if (result === undefined) {
                    setFiles(response.obj)
                    setSearchWord({string: ""})
                    setFoundItem(undefined)
                    return
                }

                const elementFiles = result.map(element => element.file);
                const filteredArray = response.obj?.filter(item => elementFiles.includes(item.id));

                if (filteredArray?.length === 0) {
                    setFiles(filteredArray)
                } else {
                    setFiles(filteredArray)
                    setFoundItem(searchWord?.string)
                    setShow(false)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, searchWord?.string, setFiles, setFoundItem, setShow, t])

    const getSubsidies = useCallback(async (result) => {
        if (!client) return;
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const requestData = {
                building_uuid: _buildingGuid,
            };
              
            // result === undefined, wenn bei der Searchbar auf reset gedrückt wird
            // dann sollen die subsidies paginated angezeigt werden
            if (result === undefined) {
                requestData.page = 1;
            }

            const response = await client.apis["building"].building_action_list(requestData);

            if (response.status >= 200 && response.status < 300) {
                // result === undefined, wenn bei der Searchbar auf reset gedrückt wird
                if (result === undefined) {
                    setSubsidies(response.obj)
                    setSearchWord({string: ""})
                    setFoundItem(undefined)
                    return
                }

                const filteredArray = response.obj.map((item) => {
                    const matchedFiles = item.files.filter((fileObj) =>
                        result.some((o) => o.file === fileObj.id)
                    );

                    if (matchedFiles.length > 0) {
                        return {
                            ...item,
                            files: matchedFiles, // nur die übereinstimmenden Dateien einfügen
                        };
                    }
          
                    // Wenn keine übereinstimmenden Dateien gefunden werden, wird undefined zurückgegeben
                    return undefined;
                }).filter((item) => item !== undefined); // Entferne die undefined-Einträge

                setSubsidies(filteredArray)

                if (filteredArray?.length > 0) {
                    setFoundItem(searchWord?.string)
                    setShow(false)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, searchWord?.string, setFoundItem, setShow, setSubsidies, t])

    const onSubmit = useCallback(async ({reset}) => {
        if (reset || searchWord?.string?.trim() === "") {
            setLoading(true)

            if (!fromSubsidies) {
                await getAllFiles(undefined);
            } else {
                await getSubsidies(undefined)
            }

            setTimeout(() => {
                setLoading(false);
            }, 650);

            setSearch && setSearch(true)
            return
        }
      
        if (!client) return;
        setLoading(true)

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                // req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = fromSubsidies ? await client.apis["building"].building_action_file_search_list({building_uuid: _buildingGuid, search: searchWord.string.trim()}) : await client.apis["building"].building_file_search_list({building_uuid: _buildingGuid, search: searchWord.string.trim()});

            if (response.status >= 200 && response.status < 300) {
                if (!fromSubsidies) {
                    await getAllFiles(response.obj);
                } else {
                    await getSubsidies(response.obj)

                }

                setTimeout(() => {
                    setLoading(false);
                }, 650);
                setSearch && setSearch(true)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, searchWord.string, setLoading, fromSubsidies, _buildingGuid, login.Authorization, setSearch, getAllFiles, getSubsidies, addToast, t])

    return (
        <>
            <div className={`searchBar ${showSearchbarOnBigDevice ? "" : "hide_on_big_screen mb-3"}`}>
                <input
                    type="text"
                    placeholder={`${t('search')} ...`}
                    className={`form-control ${!showSearchbarOnBigDevice ? "searchbar_intput_border_small_screen" : "searchbar_intput_border_big_screen"}`}
                    id='string'
                    value={searchWord.string || ''} 
                    onFocus={clearInput}
                    onChange={(e) => changeHandler(e)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSubmit({reset: false});  
                        }
                    }}
                />
                <Button
                    variant="outline-primary"
                    onClick={() => onSubmit({reset: false})}
                    style={{ borderStartStartRadius: '0', borderBottomLeftRadius: '0', maxWidth: "38px" }} 
                    className='p-10 flex-shrink-0'
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} className='flex-shrink-0' />
                </Button>

                <Button
                    variant="outline-primary"
                    onClick={() => onSubmit({reset: true})}
                    style={{ marginLeft: '15px', maxWidth: "38px" }} 
                    className='p-10 flex-shrink-0'
                >
                    <FontAwesomeIcon icon={faRotate} className='flex-shrink-0' />
                </Button>
            </div>
        </>
    )
}

export default SearchFile