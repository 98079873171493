import { useState, useEffect, useCallback } from "react"
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation, Trans } from "react-i18next";
import { Button, Form, Table } from "react-bootstrap";
import HorizontalPricingTable from "../components/HorizontalPricingTable";
import Invoice from "../components/Invoice";
import { useSwagger } from "../context/SwaggerContext";

export const AddESProduct = ({ wizard, setWizard, onClose, building }) => {
    const { login } = useAuth()
    const { t } = useTranslation()
    const { addToast } = useToast();
    const [bPlans, setBPlans] = useState(undefined)
    const [planID, setPlanID] = useState(undefined)
    const [prices, setPrices] = useState(undefined)
    const [terms, setTerms] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState(undefined)
    const [disabled, setDisabled] = useState(false)
    const [selectedBuildings, setSelectedBuildings] = useState(undefined)
    const client = useSwagger();

    const fetchESProducts = useCallback(async() => {
        if (bPlans?.length || prices !== undefined) return

        // fetch(`https://tech.sigmaheat.de/org/${building.organisation}/es_products`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .then(data => {
        //     setPrices(data?.map(p => p.prices[0].price_id))
        //     let prices = data.map(p => ({...p, quantity: 1}))
        //     setBPlans(prices)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });


        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_es_products_retrieve({org_uuid: building.organisation});

            if (response.status >= 200 && response.status < 300) {
                setPrices(response.obj?.map((p) => p.prices[0].price_id));
                let prices = response.obj.map((p) => ({ ...p, quantity: 1 }));
                setBPlans(prices);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [bPlans?.length, prices, client, building.organisation, login.Authorization, addToast, t]);

    const postBuildingHasESProduct = useCallback(async(building, stripe_sub_id, plan, bObj) => {
        return new Promise(async(resolve, reject) => {
        //     fetch(`https://tech.sigmaheat.de/building/${building.id}/esproducts`, {
        //         headers: {
        //             'Authorization': login.Authorization,
        //             "Content-Type": "application/json"
        //         },
        //         method: "POST",
        //         body: JSON.stringify({ product: plan.es_id, stripe_subscription_id: stripe_sub_id, active_until: "2023-07-15T18:32:45.123456+03:00", status: 1 }),
        //     })
        //     .then(response => {
        //         if (response.status === 406) throw new Error("Das Gebäude hat eine Anzahl von appartments kleiner als 2. Es müssen mehr als eins verfügbar sein.")
        //         if (response.status === 409) throw new Error(`Das Gebäude an ${bObj.street} ${bObj.no}, ${bObj.zip} ${bObj.city} besitzt das Product schon.`)
        //         if (!response.ok) throw new Error(t("responseError"))
        //         return response.json();
        //     })
        //     .then((result) => {
        //         resolve();
        //     })
        //     .catch(error => {
        //         reject({ id: building.id, message: error.message });
        //     });
        // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    req.body = JSON.stringify({
                        product: plan.es_id,
                        stripe_subscription_id: stripe_sub_id,
                        active_until: "2023-07-15T18:32:45.123456+03:00",
                        status: 1,
                    });
                    return req;
                };

                const response = await client.apis["building"].building_esproducts_create({ building_uuid: building.id });

                if (response.status >= 200 && response.status < 300) resolve();
                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                const statusCode = error.response?.status;
                reject({
                    id: building.id,
                    message: statusCode === 406 ? "Das Gebäude hat eine Anzahl von appartments kleiner als 2. Es müssen mehr als eins verfügbar sein." : statusCode === 409 ? `Das Gebäude an ${bObj.street} ${bObj.no}, ${bObj.zip} ${bObj.city} besitzt das Product schon.` : t("responseError")
                });
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, login.Authorization, t]);

    const createSubscriptionForBuilding = useCallback((plan) => {
        return new Promise(async(resolve, reject) => {
        //     fetch(`https://tech.sigmaheat.de/payment/org/${building?.organisation}/checkout`, {
        //         headers: {
        //             'Authorization': login.Authorization,
        //             "Content-Type": "application/json"
        //         },
        //         method: "POST",
        //         body: JSON.stringify({ planID: plan.price_id, quantity: building.appartments }),
        //     })
        //     .then(response => {
        //         if (!response.ok) throw new Error(t("responseError"))
        //         return response.json();
        //     })
        //     .then(async(result) => {
        //         setSelectedBuildings(prev => {
        //             let tmpPrev = [...prev]
        //             tmpPrev.find(b => b.id === building.id).stripe_subscription_id = result.stripe_sub_id
        //             return tmpPrev
        //         })

        //         await postBuildingHasESProduct(building, result.stripe_sub_id, plan, building);
        //         resolve();
        //     })
        //     .catch(error => {
        //         reject(error);
        //     });
        // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    req.body = JSON.stringify({
                        planID: plan.price_id,
                        quantity: building.appartments,
                    });
                    return req;
                };

                const response = await client.apis["payment"].payment_org_checkout_create({ org_uuid: building?.organisation });

                if (response.status >= 200 && response.status < 300) {
                    setSelectedBuildings((prev) => {
                        let tmpPrev = [...prev];
                        tmpPrev.find((b) => b.id === building.id).stripe_subscription_id = response.stripe_sub_id;
                        return tmpPrev;
                    });

                    await postBuildingHasESProduct(building, response.stripe_sub_id, plan, building);
                    resolve();
                }

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(t("responseError"));
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, building, login.Authorization, postBuildingHasESProduct, t]);

    const createInvoices = useCallback(async(plan) => {
        setDisabled(true)

        let checkedBld = selectedBuildings.filter(sb => sb.checked === true)

        for (let i = 0; i < checkedBld.length; i++) {
            const appartment = checkedBld[i].appartments
            if (typeof appartment !== 'number' || !Number.isInteger(appartment) || appartment < 2) {
                addToast(t("error"), t("warningNoOfApartments"), "error")
                return
            }
        }

        const promises = checkedBld.map(_ => createSubscriptionForBuilding(plan));
        await Promise.allSettled(promises).then((results) => {
            const fulfilled = results.filter(r => r.status === 'fulfilled')
            const rejected = results.filter(r => r.status === 'rejected')

            if (Boolean(rejected.length)) rejected.forEach(r => addToast(t("error"), r.reason.message, "error") )
            if (Boolean(fulfilled.length)) setWizard(prev => ({type: "order", page: prev.page + 1}))

            setDisabled(false)


        });

    }, [selectedBuildings, wizard, setWizard, planID])

    const fetchPaymentMethods = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/payment/org/${building.organisation}/paymentmethods`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .then(data => {
        //     setPaymentMethods(data)
        //     let tmpSelBuil = [...selectedBuildings]
        //     for (let i = 0; i < tmpSelBuil.length; i++) {
        //         if (tmpSelBuil[i].checked === true) tmpSelBuil[i]['payment_method_id'] = data[0].id
        //     }
        //     console.log(tmpSelBuil)
        //     setSelectedBuildings(tmpSelBuil)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["payment"].payment_org_paymentmethods_retrieve({ org_uuid: building.organisation });

            if (response.status >= 200 && response.status < 300) {
                setPaymentMethods(response.obj);
                let tmpSelBuil = [...selectedBuildings];
                for (let i = 0; i < tmpSelBuil.length; i++) {
                if (tmpSelBuil[i].checked === true)
                    tmpSelBuil[i]["payment_method_id"] = response.obj[0].id;
                }
                setSelectedBuildings(tmpSelBuil);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, building.organisation, login.Authorization, selectedBuildings, addToast, t]);

    const changeStatus = useCallback(async(building, stripe_subscription_id) => {
        // fetch(`https://tech.sigmaheat.de/building/${building.id}/esproducts`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "PATCH",
        //     body: JSON.stringify({ stripe_subscription_id: stripe_subscription_id })
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return {}
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({
                    stripe_subscription_id: stripe_subscription_id,
                });
                return req;
            };

            const response = await client.apis["building"].building_esproducts_partial_update({ building_uuid: building.id });
            if (response.status >= 200 && response.status < 300) return {};
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t]);

    const patchPaymentMethod = useCallback((build, bObj) => {
        return new Promise(async(resolve, reject) => {
            // fetch(`https://tech.sigmaheat.de/payment/org/${building.organisation}/updatepaymentmethod`, {
            //     headers: {
            //         'Authorization': login.Authorization,
            //         "Content-Type": "application/json"
            //     },
            //     method: "PATCH",
            //     body: JSON.stringify({ stripe_subscription_id: build.stripe_subscription_id, payment_method_id: build.payment_method_id })
            // })
            // .then((response) => {
            //     if (!response.ok) throw new Error(t("responseError"))
            //     return {}
            // })
            // .then(async(_) => {
            //     await changeStatus(building, build.stripe_subscription_id)
            //     addToast(t("success"), `Zahlungsmethode für das Gebäude an ${bObj.street} ${bObj.no}, ${bObj.zip} ${bObj.city} wurde erfolgreich eingesetzt.`, "success")
            //     resolve()
            // })
            // .catch(error => {
            //     addToast(t("error"), error.message, "error")
            //     reject(error);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    req.body = JSON.stringify({
                        stripe_subscription_id: build.stripe_subscription_id,
                        payment_method_id: build.payment_method_id,
                    });
                    return req;
                };

                const response = await client.apis["payment"].payment_org_updatepaymentmethod_partial_update({ org_uuid: building.organisation });

                if (response.status >= 200 && response.status < 300) {
                    await changeStatus(building, build.stripe_subscription_id);
                    addToast(t("success"), `Zahlungsmethode für das Gebäude an ${bObj.street} ${bObj.no}, ${bObj.zip} ${bObj.city} wurde erfolgreich eingesetzt.`, "success");
                    resolve();
                }
                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                addToast(t("error"), t("responseError"), "error");
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, building, login.Authorization, changeStatus, addToast, t]);

    const changePaymentMethod = useCallback((building, paymentMethod) => {
        let sbTMP = [...selectedBuildings];
        sbTMP.find(sb => sb.id === building.id).payment_method_id = paymentMethod
        setSelectedBuildings(sbTMP)
    }, [selectedBuildings])

    const finalizePayment = useCallback(() => {
        setDisabled(true)
        let selBuildings = selectedBuildings.filter(sB => sB.checked === true)
        const promises = selBuildings.map(b => patchPaymentMethod(b, building));
        Promise.all(promises).then(_ => {
            onClose();
            setDisabled(false)
        })
    }, [selectedBuildings, patchPaymentMethod, onClose, building])

    const submitContract = useCallback(async(plan) => {
        setDisabled(true)

        // fetch(`https://tech.sigmaheat.de/org/${building.organisation}/offers/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "POST",
        //     body: JSON.stringify({ product: plan.es_id }),
        // })
        // .then(response => {
        //     setDisabled(false)
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json();
        // })
        // .then(_ => setWizard(prev => ({...prev, page: 3})))
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({ product: plan.es_id });
                return req;
            };

            const response = await client.apis["org"].org_offers_create({ org_uuid: building.organisation });

            if (response.status >= 200 && response.status < 300) {
                setDisabled(false);
                setWizard((prev) => ({ ...prev, page: 3 }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, building.organisation, login.Authorization, setWizard, addToast, t]);

    const select = useCallback((plan, type) => {
        setPlanID(plan);
        if (type === "order") createInvoices(plan);
        if (type === "offer") submitContract(plan);
    }, [setPlanID, wizard, setWizard, selectedBuildings, submitContract])

    const proccedFunction = useCallback(() => {
        if (disabled) return
        if (wizard.page === 3) onClose()
        if (wizard.page === 2) finalizePayment();
        if (wizard.page === 1 && !terms) addToast(t("error"), t("productBrochureTerms"), "error")
        if (wizard.page === 1 && terms && wizard.type === "order") setWizard(prev => ({type: "order", page: prev.page + 1}));
    }, [wizard, planID, createInvoices, terms, disabled, building])

    useEffect(() => {
        if (building == undefined) return
        fetchESProducts()
    }, [building])

    useEffect(() => {
        if (building === undefined) return
        setSelectedBuildings([{ id: building.id, checked: true, appartments: building.appartments }])
    }, [building])

    useEffect(() => {
        if (wizard.page === 2) fetchPaymentMethods()
    }, [wizard])

    return <>
        {(wizard.page === 0 && Boolean(bPlans?.length)) && <HorizontalPricingTable {...{ plans: bPlans, setPlans: (arr) => setBPlans(arr), setWizard, prices, setPrices, select }} />}
        {(wizard.page === 1 && <>
            <Invoice className="mb-3" {...{ plan: bPlans.find(p => p.id === planID.es_id).title, items: [building], price: bPlans.find(p => p.id === planID.es_id).prices.find(p => p.price_id === planID.price_id) }} />
            <Form.Check
                className="mb-3"
                type="checkbox"
                id="agreement"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
                label={
                    <Trans i18nKey='productTermsnConditions'>
                        <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                    </Trans>
                }
            />
        </>)}
        {(wizard.page === 2 && <>
            <Table responsive className="mb-3">
                <thead>
                    <tr>
                        <th>{t("name")}</th>
                        <th>{t("address")}</th>
                        <th>{t("paymentMethod")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="align-middle">{building.name}</td>
                        <td className="align-middle">
                            <span className='d-block'>{building.street} {building.no}</span>
                            <span className='d-block'>{building.zip} {building.city}</span>
                        </td>
                        <td className="align-middle">
                            <Form.Group controlId={`building-paymentMethos-${building.id}`}>
                                <Form.Select onChange={(e) => changePaymentMethod(building, e.target.value)}>
                                        {paymentMethods?.map(pM => {
                                            if (pM.hasOwnProperty('card')) return <option key={`payment-method-${pM.id}`} value={pM.id}>{pM.card.brand.charAt(0).toUpperCase() + pM.card.brand.slice(1) + " " + pM.card.last4}</option>
                                            if (pM.hasOwnProperty('sepa_debit')) return <option key={`payment-method-${pM.id}`} value={pM.id}>{pM.type.charAt(0).toUpperCase() + pM.type.slice(1) + " " + pM.sepa_debit.last4}</option>
                                        })}
                                </Form.Select>
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>)}
        {(wizard.page === 3 && <>
            <span className="mb-3">{t("signRequest")}</span>
        </>)}

        {wizard.page !== 0 && <div className={`d-flex ${wizard.page !== 3 ? "justify-content-between" : "justify-content-end"}`}>
            {wizard.page !== 3 && <Button variant="outline-primary" disabled={disabled} onClick={() => {if (!disabled) setWizard(prev => ({...prev, page: prev.page - 1}))}}>{t("previous")}</Button>}
            <Button variant="outline-primary" disabled={disabled} onClick={() => proccedFunction()}>{t("next")}</Button>
        </div>}
    </>
}

export default AddESProduct;