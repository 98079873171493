import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { Button, OverlayTrigger, Placeholder, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faList, faCalculator, faTriangleExclamation, faDownload, faCircleInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import AddEditConfigurationsLog from "./AddEditConfigurationsLog";
import HeatpartConfigurationsLog from "./HeatpartConfigurationsLog";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import { Calculate } from "./CalculateDIN12831";
import Bubblechart from "../charts/Bubblechart";
// import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";
import Pagination from "../components/Pagination";
import SortableDataTable from "../components/SortableDataTable/SortableDataTable";
import { useBuilding } from "../context/BuildingContext";
import Loading from "../components/Loading";

const BuildingHeatpartsSortableTable = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation()
    const [heatparts, setHeatparts] = useState(undefined)
    const { currentBuilding, currentVariantNode } = useBuilding();
    const [isStable, setIsStable] = useState(false);

    const loadHeatparts = useCallback(async () => {
        if (!client || !currentBuilding?.id || !currentVariantNode?.id) return;
      
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getHeatpartSummary({ building_uuid: currentBuilding?.id, variant_uuid: currentVariantNode?.id });

            if (response.status === 204) {
                setHeatparts([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                const sortedRoomsWithoutUnit = response?.obj?.rooms_without_unit
                ?.sort((a, b) => a?.floor?.level - b?.floor?.level) // Sortiere Räume nach Floor Level
                ?.map(room => {
                    // Sortiere die heatparts innerhalb jedes Raumes nach thermal_power_of_heatpart in absteigender Reihenfolge
                    room.heatparts.sort((a, b) => b.thermal_power_of_heatpart - a.thermal_power_of_heatpart);
                    return room; // Gib den Raum mit sortierten heatparts zurück
                });

                const sortedUnits = response?.obj?.units?.map(unit => {
                    // Zuerst sortiere die Räume (unit_rooms) nach Floor Level in aufsteigender Reihenfolge
                    unit.unit_rooms.sort((a, b) => a?.floor?.level - b?.floor?.level);
                
                    // Sortiere innerhalb jedes Raumes die heatparts nach thermal_power_of_heatpart in absteigender Reihenfolge
                    unit.unit_rooms.forEach(room => {
                        room.heatparts.sort((a, b) => b.thermal_power_of_heatpart - a.thermal_power_of_heatpart);
                    });
                
                    return unit;
                })

                setHeatparts({rooms_without_unit: sortedRoomsWithoutUnit, units: sortedUnits});
            }
        } catch (error) {
            console.error('Error:', error);
            addToast(t('error'), t('responseError'), "error");
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t, currentVariantNode, currentBuilding])

    useEffect(() => {
        if (!currentBuilding || !currentVariantNode) return
        setIsStable(true)
    }, [currentBuilding, currentVariantNode]);

    useEffect(() => {
        if (!isStable || !currentVariantNode) return
        loadHeatparts()
    }, [isStable, currentVariantNode])
    
    return (
        <MainLayout {...{ background: Background }}>
            <div className="sidebar__padding">
                <HeatpartsTable {...{ heatparts, loadHeatparts }} />
            </div>
        </MainLayout>
    ) 
}

const HeatpartsTable = ({ heatparts, loadHeatparts }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { addToast } = useToast();
    const { login } = useAuth();
    const [show, setShow] = useState(undefined);
    const { t } = useTranslation();
    const [addLOG, setAddLOG] = useState(undefined);
    const client = useSwagger();
    const [variant, setVariant] = useState(undefined)
    const { allowEdit } = usePermissions();

    const [heatpartRows, setHeatpartRows] = useState([]);
    const [unsortedHeatparts, setUnsortedHeatparts] = useState(undefined)
    const [isStable, setIsStable] = useState(false);
    const [loader, setLoader] = useState(false);

    const { currentBuilding, variants, currentVariantNode } = useBuilding();

    const tableStructure = [
        {
            col: t('room'),
            type: 'label'
        },{
            col: t('radiator'),
            type: 'label'
        }, {
            col: t('strand'),
            type: 'label'
        }, {
            col: t('normHeatpower'),
            type: 'label'
        }, {
            col: t('massFlow'),
            type: 'label'
        }, {
            col: t('returnTemperature'),
            type: 'label'
        }, {
            col: t('differentialPressure'),
            type: 'label'
        }, {
            col: t('kvValue'),
            type: 'label'
        }, {
            col: t('settingValue'),
            type: 'label'
        }, {
            col: t('possiblePower'),
            type: 'label'
        }, {
            col: t('roomTemperature'),
            type: 'label'
        }, {
            col: t('achievableRoomTemperature'),
            type: 'label'
        },{
            col: t('actions'),
            type: 'buttons'
        }
    ]

    const getActualVariant = useCallback((obj) => {
        let result = null;
    
        const findActual = (node) => {
            if (node.actual === true) {
                result = node; 
                return; 
            }
            
            if (Array.isArray(node.children)) {
                for (let child of node.children) {
                    findActual(child); 
                    if (result) return; 
                }
            }
        };
    
        findActual(obj); 
        return result; 
    }, []);

    const downloadFile = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none'; // Das Element wird nicht angezeigt
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const downloadPDF = useCallback(async () => {
        if (!client || !variant) return;
        setLoader(true)

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_building_heatpart_group_list({ building_uuid: _buildingGuid, variant_uuid: variant });

            if (response.status >= 200 && response.status < 300) {
                downloadFile(response.data, 'heatparts.pdf')
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t("responseError"), "error");
            console.log("error", error)
        } finally {
            setLoader(false)
        }
    }, [client, login.Authorization, addToast, t, variant]);

    const mainFunctions = [
        {
            label: t("calculate"),
            onClick: () => setShow("Calculate"),
            key: "calculate",
            icon: faCalculator,
        }, {
            label: t("pdfExport"),
            onClick: () => downloadPDF(),
            key: "pdfExport",
            icon: faDownload,
        },
    ];

    const columns = React.useMemo(() => [
        {
            id: 1,
            KEY: "props.room.name",
            col: t('room'),
            clickable : true
        },
        {
            id: 2,
            KEY: "props.heatpart.name",
            col: t('radiator'),
            clickable : true
        },
        {
            id: 3,
            KEY: "strand",
            col: t('strand'),
            clickable : false
        },
        {
            id: 4,
            KEY: "props.heatpart.thermal_power_of_heatpart",
            col: t('normHeatpower'),
            clickable : true
        },
        {
            id: 5,
            KEY: "props.heatpart.target_mass_flow",
            col: t('massFlow'),
            clickable : true
        },
        {
            id: 6,
            KEY: "props.heatpart.target_return_temp",
            col: t('returnTemperature'),
            clickable : true
        },
        {
            id: 7,
            KEY: "props.heatpart.target_pressure_mbar",
            col: t('differentialPressure'),
            clickable : true
        },
        {
            id: 8,
            KEY: "props.heatpart.target_kv",
            col: t('kvValue'),
            clickable : true
        },
        {
            id: 9,
            KEY: "props.heatpart.target_setting_value",
            col: t('settingValue'),
            clickable : true
        },
        {
            id: 10,
            KEY: "props.heatpart.target_thermal_power_to_ambient",
            col: t('possiblePower'),
            clickable : true
        },
        {
            id: 11,
            KEY: "props.room_temperature",
            col: t('roomTemperature'),
            clickable : true
        },
        {
            id: 12,
            KEY: "props.heatpart.target_room_temp",
            col: t('achievableRoomTemperature'),
            clickable : true
        },
        {
            id: 13,
            KEY: "actions",
            col: t('actions'),
        }
    ], []);

    const onClose = () => {
        setShow(false);
        loadHeatparts();
    };

    const createHeatpartRows = () => {
        let h = []
        heatparts?.units?.map((unit, unitIndex) =>{
            return unit.unit_rooms?.map((room, roomIndex) => room.heatparts?.map((hp, hpIndex) => {

                return h.push(
                    <HeatpartRow 
                        key={`heatpart-${hp.id}`} {...{ unit:unit, room: room, heatpart: hp, setAddLOG, variant: variant && variant[0], room_temperature: room.room_temperature 
                    }} />
                )
            }))
        });

        heatparts?.rooms_without_unit?.map((room, roomIndex) => {
            return room.heatparts?.map((hp, hpIndex) => {

            return h.push(
                <HeatpartRow
                    key={`heatpart-${hp.id}`} {...{ unit:undefined, room: room, heatpart: hp, setAddLOG, variant: variant && variant[0], room_temperature: room.room_temperature
                }} />
            )
        })});

        setHeatpartRows(h)
        setUnsortedHeatparts([...h]);
    }

    // pagination
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;
    const [pageCount, setPageCount] = useState(Math.ceil((heatpartRows?.length || 0) / pageSize));

    const indexOfLastItem = (currentPage + 1) * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = heatpartRows?.slice(indexOfFirstItem, indexOfLastItem) || [];

    useEffect(() => {
        setPageCount(Math.ceil((heatpartRows?.length || 0) / pageSize));
    }, [heatpartRows, pageSize]);

    // sort
    const [sort, setSort] = useState({ direction: "asc" })

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const getSortedArray = (arrayToSort, header) => {
        if (sort.direction === null) {
            setHeatpartRows([...unsortedHeatparts])
            return unsortedHeatparts;
        }

        if (sort.direction === "asc") {
            let sortedArray = arrayToSort.sort((a, b) => (getNestedValue(a, header.KEY) > getNestedValue(b, header.KEY) ? 1 : -1));
            return sortedArray;
        }
        
        if (sort.direction === "desc") {
            let sortedArray = arrayToSort.sort((a, b) => (getNestedValue(a, header.KEY) > getNestedValue(b, header.KEY) ? -1 : 1));
            return sortedArray;
        }
    };

    useEffect(() => {
        if (!variants || !currentBuilding || !currentVariantNode) return;
        setIsStable(true)
    }, [currentBuilding, currentVariantNode, variants])

    useEffect(() => {
        if (!isStable) return;
        const result = getActualVariant(variants)
        setVariant(result?.id)
    }, [variants, getActualVariant, isStable])

    useEffect(() => {
        // getActualVariant();
        createHeatpartRows();
    }, [])

    useEffect(() => {
        if (!isStable) return;
        createHeatpartRows()
    }, [heatparts, isStable])

    return <>
        {(heatparts === undefined) ? (
            <>
                <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                    <div style={{ width: "100%", height: "500px" }}>
                        <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                            <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                        </Placeholder>
                    </div>
                </Card>
                {/* <Card {...{ heading: `${t('heatparts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}> */}
                <Card {...{ heading: `${t('heatpartsCalculated')} ${t('inThe')} ${t('building')}: ${currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
                    <TablePlaceholder {...{ structure: tableStructure }} />
                </Card>
            </>
        ) : (!Boolean(heatpartRows.length)) ? (
            <>
                <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                    <div style={{ width: "100%", height: "500px" }}>
                        <p>{t('noDataAvailable')}</p>
                    </div>
                </Card>
                {/* <Card {...{ heading: `${t('heatparts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, active: true, className: "height-fit mb-3" }}> */}
                <Card {...{ heading: `${t('heatpartsCalculated')} ${t('inThe')} ${t('building')}: ${currentBuilding?.name}`, active: true, className: "height-fit mb-3" }}>
                    <p className="m-0">{t('noHP')}</p>
                </Card>
            </>
        ) : (
            <>
                <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                    <Bubblechart {...{ heatparts}} />
                </Card>
                <Card {...{ heading: `${t('heatpartsCalculated')} ${t('inThe')} ${t('building')}: ${currentBuilding?.name}`, ...(allowEdit && { mainFunctions })}}>
                    <SortableDataTable headers={columns} data={heatpartRows} sort={sort} setSort={setSort} setData={setHeatpartRows} currentItems={currentItems} getSortedArray={getSortedArray} heatpartsList/>
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                        pageCount={pageCount}
                    />
                    {loader && <Loading/>}
                </Card>
            </>
        )}

        <ContentModal {...{ show: addLOG?.show === "add/edit", onHide: () => setAddLOG(undefined), title: t("addConfigurationsLog"), content: <AddEditConfigurationsLog {...{ roomID: addLOG?.roomID, hpID: addLOG?.hpID, heatpart: addLOG?.hp, onClose: () => setAddLOG(undefined) }} /> }} />
        <ContentModal {...{ show: addLOG?.show === "listlogs", onHide: () => setAddLOG(undefined), title: t("configurationsLog"), content: <HeatpartConfigurationsLog {...{ roomID: addLOG?.roomID, hpID: addLOG?.hpID, onClose: () => setAddLOG(undefined) }} />, size: 'xl' }} />
        <ContentModal {...{ show: show === "Calculate", onHide: onClose, title: t("calculateDIN12831"), content: <Calculate {...{ onClose }} /> }} />
    </>
}

export const HeatpartRow = ({ unit, room, heatpart, setAddLOG, variant, room_temperature }) => {
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();

    const parse_to_no_calculation = (value, digits = 2, unit = "", minus_1_text = "kein Ergebnis") => {
        const numberValue = Number(value);
        if (isNaN(numberValue)) return value;
        
        if (Number.parseFloat(value) == -1 || Number.parseFloat(value) * 1000 == -1) {
            return minus_1_text
        }
        else {
            return Number.parseFloat(value).toFixed(digits) + " " + unit
        }
    }

    const requiredKeys = ["target_mass_flow", "target_return_temp", "target_flow_temp"];
    const heatpartConfigRows = requiredKeys.map((key) => {
        const found = heatpart.configurations.find((item) =>  key in item);
        const value = found ? Object.values(found)[0] : "-";
    
        return (
            <tr key={key}>
                <td>{t(key)}: </td>
                <td>{value}</td>
            </tr>
        );
    });

    const heatpartConfigTooltip = (
        <Tooltip>
            <table style={{ border: "1px solid black", width: "100%" }}>
                <tbody>{heatpartConfigRows}</tbody>
            </table>
        </Tooltip>
    );

    return (
        <>
            <tr>
                <td className="th_td">{room.name}</td>
                <td className="th_td">
                    {variant && heatpart.target_return_temp > variant?.max_return_temp ? (
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('radiatorTooSmallToMaintainReturnTemperature', { maxReturnTemperature: Number(variant?.max_return_temp) })}</Tooltip>}>
                            <span>
                                {heatpart.name} <FontAwesomeIcon icon={faTriangleExclamation} color="yellow" style={{ paddingLeft: "5px" }} />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        heatpart.name
                    )}

                    {(heatpart?.comment !== "") && 
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{heatpart?.comment}</Tooltip>}>
                            <span>
                                <FontAwesomeIcon icon={faCircleInfo} color="yellow" style={{ paddingLeft: "5px" }} />
                            </span>
                        </OverlayTrigger>
                    }

                    <OverlayTrigger
                        trigger={["hover", "focus"]}
                        overlay={heatpartConfigTooltip}
                    >
                        <FontAwesomeIcon className="ms-1" icon={faInfoCircle} />
                    </OverlayTrigger>
                </td>
                <td className="th_td">{heatpart.strand?.name || t('unknown')}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.thermal_power_of_heatpart / 1000, 2, "kW")}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_mass_flow, 2, "kg/h")}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_return_temp, 2, "°C")}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_pressure_mbar, 2, "mbar")}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_kv, 4)}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_setting_value)}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_thermal_power_to_ambient / 1000, 2, "kW")}</td>
                <td className="th_td">{parse_to_no_calculation(room_temperature, 2, "°C")}</td>
                <td className="th_td">{parse_to_no_calculation(heatpart.target_room_temp, 2, "°C")}</td>
                <td className="th_td">
                    <div className="actions__buttonbar grid__two">
                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            overlay={<Tooltip>Add Configurations Log</Tooltip>}
                        >
                            <Button
                                disabled={!allowEdit}
                                variant="outline-primary"
                                className="p-10"
                                onClick={() => setAddLOG({ show: "add/edit", roomID: room.id, hpID: heatpart.id, hp: heatpart })}
                            >
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            overlay={<Tooltip>List Configurations Logs</Tooltip>}
                        >
                            <Button
                                variant="outline-primary"
                                className="p-10"
                                onClick={() => setAddLOG({ show: "listlogs", roomID: room.id, hpID: heatpart.id, hp: heatpart })}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
        </>
    )
};

export default BuildingHeatpartsSortableTable