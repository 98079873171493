import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import Collapse from "../components/Collapse";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faInfo,
  faInfoCircle,
  faTable,
  faToggleOn,
  faTrash,
  faCopy,
  faList,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
// import AddEditBuildingPart from "./AddEditBuildingPart";
import ContentModal from "../components/ContentModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import Card from "../components/Card";
import AddEditRoom from "./AddEditRoom";
import SecurityActionModal from "../components/SecurityActionModal";
import AddEditSimpleBuildingPart from "./AddEditSimpleBuildingPart";
import { useToast } from "../NotificationsContent";
import AddEditHeatingPart from "./AddEditHeatingPart";
import AddEditConfigurationsLog from "./AddEditConfigurationsLog";
import HeatpartConfigurationsLog from "./HeatpartConfigurationsLog";
import { useSwagger } from "../context/SwaggerContext";
import InfoRoom from "./InfoRoom";
import { usePermissions } from "../context/PermissionsContext";
import { useBuilding } from "../context/BuildingContext";
import DjangoPagination from "../components/DjangoPagination";
import Pagination from '../components/Pagination';
import { disableBtnIfChangelogType2, getBPChildrenColor, getChildrenColor } from "../util/helpers";

export const RoomTable = ({ floor, unit, rooms, updateRoom }) => {
  const { login } = useAuth();
  const client = useSwagger();
  const { t } = useTranslation();
  const _buildingGuid = useParams()["buildingGuid"];
  const [tableRooms, setTableRooms] = useState(unit?.id ? {results: rooms} : rooms);
  const [showPowerCol, setShowPowerCol] = useState(false);

  const [show, setShow] = useState(false);
  const [aimFloor, setAimFloor] = useState(undefined);
  const [room, setRoom] = useState(undefined);
  const { addToast } = useToast();
  const { currentVariantNode } = useBuilding();

  // pagination
  // für rooms unter Etage
  // rooms unter Unit pagination über backend
  const [currentPage, setCurrentPage] = useState(unit?.id ? 1 : 0);

  const pageSize = 10;
  const [pageCount, setPageCount] = useState(Math.ceil((tableRooms?.length || 0) / pageSize));

  useEffect(() => {
    if (unit?.id) return
      setPageCount(Math.ceil((tableRooms?.length || 0) / pageSize));
  }, [tableRooms, pageSize, unit]);
  
  const indexOfLastItem = (currentPage + 1) * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = (!unit?.id && (Array.isArray(tableRooms))) ? (tableRooms?.slice(indexOfFirstItem, indexOfLastItem) || []) : []


  const onClose = (refresh = false) => {
    // setAimFloor(undefined);
    // setRoom(undefined);
    setShow(false);
    refresh && floor?.id && loadRooms();
  };

  const loadRooms = useCallback(async() => {
        setTableRooms(undefined)
        if (!client || !currentVariantNode) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
          };
          const response = unit?.id ? await client.apis["building"].building_variant_unit_room_list({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, unit_uuid: unit?.id, page: currentPage}) : await client.apis["building"].building_variant_floor_room_list({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, floor_uuid: floor?.id})
    
          if (response.status === 204) {
            setTableRooms({results: []});
            setShowPowerCol(false);
            return;
          }
    
          if (response.status >= 200 && response.status < 300) {

            let showPower = false;
            if (unit?.id) {
                response.obj?.results.map((room) => (showPower = room.thermal_power > 0 ? true : false));
                setTableRooms(response.obj);
            } else {
                let floor = {};
                if (response.obj?.length > 0) floor = { ...response.obj?.[0] };
                floor.rooms.map((room) => (showPower = room.thermal_power > 0 ? true : false));
                setTableRooms(floor.rooms);
            }
            setShowPowerCol(showPower);
          }
          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          client.http.requestInterceptor = originalRequestInterceptor;
          addToast(t('room'), t('networkError'), "error");
        }
  }, [client, currentVariantNode, unit?.id, _buildingGuid, currentPage, floor?.id, login.Authorization, addToast, t]);

  const copyRoom = useCallback(async(room) => {
      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;
  
      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          return req;
        };
  
        const response = await client.apis["building"].building_variant_room_copy_create({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room?.id});
  
        if (response.status >= 200 && response.status < 300) {
          loadRooms();
        }
  
        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        client.http.requestInterceptor = originalRequestInterceptor;
        if (error.status === 423) {
          addToast(t("room"), t("lockedErrorEditCopy"), "error");
        } else {
          addToast(t('room'), t('networkError'), "error");
        }
      }
    }, [client, _buildingGuid, login.Authorization, t, currentVariantNode]);

  const tableStructure = [
    {
      col: (
        <FontAwesomeIcon
          icon={faToggleOn}
          size="sm"
          className="flex-shrink-0"
        />
      ),
      type: "icon",
    },
    {
      col: t("appartment"),
      type: "label",
    },
    {
      col: t("roomName"),
      type: "label",
    },
    {
      col: t("heatingLossSum"),
      type: "label",
    },
    {
      col: t("heatedArea"),
      type: "label",
    },
    {
      col: t("heatingLoadPerM2"),
      type: "label",
    },
    {
      col: t("roomTemperature"),
      type: "label",
    },
    {
      col: t("airExchangeRate"),
      type: "label",
    },
    {
      col: t("actions"),
      type: "buttons",
    },
  ];

  const deleteRoom = useCallback(async() => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_room_delete_all_destroy({
        building_uuid: _buildingGuid,
        variant_uuid: currentVariantNode?.id,
        room_uuid: room.id,
      });

      if (response.status >= 200 && response.status < 300) {
        addToast(t("success"), t("deletedRoom"), "success");

        if (unit?.id && tableRooms.results?.length === 1 && currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        } else if (!unit?.id && currentItems?.length === 1 && currentPage !== 0) {
          setCurrentPage(currentPage - 1)
        } else {
          loadRooms();
        }
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, loadRooms, addToast, t, room, currentVariantNode, tableRooms, currentPage])

  useEffect(() => {
    loadRooms();
  }, [loadRooms, updateRoom, unit, currentPage]);

  return (
    <>
      {tableRooms === undefined ? (
        <TablePlaceholder {...{ structure: tableStructure }} />
      ) : (!Boolean(tableRooms?.results?.length) && !Boolean(tableRooms?.length) ) ? (
        <p className="m-0">{t("noFloorRooms")}</p>
      ) : (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex"
                    style={{ width: "16px", height: "16px" }}
                  >
                    <FontAwesomeIcon
                      icon={faToggleOn}
                      size="sm"
                      className="flex-shrink-0"
                    />
                  </div>
                </th>
                <th>
                  <span>{t("roomName")}</span>
                </th>
                {unit == undefined && (
                  <th>
                    <span>{t("appartment")}</span>
                  </th>
                )}
                {floor == undefined && (
                  <th>
                    <span>{t("level")}</span>
                  </th>
                )}
                <th>
                  <span>{t("heatingLossSum")}</span>
                </th>
                <th>
                  <span>{t("heatedArea")}</span>
                </th>
                <th>
                  <span>{t("heatingLoadPerM2")}</span>
                </th>
                <th>
                  <span>{t("roomTemperature")}</span>
                </th>
                <th>
                  <span>{t("airExchangeRate")}</span>
                </th>
                {showPowerCol && (
                  <th>
                    <span>{t("thermalPower")}</span>
                  </th>
                )}
                <th>
                  <span>{t("actions")}</span>
                </th>
              </tr>
            </thead>
            {/* <tbody>
              {tableRooms?.results.map((room) => ( */}
            <tbody>
           
              {(unit?.id ? tableRooms?.results : currentItems).map((room) => (
                <RoomRow
                  key={`room-${room.id}`}
                  {...{
                    floor,
                    unit: unit,
                    room,
                    showPowerCol,
                    copyRoom,
                    setFloor: (obj) => setAimFloor(obj),
                    setRoom: (obj) => setRoom(obj),
                    setShow: (bool) => setShow(bool),
                  }}
                />
              ))}
            </tbody>
          </Table>
          {unit?.id && <DjangoPagination {...{ total_pages: tableRooms?.total_pages, currentPage, setCurrentPage }} />}
          {!unit?.id && <Pagination currentPage={currentPage} pageSize={pageSize} onPageChange={(page) => setCurrentPage(page)} pageCount={pageCount} /> }

          <ContentModal
            {...{
              show: show === "EditRoom",
              onHide: () => onClose(),
              title: t("editRoom"),
              content: <AddEditRoom {...{ onClose: (boolean) => onClose(boolean), floor, room }} />,
            }}
          />

          <ContentModal
            {...{
              show: show === "moreInfo",
              onHide: () => onClose(),
              title: t("additionalInformations"),
              content: <InfoRoom {...{ floor, room }} />,
            }}
          />

          <SecurityActionModal
            {...{
              show: show === "Delete_Room",
              onHide: () => setShow(false),
              title: t("deleteRoom"),
              question: t("deleteRoomQuestion"),
              action: () => deleteRoom(),
            }}
          />
        </>
      )}
    </>
  );
};

export const RoomRow = ({
  floor,
  unit,
  room,
  showPowerCol,
  setFloor,
  setRoom,
  setShow,
  copyRoom,
}) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const { allowEdit } = usePermissions();

  let childrenBackgroundColor = getChildrenColor(room)
  let changelogType2 = disableBtnIfChangelogType2(room)

  return (
    <>
      <tr style={{ backgroundColor: childrenBackgroundColor, border: display ? 0 : undefined }}>
        <td className="align-middle">
          <Collapse
            {...{ boolean: display, onClick: () => setDisplay(!display) }}
          />
        </td>
        <td className="align-middle">
          <span>{room.name}</span>
        </td>
        {unit == undefined && (
          <td className="align-middle">
            <span>
              {room.utilization_unit?.name || t("belongsToNoAppartment")}
            </span>
          </td>
        )}
        {floor == undefined && (
          <td className="align-middle">
            <span>{room.floor?.name}</span>
          </td>
        )}
        <td className="align-middle">
          <span>
            {room.heating_load.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            W
          </span>
        </td>
        <td className="align-middle">
          <span>
            {room.area.toLocaleString(undefined, { maximumFractionDigits: 2 })}{" "}
            m²
          </span>
        </td>
        <td className="align-middle">
          <span>
            {(room.heating_load / room.area).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            W/m²
          </span>
        </td>
        <td className="align-middle">
          <span>
            {room.room_temperature.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            °C
          </span>
        </td>
        <td className="align-middle">
          <span>
            {room.air_exchange_rate.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>
        {showPowerCol &&
          (room.thermal_power > 1000 ? (
            <td className="align-middle">
              <span>
                {(room.thermal_power / 1000).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
                kW
              </span>
            </td>
          ) : (
            <td className="align-middle">
              <span>
                {room.thermal_power.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
                W
              </span>
            </td>
          ))}
        <td className="align-middle">
          <div className={`actions__buttonbar ${changelogType2 ? 'grid__one' : 'grid__four'}`}>
            {!changelogType2 ? (
              <>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("edit")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      setRoom(room);
                      setShow("EditRoom");
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </OverlayTrigger>
                {/* <OverlayTrigger
                                trigger={['hover', 'focus']}
                                overlay={<Tooltip>{t("partTable")}</Tooltip>}>
                                <Button variant="outline-primary" className="p-10" onClick={() => { setFloor(floor); setRoom(room); setShow('add_buildingpart'); }}>
                                    <FontAwesomeIcon icon={faTable} />
                                </Button>
                            </OverlayTrigger> */}

                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("copyRoom")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      copyRoom(room);
                      setShow("copy_room");
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("moreInfo")}</Tooltip>}
                >
                  <Button
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      setRoom(room);
                      setShow("moreInfo");
                    }}
                  >
                    <FontAwesomeIcon icon={faInfo} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("delete")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      setRoom(room);
                      setShow("Delete_Room");
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </OverlayTrigger>
              </>
            ) : (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>{t("moreInfo")}</Tooltip>}
              >
                <Button
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => {
                    setRoom(room);
                    setShow("moreInfo");
                  }}
                >
                  <FontAwesomeIcon icon={faInfo} />
                </Button>
              </OverlayTrigger>
            )}
          </div>
        </td>
      </tr>
      {display && (
        <>
          <tr style={{ border: "hidden" }}>
            <td colSpan="100%">
              <BuildingPartRoomTable {...{ room }} />
            </td>
          </tr>
          <tr>
            <td colSpan="100%">
              <HeatingPartRoomTable {...{ room }} />
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export const BuildingPartRoomTable = ({ room }) => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const _buildingGuid = useParams()["buildingGuid"];
  const [buildingparts, setBuildingParts] = useState(undefined);
  const [show, setShow] = useState(false);
  const [deleteBPID, setDeleteBPID] = useState(false);
  const [editBP, setEditBP] = useState(false);
  const [parentBP, setParent] = useState(false);
  const { allowEdit } = usePermissions();
  const { currentBuilding: building, currentVariantNode } = useBuilding();
  const [currentPage, setCurrentPage] = useState(1);

  // const [bpt, setBPT] = useState(undefined);

  const client = useSwagger();

  const loadBuildingParts = useCallback(async() => {
    setBuildingParts(undefined)
    if (!client || !currentVariantNode) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_room_buildingpart_list({
        building_uuid: _buildingGuid,
        variant_uuid: currentVariantNode?.id,
        room_uuid: room.id,
        page: currentPage
      });

      if (response.status === 204) {
        setBuildingParts({results: []});
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        // let responseList = response.obj.map(bp => {
        //   return {...bp, type: t(bpt.find(bt => bt.value === bp.type).label)}
        // });
        // setBuildingParts(responseList);
        setBuildingParts(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  // }, [client, login.Authorization, _buildingGuid, room.id, t, bpt]);
  }, [client, login.Authorization, _buildingGuid, room.id, t, currentVariantNode, currentPage]);


  const tableStructure = [
    {
      col: (
        <FontAwesomeIcon
          icon={faToggleOn}
          size="sm"
          className="flex-shrink-0"
        />
      ),
      type: "icon",
    },
    {
      col: t("type"),
      type: "label",
    },
    {
      col: t("name"),
      type: "label",
    },
    {
      col: t("area"),
      type: "label",
    },
    {
      col: t("transmissionLossNotation"),
      type: "label",
    },
    {
      col: t("uValue"),
      type: "label",
    },
    {
      col: t("adjacentTemperature"),
      type: "label",
    },
    {
      col: "F1",
      type: "label",
    },
    {
      col: "F2",
      type: "label",
    },
    {
      col: t("actions"),
      type: "buttons",
    },
  ];

  const mainFunctions = [
    {
      label: t("add"),
      onClick: () => setShow("showbps"),
      key: "add",
      icon: faAdd,
    },
  ];

  const deleteBP = useCallback(async(deleteBPID) => {

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_buildingpart_destroy({
        building_uuid: _buildingGuid,
        variant_uuid: currentVariantNode?.id,
        bp_uuid: deleteBPID
      });

      if (response.status >= 200 && response.status < 300) {
        addToast(t("success"), t("deletedBuildingpart"), "success");

        if (buildingparts.results?.length === 1 && currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        } else {
          loadBuildingParts();
        }
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("responseError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, addToast, t, loadBuildingParts, currentVariantNode, buildingparts, currentPage]);

  const copyBP = useCallback(async(copyBPID) => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_buildingpart_simple_copy_create({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, buildingpart_uuid: copyBPID});

      if (response.status >= 200 && response.status < 300) {
        loadBuildingParts();
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      if (error.status === 423) {
        addToast(t("error"), t("lockedErrorEditCopy"), "error");
      } else {
        addToast(t("error"), t("responseError"), "error");
      }
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [_buildingGuid, login.Authorization, t, currentVariantNode, client]);

  const onCloseBuildingPart = (refresh = false) => {
    setShow(false);
    refresh && loadBuildingParts();
  };

  // useEffect(() => {
  //   if (bpt) return
  //   getTypes();
  // }, [bpt]);

  useEffect(() => {
    // if (bpt === undefined) return
    loadBuildingParts();
  // }, [room, loadBuildingParts, bpt]);
  }, [room, loadBuildingParts]);

  return (
    <>
      <Card
        {...{
          heading: `${t('buildingParts')} (${(
            room.transmission_losses / 1000
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })}kW)`,
          ...(allowEdit && { mainFunctions }),
        }}
      >
        {buildingparts === undefined ? (
          <TablePlaceholder {...{ structure: tableStructure }} />
        ) : !Boolean(buildingparts?.results.length) ? (
          <p className="m-0">{t("noBuildingParts")}</p>
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex"
                      style={{ width: "16px", height: "16px" }}
                    >
                      <FontAwesomeIcon
                        icon={faToggleOn}
                        size="sm"
                        className="flex-shrink-0"
                      />
                    </div>
                  </th>
                  <th>
                    <span>{t("name")}</span>
                  </th>
                  <th>
                    <span>{t("type")}</span>
                  </th>
                  <th>
                    <span>{t("area")}</span>
                  </th>
                  <th>
                    <span>{t("transmissionLossNotation")}</span>
                  </th>
                  <th>
                    <span>{t("uValue")}</span>
                  </th>
                  <th>
                    <span>{t("adjacentTemperature")}</span>
                  </th>
                  <th>
                    <span>F1</span>
                  </th>
                  <th>
                    <span>F2</span>
                  </th>
                  <th>
                    <span>{t("actions")}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {buildingparts?.results.map((buildingpart) => (
                  <BuildingPartRow
                    key={`bp-${buildingpart.id}`}
                    {...{
                      building,
                      room,
                      buildingpart,
                      copyBP,
                      reload: () => loadBuildingParts(),
                      setShow: (bool) => setShow(bool),
                      deleteBP: (id) => setDeleteBPID(id),
                      setEditBP: (buildingpart) => setEditBP(buildingpart),
                      setParent: (buildingpart) => setParent(buildingpart),
                    }}
                  />
                ))}
              </tbody>
            </Table>
            <DjangoPagination {...{ total_pages: buildingparts?.total_pages, currentPage, setCurrentPage }} />
          </>
        )}
      </Card>
    
      <ContentModal
        {...{
          show: show === "showbps",
          onHide: () => setShow(false),
          title: t("addBuildingpart"),
          content: (
            <AddEditSimpleBuildingPart
              {...{
                // onClose: () => {
                //   loadBuildingParts();
                //   setShow(false);
                // },
                onClose: (boolean) => onCloseBuildingPart(boolean),
                room,
                building
              }}
            />
          ),
        }}
      />

      <ContentModal
        {...{
          show: show === "editBP",
          onHide: () => setShow(false),
          title: t("editBuildingpart"),
          content: (
            <AddEditSimpleBuildingPart
              {...{
                onClose: (boolean) => onCloseBuildingPart(boolean),
                room,
                editBP,
                building
              }}
            />
          ),
        }}
      />

      <ContentModal
        {...{
          show: show === "addChild",
          onHide: () => setShow(false),
          title: t("partTable"),
          content: (
            <AddEditSimpleBuildingPart
              {...{
                onClose: (boolean) => onCloseBuildingPart(boolean),
                room,
                parentBP,
                building
              }}
            />
          ),
        }}
      />

      <SecurityActionModal
        {...{
          show: deleteBPID,
          onHide: () => setDeleteBPID(false),
          title: t("deleteBuildingPart"),
          question: t("questionDeleteBuildingPart"),
          action: () => deleteBP(deleteBPID),
        }}
      />
    </>
  );
};

export const BuildingPartRow = ({
  building,
  buildingpart,
  floor,
  room,
  reload,
  setShow,
  deleteBP,
  setEditBP,
  setParent,
  copyBP,
  count,
  ...props
}) => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const client = useSwagger();
  const [type, setType] = useState(undefined)
  const { allowEdit } = usePermissions();

  let childrenBackgroundColor = getBPChildrenColor(buildingpart)
  let changelogType2 = disableBtnIfChangelogType2(buildingpart)

  const getTypes = useCallback(async() => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["buildingparttypes"].buildingparttypes_list();

      if (response.status === 204) {
        setType(undefined)
        return
      }

      if (response.status >= 200 && response.status < 300) {
        let matchedItem = response.obj.find(item => item.value === buildingpart.type);
        if (matchedItem) {
          const labelMap = {
              Floor: "floor",
              OuterWall: "outerwall",
              Ceiling: "ceiling",
              Window: "window",
              OuterDoor: "outerdoor",
              Roof: "roof",
              InnerWall: "innerwall",
              InnerDoor: "innerdoor",
              InnerCeiling: "innerceiling",
              Shutterbox: "shutterbox",
              "Virtual Group": "virtualgroup",
              "Ground Soil" : "groundsoil",
              "OuterWall Soil": "outerwallsoil",
          };
          matchedItem.label = labelMap[matchedItem.label] || matchedItem.label;
        }
        
        if (buildingpart.type === 100) matchedItem = "virtualgroup"
        if (buildingpart.type === 101) matchedItem = "deductionSurfaceWithoutLoss"

        setType(matchedItem?.label ? matchedItem.label : matchedItem);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      client.http.requestInterceptor = originalRequestInterceptor;
    }

  }, [client, login.Authorization, buildingpart])

  useEffect(() => {
    getTypes()
  }, [buildingpart])

  const [buildingPartRowCount, setBuildingPartRowCount] = useState(0);

  const incrementRowCount = () => {
    setBuildingPartRowCount(count ? count + 1 : 1);
  };

  useEffect(() => {
    incrementRowCount();
  }, []);

  return (
    <>
        {/* <tr style={props.style ? { backgroundColor: "rgb(120, 120, 120, 0.5)" } : {}}> */}
        <tr style={(!buildingpart?.from_parent && props.style) ? { backgroundColor: "rgb(120, 120, 120, 0.5)" } : { backgroundColor: childrenBackgroundColor }}>
        <td className="align-middle" style={(props.style && count <=10)  ? { paddingLeft: `${count * 10}px` } : {}}>
          {buildingpart?.children ? (
            buildingpart?.children.length > 0 ? (
              <Collapse
                {...{
                  boolean: display,
                  onClick: () => setDisplay(!display),
                  color: "#ffffff",
                }}
              />
            ) : (
                <span>-</span>
            )
          ) : (
            <>{t("deduction")}</>
          )}
        </td>
        <td className="align-middle">
          <span>{buildingpart?.name}</span>
          {(buildingpart?.individual?.comment && buildingpart?.individual?.comment !== "") &&
            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{buildingpart?.individual?.comment}</Tooltip>}>
              <span>
                <FontAwesomeIcon icon={faCircleInfo} color="yellow" style={{ paddingLeft: "5px" }} />
              </span>
            </OverlayTrigger>
          }
        </td>
        {/* <td className="align-middle">
          <span>{t(buildingpart?.type)}</span>
        </td> */}
        <td className="align-middle">{t(`${type || ""}`)}</td>
        <td className="align-middle">
          <span>
            {buildingpart.type === 100 ? `${buildingpart.additional_volume.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })} m³` : `${buildingpart?.area.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })} m²`}
          </span>
        </td>
        <td className="align-middle">
          <span>
            {(buildingpart?.transmission_losses).toLocaleString(
              undefined,
              { maximumFractionDigits: 2 }
            )}{" "}
            W
          </span>
        </td>
        <td className="align-middle">
          <span>
            {(buildingpart?.u_value || -1).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            W/m²/K
          </span>
        </td>
        <td className="align-middle">
          <span>
            {(
              room.room_temperature +
              buildingpart?.f2 *
                buildingpart?.f *
                (building.design_temperature - room.room_temperature)
            ).toLocaleString(undefined, { maximumFractionDigits: 2 })}{" "}
            °C
          </span>
        </td>
        <td className="align-middle">
          <span>
            {buildingpart?.f.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>
        <td className="align-middle">
          <span>
            {buildingpart?.f2.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>

        <td className="align-middle">
          <div className={`actions__buttonbar ${changelogType2 ? '' : 'grid__four'}`}>
            {!changelogType2 && (
              <>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("add")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      setShow("addChild");
                      setParent(buildingpart);
                    }}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("copy")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => copyBP(buildingpart.id)}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("edit")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => {
                      setShow("editBP");
                      setEditBP(buildingpart);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </OverlayTrigger>
              
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("delete")}</Tooltip>}
                >
                  <Button
                    disabled={!allowEdit}
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => deleteBP(buildingpart.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </OverlayTrigger>
              </>
            )}
          </div>
        </td>
      </tr>
      {display &&
        buildingpart.children.map((ded) => (
          <BuildingPartRow
            buildingpart={ded}
            building={building}
            room={room}
            copyBP={copyBP}
            deleteBP={deleteBP}
            setEditBP={setEditBP}
            setParent={setParent}
            setShow={setShow}
            style={true}
            count={buildingPartRowCount} 
          />
        ))}
    </>
  );
};

export const HeatingPartRoomTable = ({ room }) => {
  const { addToast } = useToast();
  const { login } = useAuth();
  const { t } = useTranslation();
  const _buildingGuid = useParams()["buildingGuid"];
  const [heatingparts, setHeatingparts] = useState(undefined);
  const [show, setShow] = useState(undefined);
  const [deleteHPID, setDeleteHPID] = useState(false);
  const [addLOG, setAddLOG] = useState(false);
  const [loadLogs, setLoadLogs] = useState(false);
  const [editHP, setEditHP] = useState(false);
  const { allowEdit } = usePermissions();
  const { currentBuilding: building, currentVariantNode } = useBuilding();
  const [currentPage, setCurrentPage] = useState(1);

  const client = useSwagger();

  const loadHeatingParts = useCallback(async() => {
    setHeatingparts(undefined)
    if (!client || !currentVariantNode) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_room_heatpart_list({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room.id, page: currentPage});

      if (response.status === 204) {
        setHeatingparts({results: []});
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        setHeatingparts(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, _buildingGuid, room.id, t, currentVariantNode, currentPage]);

  const mainFunctions = [
    {
      label: t("addComponent"),
      onClick: () => {setShow("showHp"); setEditHP(false)},
      key: "add_component",
      icon: faAdd,
    },
  ];

  const tableStructure = [
    {
      col: t("name"),
      type: "label",
    },
    {
      col: t("type"),
      type: "label",
    },
    {
      col: t("calculatedThermalPowerToAmbient"),
      type: "label",
    },
    {
      col: t("calculatedVolumeFlow"),
      type: "label",
    },
    {
      col: t("designThermalPower"),
      type: "label",
    },
    {
      col: t("actions"),
      type: "buttons",
    },
  ];

  const deleteHP = useCallback(async(hpID) => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_variant_room_heatpart_destroy({
        building_uuid: _buildingGuid,
        variant_uuid: currentVariantNode?.id,
        room_uuid: room.id,
        room_heatpart_uuid: hpID,
      });

      if (response.status >= 200 && response.status < 300) {
        addToast(t("success"), t("hpDeletedSuccessfully"), "success");

        if (heatingparts.results?.length === 1 && currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        } else {
          loadHeatingParts();

        }
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("responseError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, addToast, t, loadHeatingParts, currentVariantNode, heatingparts, currentPage]);

  const copyHP = useCallback(async(hpID) => {
      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          return req;
        };

        const response = await client.apis["building"].building_variant_room_heatpart_copy_create({building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room.id, room_heatpart_uuid: hpID});

        if (response.status >= 200 && response.status < 300) {
            loadHeatingParts();
        }

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        
        if (error.status === 423) {
          addToast(t("error"), t("lockedErrorEditCopy"), "error");
        } else {
          addToast(t("error"), t("responseError"), "error");
        }
        client.http.requestInterceptor = originalRequestInterceptor;
      }
      
    }, [client, _buildingGuid, loadHeatingParts, login.Authorization, t, currentVariantNode]);

    const editSelectedHP = (hpID) => {
     setShow("showHp")
     setEditHP(hpID)
    }

  useEffect(() => {
    loadHeatingParts();
  }, [room, loadHeatingParts]);

  return (
    <>
      <Card
        {...{
          heading: `${t("HeatingParts")} (${(
            room.thermal_power / 1000
          ).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} kW möglich, Überdimensionierung: ${(
            room.thermal_power / room.heating_load
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })})`,
          ...(allowEdit && { mainFunctions }),
          
        }}
      >
        {heatingparts === undefined ? (
          <TablePlaceholder {...{ structure: tableStructure }} />
        ) : !Boolean(heatingparts?.results.length) ? (
          <p className="m-0">{t("noHeatingParts")}</p>
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <span>{t("name")}</span>
                  </th>
                  <th>
                    <span>{t("type")}</span>
                  </th>
                  <th>
                    <span>{t("calculatedThermalPowerToAmbient")}</span>
                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('scenario_flow_temp_calculated_kv')}</Tooltip>}>
                      <FontAwesomeIcon icon={faCircleInfo} style={{ paddingLeft: "5px" }} />
                    </OverlayTrigger>
                  </th>
                  <th>
                    <span>{t("radiator_valve")}</span>
                  </th>
                  <th>
                    <span>{t("calculated_K_v")}</span>
                  </th>
                  <th>
                    <span>{t("configurationValue")}</span>
                  </th>
                  <th>
                    <span>{t("normHeatpower")}</span>
                  </th>
                  <th>
                    <span>{t("strand")}</span>
                  </th>
                  <th>
                    <span>{t("actions")}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {heatingparts?.results.map((heatingpart) => (
                  <HeatingPartRow
                    key={`bp-${heatingpart.id}`}
                    {...{
                      building,
                      room,
                      heatingpart,
                      copyHP,
                      editSelectedHP,
                      deleteHP: (id) => setDeleteHPID(id),
                      addLog: (id) => setAddLOG(id),
                      loadLogs: (bool) => setLoadLogs(bool)
                    }}
                  />
                ))}
              </tbody>
            </Table>
            <DjangoPagination {...{ total_pages: heatingparts?.total_pages, currentPage, setCurrentPage }} />
          </>
        )}
      </Card>
      <ContentModal {...{ show: addLOG, onHide: () => setAddLOG(false), title: t("addConfigurationsLog"), content:  <AddEditConfigurationsLog {...{ roomID: room.id, hpID: addLOG.id, heatpart: addLOG, onClose: () => setAddLOG(false)  }} /> }} />
      <ContentModal {...{ show: loadLogs, onHide: () => setLoadLogs(false), title: t("configurationsLog"), content:  <HeatpartConfigurationsLog {...{ roomID: room.id, hpID: loadLogs, disableBtnIfChangelogType2, onClose: () => setLoadLogs(false) }} />, size: 'xl' }} />
      {/* <ContentModal {...{ show: show === "editHp", onHide: () => setShow(false), title: t("partTable"), content:  <AddEditBuildingPart {...{ onClose: () => setShow(false), room, building }} /> }} /> */}
      <ContentModal {...{ show: show === "showHp", onHide: () => setShow(false), title: t("add_heatpart"), content: <AddEditHeatingPart {...{ onClose: () => { loadHeatingParts(); setShow(false); }, room, editHP }} /> }} />
      <SecurityActionModal {...{ show: deleteHPID, onHide: () => setDeleteHPID(false), title: t("deleteHeatingPart"), question: t("questionDeleteHeatingPart"), action: () => deleteHP(deleteHPID) }} />
    </>
  );
};

export const HeatingPartRow = ({
  building,
  room,
  heatingpart,
  deleteHP,
  addLog,
  copyHP,
  editSelectedHP,
  loadLogs 
}) => {
  const { login } = useAuth();
  const client = useSwagger();
  const { t } = useTranslation();
  const [type, setType] = useState(undefined)
  const { allowEdit } = usePermissions();

  let childrenBackgroundColor = getChildrenColor(heatingpart)
  let changelogType2 = disableBtnIfChangelogType2(heatingpart)

  const getTypes = useCallback(async() => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["heatingparttypes"].heatingparttypes_list();

      if (response.status === 204) {
        setType(undefined)
        return
      }

      if (response.status >= 200 && response.status < 300) {
        const transformedHpt = response.obj?.map(h => ({
          ...h, 
          label: h.label.toLowerCase().replace(/[\s-]+/g, '_')
        }));

        // const matchedItem = response.obj.find(item => item.value === heatingpart.type);
        const matchedItem = transformedHpt.find(item => item.value === heatingpart.type);

        if (matchedItem) {
          // const labelMap = {
          //     Pipe: "pipe",
          //     Radiator: "radiator",
          //     Heatsource: "heatsource",
          //     Valve: "valve",
          //     Other: "other",
          //     "Virtual Calculation-Group": "virtualgroup",
          //     Pump: "pump",
          //     RadiatorValve: "radiatorvalve"
          // };
      
          // matchedItem.label = labelMap[matchedItem.label] || matchedItem.label;
          setType(matchedItem.label);
        }
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      client.http.requestInterceptor = originalRequestInterceptor;
    }

  }, [client, login.Authorization, heatingpart])

  useEffect(() => {
    getTypes()
  }, [heatingpart])

  return (
    <tr style={{ backgroundColor: childrenBackgroundColor }}>
      <td className="align-middle">
        {heatingpart.name}
        {(heatingpart?.comment && heatingpart?.comment !== "") && 
          <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{heatingpart?.comment}</Tooltip>}>
            <span>
              <FontAwesomeIcon icon={faCircleInfo} color="yellow" style={{ paddingLeft: "5px" }} />
            </span>
          </OverlayTrigger>
        }
      </td>
      {/* <td className="align-middle">{heatingpart.type}</td> */}
      <td className="align-middle">{t(`${type || ""}`)}</td>
      <td className="align-middle">
        {heatingpart.target_thermal_power_to_ambient > 0
          ? heatingpart.target_thermal_power_to_ambient.toLocaleString(
              undefined,
              { maximumFractionDigits: 2 }
            ) + " W"
          : t("to_calculate")}
      </td>
      <td className="align-middle">{heatingpart.radiator_throttle?.name || t('unknown')}</td>
      <td className="align-middle">
        {heatingpart.target_kv > 0 ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip>
                <div>
                  {t("target_pressure") +
                    ": " +
                    (heatingpart.strand &&
                    heatingpart?.target_pressure_mbar > 0
                      ? heatingpart.target_pressure_mbar.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        ) + "  mbar"
                      : t("to_calculate"))}
                  <br />
                  {t("target_mass_flow") +
                    ": " +
                    (heatingpart.target_mass_flow > 0
                      ? heatingpart.target_mass_flow.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        }) + "  kg/h"
                      : t("to_calculate"))}
                  <br />
                  {t("target_return_temp") +
                    ": " +
                    (heatingpart.target_return_temp > 0
                      ? heatingpart.target_return_temp.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        ) + "  °C"
                      : t("to_calculate"))}
                  <br />
                </div>
              </Tooltip>
            }
          >
            <div>
              {heatingpart.target_kv > 0
                ? heatingpart.target_kv.toLocaleString(undefined, {
                    maximumFractionDigits: 5,
                  })
                : t("to_calculate")}
              <FontAwesomeIcon className="mx-2" icon={faInfoCircle} />
            </div>
          </OverlayTrigger>
        ) : (
          t("to_calculate")
        )}
      </td>
      <td className="align-middle">
        {heatingpart.target_setting_value > 0
          ? heatingpart.target_setting_value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : t("to_calculate")}
      </td>
      <td className="align-middle">
        {(heatingpart.thermal_power_of_heatpart || 0).toLocaleString(
          undefined,
          { maximumFractionDigits: 2 }
        )}{" "}
        W
      </td>
      <td className="align-middle">
        {heatingpart.strand?.name||t('unknown')} 
      </td>
      {/* <td className="align-middle">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip>
                        <div>
                            <table>
                                <thead>
                                    <th>{t('spreading')}</th>
                                    <th>VL</th>
                                    <th>{t('volumeWithoutUnit')}</th>
                                </thead>
                                <tbody>
                                {heatingpart.minimum_possible_configuration.map(ele => <tr>
                                        <td className="align-middle">{(ele.spreading_temperature).toLocaleString(undefined, { maximumFractionDigits: 1 })} K</td>
                                        <td className="align-middle">{(ele.flow_temperature).toLocaleString(undefined, { maximumFractionDigits: 1 })} °C</td>
                                        <td className="align-middle">{(ele.volume_flow).toLocaleString(undefined, { maximumFractionDigits: 1 })} l/h</td>
                                    </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </Tooltip>}>
                    <div>
                        {(heatingpart.calculated_volume_flow).toLocaleString(undefined, { maximumFractionDigits: 1 })} l/h 
                        <FontAwesomeIcon className='mx-2' icon={faInfoCircle} />
                    </div>
                </OverlayTrigger>
            </td>
            <td className="align-middle">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip>
                        <div>
                            <p>{t('interpretation')}<br />
                                VL: {heatingpart.design_flow_temperature}<br />
                                RL: {heatingpart.design_return_temperature}<br />}
                                {t('room')}: {heatingpart.design_room_temperature}</p>
                        </div>
                    </Tooltip>}>
                    <div>
                         {(heatingpart.design_thermal_power).toLocaleString(undefined, { maximumFractionDigits: 0 })} W
                        <FontAwesomeIcon className='mx-2' icon={faInfoCircle} />
                    </div>
                </OverlayTrigger>
            </td> */}

      <td className="align-middle">
        <div className={`actions__buttonbar ${changelogType2 ? 'grid__one' : 'grid__five'}`}>
          {changelogType2 ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>List All Configurations Log</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => loadLogs(heatingpart.id)}
              >
                <FontAwesomeIcon icon={faList} />
              </Button>
            </OverlayTrigger>
          ) : (
            <>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Add Configurations Log</Tooltip>}
              >
                <Button
                  disabled={!allowEdit}
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => addLog(heatingpart)}
                >
                  <FontAwesomeIcon icon={faAdd} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>List All Configurations Log</Tooltip>}
              >
                <Button
                  disabled={!allowEdit}
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => loadLogs(heatingpart.id)}
                >
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>{t("copy")}</Tooltip>}
              >
                <Button
                  disabled={!allowEdit}
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => copyHP(heatingpart.id)}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>{t("edit")}</Tooltip>}
              >
                <Button
                  disabled={!allowEdit}
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => editSelectedHP(heatingpart)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>{t("delete")}</Tooltip>}
              >
                <Button
                  disabled={!allowEdit}
                  variant="outline-primary"
                  className="p-10"
                  onClick={() => deleteHP(heatingpart.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </OverlayTrigger>
          </>
        )}
        </div>
      </td>
    </tr>
  );
};
