import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useSwagger } from "../context/SwaggerContext";

export const Calculate = ({ onClose }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();
    const client = useSwagger();
    const [loading, setLoading] = useState(false);

    const submit = useCallback(async(e) => {
        if (loading) return
        setLoading(true)
        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_calculate_intern_retrieve({building_uuid: _buildingGuid});

            if (response.status >= 200 && response.status < 300) {
                addToast(t("DIN 12831"), t("calculation_started"), "success");
                setLoading(false)
                onClose && onClose(true);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            let msg = "";
            if (statusCode === 400) {
                if (error.response.obj.i18next_message == "no_radiator_throttle")
                    msg = "in Raum " + error.response.obj.room + " in " + error.response.obj.floor + " (Stockwerk " + error.response.obj.level + ")";
                
                setLoading(false)
                addToast(t("DIN 12831"), t("calculate_bad_request." + error.response.obj.i18next_message?.toLowerCase()) + "\n" + msg, "error"); 
            } else if (statusCode === 406) {
                if (error.response.ob.i18next_message == "error_calculation_found_no_config")
                    msg = "in Raum " + error.response.ob.room + " in " + error.response.ob.floor + " (Stockwerk " + error.response.ob.level + ")";
                
                addToast(t("DIN 12831"), t("calculate_bad_request." + error.response.ob.i18next_message?.toLowerCase()) + "\n" + msg, "error");
            } else {
                addToast(t("DIN 12831"), t("networkError"), "error");
            }
            onClose && onClose(true);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, loading]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') submit(event);
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress)
    }, []);

    return (
        <Form onSubmit={(e) => submit(e)}>
            <Form.Group className={'d-block mb-3'}>
                <Form.Label>Die Berechnungsergebnisse sind in den Räumen zu sehen und zudem auch in einer PDF-Ausgabe im Bereich "Energieberichte" verfügbar.</Form.Label>
            </Form.Group>

            <ProgressBar className="mb-3" animated={loading} now={100} disabled={!loading} />

            <div className="d-flex flex-end">
                <Button variant="outline-primary" type="submit" disabled={loading}>{t('calculateNow')}</Button>
            </div>
        </Form>
    );
};
