import React from 'react'

const Loading = ({chat}) => {
  return (
    <div className={chat ? 'loading_chat' : 'loading_container'}>
        {/* <p>Loading...</p>
        <p className='loading_icon'><FontAwesomeIcon icon={faSpinner} spin /></p> */}
        <div className="lds-ripple"><div></div><div></div></div>
    </div>
  )
}

export default Loading