import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useBuilding } from "../context/BuildingContext";
import { useSwagger } from "../context/SwaggerContext";
import { usePermissions } from "../context/PermissionsContext";
import { useTranslation } from "react-i18next";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCalculator, faCopy, faEdit, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import DjangoPagination from "../components/DjangoPagination";
import { useToast } from "../NotificationsContent";
import { RoomTable } from "./BuildingRoom";
import Collapse from "../components/Collapse";
import SecurityActionModal from "../components/SecurityActionModal";
import ContentModal from "../components/ContentModal";
import { Calculate } from "./CalculateDIN12831";
import { AddUnit } from "./AddEditUnit";
import AddEditRoom from "./AddEditRoom";
import { disableBtnIfChangelogType2, getChildrenColor } from "../util/helpers";

export const BuildingUnits = () => {

    return (
        <MainLayout {...{ background: Background }}>
            <div className="sidebar__padding">
                <UtilizationUnitTable />
            </div>
        </MainLayout>
    )
}

const UtilizationUnitTable = () => {
    const { login } = useAuth()
    const { t } = useTranslation();
    const { addToast } = useToast();
    const client = useSwagger();
    const { allowEdit } = usePermissions();
    const { currentBuilding, currentVariantNode } = useBuilding();

    const [show, setShow] = useState(undefined);
    const [units, setUnits] = useState(undefined);
    const [unit, setUnit] = useState(undefined);
    const [room, setRoom] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [isStable, setIsStable] = useState(false);

    const mainFunctions = [
        {
            label: t("addUnit"),
            onClick: () => setShow("Add Unit"),
            key: "add_unit",
            icon: faAdd,
        }, {
            label: t("calculate"),
            onClick: () => setShow("Calculate"),
            key: "calculate",
            icon: faCalculator,
        }
    ];
    
    const tableStructure = [
        {
            col: <div className="d-flex" style={{ width: "16px", height: "16px" }}><FontAwesomeIcon icon={faToggleOn} size="sm" className="flex-shrink-0" /></div>,
            type: "icon",
        }, {
            col: t("name"),
            type: "label",
        }, {
            col: t("entranceLevel"),
            type: "label",
        }, {
            col: t("area"),
            type: "label",
        }, {
            col: t("calculatedArea"),
            type: "label",
        }, {
            col: t("heatingLoad"),
            type: "label",
        }, {
            col: t("thermalPower"),
            type: "label",
        }, {
            col: t("actions"),
            type: "buttons",
        },
    ];

    const loadUtilizationUnits = useCallback(async() => { 
        setUnits(undefined)
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
            const response = await client.apis["building"].getUnit({ building_uuid: currentBuilding.id, variant_uuid: currentVariantNode.id, page: currentPage, ordering: "entrance_level__level" })

            if (response.status >= 200 && response.status < 300) {
                setUnits(response.obj || {results: []})
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, currentVariantNode, addToast, t, currentPage]);

    const copyUnit = useCallback(async(unit) => {
        if (!client || !unit) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_variant_unit_copy_create({ building_uuid: currentBuilding.id, variant_uuid: currentVariantNode.id, unit_uuid: unit.id })
            
            if (response.status >= 200 && response.status < 300) loadUtilizationUnits();
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            if (error.status === 423) {
                addToast(t("error"), t("lockedErrorEditCopy"), "error");
            } else {
                addToast(t("error"), t("networkError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, currentVariantNode, loadUtilizationUnits, addToast, t]);
    
    const deleteUnit = useCallback(async() => {
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_variant_unit_destroy({ building_uuid: currentBuilding.id, variant_uuid: currentVariantNode.id, unit_uuid: unit?.id })
        
            if (response.status >= 200 && response.status < 300) {
                setUnit(undefined)
                addToast(t("success"), t("deletedUnit"), "success");

                
                if (units.results?.length === 1 && currentPage !== 1) {
                    setCurrentPage(currentPage - 1)
                } else {
                    loadUtilizationUnits();
                }
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, currentVariantNode, loadUtilizationUnits, addToast, t, unit, currentPage, units]);

    const getUnitRooms = useCallback(async() => {
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_variant_unit_room_list({ building_uuid: currentBuilding.id, variant_uuid: currentVariantNode.id, unit_uuid: unit?.id })
        
            if (response.status >= 200 && response.status < 300) {
                const updatedUnits = units?.results.map(u =>
                    u.id === unit.id ? { ...u, unit_rooms: response.obj } : u
                );
                setUnits(prevUnits => ({
                    ...prevUnits, 
                    results: updatedUnits 
                }));
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, currentBuilding, currentVariantNode, unit, login.Authorization, units?.results, addToast, t]);

    const onClose = (refresh = false, getRooms = false) => {
        setShow(false);
        setUnit(undefined);
        setRoom(undefined);
        if (refresh) loadUtilizationUnits();
        if (getRooms) getUnitRooms();
    };

    useEffect(() => {
        if (!currentBuilding || !currentVariantNode) return
        setIsStable(true)
    }, [currentBuilding, currentVariantNode]);

    useEffect(() => {
        if (!isStable || !currentVariantNode) return
        loadUtilizationUnits()
    }, [isStable, currentVariantNode, currentPage])

    return (
        <>
            <Card {...{ heading: `${t('utilizationUnits')} ${t('inThe')} ${t('building')}: ${isStable ? currentBuilding?.name : t('loading')}`, ...(allowEdit && { mainFunctions }) }}>
                {units === undefined ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : !Boolean(units?.results?.length) ? (
                    <p className="m-0">{t("noUnits")}</p>
                ) : (
                    <>
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{(ts.type == "label") ? t(ts.col) : ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {units?.results.map((unit) => <UnitRow key={`unit-${unit.id}`} {...{ unit, setUnit, setShow: (key) => setShow(key), reload: () => loadUtilizationUnits(), copyUnit, deleteUnit }} />)}
                            </tbody>
                        </Table>
                        <DjangoPagination {...{ total_pages: units?.total_pages, currentPage, setCurrentPage }} />
                    </>
                )}
            </Card>

            <ContentModal {...{ show: show === "Calculate", onHide: () => onClose(), title: t("calculateDIN12831"), content: <Calculate {...{ onClose: (boolean) => onClose(boolean) }} /> }} />
            <ContentModal {...{ show: show === "Add Unit", onHide: () => onClose(), title: t("unitAddEdit"), content: <AddUnit {...{ unit, onClose: (boolean) => onClose(boolean) }} /> }} />
            <ContentModal {...{ show: show === "Room", onHide: () => onClose(), title: `${t("unit")} ${unit?.name}`, content: <AddEditRoom {...{ unit, onClose: (boolean) => onClose(boolean, true) }} /> }} />
            <SecurityActionModal {...{ show: show === "Delete_Unit", onHide: () => onClose(), title: t("deleteUnit"), question: t("deleteUnitQuestion"), action: () => deleteUnit() }} />
        </>
    )
}

const UnitRow = ({ unit, setUnit, setShow, reload, copyUnit }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    const { allowEdit } = usePermissions();

   let childrenBackgroundColor = getChildrenColor(unit)
   let changelogType2 = disableBtnIfChangelogType2(unit)

    return (
        <>
            <tr style={{ backgroundColor: childrenBackgroundColor }}>
                <td className="align-middle">
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td className="align-middle">
                    <span>{unit.name}</span>
                </td>
                <td className="align-middle">
                    <span>{unit.entrance_level?.name || t("NA")}</span>
                </td>
                <td className="align-middle">
                    <span> {unit.area.toLocaleString(undefined, { maximumFractionDigits: 0 })} m²</span>
                </td>
                <td className="align-middle">
                    <span>{unit.calculated_area.toLocaleString(undefined, { maximumFractionDigits: 2 })} m²</span>
                </td>
                <td className="align-middle">
                    <span>{(unit.heating_load / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    <span>{(unit.thermal_power / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    {/* <div className="d-flex gap-3"> */}
                    <div className={`actions__buttonbar ${changelogType2 ? '' : 'grid__four'}`}>
                        {!changelogType2 && (
                            <>
                            <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("addRoom")}</Tooltip>}>
                                <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setUnit(unit); setShow("Room") }}>
                                    <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                        <FontAwesomeIcon icon={faAdd} size='sm' className='flex-shrink-0'/>
                                    </div>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("edit")}</Tooltip>}>
                                <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setUnit(unit); setShow("Add Unit") }}>
                                    <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                        <FontAwesomeIcon icon={faEdit} size='sm' className='flex-shrink-0' />
                                    </div>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("copyUnit")}</Tooltip>}>
                                <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { copyUnit(unit); setShow("copy_unit") }}>
                                    <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                        <FontAwesomeIcon icon={faCopy} size='sm' className='flex-shrink-0' />
                                    </div>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("delete")}</Tooltip>}>
                                <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setUnit(unit); setShow("Delete_Unit") }}>
                                    <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                        <FontAwesomeIcon icon={faTrash} size='sm' className='flex-shrink-0' />
                                    </div>
                                </Button>
                            </OverlayTrigger>
                            </>
                        )}
                    </div>
                </td>
            </tr>
            {display && (
                <tr>
                    <td colSpan="100%">
                        <Card {...{ heading: t('unit') + ": " + unit.name, active: true, className: "height-fit" }}>
                            <RoomTable {...{ floor: unit.entrance_level, rooms: unit.unit_rooms, unit, reload }} />
                        </Card>
                    </td>
                </tr>
            )}
        </>
    )
}

export default BuildingUnits;