import { useState, useEffect, useCallback, useRef } from "react"
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useToast } from "../NotificationsContent";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import { getChangedFields, isEmpty, validateIBAN } from '../util/helpers';

// import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { useSwagger } from "../context/SwaggerContext";
import AddLicence from "./AddLicence";
import DjangoPagination from "../components/DjangoPagination";

// const PaymentComponent = ({ onHide }) => {
//     const { t } = useTranslation();
//     const { addToast } = useToast();
//     const btnRef = useRef();
//     // const stripe = useStripe();
//     // const elements = useElements();

//     const handleSubmit = async(event) => {
//         event.preventDefault();
//         // btnRef.current.disabled = true;

//         // if (!stripe || !elements) return;

//         // const result = await stripe.confirmSetup({
//         //     elements,
//         //     redirect: 'if_required'
//         // });

//         // if (result.error) {
//         //     addToast(t("error"), result.error, "error");
//         //     btnRef.current.disabled = false;
//         // } else {
//         //     addToast(t("success"), t("paymentMethodAddedSuccessfully"), "success");
//         //     onHide();
//         // }
//     }

//     return (
//         // <></>
//         // <form onSubmit={(e) => handleSubmit(e)}>
//         //     <PaymentElement className="mb-3" />
//         //     <div className="d-flex justify-content-end">
//         //         <Button ref={btnRef} variant="outline-primary" type="submit">{t("submit")}</Button>
//         //     </div>
//         // </form>
//     );
// };

// {/* HIER ADD PAYMENT METHOD */}
// export const PaymentMethod = ({ paymentMethod, paymentMethodHandler, setPaymentAgreement, paymentAgreement, highlightCheck }) => {
export const PaymentComponent = ({cameFromAddLicence, planID, onHide, payment}) => {
    const [wizardStep, setWizard] = useState(1);

    const _orgGuid = useParams()['orgGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const [types, setTypes] = useState([])
    const client = useSwagger();

    let initialPaymentMethod = {
        name: payment ? payment.name : "", 
        data: {bic: payment ? payment.data.bic : "", iban: payment ? payment.data.iban : "" }, 
        type: payment ? payment.type : undefined
    }

    const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);
    // const [paymentMethod, setPaymentMethod] = useState(payment || {name: "", data: {bic: "", iban: "" }, type: undefined});
    const [isValid, setIsValid] = useState(payment ? true : null);
    const [paymentAgreement, setPaymentAgreement] = useState(false);
    let initialDefaultPayment = payment?.default || false
    const [defaultPayment, setDefaultPayment] = useState(initialDefaultPayment);
    const [highlightCheck, setHighlightCheck] = useState(false);

    const paymentMethodHandler = useCallback((e) => {
        const { id, value } = e.target;

        if (id === "iban" || id === "bic") {
            setPaymentMethod(prevState => ({
                ...prevState,
                  data: {
                      ...prevState.data,
                      [id]: value,
                  }
            }))
        } else {
            setPaymentMethod(prevState => ({
                ...prevState,
                [id]: value,
            }))
        }

        if (id == "iban") {
          setIsValid(validateIBAN(value));
        }
        
    }, [paymentMethod])

    const getPaymentTypes = useCallback(async() => {
      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;
  
      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          return req;
        };
  
        const response = await client.apis["payment"].payment_types_retrieve();
  
        if (response.status >= 200 && response.status < 300) {
          let paymentMethods = response.obj.map(method => {
            if (method.label === "SEPA_Direct_Debit") {
                return {
                    ...method,
                    label: "SEPA-Lastschriftmandat"
                };
            }
            return method;
        });
          setTypes(paymentMethods)
        }
  
        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        console.error(error);
        addToast(t("error"), t("responseError"), "error");
  
        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, login.Authorization, t, addToast])

    useEffect(() => {
        getPaymentTypes();
    }, [])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        
        if(!isValid) {
            addToast(t("error"), t("verifyIBAN"), "error");
            return
        }

        if(!paymentAgreement) {
            setHighlightCheck(true);
            addToast(t("error"), t("registrationPaymentConditions"), "error");
            return
        }

        const changedFields = getChangedFields(paymentMethod, initialPaymentMethod);

        if (!client) return;
        if (!cameFromAddLicence && isEmpty(changedFields) && initialDefaultPayment === defaultPayment && payment) {
            onHide()
            return
        }

        // payment.data muss immer ans backend geschickt werden, da sonst ein z.B. ein leeres IBAN Feld kreiert wird 
        if (payment) {
            changedFields.data = paymentMethod.data;
        }

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            // req.body = JSON.stringify({name: paymentMethod.name, default: defaultPayment, data: {iban: paymentMethod.iban, bic: paymentMethod.bic}});
            // req.body = JSON.stringify({...paymentMethod, default: defaultPayment});
            
            req.body = JSON.stringify(
                payment
                    ? { 
                        ...changedFields, 
                        ...(initialDefaultPayment !== defaultPayment ? { default: defaultPayment } : {}) 
                      }
                    : { 
                        ...paymentMethod, 
                        default: defaultPayment 
                      }
            );
            
            return req;
          };
  
          const response = payment ?  await client.apis["payment"].payment_org_paymentmethods_partial_update({org_uuid: _orgGuid, payment_method_uuid: payment.id}) : await client.apis["payment"].payment_org_paymentmethods_create({// org_uuid: newOrgID ? newOrgID : myOrgID,
            org_uuid: _orgGuid 
          });
  
          if (response.status >= 200 && response.status < 300) {
            // redirect()
            if (cameFromAddLicence) {
                setWizard(2)
            } else {
                onHide()
            }
          }
  
          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          addToast(t("error"), t("networkError"), "error");
  
          client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [isValid, paymentAgreement, client, addToast, t, _orgGuid, login.Authorization, onHide, setWizard, defaultPayment, payment])

    return (
        <>
            {wizardStep === 1 &&
                <Form onSubmit={(e) => onSubmit(e)}>
                    {login?.currentOrganisation?.name && <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{t('organisation')}</Form.Label>
                        <Form.Control type="text" disabled value={login?.currentOrganisation?.name}/>
                    </Form.Group>}

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={paymentMethod?.name} onChange={(e) => paymentMethodHandler(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="type">
                        <Form.Label>{t("type")}</Form.Label>
                        <Form.Select value={paymentMethod?.type} onChange={(e) => paymentMethodHandler(e)}>
                            {types?.map(typ => <option key={`payment-type-${typ.id}`} value={typ.id}>{typ.label}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="iban">
                        <Form.Label>{t("IBAN")}</Form.Label>
                        <Form.Control type="text" required value={paymentMethod?.data?.iban} onChange={(e) => paymentMethodHandler(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="bic">
                        <Form.Label>{t("BIC")}</Form.Label>
                        <Form.Control type="text" required value={paymentMethod?.data?.bic} onChange={(e) => paymentMethodHandler(e)} />
                    </Form.Group>

                    <Form.Check
                        className="mb-3"
                        type="checkbox"
                        id="paymentAgreement"
                        checked={paymentAgreement}
                        label={t('authorizePayments')}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Form.Check.Input
                            onChange={(e) => {setPaymentAgreement(e.target.checked)}}
                            style={{
                                outline: highlightCheck ? '2px solid #ff0000' : 'none',
                                outlineOffset: highlightCheck ? '2px' : 'none'
                            }}
                        />
                        <Form.Check.Label style={{ marginLeft: '10px' }}>
                            {t('authorizePayments')}
                        </Form.Check.Label>
                    </Form.Check>

                    <Form.Check
                        className="mb-3"
                        type="checkbox"
                        id="defaultPayment"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Form.Check.Input
                            checked={defaultPayment}
                            onChange={(e) => {setDefaultPayment(e.target.checked)}}
                        />
                        <Form.Check.Label style={{ marginLeft: '10px' }}>
                            {t('defaultPayment')}
                        </Form.Check.Label>
                    </Form.Check>

                    <Button variant="outline-primary" className='w-100' type='submit'>{t('submit')}</Button>
                </Form>
            }
            
            {wizardStep === 2 && <AddLicence onHide={onHide} wizardStep={wizardStep} plan={planID} />}
        </>
    )
}

const PaymentMethod = ({ onHide }) => {
    const _orgGuid = useParams()['orgGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const [stripePromise, setStripePromise] = useState(undefined);
    const [setupIntent, setSetupIntent] = useState(undefined)
    const client = useSwagger();

    const publishableKey = useCallback(async () => {
    //     fetch('https://tech.sigmaheat.de/payment/config', {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         }
    //     })
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setStripePromise(loadStripe(data.publishableKey));
    //     })
    //     .catch(error => {
    //         console.log('Error:', error);
    //     });
    // }, [login.Authorization])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
        };

            const response = await client.apis["payment"].payment_config_retrieve();

            if (response.status >= 200 && response.status < 300) {
                // setStripePromise(loadStripe(response.obj.publishableKey));
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("Error:", error);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    // const fetchSetupIntent = useCallback(async () => {
    // //     fetch(`https://tech.sigmaheat.de/payment/org/${_orgGuid}/client_secret`, {
    // //         headers: {
    // //             'Authorization': login.Authorization,
    // //             "Content-Type": "application/json"
    // //         },
    // //         method: "GET"
    // //     })
    // //     .then((response) => {
    // //         if (!response.ok) throw new Error(t("responseError"))
    // //         return response.json()
    // //     })
    // //     .then(data => {
    // //         setSetupIntent(data)
    // //     })
    // //     .catch(error => {
    // //         addToast(t("error"), error.message, "error")
    // //     });
    // // }, [login.Authorization, t, addToast])

    //     if (!client) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;

    //     try {
    //         client.requestInterceptor = (req) => {
    //         req.headers["Content-Type"] = "application/json";
    //         req.headers["Authorization"] = login.Authorization;
    //         return req;
    //     };

    //         const response = await client.apis["payment"].payment_org_client_secret_retrieve({org_uuid: _orgGuid});

    //         if (response.status >= 200 && response.status < 300) {
    //             setSetupIntent(response.obj);
    //         }

    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         console.log("error", error)

    //         addToast(t("error"), t("responseError"), "error");

    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }
    // }, [client, login.Authorization, t, addToast]);

    useEffect(() => {
        publishableKey();
        // fetchSetupIntent();
    }, [])

    return <>
            <PaymentComponent {...{ onHide }} />
    </>
}

// export const PaymentMethods = ({ shw, setShw }) => {
export const PaymentMethods = ({ shw, setShw, cameFromAddLicence, planID, onHide }) => {

    const { login } = useAuth();
    const { addToast } = useToast();
    const _orgGuid = useParams()['orgGuid'];
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState(undefined);
    const [pm_id, setPM_ID] = useState(undefined);
    const [show, setShow] = useState(false)
    const [types, setTypes] = useState(undefined)
    const [currentPage, setCurrentPage] = useState(1);

    const client = useSwagger();

    const paymentMethodsTableStructure = [
        {
            col: "#",
            type: 'label'
        }, {
            col: t('paymentMethod'),
            type: 'label'
        }, {
            col: t('type'),
            type: 'label'
        }, {
            col: t('default'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        },
        // {
        //     col: t('updatedAt'),
        //     type: 'label'
        // }, 
        {
            col: t('actions'),
            type: 'buttons'
        }
    ]

    const fetchPaymentMethods = useCallback(async () => {
        setPaymentMethods(undefined)
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
                const response = await client.apis["payment"].payment_org_paymentmethods_list({org_uuid: _orgGuid, page: currentPage});

            if (response.status === 204) {
                setPaymentMethods({results: []})
                return
            }

            if (response.status >= 200 && response.status < 300 && response.status !== 204) {
                setPaymentMethods(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                addToast(t("error"), t("noAuthorization"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization, addToast, t, currentPage]);
    
    const getPaymentTypes = useCallback(async() => {
        if (!client) return;
  
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
          };
    
          const response = await client.apis["payment"].payment_types_retrieve();
    
          if (response.status >= 200 && response.status < 300) {
            let paymentMethods = response.obj.map(method => {
              if (method.label === "SEPA_Direct_Debit") {
                  return {
                      ...method,
                      label: "SEPA-Lastschriftmandat"
                  };
              }
              return method;
          });
            setTypes(paymentMethods)
          }
    
          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          console.error(error);
          addToast(t("error"), t("responseError"), "error");
    
          client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast])

    const deletePaymentMethod = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["payment"].payment_org_paymentmethods_destroy({org_uuid: _orgGuid, payment_method_uuid: pm_id});

            if (response.status >= 200 && response.status < 300) {
                addToast(t('deletePaymentMethod'), t('paymentMethodDeleted'), "success");

                if (paymentMethods.results?.length === 1 && currentPage !== 1) {
                    setCurrentPage(currentPage - 1)
                } else {
                    fetchPaymentMethods()
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
        }, [client, login.Authorization, t, addToast, pm_id, paymentMethods, currentPage]);

        function formatDate(isoDateString) {
            const date = new Date(isoDateString);
            const options = {
                year: 'numeric',
                month: 'short', 
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false 
            };
          
            return date.toLocaleDateString('de-DE', options);
        }

    useEffect(() => {
        fetchPaymentMethods()
        getPaymentTypes()
    }, [])

    useEffect(() => {
        fetchPaymentMethods()
    }, [currentPage])

    const itemsPerPage = 10

    return <>
        {cameFromAddLicence ? (
            <PaymentComponent {...{ cameFromAddLicence: true, planID, onHide: () => onHide()}} />
        ) : (
            (paymentMethods === undefined) ? (
                <TablePlaceholder {...{structure: paymentMethodsTableStructure}} />
            ) : (!Boolean(paymentMethods?.results?.length)) ? (
                <p className="m-0">{t("noPaymentMethods")}</p>
            ) : (
                <>
                    <Table responsive>
                        <thead>
                            <tr>
                                {/* <th>#</th>
                                <th>{t('paymentMethod')}</th>
                                <th>{t('createdAt')}</th>
                                <th>{t('updatedAt')}</th>
                                <th>{t('delete')}</th> */}
                                {paymentMethodsTableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                    <span>{(ts.type == "label") ? t(ts.col) : ts.col}</span>
                                </th>)}
                            </tr>
                        </thead>

                        <tbody>
                            {paymentMethods?.results?.map((payment, i) => {
                                // let type = payment.type === "card" ? payment.card.brand.charAt(0).toUpperCase() + payment.card.brand.slice(1) : payment.type.charAt(0).toUpperCase() + payment.type.slice(1);
                                return <tr key={`key-payment-${i}`}>
                                    <td className="align-middle">{(currentPage - 1) * itemsPerPage + (i + 1)}</td>
                                    <td className="align-middle">{payment.name}</td>
                                    <td className="align-middle">{types?.find((type) => type.value === payment.type)?.label}</td>
                                    {/* <td className="align-middle">{`${new Date(payment.created_at * 1000).getDate()}.${new Date(payment.created_at * 1000).getMonth() + 1}.${new Date(payment.created_at * 1000).getFullYear()} ${new Date(payment.created_at * 1000).getHours()}:${new Date(payment.created_at * 1000).getMinutes()}`}</td>
                                    <td className="align-middle">{`${new Date(payment.created_at * 1000).getDate()}.${new Date(payment.created_at * 1000).getMonth() + 1}.${new Date(payment.created_at * 1000).getFullYear()} ${new Date(payment.created_at * 1000).getHours()}:${new Date(payment.created_at * 1000).getMinutes()}`}</td> */}
                                    <td className="align-middle">{payment.default ? <FontAwesomeIcon icon={faCheck} />  : ""}</td>
                                    <td className="align-middle">{formatDate(payment.created_at)}</td>
                                    <td className="align-middle">
                                        <div className='actions__buttonbar grid__two'>
                                            <Button variant="outline-primary" className="p-10" onClick={() => {setPM_ID(payment); setShow("edit")}}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-primary" className="p-10" onClick={() => {setPM_ID(payment.id); setShow("delete")}}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <DjangoPagination {...{ total_pages: paymentMethods?.total_pages, currentPage, setCurrentPage }} />
                    <SecurityActionModal {...{ show: show === "delete", onHide: () => setShow(false), title: t('deletePaymentMethod'), question: t('paymentMethodDeleteQuestion'), action: () => deletePaymentMethod() }} />
                    <ContentModal {...{ show: show === "edit", onHide: () => setShow(false), title: t("edit"), content: <PaymentComponent {...{ payment: pm_id, onHide: () => { fetchPaymentMethods(); setShow(false) } }} /> }} />
                </>
            )
        )}
        <ContentModal {...{ show: shw === "payment", onHide: () => setShw(false), title: t("setupPaymentMethod"), content: <PaymentMethod {...{ onHide: () => { fetchPaymentMethods(); setShw(false) } }} /> }} />
    </>
}

export default PaymentMethods;