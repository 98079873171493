import { useState, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Accordion, Button, Form } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";
import { useBuilding } from "../context/BuildingContext";

export const AddEditConfigurationsLog = ({ roomID, hpID, heatpart, cL, onClose }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const client = useSwagger();
    const { currentVariantNode } = useBuilding();
    const [configurationsLog, setConfigurationsLog] = useState(cL || {
        comment: "",
        kv: undefined,
        setting_value: heatpart?.target_setting_value || "",
        calc_from_external: false,
        author_id: login.id
    });

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/room/${roomID}/heatpart/${hpID}/configurationlogentry/${configurationsLog.id ? configurationsLog.id : ''}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: configurationsLog?.id ? "PATCH" : "POST",
        //     body: JSON.stringify(configurationsLog)
        // })
        // .then((response) => {
        //     if (response.status === 409) throw new Error("CONFLICT");
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return response.json();
        // })
        // .then(_ => {
        //     onClose();
        //     addToast(t("success"), configurationsLog?.id ? t("patchConfigurationsLog") : t("postConfigurationsLog"), "success");
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // });

        if (!client || !currentVariantNode) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({...configurationsLog, kv: parseFloat(configurationsLog.kv)})
                return req;
            };
                
            const response = await client.apis["building"].building_variant_room_heatpart_configurationlogentry_create({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: roomID, room_heatpart_uuid: hpID });

            if (response.status >= 200 && response.status < 300) {
                onClose();
                addToast(t("success"), configurationsLog?.id ? t("patchConfigurationsLog") : t("postConfigurationsLog"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            if (error.status === 423) {
                addToast(t("error"), t("lockedErrorPost"), "error");
            } else {
                addToast(t("error"), t('networkError'), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, roomID, hpID, configurationsLog, onClose, currentVariantNode]);

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
    
        // Regex to allow "N/n", -1, integers, and floats with '.' or ','
        const regex = /^[Nn]$|^-1$|^\d+$|^\d+[.,]?\d*$/;

        // Allow empty input explicitly or valid input
        if (
            id === "setting_value" &&
            value !== "" &&
            value !== "-" && // Allow intermediate "-" during editing
            !regex.test(value)
        ) {
            addToast(t('error'), t('configurationLimit'), 'error');
            return;
        }
    
        setConfigurationsLog(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);    

    const changeCalc = useCallback((e) => {
        const { id, checked } = e.target;
        setConfigurationsLog(prevState => ({
            ...prevState,
            [id]: checked
        }));
    }, []);

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3' controlId="setting_value">
            <Form.Label>{t('settingValue')}</Form.Label>
            <Form.Control type="text" value={configurationsLog?.setting_value || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3' controlId="comment">
            <Form.Label>{t('comment')}</Form.Label>
            <Form.Control type="text"  value={configurationsLog?.comment || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Accordion defaultActiveKey={null} className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>{t('expertMode')}</Accordion.Header>
                <Accordion.Body>
                    <Form.Group className='mb-3' controlId="kv">
                        <Form.Label>{t('kv')}</Form.Label>
                        <Form.Control type="text"  value={configurationsLog?.kv || ''} onChange={(e) => changeHandler(e)} />
                    </Form.Group>
                    <Form.Check
                        type='switch'
                        id='calc_from_external'
                        label={t("calcFromExternal")}
                        onChange={(e) => changeCalc(e)}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        {/* <Form.Group className='mb-3' controlId="valve_authority">
            <Form.Label>{t('valveAuthority')}</Form.Label>
            <Form.Control type="text"  value={configurationsLog?.valve_authority || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group> */}

        {/* <Form.Group>
            <Form.Check
                type='checkbox'
                id={'calc_from_external'}
                checked={configurationsLog?.calc_from_external}
                label={t("calcFromExternal")}
                onChange={(e) => changeHandler(e)}
            />
        </Form.Group> */}

        <Button variant="outline-primary" className='w-100' type='submit'>{t('id' in configurationsLog ? 'edit' : 'add')}</Button>
    </Form>;
};

export default AddEditConfigurationsLog;