import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useAuth } from "../Context"
import { useTranslation } from "react-i18next"
import { useToast } from "../NotificationsContent"
import TablePlaceholder from "../components/Placeholders/TablePlaceholder"
import { Button, Form, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import ContentModal from "../components/ContentModal"
import SecurityActionModal from "../components/SecurityActionModal"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { usePermissions } from "../context/PermissionsContext"
import { useSwagger } from "../context/SwaggerContext"
import cloneDeep from 'lodash/cloneDeep';
import AddLicence from "./AddLicence"
import DjangoPagination from "../components/DjangoPagination"

const EditAdministrator = (props) => {
    const _orgGuid = useParams()['orgGuid'];
    const { t } = useTranslation();
    const { login } = useAuth();
    const { addToast } = useToast();
    const [user, setUser] = useState(props.user);
    // const [permissions, setPermissions] = useState(undefined)
    const client = useSwagger();
    const {userPermissions} = usePermissions();

    const filteredUserPermissionsArray = userPermissions.filter(item => !(user.permission_level < 200 && item.value === 200));

    const sortedUserPermissionsArray = filteredUserPermissionsArray.sort((a, b) => {
        if (a.value === user.permission_level) return -1;
        if (b.value === user.permission_level) return 1;
        return 0; 
    });

    const handleChange = useCallback((value) => {
        // let perm = permissions.find(p => p.id === value).name
        let perm = userPermissions.find(p => p.value === value).label

        if (perm === undefined) {
            addToast(t("error"), "", "error")
            return
        }

        let tmpUsr = { ...user };
        tmpUsr.permission_level = perm;
        setUser(tmpUsr);
    // }, [user, permissions, t])
    }, [user, userPermissions, t])


    const patchOrgHasUser = useCallback(async (e) => {
        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({ permission_level: userPermissions?.find(p => p.label === user.permission_level)?.value })
                return req;
            };

            const response = await client.apis["org"].org_user_partial_update({org_uuid: _orgGuid, user_uuid: user.id});

            if (response.status >= 200 && response.status < 300) {
                // let users = [...props.users];
                let users = [...props.users?.results];
                let tmpUsr = users.find(u => u.id = user.id)
                tmpUsr.permission = user.permission
                props.setUsers(users)
                props.onClose()
                addToast(t("success"), t("userPermissionsChanged"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, user, addToast, t, userPermissions]) 

    return <Form onSubmit={(e) => patchOrgHasUser(e)}>

        <Form.Group className="mb-3" controlId="permission">
            <Form.Label>{t('permission')}</Form.Label>
            <Form.Select value={userPermissions?.find(p => p.value === user.permission_level)} onChange={(e) => handleChange(parseInt(e.target.value))}>

                {sortedUserPermissionsArray?.map(p => <option key={`userPermissions-${p.value}`} value={p.value}>{p.label}</option>)}
            </Form.Select>
        </Form.Group>

        <div className="d-flex justify-content-end">
            <Button variant="outline-primary" type="submit">{t('saveChanges')}</Button>
        </div>
    </Form>
}

export const AddRegisterUser = ({ onClose, shw }) => {
    let registerUser = shw === "registeruser"
    const _orgGuid = useParams()['orgGuid'];
    const { t } = useTranslation();
    const { login } = useAuth();
    const { addToast } = useToast();
    const [mail, setMail] = useState("")
    const client = useSwagger();
    const [info, setInfo] = useState({prename: "", name: ""})
    const [isSubmitting, setIsSubmitting] = useState(false);

    const changeHandler = (e) => {
        const { id, value } = e.target;
        setInfo(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const addUser = useCallback(async () => {
        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                req.body = JSON.stringify({ mail: mail });
                return req;
            };

            const response = await client.apis["org"].org_invite_create({org_uuid: _orgGuid});

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("toOrgInvitation"), "success");
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [client, isSubmitting, login.Authorization, mail, _orgGuid, addToast, onClose])

    const createUserAccount = useCallback(async () => {
        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        info.mail = mail;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                req.body = JSON.stringify(info);
                return req;
            };

            const response = await client.apis["org"].org_user_register_create({org_uuid: _orgGuid});

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("toOrgRegistration"), "success");
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [client, login.Authorization, mail, _orgGuid, addToast, onClose, isSubmitting])

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (registerUser) {
            createUserAccount()
        } else {
            addUser()
        }
    }, [registerUser, createUserAccount, addUser])

    const register_user = () => {
        return (
            <>
                <Form.Group className='mb-3' controlId='prename'>
                    <Form.Label>{t('prename')}</Form.Label>
                    <Form.Control type='text' required value={info?.prename || ""} onChange={(e) => changeHandler(e)} />
                </Form.Group>

                <Form.Group className='mb-3' controlId='name'>
                    <Form.Label>{t('lastName')}</Form.Label>
                    <Form.Control type='text' required value={info?.name || ""} onChange={(e) => changeHandler(e)} />
                </Form.Group>
            </>
        );
    };

    return <Form onSubmit={(e) => onSubmit(e)}>
        {registerUser && register_user()}

        <Form.Group className='mb-3' controlId='mail'>
            <Form.Label>Email</Form.Label>
            <Form.Control type='email' required value={mail} onChange={(e) => setMail(e.target.value)} />
        </Form.Group>

        <div className="d-flex justify-content-end">
            <Button variant="outline-primary" disabled={isSubmitting} type="submit">{registerUser ? t('submit') : t('add')}</Button>
        </div>
    </Form>
}

export const CompanyUsers = ({ shw, setShw, showLicenceManagement }) => {
    const _orgGuid = useParams()['orgGuid'];
    const { login, doSetLogin } = useAuth();
    const client = useSwagger();
    const { t } = useTranslation()
    const { addToast } = useToast();
    const [users, setUsers] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [show, setShow] = useState(false)
    // const [currentPage, setCurrentPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // const pageSize = 5;
    // const [pageCount, setPageCount] = useState(Math.ceil(9 / pageSize))
    const [allLicences, setAllLicences] = useState(undefined)
    const [selectedLicences, setSelectedLicences] = useState([])
    const [toBuy, setToBuy] = useState([]);
    const [increaseQuantity, setIncreaseQuantity] = useState([]);
    const [triggerSubmitLicenceManagement, setTriggerSubmitLicenceManagement] = useState(false);

    const { allowEdit, userPermissions, superUser} = usePermissions();

    const concurrentProId = "31bd05e8-a527-4bff-8fdf-fef61b22e3b6";
    const concurrentPremiumId = "2d1dea5d-5e5c-4f6f-b5dc-6a6036d2fc25";

    const usersTableStructure = [
        {
            col: "#",
            type: 'label'
        }, {
            col: t('name'),
            type: 'label'
        }, {
            col: t('emailAddress'),
            type: 'label'
        }, {
            col: t('permissions'),
            type: 'label'
        }, {
            col: t('editDelete'),
            type: 'buttons'
        }
    ]

    const getAllLicences = useCallback(async (submitLicenses) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["org"].org_sw_products_retrieve({
            const response = await client.apis["org"].getBuildingProduct_3({
                org_uuid: _orgGuid,
            });

            if (response.status >= 200 && response.status < 300) {

                const sortedArray = response.obj.sort((a, b) => {
                    // falls `title` "Concurrent" enthält, wird das Objekt nach hinten geschoben
                    if (a.title.includes("Concurrent") && !b.title.includes("Concurrent")) {
                        return 1;
                    } else if (!a.title.includes("Concurrent") && b.title.includes("Concurrent")) {
                        return -1;
                    } else {
                        return 0;
                    }
                });

                setAllLicences(sortedArray)

                // nachdem neue Lizenzen hinzugefügt wurden oder die Menge verändert wurde
                // wird der state von allLicences aktuallisiert
                // erst dann wird submitLicenceManagement mit aktuellen state ausgeführt und der user muss nicht nochmal "speichern" klicken
                if (submitLicenses) setTriggerSubmitLicenceManagement(true)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization]);

    const fetchCompanyMembers = useCallback(async () => {
        setUsers(undefined);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["org"].org_user_list({org_uuid: _orgGuid, limit: pageSize, offset: currentPage * pageSize});
            const response = await client.apis["org"].org_user_list({org_uuid: _orgGuid, page: currentPage});

            if (response.status !== 200) {
                // setPageCount(undefined)
                setUsers(undefined);
                return;
            }

            if (response.status === 200) {
                // setPageCount(Math.ceil(response.obj?.count / pageSize));
                // setUsers(response.obj.users);
                setUsers(response.obj);

                const initialSelected = [];
                // response.obj.users.forEach(user => {
                response.obj.results.forEach(user => {
                    if (user.licence) {
                        initialSelected.push({ id: user.id, licence: user.licence });
                    }
                });

                const licenceCount = {};

                // Gehe durch jedes Element im Array und durch dessen Lizenz-Array
                initialSelected.forEach(user => {
                    user.licence.forEach(licenceId => {
                        if (licenceCount[licenceId]) {
                            licenceCount[licenceId]++;
                        } else {
                            licenceCount[licenceId] = 1;
                        }
                    });
                });

                // Füge das "amount"-Objekt direkt hinzu
                const result = {
                    users: initialSelected,
                    amount: Object.entries(licenceCount).map(([id, value]) => ({ id, value }))
                };
                setSelectedLicences(result);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.error(error);

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, currentPage, login.Authorization]);

    const removeUserFromOrganisation = useCallback(async () => {
         if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_user_destroy({org_uuid: _orgGuid, user_uuid: user.id});

            if (response.status >= 200 && response.status < 300) {

                if (user.id == login.id) {
                    let loginCopy = cloneDeep(login);
                    delete loginCopy.currentOrganisation;
                    let filterOrg = loginCopy.organisations.filter((organisation) => organisation.id !== login.currentOrganisation.id);
                    loginCopy.organisations = filterOrg;
                    doSetLogin(loginCopy);
            
                    addToast(t("leaveOrganisation"), t("leftOrganization"), "success");
                    <Navigate to={`/`} replace />

                } else {
                    setUser(undefined);
                    setShow(false);
                    addToast(t("deleteAdministrator"), t("administratorDeleted"), "success");
                    
                    if (users.results?.length === 1 && currentPage !== 1) {
                        setCurrentPage(currentPage - 1)
                    } else {
                        fetchCompanyMembers();
                    }
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                addToast(
                t("httpStatus.fourzeroone.title"),
                t("httpStatus.fourzeroone.message"),
                "error"
                );
            } else if (statusCode === 404) {
                addToast(
                t("httpStatus.fourzerofour.title"),
                t("httpStatus.fourzerofour.message"),
                "error"
                );
            } else if (statusCode === 500) {
                addToast(
                t("httpStatus.fivezerozero.title"),
                t("httpStatus.fivezerozero.message"),
                "error"
                );
            } else {
                addToast(t("error"), t("responseError"), "error");
            }

            setUser(undefined);
            setShow(false);

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _orgGuid, fetchCompanyMembers, users, user, currentPage]);

    const increaseQuantityOrBuyNewLicence = () => {
        const newToBuy = [];
        const newIncreaseQuantity = [];

        selectedLicences.amount.forEach((selected) => {
            const matchingLicence = allLicences.find(licence => licence.id === selected.id);

            if (matchingLicence) {
                const amountDifference = selected.value - matchingLicence.amount;

                // bei Concurrent Licence soll die Menge nicht erhöht werden, wenn amount > 0 ist
                // nur wenn amount === 0 ist, dann soll man automatisch eine Licence kaufen können (Weiterleitung zur Bestellübersicht)
                const isConcurrentProOrPremium = [concurrentProId, concurrentPremiumId].includes(selected.id);

                if (matchingLicence.amount === 0 && amountDifference > 0) {
                    newToBuy.push({
                        title: matchingLicence.title,
                        quantity: amountDifference,
                        software_id: selected.id,
                        price: matchingLicence.price,
                        type: matchingLicence.type,
                        amount: selected.value 
                    });
                // } else if (matchingLicence.amount > 0 && amountDifference > 0) {
                } else if (!isConcurrentProOrPremium && matchingLicence.amount > 0 && amountDifference > 0) {
                    newIncreaseQuantity.push({
                        title: matchingLicence.title,
                        quantity: amountDifference,
                        software_id: selected.id,
                        price: matchingLicence.price,
                        type: matchingLicence.type,
                        amount: selected.value 
                    });
                }
            }
        });

        setToBuy(newToBuy);
        setIncreaseQuantity(newIncreaseQuantity);
    };

    const submitLicenceManagement = useCallback(async () => {
            if (!client) return;
            // Wenn mehr Lizenzen vergeben wurde, als man eingentlich darf (oder eine vergeben wurde, die man noch nicht gekauft hat)
            // Prüfen
            const exceededLimit = selectedLicences.amount.some(amountItem => {
                const matchingAllItem = allLicences.find(allItem => allItem.id === amountItem.id);

                // Wenn ja eine Lizenzen vergeben wurde, die noch gekauft werden muss, oder die Menge beim vergeben überschritten wurde
                if (matchingAllItem) {
                    const isConcurrentProOrPremium = [concurrentProId, concurrentPremiumId].includes(amountItem.id);

                    if (amountItem.value > matchingAllItem.amount) {

                        // bei Concurrent Licence soll die Menge nicht erhöht werden (Kauf), wenn amount > 0 ist
                        // nur wenn amount === 0 ist, dann soll man automatisch eine Licence kaufen können (Weiterleitung zur Bestellübersicht)
                        if (isConcurrentProOrPremium && matchingAllItem.amount > 0) {
                            return false;
                        }

                        // dann soll der User weiter geleitet werden auf "Order Summery"
                        setShow("buyLicence")

                        // hier wird direkt unterschieden, ob Lizenzen gekauft werden muss oder nur die Menge erhöht werden muss
                        increaseQuantityOrBuyNewLicence()

                        return true; 
                    }
                }
                return false;
            });

            // Wenn mehr Lizenzen vergeben wurden, dann wird Endpunkt updatelicences nicht ausgeführt
            if (exceededLimit) return

            if (triggerSubmitLicenceManagement) setTriggerSubmitLicenceManagement(false)

            let changes = [];

            // Hier wird geprüft, ob Lizenzen hinzugefügt wurden oder entfernt wurden (für backend)
            // users.forEach(user => {
            users.results.forEach(user => {
                const updatedUser = selectedLicences.users.find(u => u.id === user.id);
                if (updatedUser) {
                    // Neue Lizenzen hinzufügen
                    updatedUser.licence.forEach(licence => {
                        if (!user.licence.includes(licence)) {
                            changes.push({
                                user: user.id,
                                software: licence,
                                assign: true
                            });
                        }
                    });

                    // Entfernte Lizenzen prüfen
                    user.licence.forEach(licence => {
                        if (!updatedUser.licence.includes(licence)) {
                            changes.push({
                                user: user.id,
                                software: licence,
                                revoke: true
                            });
                        }
                    });
                }
            });

            const originalRequestInterceptor = client.http.requestInterceptor;
    
            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    req.body = JSON.stringify(changes);
                    return req;
                };
    
                const response = await client.apis["org"].org_user_updatelicences_partial_update({org_uuid: _orgGuid});
    
                if (response.status >= 200 && response.status < 300) {
                    addToast(t("success"), t("allLicencesAssigned"), "success");
                    fetchCompanyMembers()                   
                }
    
                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                addToast(t("error"), t("licenceGrantError"), "error");
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        }, [client, selectedLicences, users, allLicences, addToast, t, _orgGuid, login.Authorization, fetchCompanyMembers, triggerSubmitLicenceManagement]);

    const handleOptions = (u, option) => {
        if (option === "leaveOrganisation") {
            setUser(u);
            setShow(option)
            return
        }

        if (superUser) {
            setUser(u);
            setShow(option)
        } else {
            addToast(t("error"), t("noPermisson"), "error")
        }
    }

    const handleLicenceClick = (userId, licenceId) => {
        setSelectedLicences(prevLicences => {
            // Lizenzliste User aktualisieren ("X")
            const updatedUsers = prevLicences.users.map(user => {
                if (user.id === userId) {
                    // Lizenz bereits vorhanden? Dann Lizenz ("X") entfernen
                    if (user.licence.includes(licenceId)) {
                        return {
                            ...user,
                            licence: user.licence.filter(id => id !== licenceId)
                        };
                    } else {
                        // sonst Lizenz hinzufügen
                        return {
                            ...user,
                            licence: [...user.licence, licenceId]
                        };
                    }
                }
                return user;
            });
    
            // Amount-Werte aktulisieren in der Tabelle
            const updatedAmount = prevLicences.amount.map(item => {
                if (item.id === licenceId) {
                    // Lizenz wurde hinzugefügt oder entfernt?
                    const isLicenceAdded = updatedUsers.find(user => user.id === userId)?.licence.includes(licenceId);
                    return {
                        ...item,
                        value: isLicenceAdded ? item.value + 1 : Math.max(item.value - 1, 0) // Wert nicht negativ werden lassen
                    };
                }
                return item;
            });
    
            // Falls die Lizenz noch nicht im "amount" Array existiert, wird sie hinzugefügt
            if (!updatedAmount.some(item => item.id === licenceId)) {
                updatedAmount.push({ id: licenceId, value: 1 });
            }
    
            return {
                ...prevLicences,
                users: updatedUsers,
                amount: updatedAmount
            };
        });
    };

    const onHide = (submitLicenses) => {
        setShow(false)
        getAllLicences(submitLicenses)
    }

    useEffect(() => {
        // wenn neue Lizenzen hinzugefügt wurden oder die Menge verändert wurde
        // dann kann der User direkt kaufen / hinzufügen
        // danach sollen die ausgewählten Lizenzen direkt den Usern zugeordnet werden, ohne den Button nochmal klicken zu müssen
        if (triggerSubmitLicenceManagement) {
            submitLicenceManagement();
        }
    }, [allLicences, submitLicenceManagement, triggerSubmitLicenceManagement]); 

    useEffect(() => {
        fetchCompanyMembers()
    }, [fetchCompanyMembers, currentPage])

    useEffect(() => {
        getAllLicences()
    }, [])

    const itemsPerPage = 10

    return <>
        {(users === undefined) ? (
            <TablePlaceholder {...{structure: usersTableStructure}} />
        ) : (
            <>
                {!showLicenceManagement ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('name')}</th>
                                <th>{t('emailAddress')}</th>
                                <th>{t('permissions')}</th>
                                <th>{t('editDelete')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {/* {users?.map((u, i) => { */}
                            {users?.results?.map((u, i) => {
                                return <tr key={`key-number-${i}`}>
                                    <td className="align-middle">{(currentPage - 1) * itemsPerPage + (i + 1)}</td>
                                    <td className="align-middle">{u.prename + " " + u.name}</td>
                                    <td className="align-middle">{u.mail}</td>
                                    {/* <td className="align-middle">{u?.permission_level}</td> */}
                                    <td className="align-middle">
                                    {userPermissions.find(p => p.value === u?.permission_level)?.label || ''}
                                    </td>
                                    <td className="align-middle">
                                        <Button variant="outline-primary" className="p-10 me-3" onClick={() => handleOptions(u, "edit")} disabled={!superUser || u.permission_level === 200}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button variant="outline-primary" className="p-10" onClick={() => handleOptions(u, "delete")} disabled={!superUser}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('name')}</th>
                                    <th>{t('emailAddress')}</th>
                                    {allLicences?.map((licence) => (
                                        <th key={licence.id}>
                                            <div className="th_div">
                                                <span className="th_div_span_bold">{licence.title}</span>
                                                {/* <span>({selectedLicences.amount.find(li => li.id === licence.id)?.value || 0} / {licence.amount})</span> */}
                                                <div><span className="th_div_span">{t('added')}: </span><span className="th_div_span_color">{selectedLicences.amount.find(li => li.id === licence.id)?.value || 0}</span></div>
                                                {(licence.id !== concurrentProId && licence.id !== concurrentPremiumId) ? <div><span className="th_div_span_italic th_div_span">{t('available')}: </span><span className="th_div_span_color">{licence.amount}</span></div> : <span style={{visibility: "hidden"}}>__</span>}
                                                {/* {(licence.id !== concurrentProId && licence.id !== concurrentPremiumId) ? <div><span className="th_div_span_italic th_div_span">{t('available')}: </span><span className="th_div_span_color">{licence.amount}</span></div> : <span>{licence.amount}</span>} */}

                                            </div> 
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {/* {users?.map((u, i) => { */}
                                {users?.results.map((u, i) => {
                                    return <tr key={`key-number-${i}`}>
                                        <td className="align-middle">{(currentPage - 1) * itemsPerPage + (i + 1)}</td>
                                        <td className="align-middle">{u.prename + " " + u.name}</td>
                                        <td className="align-middle">{u.mail}</td>

                                        {allLicences?.map((licence) => (
                                            // <td onClick={() => handleLicenceClick(u.id, licence.id)} key={licence.id} style={{ cursor: 'pointer' }}>
                                            //     {selectedLicences.users.filter((user) => user.id === u.id).map((user) => user.licence?.includes(licence.id) ? "X" : "")}
                                            // </td>
                                            <td key={licence.id} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedLicences.users.some((user) => user.id === u.id && user.licence?.includes(licence.id))}
                                                    onChange={() => handleLicenceClick(u.id, licence.id)}
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#ffd981', 
                                                        accentColor: '#ffd981', 
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end">
                            <Button variant="outline-primary" className="p-10" onClick={() => submitLicenceManagement()} disabled={!allowEdit}>
                                {t("save")}
                            </Button>
                        </div>
                    </>
                )}
                <DjangoPagination {...{ total_pages: users?.total_pages, currentPage, setCurrentPage }} />
            </>
        )}
        {/* <Pagination {...{ currentPage, pageSize, onPageChange: (page) => setCurrentPage(page), pageCount }} /> */}
        <ContentModal {...{ show: ( shw === "adduser" || shw === "registeruser" ) , onHide: () => setShw(false), title: shw === "adduser" ? t("addUserToOrg") : t("registerUser"), content: <AddRegisterUser {...{ onClose: () => {fetchCompanyMembers(); setShw(false)}, shw }} /> }} />
        <ContentModal {...{ show: show === "edit", onHide: () => {setShow(false); setUser(undefined)}, title: t('editAdministrator'), content: <EditAdministrator {...{ user, users, setUsers, onClose: () => { fetchCompanyMembers(); setShow(false); setUser(undefined) } }} /> }} />
        <ContentModal {...{ show: show === "buyLicence", onHide: () => {setShow(false)}, title: t('addSWPackage'), content: <BuyLicence {...{setShow}}/> }} />
        <ContentModal {...{ size: "xl", show: show === "addLicence", onHide: () => {setShow(false)}, title: t('addSWPackage'), content: <AddLicence {...{onHide, showOnlySummery: true, toBuy, increaseQuantity}}/> }} />

        
        <SecurityActionModal {...{ show: show === "delete", onHide: () => {setShow(false); setUser(undefined)}, title: t('deleteAdministrator'), question: t('administratorDeleteQuestion'), action: () => removeUserFromOrganisation() }} />
        <SecurityActionModal {...{ show: show === "leaveOrganisation", onHide: () => {setShow(false); setUser(undefined)}, title: t('leaveOrganisation'), question: t('leaveOrganisationQuestion'), action: () => removeUserFromOrganisation() }} />

        {!showLicenceManagement && (
            <div className="d-flex justify-content-end">
                <Button variant="outline-danger" className="p-10" onClick={() => handleOptions(login, "leaveOrganisation")} disabled={superUser}>
                    {t("leaveOrganisation")}
                </Button>
            </div>
        )}
    </>
}

export const BuyLicence = ({setShow, comesFromSidebar, menuToggle}) => {
    const { t } = useTranslation()
    const { allowEdit } = usePermissions();
    const { login } = useAuth();

    const navigate = useNavigate();

    const submit = () => {
        if (comesFromSidebar) {
            navigate(`/${login.currentOrganisation.id}/orgprofile`, {
                state: { fromSidebar: true, toBuy: [
                    {
                        "title": "Pro",
                        "quantity": 1,
                        "software_id": "31bd05e8-a527-4bff-8fdf-fef61b22e3b3",
                        "price": 10,
                        "type": 0,
                        "amount": 1
                    }
                ]},
            });
            setShow(false)
            if (menuToggle) {
                menuToggle(false)
            }
        } else {
            setShow("addLicence")
        }
    }

    return (
        <div className="mb-3">
            <p>{comesFromSidebar ? t('buyLicencesQuestion') : `${t('moreLicensesThanAllowed')} ${t('buyLicencesQuestion')}`}</p>

            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" className="p-10 mt-3" onClick={() => submit()} disabled={!allowEdit}>
                    {t("confirm")}
                </Button>
            </div>
        </div>
    )

}