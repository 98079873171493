import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faAdd, faEdit, faTrash, faCheck, faCalculator, faCancel, faA, faF, faB, faC, faD, faE, faG, faH, faDna, faXmark, faCopy, faInfo, faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ContentModal from "../components/ContentModal";
import { timeReadableFormat } from "../util/helpers";
import SecurityActionModal from "../components/SecurityActionModal";
import Collapse from "../components/Collapse";
import { Calculate } from "./CalculateDIN12831";
import { AddEditBuildingPartVariant } from "./AddEditBuildingPartVariant";
import { AddEditVariant } from "./AddEditVariant";
import { StandardReferenceTable } from "./StandardReferenceTable";
import { ListExchangeHeatpart } from "./ListExchangeHeatpart";
import StrandReference from "./StrandReference";
import { useSwagger } from "../context/SwaggerContext";
import {IndividualBuildingParts} from "./IndividualBuildingParts";
// import useBuildingHook from "../hooks/useBuildingHook";
import { useBuilding } from "../context/BuildingContext";

export const BuildingVariantActual = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [variant, setVariant] = useState(undefined);
    const [buildingPartV, setBuildingPartV] = useState(undefined);
    const [show, setShow] = useState(false);
    const [reloadBP, setReloadBP] = useState(Math.random());
    // useBuildingHook()
    const { currentBuilding } = useBuilding();

    const mainFunctions = [
    ]
    if(variant==undefined){
        mainFunctions.push({ label: t('add'), onClick: () => setShow('variant'), key: 'add', icon: faAdd })
    }
    mainFunctions.push({ label: t('calculate'), onClick: () => setShow("Calculate"), key: 'calculate', icon: faCalculator })

    const tableStructure = [
        {
            col: "Date",
            type: 'label'
        }, {
            col: "Name",
            type: 'label'
        }, {
            col: "lastCalculation",
            type: 'label'
        }, {
            col: "Consumption_demand",
            type: 'label'
        }, {
            col: "Reduction",
            type: 'label'
        }, {
            col: "co2_emissions_gas",
            type: 'label'
        },
        {
            col: "landlordsharesco2",
            type: 'label'
        },
        {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const getActualVariant = useCallback(async () => {
        setVariant(undefined);

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariants({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setVariant(undefined);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setVariant(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const onClose = () => {
        setShow(false);
        setBuildingPartV(undefined);
    }

    useEffect(() => {
        getActualVariant()
    }, [getActualVariant])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            {/* <Card {...{ heading: `${t('actualVariant')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions }}> */}
            <Card {...{ heading: `${t('actualVariant')} ${t('inThe')} ${t('building')}: ${currentBuilding?.name}`, mainFunctions }}>
                {(variant === undefined) ? (
                    <p className="m-0">{t("noActualVariant")}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                    <span>{(ts.type == "label") ? t(ts.col.toLowerCase()) : ts.col}</span>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {<VariantRow key={`variant-key-${variant.id}`} {...{ variant, setVariant, setShow, setBuildingPartV, reloadBP }} />}
                        </tbody>
                    </Table>
                )}
            </Card>

            <ContentModal {...{ show: show === 'Calculate', onHide: onClose, title: t('calculateDIN12831'), content: <Calculate {...{ onClose }} /> }} />
            <ContentModal {...{ show: show === 'variant', onHide: onClose, title: t('addVariant'), content: <AddEditVariant {...{ variant, onClose }} /> }} />
            <ContentModal {...{ show: show === 'exchange_list', onHide: onClose, size: "fullscreen", title: t('exchange_list'), content: <ListExchangeHeatpart {...{ variant, onClose }} /> }} />
            <ContentModal {...{ show: show === 'bpv', onHide: onClose, title: t('addBPV'), size: 'lg', content: <AddEditBuildingPartVariant {...{ buildingPartV, variantID: variant?.id, onClose: () => onClose() }} /> }} />
            <ContentModal {...{ show: show === 'strand', onHide: () => { setVariant(undefined); setShow(false) }, title: t('strands'), content: <StrandReference {...{ variantID: variant?.id }} />, size: 'xl' }} />
            <ContentModal {...{ show: show === 'individual_bp', size: 'xl', onHide: onClose, title: t('individual_bp'), content: <IndividualBuildingParts {...{ onClose, variant }} /> }} />
        </div>
    </MainLayout>
}

const VariantRow = ({ variant, setVariant, setShow, setBuildingPartV, reloadBP, copyVariant }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(true);
    const [bpv, setBPV] = useState(undefined);
    const [lastCalculationOlder, setLastCalculationOlder] = useState(false);

    const colors = {
        "A": "#008000", // Grün
        "B": "#ADFF2F", // Hellgrün
        "C": "#FFFF00", // Gelb
        "D": "#FFD700", // Gold
        "E": "#FFA500", // Orange
        "F": "#D2691E", // Schokoladenbraun
        "G": "#A52A2A", // Braun
        "H": "#8B4513", // Sattelbraun
    };

    const icons = {
        "A": faA,
        "B": faB,
        "C": faC,
        "D": faD,
        "E": faE,
        "F": faF,
        "G": faG,
        "H": faH,
    }

    const lastCalculation = variant?.last_calculation ? new Date(variant?.last_calculation) : undefined;
    const updatedAt = variant?.updated_at ? new Date(variant?.updated_at) : undefined;
    let formattedDate = undefined;

    if (lastCalculation) {
        formattedDate = lastCalculation.toLocaleDateString("de-DE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const addSecondsToDate = (date, seconds) => {
        return new Date(date.getTime() + seconds * 1000);
    };

    useEffect(() => {
        if (lastCalculation && updatedAt) {
            const lastCalculationWithSecondsAdded = addSecondsToDate(lastCalculation, 5); // 5 Sekunden hinzufügen
            const isLastCalculationOlder = lastCalculationWithSecondsAdded < new Date(updatedAt);
            setLastCalculationOlder(isLastCalculationOlder)
        }
    }, [])


    return <>
        <tr>
            <td className="align-middle">
                <span>{variant.realization || t('planning')}</span>
            </td>
            <td className="align-middle">
                <span>{variant.name}</span>
            </td>
            <td className="align-middle">
                <span>
                    {variant?.last_calculation === undefined && (<span>Berechnung Fehlt</span>)}
                    
                    {lastCalculationOlder && (
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('notActual')}</Tooltip>}>
                            <FontAwesomeIcon icon={faTriangleExclamation} color="#FFBF00" style={{ paddingLeft: "5px" }} />
                        </OverlayTrigger>
                    )}

                    {(formattedDate && !lastCalculationOlder) && (<span>{formattedDate}</span>)}
                </span>
            </td>
            <td className="align-middle">
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <Row>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '26px', // Breite des Containers
                            height: '26px', // Höhe des Containers
                            backgroundColor: colors[variant.ee_class], // Hintergrundfarbe des Containers
                            borderRadius: '20%', // Macht den Container rund
                            padding: '10px', // Innenabstand
                            marginRight: '10px',
                            boxSizing: 'border-box', // Stellt sicher, dass Padding zur Breite/Höhe dazugezählt wird
                            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)' // Optional: fügt einen Schatten hinzu
                        }}>
                            <FontAwesomeIcon icon={icons[variant.ee_class] || faA} style={{ color: 'black' }} />
                        </div>
                        {variant.specific_consumption?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("kwh_year")}
                    </Row>
                    )
                }
            </td>
            <td className="align-middle">
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${(variant.reduction_to_actual * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`}</span>
            </td>
            <td className="align-middle">
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${variant.co2_emissions_m2?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${t("kg_year")}`}</span>
            </td>
            <td className="align-middle">
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>
                                <>
                                    <p>{t('landlordCosts')}</p>
                                    {variant.co2_costs?.map(k => <p>{k.year} : {(k.costs_eur * variant.landlord_co2_share)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € </p>)}
                                </>
                            </Tooltip>
                        }>
                        <span>
                            {(variant.landlord_co2_share * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} %
                            {(variant.reduction_co2_costs_landlord_annually > 0) ?
                                <>
                                    (Ersparnis 20y: {(20 * variant.reduction_co2_costs_landlord_annually)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €)
                                </>
                                :
                                <></>
                            }
                            <FontAwesomeIcon icon={faInfoCircle} style={{ paddingLeft: "10px" }} />
                        </span>
                        {/* <span>{(variant.landlord_co2_share * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} % {(variant.reduction_co2_costs_landlord_annually > 0) ? <>(Ersparnis 20y: {(20 * variant.reduction_co2_costs_landlord_annually)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €)</> : <></>}</span> */}
                    </OverlayTrigger>
                    )
                }
            </td>
            <td className="align-middle">
                <div className='actions__buttonbar grid__six'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("addStandardBP")}</Tooltip>
                        }>
                        <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("bpv") }}>
                            <FontAwesomeIcon icon={faAdd} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("edit")}</Tooltip>
                        }>
                        <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("variant") }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t('individual_bp')}</Tooltip>
                        }>
                        <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("individual_bp") }} disabled={variant?.actual ? true : false}>
                            <FontAwesomeIcon icon={faList} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>

        {display && <StandardReferenceTable {...{ variant, setShow, reloadBP }} />}
    </>
};

export default BuildingVariantActual;