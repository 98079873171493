import React, { useCallback, useEffect, useState } from 'react'
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useParams } from 'react-router-dom';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import TablePlaceholder from '../components/Placeholders/TablePlaceholder';
import { Button, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import ContentModal from '../components/ContentModal';
import AddEditSimpleBuildingPart from './AddEditSimpleBuildingPart';
import SecurityActionModal from '../components/SecurityActionModal';
import {EditIndividualBuildingParts} from './EditIndividualBuildingParts';

export const IndividualBuildingParts = () => {
    const client = useSwagger();
    const { login } = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const { addToast } = useToast();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const [individualBuildingParts, setIndividualBuildingParts] = useState(undefined)

    const tableStructure = [
        {
            col: t('name'),
            type: 'label'
        }, {
            col: t('unit'),
            type: 'label'
        }, {
            col: t('level'),
            type: 'label'
        }, {
            col: t('room'),
            type: 'label'
        }, {
            col: t('type'),
            type: 'label'
        }, {
            col: t('from_catalog'),
            type: 'label'
        }, {
            col: t('uValue'),
            type: 'label'
        }, {
            col: t('thermalBridgeSurcharge'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }
    ];

    const sortArray = (array) => {
        return array.sort((a, b) => {
            // Extrahiere die IDs oder setze sie auf Infinity, wenn sie undefined sind
            // const utilizationUnitIdA = a.room.utilization_unit?.id ?? Infinity;
            // const utilizationUnitIdB = b.room.utilization_unit?.id ?? Infinity;

            // const floorIdA = a.room.floor?.id ?? Infinity;
            // const floorIdB = b.room.floor?.id ?? Infinity;

            // const roomIdA = a.room.id ?? Infinity;
            // const roomIdB = b.room.id ?? Infinity;
            
            const floorIdA = a.floor ?? Infinity;
            const floorIdB = b.floor ?? Infinity;

            const roomIdA = a.room ?? Infinity;
            const roomIdB = b.room ?? Infinity;

            // Vergleiche zuerst nach utilization_unit.id
            // if (utilizationUnitIdA !== utilizationUnitIdB) {
            // return utilizationUnitIdA.localeCompare(utilizationUnitIdB);
            // }
            
            // Wenn gleich, vergleiche nach floor.id
            if (floorIdA !== floorIdB) {
                return floorIdA.localeCompare(floorIdB);
            }
            
            // Wenn immer noch gleich, vergleiche nach room.id
            return roomIdA.localeCompare(roomIdB);
        });
    };

    const getIndividualBuildingParts = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_buildingpart_list({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setIndividualBuildingParts([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                const newArray = response.obj?.filter(bp => (bp?.individual && (bp?.individual?.U_typical || bp?.individual?.standard_reference)))
                let result = sortArray(newArray)
                setIndividualBuildingParts(result);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const deleteIndividualBuildingPart = useCallback(async(deleteBpID) => {
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
          };
    
          const response = await client.apis["building"].building_buildingpart_destroy({
            building_uuid: _buildingGuid,
            bp_uuid: deleteBpID
          });
    
          if (response.status >= 200 && response.status < 300) {
            addToast(t("success"), t("deletedBuildingpart"), "success");
            getIndividualBuildingParts();
          }
    
          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          addToast(t("error"), t("responseError"), "error");
          client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t, getIndividualBuildingParts]);
  
    useEffect(() => {
        getIndividualBuildingParts()
    }, [])

  return (
    <>
        {(individualBuildingParts === undefined) ? (
            <TablePlaceholder {...{ structure: tableStructure }} />
        ) : (!Boolean(individualBuildingParts?.length)) ? (
            <p className="m-0">{t("noIndividualBuildingParts")}</p>
        ) : (
            <>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                            <span>{ts.col}</span>
                        </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {individualBuildingParts?.map((individualBP) => <IndividualBuildingPartRow key={`ref-key-${individualBP.id}`} {...{ getIndividualBuildingParts, deleteIndividualBuildingPart, individualBP }} />)}
                    </tbody>
                    <Button
                        variant="outline-primary"
                        className="w-100 mt-3"
                        onClick={() => setShow("edit_all_individual_bp")}
                    >
                        {t("edit")}
                    </Button>
                    <ContentModal show={show === "edit_all_individual_bp"} onHide={() => { setShow(false); getIndividualBuildingParts(); }} title={t('edit_individual_bp')} content={<EditIndividualBuildingParts {...{ setShow: setShow, individualBuildingParts: individualBuildingParts, getIndividualBuildingParts: getIndividualBuildingParts }} />} size="xl" />
                </Table>
            </>
        )}
    </>
  )
}

const IndividualBuildingPartRow = ({ getIndividualBuildingParts, deleteIndividualBuildingPart, individualBP }) => {
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();

    const [type, setType] = useState("")
    const [show, setShow] = useState(false)
    const [deleteBPID, setDeleteBPID] = useState(undefined);

    const getTypeLabel = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildingparttypes"].buildingparttypes_list();

            if (response.status === 204) {
                setType(undefined)
                return
            }

            if (response.status >= 200 && response.status < 300) {
                const matchedItem = response.obj.find(item => item.value === individualBP.type);
                if (matchedItem) {
                    const labelMap = {
                        Floor: "floor",
                        OuterWall: "outerwall",
                        Ceiling: "ceiling",
                        Window: "window",
                        OuterDoor: "outerdoor",
                        Roof: "roof",
                        InnerWall: "innerwall",
                        InnerDoor: "innerdoor",
                        InnerCeiling: "innerceiling",
                        Shutterbox: "shutterbox",
                        "Virtual Group": "virtualgroup",
                        "Ground Soil" : "groundsoil",
                        "OuterWall Soil": "outerwallsoil",
                    };

                    matchedItem.label = labelMap[matchedItem.label] || matchedItem.label;
                    setType(matchedItem.label);
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error:", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, individualBP])

    const onClose = () => {
        setShow(false)
        getIndividualBuildingParts()
    }

    useEffect(() => {
        getTypeLabel()
    }, [])

    return (
        <>
            <tr>
                <td className="align-middle">
                    <span>{individualBP?.name}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.room_info?.utilization_unit || individualBP?.room?.utilization_unit?.name || t('belongsToNoAppartment')}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.floor_info?.name || individualBP?.room?.floor?.name || t('belongsToNoAppartment')}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.room_info?.name || t('belongsToNoAppartment')}</span>
                </td>
                <td className="align-middle">
                    <span>{t(`${type || ""}`)}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.individual?.standard_reference ? <FontAwesomeIcon icon={faCheck} /> : t("individual")}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.individual?.U_typical || t('noValues')}</span>
                </td>
                <td className="align-middle">
                    <span>{individualBP?.individual?.U_thermal_bridge || t('noValues')}</span>
                </td>
                <td className="align-middle">
                    <div className='actions__buttonbar grid__two'>
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t("edit")}</Tooltip>}>
                            <Button variant="outline-primary" className="p-10" onClick={() => setShow("edit_individual_bp")}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("delete")}</Tooltip>}>
                            <Button variant="outline-primary" className="p-10" onClick={() => setDeleteBPID(individualBP?.id, setShow("delete_individual_bp"))}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
            <ContentModal {...{ show: show === 'edit_individual_bp', onHide: () => setShow(false), title: t('edit_individual_bp'), content: <AddEditSimpleBuildingPart {...{ onClose: onClose, room: individualBP?.room, editBP: individualBP }} /> }} />
            <SecurityActionModal {...{show: show === 'delete_individual_bp', onHide: () => { setDeleteBPID(undefined); setShow(false) }, title: t("deleteIndividualBuildingPart"), question: t("questionDeleteIndividualBuildingPart"), action: () => deleteIndividualBuildingPart(deleteBPID)}}/>
        </>
    )
}
