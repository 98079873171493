import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { CenterRow, LargeCol, StyledCard } from "../style/StyledContainer";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerSelection from "../widgets/CustomerSelection";
import { faArrowRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { dateToString } from "../util/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SHButton } from "../style/SigmaHeatTheme";
import { Link } from "react-router-dom";


function GrantOverview(props) {
    // eslint-disable-next-line no-unused-vars
    const {login, doSetLogin} = useAuth();
    const { t } = useTranslation();
    const [orgGrants, setOrgGrants] = useState([]);
    const [userGrants, setUserGrants] = useState([]);

    const getGrants = useCallback(() => {
        fetch("https://tech.sigmaheat.de/granted", {
            headers: {
                "Authorization": login.Authorization,
                "Content-Type": "application/json"
            },
            method: "GET"
        }).then(data => (data.status === 200) ? data.json() : []).then(data => setUserGrants(data));
        fetch("https://tech.sigmaheat.de/org/" + login.currentOrganisation.id + "/granted", {
            headers: {
                "Authorization": login.Authorization,
                "Content-Type": "application/json"
            },
            method: "GET"
        }).then(data => (data.status === 200) ? data.json() : []).then(data => setOrgGrants(data));
    }, [login.Authorization, login.currentOrganisation.id])

    useEffect(() => {
        getGrants();
    }, [getGrants]);

    console.log(userGrants);
    console.log(orgGrants);

    return (
        <>
            <CenterRow>
                <CustomerSelection open={parseInt(localStorage.getItem('sigmaheat-customer-selection')) === 0} />
                <LargeCol last={true}>
                    <StyledCard title={"Organisations Freigaben"}>
                    <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>{t("owner")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("type")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("name")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("valid_until")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("created_at")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("actions")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orgGrants.map((grant) => {
                                    return <>
                                        <tr>
                                            <td className="align-middle">

                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{grant.ressource_type}</span>
                                            </td>
                                            <td className="align-middle">

                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{dateToString(new Date(Date.parse(grant.until)))}</span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{dateToString(new Date(Date.parse(grant.created_at)))}</span>
                                            </td>
                                            <td className="align-middle">
                                                <Link to={'/building/' + grant.ressource_uuid}>
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        overlay={
                                                            <Tooltip>{t("totheBuilding")}</Tooltip>
                                                        }>
                                                        <SHButton value="goto" onClick={() => { }}>
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </SHButton>
                                                    </OverlayTrigger>
                                                </Link>
                                            </td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                        </table>
                    </StyledCard>

                    <StyledCard title={"Persönliche Freigaben"}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>{t("owner")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("type")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("name")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("valid_until")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("created_at")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                    <th>
                                        <span>{t("actions")}</span>
                                        <FontAwesomeIcon className="d-sm-none" icon={faHouse} size="sm" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userGrants.map((grant) => {
                                    return <>
                                        <tr>
                                            <td className="align-middle">

                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{grant.ressource_type}</span>
                                            </td>
                                            <td className="align-middle">

                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{dateToString(new Date(Date.parse(grant.until)))}</span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="d-block break-word">{dateToString(new Date(Date.parse(grant.created_at)))}</span>
                                            </td>
                                            <td className="align-middle">
                                                <Link to={'/building/' + grant.ressource_uuid}>
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        overlay={
                                                            <Tooltip>{t("totheBuilding")}</Tooltip>
                                                        }>
                                                        <SHButton value="goto" onClick={() => { }}>
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </SHButton>
                                                    </OverlayTrigger>
                                                </Link>
                                            </td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                        </table>
                    </StyledCard>
                </LargeCol>

            </CenterRow>
        </>
    );
}

export default GrantOverview