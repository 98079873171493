import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useSwagger } from "../context/SwaggerContext";
import { useBuilding } from "../context/BuildingContext";
import { getChangedFields, isEmpty } from "../util/helpers";
import { Button, Form, Placeholder } from "react-bootstrap";

export const AddEditAlarm = ({ onClose, types, ...props }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const client = useSwagger();
    const { currentBuilding } = useBuilding();

    let initialData = props.alarm || {
        device_id: "",
        measurement: "",
        alarm_type: types[0].value,
        value: ""
    }

    const [devices, setDevices] = useState(undefined);
    const [alarm, setAlarm] = useState(initialData);

    const loadDevices = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_device_list({ building_uuid: currentBuilding.id, filter_measurements: false });

            if (response.status >= 200 && response.status < 300) {
                if (response.obj && response.obj.some(obj => Array.isArray(obj.measurements) && obj.measurements.length > 0)) {
                    const devices = response.obj.filter(obj => Array.isArray(obj.measurements) && obj.measurements.length > 0);
                    setDevices(response.status === 204 ? [] : devices);
                    if (devices.length && !props.alarm) setAlarm(prev => ({ ...prev, device_id: devices[0].device_id, measurement: devices[0].measurements[0].uuid }))
                } else {
                    setDevices([])
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log(error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding]);

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        let tmpAlarm = { ...alarm };
        delete tmpAlarm?.device_id

        const changedFields = getChangedFields(tmpAlarm, initialData);
        if (isEmpty(changedFields)) onClose(undefined, false, false)

        if (!client || !currentBuilding || (props.alarm !== undefined && isEmpty(changedFields))) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(props.alarm ? changedFields : tmpAlarm);
                return req;
            };

            const response = alarm.id !== undefined ? await client.apis["building"].building_measurement_alarm_partial_update({ building_uuid: currentBuilding?.id, alarm_uuid: alarm.id }) : 
            await client.apis["building"].building_measurement_alarm_create({ building_uuid: currentBuilding?.id });
            
            if (response.status >= 200 && response.status < 300) {
                addToast(t("floor"), alarm.id ? t("alarmPatch") : t("alarmPost"), "success");
                onClose(undefined, false, true);
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('alarm'), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, alarm, onClose, addToast, t])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setAlarm(prevState => ({
            ...prevState,
            [id]: value
        }))
    }, [])

    useEffect(() => {
        if (!currentBuilding) return
        loadDevices();
    }, [currentBuilding])

    return <>
        {(devices === undefined) ? (
            <FormPlaceholder {...{ alarm }} />
        ) : (!Boolean(devices.length)) ? (
            <span>{t('alarmDeviceAssignInfo')}</span>
        ) : (
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className='mb-3' controlId="device_id">
                    <Form.Label>{t('device')}</Form.Label>
                    <Form.Select value={alarm?.device_id} onChange={(e) => changeHandler(e)}>
                        {devices?.map(device => <option key={`device-key-${device.device_id}`} value={device.device_id}>{device.name}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3' controlId="measurement">
                    <Form.Label>{t('measurement')}</Form.Label>
                    <Form.Select value={alarm?.measurement} onChange={(e) => changeHandler(e)}>
                        {devices?.find(d => d.device_id == alarm.device_id).measurements.map(measurement => <option key={`measurement-key-${measurement.uuid}`} value={measurement.uuid}>{measurement.name}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3' controlId="alarm_type">
                    <Form.Label>{t('alarmType')}</Form.Label>
                    <Form.Select value={alarm?.alarm_type} onChange={(e) => changeHandler(e)}>
                        {types.map(type => <option key={`alarm-type-key-${type.value}`} value={type.value}>{type.label}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="value">
                    <Form.Label>{t("threshhold")} (°C)</Form.Label>
                    <Form.Control required step="0.1" type="number" value={alarm.value} onChange={(e) => changeHandler(e)} />
                </Form.Group>

                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" className='w-100' type='submit'>{t('id' in alarm ? 'edit' : 'add')}</Button>
                </div>
            </Form>
        )}
    </>
}

const FormPlaceholder = ({ alarm }) => {
    const { t } = useTranslation()

    return <Form>
        <Form.Group className="mb-3">
            <Form.Label>{t('device')}</Form.Label>
            <Placeholder as="div" animation="glow" style={{'height': 'calc(1rem * 1.5 + .375rem * 2 + 2px)', 'borderRadius': 'var(--bs-borderRadius)', 'overflow': 'hidden'}}>
                <Placeholder xs={12} />
            </Placeholder>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t('measurement')}</Form.Label>
            <Placeholder as="div" animation="glow" style={{'height': 'calc(1rem * 1.5 + .375rem * 2 + 2px)', 'borderRadius': 'var(--bs-borderRadius)', 'overflow': 'hidden'}}>
                <Placeholder xs={12} />
            </Placeholder>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t('alarmType')}</Form.Label>
            <Placeholder as="div" animation="glow" style={{'height': 'calc(1rem * 1.5 + .375rem * 2 + 2px)', 'borderRadius': 'var(--bs-borderRadius)', 'overflow': 'hidden'}}>
                <Placeholder xs={12} />
            </Placeholder>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t('threshhold')} (°C)</Form.Label>
            <Placeholder as="div" animation="glow" style={{'height': 'calc(1rem * 1.5 + .375rem * 2 + 2px)', 'borderRadius': 'var(--bs-borderRadius)', 'overflow': 'hidden'}}>
                <Placeholder xs={12} />
            </Placeholder>
        </Form.Group>

        <div className="d-flex justify-content-end">
            <Button variant="outline-primary" className="w-100" disabled>{t('id' in alarm ? 'edit' : 'add')}</Button>
        </div>
    </Form>
}

export default AddEditAlarm;