import { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faAdd, faEdit, faTrash, faCheck, faCalculator, faA, faF, faB, faC, faD, faE, faG, faH, faDna, faXmark, faCopy, faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import Collapse from "../components/Collapse";
import { Calculate } from "./CalculateDIN12831";
import { AddEditBuildingPartVariant } from "./AddEditBuildingPartVariant";
import { AddEditVariant } from "./AddEditVariant";
import { StandardReferenceTable } from "./StandardReferenceTable";
import { ListExchangeHeatpart } from "./ListExchangeHeatpart";
import StrandReference from "./StrandReference";
import { useSwagger } from "../context/SwaggerContext";
import {IndividualBuildingParts} from "./IndividualBuildingParts";
import { usePermissions } from "../context/PermissionsContext";
import { useBuilding } from "../context/BuildingContext";
import { isEmpty } from "../util/helpers";

export const BuildingVariants = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [variant, setVariant] = useState(undefined);
    const [buildingPartV, setBuildingPartV] = useState(undefined);
    const [show, setShow] = useState(false);
    const { allowEdit } = usePermissions();
    const { currentBuilding, getVariants, variants } = useBuilding();
    const location = useLocation();

    const userShouldAddVariant = useMemo(() => {
        return location.state?.userShouldAddVariant || false;
    }, [location.state?.userShouldAddVariant]);

    const mainFunctions = [
        { label: t('add'), onClick: () => setShow('variant'), key: 'add', icon: faAdd },
        { label: t('calculate'), onClick: () => setShow("Calculate"), key: 'calculate', icon: faCalculator }
    ]

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        }, {
            col: "Date",
            type: 'label'
        }, {
            col: "Name",
            type: 'label'
        }, {
            col: "lastCalculation",
            type: 'label'
        }, {
            col: "Consumption_demand",
            type: 'label'
        }, {
            col: "Reduction",
            type: 'label'
        }, {
            col: "co2_emissions_gas",
            type: 'label'
        },
        //  {
        //     col: "Max_Flow_Temperature",
        //     type: 'label'
        // }, {
        //     col: "Max_Return_Temperature",
        //     type: 'label'
        // }, {
        //     col: "exchange_individual_bp_short",
        //     type: 'label'
        // }, {
        //     col: "heatingLoad",
        //     type: 'label'
        // }, {
        //     col: "Created",
        //     type: 'label'
        // },
        {
            col: "landlordsharesco2",
            type: 'label'
        },
        {
            col: "actual",
            type: 'label'
        },
        {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const deleteVariant = useCallback(async (id) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_destroy({
                building_uuid: _buildingGuid,
                variant_uuid: id,
            });
              
            if (response.status >= 200 && response.status < 300) {
                getVariants();
                addToast(t("success"), t('deletedVariantpart'), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 409) {
                addToast(t("error"), t('deleteVariantConflict'), "error");
            } else {
                addToast("error", t('networkError'), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, _buildingGuid, login.Authorization, addToast, t])

    const copyVariant = useCallback(async (variantID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variant_copy_create({
                building_uuid: _buildingGuid, variant_uuid: variantID,
            });

            if (response.status >= 200 && response.status < 300) {
                getVariants();
                addToast(t("success"), t("variantCopiedSuccessfully"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 429) {
                addToast(t("error"), t("variantCopiedFailed"), "error");
            } else {
                addToast("error", t('networkError'), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast])

    const onHide = (refresh = false) => {
        setShow(false);
        setVariant(undefined);
        setBuildingPartV(undefined);
        if (refresh) getVariants();
    }

    // Debouncing
    // wenn noch keine Varianten hinzugefügt wurde, man aber z.B. in der url "/level" eingibt
    // wird der User automaitsch zu "/variants" weiter geleitet und es erscheint ein toast
    let toastTimeout;

    useEffect(() => {
        if (userShouldAddVariant && isEmpty(variants)) {
            clearTimeout(toastTimeout);
            toastTimeout = setTimeout(() => {
                addToast(t("error"), t("userShouldAddVariant"), "error");
            }, 200); // 200ms Debounce-Zeit
        }
        return () => clearTimeout(toastTimeout); 
    }, [userShouldAddVariant]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('variants')} ${t('inThe')} ${t('building')}: ${currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
                {(variants === undefined) ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : (isEmpty(variants)) ? (
                    <p className="m-0">{t("noVariants")}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                    <span>{(ts.type == "label") ? t(ts.col.toLowerCase()) : ts.col}</span>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <VariantRow {...{ variant: variants, setVariant, setShow, setBuildingPartV, copyVariant, margin: 0, buildingPartV }} />
                        </tbody>
                    </Table>
                )}
            </Card>

            <ContentModal {...{ show: show === 'Calculate', onHide: () => onHide(), title: t('calculateDIN12831'), content: <Calculate {...{ onClose: () => onHide() }} /> }} />
            <ContentModal {...{ show: show === 'variant', onHide: () => onHide(), title: t('addVariant'), content: <AddEditVariant {...{ variant, onClose: (boolean) => onHide(boolean) }} /> }} />
            <ContentModal {...{ show: show === 'exchange_list', onHide: () => onHide(), size: "fullscreen", title: t('exchange_list'), content: <ListExchangeHeatpart {...{ variant }} /> }} />
            {/* <ContentModal {...{ show: show === 'bpv', onHide: () => onHide(), title: t('addBPV'), size: 'lg', content: <AddEditBuildingPartVariant {...{ buildingPartV, variantID: variant?.id, onClose: () => onHide() }} /> }} /> */}
            <ContentModal {...{ show: show === 'strand', onHide: () => onHide(), title:  t('strands'), content: <StrandReference {...{ variantID: variant?.id }} />, size: 'xl' }} />
            <ContentModal {...{ show: show === 'individual_bp', size: 'xl', onHide: () => onHide(), title: t('individual_bp'), content: <IndividualBuildingParts /> }} />
            <SecurityActionModal {...{ show: show === "delete", onHide: () => onHide(), title: t('deleteVariant'), question: t('deleteVariantQuestion'), action: () => deleteVariant(variant.id) }} />
        </div>
    </MainLayout>
}

const VariantRow = ({ variant, setVariant, setShow, setBuildingPartV, copyVariant, margin, buildingPartV }) => {
    const { t } = useTranslation();
    const { login } = useAuth();
    const [display, setDisplay] = useState(false);
    const [lastCalculationOlder, setLastCalculationOlder] = useState(false);
    const [standardRef, setStandardRef] = useState(undefined);
    const [showIntern, setShowIntern] = useState(false);
    const { allowEdit } = usePermissions();
    const _buildingGuid = useParams()['buildingGuid'];

    const client = useSwagger();

    const colors = {
        "A": "#008000", // Grün
        "B": "#ADFF2F", // Hellgrün
        "C": "#FFFF00", // Gelb
        "D": "#FFD700", // Gold
        "E": "#FFA500", // Orange
        "F": "#D2691E", // Schokoladenbraun
        "G": "#A52A2A", // Braun
        "H": "#8B4513", // Sattelbraun
    };

    const icons = {
        "A": faA,
        "B": faB,
        "C": faC,
        "D": faD,
        "E": faE,
        "F": faF,
        "G": faG,
        "H": faH,
    }

    const lastCalculation = variant?.last_calculation ? new Date(variant?.last_calculation) : undefined;
    const updatedAt = variant?.updated_at ? new Date(variant?.updated_at) : undefined;
    let formattedDate = undefined;

    if (lastCalculation) {
        formattedDate = lastCalculation.toLocaleDateString("de-DE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const addSecondsToDate = (date, seconds) => {
        return new Date(date.getTime() + seconds * 1000);
    };
    
    const loadStandardRef = useCallback(async () => {
        setStandardRef(undefined);

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariantsbp({
                building_uuid: _buildingGuid,
                variant_uuid: variant.id,
            });

            if (response.status === 204) {
                setStandardRef([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setStandardRef(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const onHide = (refresh) => {
        setShowIntern(false);
        setBuildingPartV(undefined)
        if (refresh) loadStandardRef()
    }

    useEffect(() => {
        if (lastCalculation && updatedAt) {
            const lastCalculationWithSecondsAdded = addSecondsToDate(lastCalculation, 5); // 5 Sekunden hinzufügen
            const isLastCalculationOlder = lastCalculationWithSecondsAdded < new Date(updatedAt);
            setLastCalculationOlder(isLastCalculationOlder)
        }
    }, [])
   
    
    return <>
        <tr>
            <td className="align-middle">
                <div className={`ms-${margin}`}>
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </div>
            </td>
            <td className="align-middle">
                <span>{variant.realization || t('planning')}</span>
            </td>
            <td className="align-middle">
                <span>{variant.name}</span>
            </td>
            <td className="align-middle">
                <span>
                    {variant?.last_calculation === undefined && (<span>Berechnung Fehlt</span>)}

                    {lastCalculationOlder && (
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('notActual')}</Tooltip>}>
                            <FontAwesomeIcon icon={faTriangleExclamation} color="#FFBF00" style={{ paddingLeft: "5px" }} />
                        </OverlayTrigger>
                    )}

                    {(formattedDate && !lastCalculationOlder) && (<span>{formattedDate}</span>)}
                </span>
            </td>
            <td className="align-middle">
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <Row>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '26px', // Breite des Containers
                            height: '26px', // Höhe des Containers
                            backgroundColor: colors[variant.ee_class], // Hintergrundfarbe des Containers
                            borderRadius: '20%', // Macht den Container rund
                            padding: '10px', // Innenabstand
                            marginRight: '10px',
                            boxSizing: 'border-box', // Stellt sicher, dass Padding zur Breite/Höhe dazugezählt wird
                            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)' // Optional: fügt einen Schatten hinzu
                        }}>
                            <FontAwesomeIcon icon={icons[variant.ee_class] || faA} style={{ color: 'black' }} />
                        </div>
                        {variant.specific_consumption?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("kwh_year")}
                    </Row>
                    )
                }
            </td>
            <td className="align-middle">
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${(variant.reduction_to_actual * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`}</span>
            </td>
            <td className="align-middle">
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${variant.co2_emissions_m2?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${t("kg_year")}`}</span>
            </td>
            <td className="align-middle">
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>
                                <>
                                    <p>{t('landlordCosts')}</p>
                                    {variant.co2_costs?.map(k => {
                                        let label;
                                        if (k.year === 2030) {
                                            label = `${t("from_ab")} 2027 (100 EUR/${t('ton')}): `;
                                        } else if (k.year === 2050) {
                                            label = `${t("from_ab")} 2027 (500 EUR/${t('ton')}): `;
                                        } else {
                                            label = `${k.year}: `;
                                        }

                                        return (
                                            <p key={k.year}>
                                                {label}
                                                {(k.costs_eur * variant.landlord_co2_share)?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })} €
                                            </p>
                                        );
                                    })}
                                </>
                            </Tooltip>
                        }>
                        <span>
                            {(variant.landlord_co2_share * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} %
                            {(variant.reduction_co2_costs_landlord_annually > 0) ?
                                <>
                                    (Ersparnis 20y: {(20 * variant.reduction_co2_costs_landlord_annually)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €)
                                </>
                                :
                                <></>
                            }
                            <FontAwesomeIcon icon={faInfoCircle} style={{ paddingLeft: "10px" }} />
                        </span>
                    </OverlayTrigger>
                    )
                }
            </td>
            <td className="align-middle">
                <FontAwesomeIcon icon={variant.actual ? faCheck : faXmark} />
            </td>
            <td className="align-middle">
                <div className='actions__buttonbar grid__five'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("edit")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("variant") }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("copy")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => copyVariant(variant.id)}>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{ t('strands')}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("strand") }}>
                            <FontAwesomeIcon icon={faDna} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t('individual_bp')}</Tooltip>
                        }>
                        <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("individual_bp") }} disabled>

                            <FontAwesomeIcon icon={faList} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("delete")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("delete") }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>

        {display && <StandardReferenceTable {...{ variant, setVariant, setShow: setShowIntern, standardRef, setStandardRef }} />}
        <ContentModal {...{ show: showIntern === 'bpv', onHide: () => onHide(), title: t('addBPV'), size: 'lg', content: <AddEditBuildingPartVariant {...{ buildingPartV, variantID: variant.id, onClose: (boolean) => onHide(boolean), setStandardRef }} /> }} />

        {variant.children && variant.children.map((child, index) => (
            <VariantRow {...{ variant: child, setVariant, setShow, setBuildingPartV, copyVariant, margin: margin + 1, buildingPartV }} />
        ))}
    </>
};

export default BuildingVariants;