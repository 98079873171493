import { useState, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSwagger } from "../context/SwaggerContext";

export const AddEditSchema = ({ onClose, variant, ...props }) => {
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const [schema, setSchema] = useState(props?.schema || {
        name: ""
    });

    const handleChange = useCallback((e) => {
        const { id, value } = e.target;
        setSchema(prev => ({
            ...prev,
            [id]: value
        }))
    }, [schema])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant}/scheme/${schema?.id ? `${schema?.id}/` : ''}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: schema?.id ? "PATCH" : "POST",
        //     body: JSON.stringify(schema)
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     addToast("Add/Edit Schema", "Schema was added / edited successfully", "success")
        //     onClose();
        //     return response.json();
        // })
        // .catch(error => addToast(t("error"), error.message, "error"));

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(schema);
                return req;
            };

            const response = schema?.id ? await client.apis["building"].building_variant_scheme_partial_update({building_uuid: _buildingGuid, variant_uuid: variant, scheme_uuid: schema?.id}) : await client.apis["building"].building_variant_scheme_create({building_uuid: _buildingGuid, variant_uuid: variant}) ;

            if (response.status >= 200 && response.status < 300) {
                addToast("Add/Edit Schema", "Schema was added / edited successfully", "success")
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, variant, schema])

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" value={schema?.name || ""} onChange={(e) => handleChange(e)} />
        </Form.Group>

        <Button className="w-100" variant="outline-primary" type="submit">{t(schema?.id ? "edit" : "add")}</Button>
    </Form>
}

export default AddEditSchema;