import { useState, useEffect, createContext, useContext } from "react";
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Context";
import { useCallback } from "react";
import { useSwagger } from "../context/SwaggerContext";

const PermissionsContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionsContext);
}

export const PermissionsProvider = ({children}) => {
  const { login } = useAuth();
  const client = useSwagger();
  const [userPermissions, setUserPermissions] = useState(undefined);
  const [permissionLevel, setPermissionLevel] = useState(undefined);

  const [allowEdit, setAllowEdit] = useState(false);
  const [allowPaymentMethods, setAllowPaymentMethods] = useState(false);
  const [engineerRights, setEngineerRights] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const { addToast } = useToast();
  const { t } = useTranslation();

  const getUserPermission = useCallback(async() => {

    if (!login || !login.currentOrganisation || !client || login?.accepted === false) return; 

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login?.Authorization;
        return req;
      };

      const response = await client.apis["org"].org_user_retrieve({org_uuid: login.currentOrganisation.id, user_uuid: login.id});

      if (response.status >= 200 && response.status < 300) {
        setPermissionLevel(response.obj.permission_level)
      }

      client.http.requestInterceptor = originalRequestInterceptor;

    } catch (error) {
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login, addToast])

  const getPermissions = useCallback(async() => {
    if (!client) return;
    if (login?.accepted === false || (login?.accepted === undefined && login?.Authorization === undefined) || login?.TwoFA === true) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login?.Authorization;
        return req;
      };

      const response = await client.apis["org"].org_permission_list();

      if (response.status >= 200 && response.status < 300) {
        setUserPermissions(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;

    } catch (error) {
      addToast(t("error"), t("responseError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login?.Authorization, addToast, t])

  useEffect(() => {
    if (!login) return 

    getUserPermission();
    getPermissions();
  }, [login, login?.currentOrganisation]);

  useEffect(() => {
    if (!userPermissions) return;

    const isAdmin = login && 'admin_level' in login && login.admin_level === 1;

    setEngineerRights(isAdmin || (permissionLevel <= 20));
    setAllowEdit(isAdmin || (permissionLevel <= 15) );
    setAllowPaymentMethods(isAdmin || (permissionLevel <= 5));
    setSuperUser(isAdmin || (permissionLevel === 1));
    
  }, [userPermissions, login, permissionLevel]);

  return (
    <PermissionsContext.Provider value={{ allowEdit, allowPaymentMethods, engineerRights, userPermissions, superUser }}>
      {children}
    </PermissionsContext.Provider>
  );
};