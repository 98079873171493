import { useEffect, useCallback, useState } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faAdd, faMagnifyingGlass, faRotate } from "@fortawesome/free-solid-svg-icons";
import { arraysAreEqual } from "../util/helpers";
import Card from "../components/Card";
import Organisation from "../login/Organisation";
import ContentModal from "../components/ContentModal";
import { useSwagger } from "../context/SwaggerContext";
// import useBuildingHook from "../hooks/useBuildingHook";

export const ListAllOrganisations = ({ setPage, changeOrgFromSettings }) => {
    const { login, doSetLogin } = useAuth();
    const client = useSwagger();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [orgNotComplete, setOrgNotComplete] = useState(undefined)
    const [combinedProps, setCombinedProps] = useState(undefined)
    const [show, setShow] = useState(undefined);
    const [searchWord, setSearchWord] = useState({string: ""})
    const [organisation, setOrganisation] = useState(login.organisations.filter(org => org.permission_level !== 200))

    // useBuildingHook()

    const fetchOrganisations = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/org`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .then(data => {
        //     if (!arraysAreEqual(data, login.organisations)) {
        //         let tmpLogin = {...login}
        //         tmpLogin.organisations = data
        //         doSetLogin(tmpLogin)
        //     }
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
            
            const response = await client.apis["org"].getOrgs();

            if (response.status >= 200 && response.status < 300) {
                if (!arraysAreEqual(response.obj, login.organisations)) {
                    let tmpLogin = { ...login };
                    tmpLogin.organisations = response.obj;
                    doSetLogin(tmpLogin);
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login, doSetLogin, addToast, t]);

    const changeOrganisation = (id) => {
        let newLogin = login;

        // setCurrentOrg(id)

        // newLogin.currentOrganisation = newLogin.organisations[id];  
        newLogin.currentOrganisation = organisation[id];     


        // setCombinedProps({level : newLogin.organisations[id].in_onboarding, myOrgID : newLogin.organisations[id].id})
        setCombinedProps({level : organisation[id].in_onboarding, myOrgID : organisation[id].id})


        if(changeOrgFromSettings && newLogin.currentOrganisation.in_onboarding < 3 ) {
            setShow("changeOrgFromSettings")
            newLogin.currentOrganisation = undefined
        }

        doSetLogin(newLogin);
       
        // setCombinedProps({level : newLogin.organisations[id].in_onboarding, myOrgID : newLogin.organisations[id].id})
        // setOnboardingLevel({level : newLogin.organisations[id].in_onboarding})
        // setOrganisation({myOrgID : newLogin.organisations[id].id})

        // newLogin.organisations[id].in_onboarding == 3 ? navigate(`/${newLogin.organisations[id].id}/dashboard`) : setOrgNotComplete(true)
        organisation[id].in_onboarding == 3 ? navigate(`/${organisation[id].id}/dashboard`) : setOrgNotComplete(true)
    }

    const acceptInvitation = useCallback(async(org_guid) => {
        // fetch(`https://tech.sigmaheat.de/org/${org_guid}/invite/accept`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "PATCH"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .then(data => {
        //     let tmpLogin = {...login}
        //     let id = login.organisations.findIndex(org => org.id === org_guid);
        //     tmpLogin.organisations[id].permission = 'Only Read';
        //     tmpLogin.organisations[id].permission_level = data.permission_level;
        //     doSetLogin(tmpLogin)
        //     addToast(t('success'), t("invitationAccepted"), "success");
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["org"].org_invite_accept_partial_update({
            const response = await client.apis["org"].org_invitation_partial_update({
                org_uuid: org_guid,
            });

            if (response.status >= 200 && response.status < 300) {
                let tmpLogin = { ...login };
                let id = login.organisations.findIndex((org) => org.id === org_guid);
                tmpLogin.organisations[id].permission = "Only Read";
                tmpLogin.organisations[id].permission_level = response.obj.permission_level;
                setOrganisation(tmpLogin.organisations.filter(org => org.permission_level !== 200));
                doSetLogin(tmpLogin);
                addToast(t("success"), t("invitationAccepted"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t])

    const rejectInvitation = useCallback(async(org_guid) => {
        // fetch(`https://tech.sigmaheat.de/org/${org_guid}/invite/reject`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return {}
        // })
        // .then(_ => {
        //     let tmpLogin = {...login}
        //     tmpLogin.organisations = tmpLogin.organisations.filter(item => item.id !== org_guid)
        //     doSetLogin(tmpLogin)
        //     addToast(t('success'), t("invitationRejected"), "success");
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["org"].org_invite_reject_destroy({
            const response = await client.apis["org"].org_invitation_destroy({
                org_uuid: org_guid,
            });

            if (response.status >= 200 && response.status < 300) {
                let tmpLogin = { ...login };
                tmpLogin.organisations = tmpLogin.organisations.filter((item) => item.id !== org_guid);
                setOrganisation(tmpLogin.organisations.filter(org => org.permission_level !== 200));
                doSetLogin(tmpLogin);
                addToast(t("success"), t("invitationRejected"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t])

    // const combinedProps = { ...onboardingLevel, ...organisation };

    useEffect(() => {
        fetchOrganisations()
    }, [])

    // useEffect(() => {
    //     let newLogin = login;

    //     console.log("login hier:", login)

    //     // if(currentOrg) {
    //     //     setCombinedProps({level : newLogin.organisations[currentOrg].in_onboarding, myOrgID : newLogin.organisations[currentOrg].id})
    //     // }
    // }, [login, show])

    // Search

    const onSubmit = async ({reset}) => {
        if (reset) {
            setOrganisation(login.organisations.filter(org => org.permission_level !== 200))
            clearInput()

        } else {
            const result = login.organisations.filter(org => org.permission_level !== 200).filter(item => item.name.toLowerCase().includes(searchWord.string.toLowerCase()));
            setOrganisation(result)
        }
    }

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setSearchWord(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const clearInput = () => {
        setSearchWord({ string: '' });
    };

    const searchOrganisation = (
        <div className={`searchBar mb-3`}>
            <input
                type="text"
                placeholder={`${t('search')} ...`}
                className={`form-control`}
                style={{ borderEndEndRadius: '0', borderStartEndRadius: '0' }} 
                id='string'
                value={searchWord.string || ''} 
                onFocus={clearInput}
                onChange={(e) => changeHandler(e)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onSubmit({reset: false});  
                    }
                }}
            />
            <Button
                variant="outline-primary"
                onClick={() => onSubmit({reset: false})}
                style={{ borderStartStartRadius: '0', borderBottomLeftRadius: '0', maxWidth: "38px" }} 
                className='p-10 flex-shrink-0'
            >
                <FontAwesomeIcon icon={faMagnifyingGlass} className='flex-shrink-0' />
            </Button>

            <Button
                variant="outline-primary"
                onClick={() => onSubmit({reset: true})}
                style={{ marginLeft: '15px', maxWidth: "38px" }} 
                className='p-10 flex-shrink-0'
            >
                <FontAwesomeIcon icon={faRotate} className='flex-shrink-0' />
            </Button>
        </div>
    )

    const orgSelectionWrapper = (
        <div className='org__selection__wrapper'>
            {/* {login.organisations.filter(org => org.permission_level !== 200).map((org, i) => <Button variant="outline-primary" key={org.id} disabled={login?.currentOrganisation?.id === org.id} onClick={() => changeOrganisation(i)}>{org.name}</Button>)} */}
            {organisation.map((org, i) => <Button variant="outline-primary" key={org.id} disabled={login?.currentOrganisation?.id === org.id} onClick={() => changeOrganisation(i)}>{org.name}</Button>)}

            {Boolean(login.organisations.filter(org => org.permission_level === 200).length) && <Card {...{ heading: t("invitations") }}>
                <div className="d-flex flex-column gap-3">
                    {login.organisations.filter(org => org.permission_level === 200).map((org, i) => <div key={org.id} className="d-flex justify-content-between align-items-center gap-3">
                        <span>{org.name}</span>
                        <div className="d-flex justify-content-between align-items-center">
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Tooltip>{t("acceptInvitation")}</Tooltip>
                                }>
                                <Button variant="outline-primary" className="me-3" onClick={() => acceptInvitation(org.id)}><FontAwesomeIcon icon={faCheck} /></Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Tooltip>{t("rejectInvitation")}</Tooltip>
                                }>
                                <Button variant="outline-danger" onClick={() => rejectInvitation(org.id)}><FontAwesomeIcon icon={faClose} /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>)}
                </div>
            </Card>}
            { !changeOrgFromSettings &&
                <Button variant="outline-primary" className="d-flex justify-content-around w-100" onClick={() => setPage(2)}>
                    <FontAwesomeIcon icon={faAdd} />
                </Button>
            }
        </div>
    );


    return (
        <>
            {!orgNotComplete ? ( 
                <>
                    {searchOrganisation}
                    {orgSelectionWrapper}
                </>

            ) : (
                changeOrgFromSettings ? (
                    <>
                        {orgSelectionWrapper}
                        <ContentModal {...{ size: "xl", show: show === "changeOrgFromSettings", onHide: () => { setShow(false); fetchOrganisations()}, title: t("onboarding"), content: <Organisation {...combinedProps} />}} />
                    </>
                ) : (
                    <Organisation {...combinedProps} />
                )   
            )}
      </>
    )
}

export default ListAllOrganisations;