import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from '../components/MainLayout'
import Background from "../assets/background_3_3.png";
import Card from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowRight, faEdit, faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import TablePlaceholder from '../components/Placeholders/TablePlaceholder';
import { Button, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useToast } from '../NotificationsContent';
import ContentModal from '../components/ContentModal';
import EnergyResourcesForm from './Forms/EnergyResourcesForm';
import DjangoPagination from '../components/DjangoPagination';
import Collapse from '../components/Collapse';
import BuildingConsumptionData from './Forms/BuildingConsumptionData';
import BuildingCounters from '../BuildingPage/BuildingCounters'
import SecurityActionModal from "../components/SecurityActionModal";
import SelectYear from '../components/SelectYear';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
import "../BuildingPage/ckeditorskin.css";
import { usePermissions } from '../context/PermissionsContext';

const EnergyResourcesTableRow = ({ resource, setResource, types, setShow }) => {
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();

    return <tr>
        <td className="align-middle">{t(types.find(type => type.value === resource.type).label)}</td>
        <td className="align-middle">{resource.g_co2_kWh}</td>
        <td className="align-middle">{resource.cent_kWh}</td>
        <td className="align-middle">{resource.from_date}</td>
        <td className="align-middle">
            <div className='actions__buttonbar grid__two'>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t('edit')}</Tooltip>
                    }>
                    <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {setResource(resource); setShow('editResource')}}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t('delete')}</Tooltip>
                    }>
                    <Button disabled={!allowEdit} variant='outline-primary' className='p-10' onClick={() => {setResource(resource); setShow('deleteResource')}}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            </div>
        </td>
    </tr>
}


const BuildingRow = ({ building, types, availableTypes, energyResources }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false)

    return (
        <>
            <tr>
                <td className="align-middle">
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td className="align-middle">
                    <span>{building.name}</span>
                </td>
                <td className="align-middle">
                    <span className='d-block'>{building.street} {building.no}</span>
                    <span className='d-block'>{building.zip} {building.city}</span>
                </td>
                <td className="align-middle">
                    <span>{(building.heating_load / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    <span>{(building.thermal_power / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    <span>{(building.calculated_heated_area).toLocaleString(undefined, { maximumFractionDigits: 2 })} m²</span>
                </td>
                <td className="align-middle">
                    <Link to={`/building/${building.id}/dashboard`} className='d-flex flex-shrink-0'>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t('totheBuilding')}</Tooltip>
                            }>
                            <Button variant='outline-primary' className='p-10'>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </OverlayTrigger>
                    </Link>
                </td>
            </tr>
            {display && <tr  style={{ border: "hidden" }}>
                <td colSpan='100%'>
                    {/* <BuildingConsumptionData {...{ buildingID: building.id, types, availableTypes, energyResources }} /> */}
                    <BuildingCounters comeFromEnergyReports={true} buildingID={building.id} />
                </td>
            </tr>}
        </>
    );
};


const EnergyReports = () => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _orgGuid = useParams()['orgGuid'];
    const _eReportGuid = useParams()['eReportGuid'];

    const [show, setShow] = useState(false);

    const [types, setTypes] = useState([])
    const [availableTypes, setAvailableTypes] = useState([])

    const [energyResources, setEnergyResources] = useState(undefined);
    const [resource, setResource] = useState(undefined);
    const [currentBuildingPage, setCurrentBuildingPage] = useState(1);
    const [disabled, setDisabled] = useState(false);

    const [buildings, setBuildings] = useState(undefined);

    const [year, setYear] = useState(2024);
    const [introduction, setIntroduction] = useState('');
    const { allowEdit } = usePermissions();

    const mainFunctions = [
        {label: t('add'), onClick: () => setShow('addEnergyResource'), key: 'addEnergyResource', icon: faAdd},
    ]

    const tableStructure = [
        {
            col: t('type'),
            type: 'label'
        }, {
            col: t('g_co2_kWh'),
            type: 'label'
        }, {
            col: t('cent_kWh'),
            type: 'label'
        }, {
            col: t('from_date'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }
    ]

    const buildingTableStructure = [
        {
            col: <FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' />,
            type: 'icon'
        }, {
            col: t('name'),
            type: 'label'
        }, {
            col: t('city'),
            type: 'label'
        }, {
            col: t('heatingLossSum'),
            type: 'label'
        }, {
            col: t('thermalPower'),
            type: 'label'
        }, {
            col: t('heatedSurface'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }
    ]

    const getReport = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["energy_reports"].energy_reports_retrieve({
                org_uuid: _orgGuid,
                energy_report_uuid: _eReportGuid
            });

            if (response.status >= 200 && response.status < 300) {
                setYear(response.obj?.generation_document_data?.from_year)
                setIntroduction(response.obj?.generation_document_data?.introduction || '')
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization])

    const getResourceTypes = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["mediatypes"].mediatypes_list();

            if (response.status >= 200 && response.status < 300) {
                const transformedHpt = response.obj?.map(h => ({
                    ...h, 
                    label: h.label.toLowerCase()
                        .replace(/[\s-]+/g, '_')
                        .replace(/%/g, 'percent') 
                        .replace(/,/g, '')        
                }));

                setTypes(transformedHpt)
                getEnergyResources()
            }
            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('floor'), t('networkError'), "error");
            
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, client, login.Authorization, login.currentOrganisation.id, t]);

    const getEnergyResources = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["energy_resource"].energy_resource_list({ 
                org_uuid: _orgGuid,
                from_date: year
             });

            if (response.status >= 200 && response.status < 300) {
                console.log("response.obj", response.obj)
                setEnergyResources(response.obj)
                setAvailableTypes([...new Set(response.obj.map(obj => obj.type))])
            }
            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('floor'), t('networkError'), "error");
            
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, client, login.Authorization, login.currentOrganisation.id, t, year]);

    const loadbuildings = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildings"].buildings_list({
                org_uuid: _orgGuid,
                page: currentBuildingPage
            });

            if (response.status >= 200 && response.status < 300) {
                setBuildings([])
                if (response.obj === undefined) return
                setBuildings(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization, currentBuildingPage])

    const deleteResource = useCallback(async(resource) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["energy_resource"].energy_resource_destroy({
                org_uuid: _orgGuid,
                energy_resource_uuid: resource.id
            });

            if (response.status >= 200 && response.status < 300) {
                setResource(undefined);
                addToast(t("success"), t("energyResourceDeletedSuccessfully") , "success")

                if (energyResources.results?.length === 1 && currentBuildingPage !== 1) {
                    setCurrentBuildingPage(currentBuildingPage - 1)
                } else {
                    getEnergyResources();
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization, currentBuildingPage, currentBuildingPage, energyResources])

    const onSubmit = useCallback(async() => {
        if (!client) return;
        setDisabled(true)
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({
                    title: "Something is being cooked",
                    filename: "report_2024.pdf",
                    type: 1021,
                    description: "something new",
                    content_type: "application/pdf",
                    file_size_kb: 100,
                    generation_document_data: {
                        "from_year": year,
                        "introduction": introduction
                    }
                })
                return req;
            };

            const response = _eReportGuid ? await client.apis["energy_reports"].energy_reports_partial_update({ org_uuid: _orgGuid, energy_report_uuid: _eReportGuid }) : await client.apis["energy_reports"].energy_reports_create({ org_uuid: _orgGuid });

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("reportPostedSuccessfully") , "success")
                setDisabled(false)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setDisabled(false)
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _orgGuid, login.Authorization, year, introduction, _eReportGuid])

    const onCloseEnergyResource = () => {
        setResource(undefined)
        setShow(false)
        getEnergyResources()
    }

    const handleEditorChange = useCallback((event, editor) => {
        const data = editor.getData();
        setIntroduction(data);
    }, [introduction]);

    useEffect(() => {
        getResourceTypes();
    }, [])

    useEffect(() => {
        loadbuildings();
    }, [currentBuildingPage])

    useEffect(() => {
        if (_eReportGuid) getReport();
    }, [_eReportGuid])

    return (
        <>
            <MainLayout {...{ background: Background }}>
                <div className="sidebar__padding">
                    <Card {...{ heading: t('energyReport'), info: t('energyResourceInfo') }}>

                        <SelectYear {...{ onYearChange: setYear, selectedYear: year, className: "mb-3", disabled: _eReportGuid !== undefined }} />

                        <Card {...{ heading: t('energyResources'), ...(allowEdit && { mainFunctions }), className: "mb-3" }}>
                            {(Boolean(energyResources === undefined)) ? (
                                <TablePlaceholder {...{ structure: tableStructure }} />
                            ) : (Boolean(energyResources?.length)) ? (
                                <>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                {tableStructure.map(ts => <th key={`tableCol-${Math.random()}`}>{ts.col}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {energyResources.map(res => <EnergyResourcesTableRow key={`resource-key-${res.id}`} {...{ resource: res, setResource, types, setShow }} />)}
                                        </tbody>
                                    </Table>
                                </>
                            ) : (
                                <p className='m-0'>{t('noEnergyReports')}</p>
                            )}

                            <ContentModal {...{ show: show === 'addEnergyResource' || show === 'editResource', onHide: () => {setShow(false); setResource(undefined)}, title: t(resource ? 'editResource' : 'addResource'), content: <EnergyResourcesForm {...{ resource, types, onClose: () => onCloseEnergyResource() }} /> }} />
                            <SecurityActionModal {...{ show: show === 'deleteResource', onHide: () => {setShow(false); setResource(undefined)}, title: t("energyResourceDelete"), question: t("energyResourceDeleteQuestion"), action: () => deleteResource(resource) }} />
                        </Card>

                        <Card {...{ heading: t('buildings'), className: "mb-3" }}>
                            {(Boolean(buildings === undefined)) ? (
                                <TablePlaceholder {...{ structure: buildingTableStructure }} />
                            ) : (Boolean(buildings?.results?.length)) ? (
                                <>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className='d-flex' style={{width: '16px', height: '16px'}}>
                                                        <FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' />
                                                    </div>
                                                </th>
                                                <th>
                                                    <span>{t('name')}</span>
                                                </th>
                                                <th>
                                                    <span>{t('address')}</span>
                                                </th>
                                                <th>
                                                    <span>{t('heatingLossSum')}</span>
                                                </th>
                                                <th>
                                                    <span>{t('thermalPower')}</span>
                                                </th>
                                                <th>
                                                    <span>{t('heatedSurface')}</span>
                                                </th>
                                                <th>
                                                    <span>{t('actions')}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {buildings.results.map((building) => <BuildingRow key={building.id} {...{ building, types, availableTypes, energyResources }} />)}
                                        </tbody>
                                    </Table>
                                    <DjangoPagination {...{ total_pages: buildings?.total_pages, currentPage: currentBuildingPage, setCurrentPage: setCurrentBuildingPage }} />
                                </>
                            ) : (
                                <p className='m-0'>{t('noBuildings')}</p>
                            )}
                        </Card>
                        
                        <Card {...{ heading: "Submit" }}>
                            <Form>
                                <Form.Group className="mb-3" controlId="introduction">
                                    <Form.Label>{t("introduction")}</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={introduction}
                                        onChange={handleEditorChange}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'undo', 'redo',
                                                    '|',
                                                    'heading',
                                                    '|',
                                                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                    '|',
                                                    'bold', 'italic', 'strikethrough', 'subscript', 'superscript',
                                                    '|',
                                                    'link', 'blockQuote', 'insertTable',
                                                    '|',
                                                    'alignment',
                                                    '|',
                                                    'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                                ],
                                                shouldNotGroupWhenFull: true
                                            }
                                        }}
                                    />
                                </Form.Group>


                                <div className='d-flex justify-content-end'>
                                    <Button variant='outline-primary' onClick={() => onSubmit()} disabled={!allowEdit || disabled}>{t('submit')}</Button>
                                </div>
                            </Form>
                        </Card>
                    </Card>
                </div>
            </MainLayout>
        </>
    )
}

export default EnergyReports;