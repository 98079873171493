import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";
import { getChangedFields, isEmpty } from "../util/helpers";
import { useBuilding } from "../context/BuildingContext";

export const AddEditRoom = ({ floor, unit, onClose, ...props }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();

    let room_types = [
        [t('corridor'), 18],
        [t('livingRoom'), 20],
        [t('kitchen'), 20],
        [t('bath'), 24],
        [t('bedroom'), 20]
    ]

    let initialRoom = props.room ? { floor: props.room.floor?.id || props.room.floor, utilization_unit: props.room.utilization_unit?.id || props.room.utilization_unit, room_temperature: props.room.room_temperature, area: props.room.area, name: props.room.name, air_exchange_rate: props.room.air_exchange_rate, height: props.room.height } : {}
    let initialData = props.room || { floor: floor?.id, utilization_unit: unit?.id, room_temperature: 20, area: 5, name: room_types[0][0], air_exchange_rate: 0.5, height: floor?.height || unit?.entrance_level?.height || 2.7 }

    const [room, setRoom] = useState(initialData);
    const [floors, setFloors] = useState(undefined);
    const [units, setUnits] = useState(undefined);
    const [sortedUnits, setSortedUnits] = useState(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const client = useSwagger();
    const { currentVariantNode } = useBuilding();

    const sortUnitsByFloor = useCallback(() => {
        if (!units) return
        let sorted = units.sort((a, b) => {
            const floorA = a.entrance_level ? a.entrance_level.floor : Infinity;
            const floorB = b.entrance_level ? b.entrance_level.floor : Infinity;

            return floorA - floorB;
        });

        return setSortedUnits(sorted);
    });

    const loadFloors = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getFloors({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id });

            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return
                let a = response.obj.sort((a, b) => {
                    return a.level < b.level ? -1 : 1
                });

                if (room.id === undefined ) {
                    setRoom((prevRoom) => ({
                        ...prevRoom,
                        floor: unit ? unit?.entrance_level?.id : a[0].id,
                    }));
                }
                setFloors(a);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, t, currentVariantNode])

    const loadUnits = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getUnit({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id });

            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return

                let a = response.obj.sort((a, b) => {
                    return a.floor < b.floor ? -1 : 1
                });

                setRoom((prevRoom) => ({
                    ...prevRoom,
                    unit: a[0]?.id,
                }));

                setUnits(a);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, _buildingGuid, t])

    const onSubmit = useCallback(async (e) => {

        e.preventDefault();
        let newRoom = {
            utilization_unit: (typeof room.utilization_unit === 'object' && room.utilization_unit !== null) ? room.utilization_unit?.id : room.utilization_unit,
            floor: typeof room.floor === 'object' ? room.floor.id : room.floor,
            name: room.name,
            area: room.area,
            room_temperature: room.room_temperature,
            air_exchange_rate: room.air_exchange_rate,
            height: room.height
        }

        if (room.utilization_unit === null) {
            delete newRoom.utilization_unit
        }

        if (room?.id)
            newRoom['id'] = room.id

        // const changedFields = getChangedFields(newRoom, initialData);
        const changedFields = getChangedFields(newRoom, initialRoom);
        if (props.room !== undefined && isEmpty(changedFields)) onClose()

        if (!client || isSubmitting || (props.room !== undefined && isEmpty(changedFields))) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(props.room ? changedFields : newRoom);
                return req;
            };

            const response = newRoom.id === undefined ? (floor ? await client.apis["building"].building_variant_floor_room_create({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, floor_uuid: floor.id }) : await client.apis["building"].building_variant_unit_room_create({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, unit_uuid: unit?.id })) : await client.apis["building"].building_variant_room_partial_update({ building_uuid: _buildingGuid, variant_uuid: currentVariantNode?.id, room_uuid: room?.id })

            if (response.status >= 200 && response.status < 300) {
                addToast(t('room'), newRoom.id ? t('roomPatch') : t('roomPost'), "success");
                unit ? onClose() : onClose(true);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 423 && newRoom.id === undefined ) {
                addToast(t("error"), t("lockedErrorPost"), "error");
            } else if (statusCode === 423 && newRoom.id !== undefined) {
                addToast(t("error"), t("lockedErrorEditCopy"), "error");
            } else {
                addToast(t("error"), t(statusCode === 400 ? 'errorRoomName' : 'responseError'), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [client, login.Authorization, room, unit?.id, _buildingGuid, addToast, onClose, floor, t, currentVariantNode])

    const editHandler = useCallback((e) => {
        const { id, value } = e.target;
        setRoom((prevRoom) => ({
            ...prevRoom,
            [id]: value,
        }));
    }, []);

    useEffect(() => {
        if (floor === undefined || room.id) loadFloors();
    }, [loadFloors])

    useEffect(() => {
        if (unit === undefined || room.id) loadUnits();
    }, [loadUnits])

    useEffect(() => {
        if (floor) sortUnitsByFloor()
    }, [floor, sortUnitsByFloor, units]);

    let get_room_height = (room, floor, unit) => {
        if (room.height !== undefined && room.height !== null) {
            return Number(room['height'])
        }

        if (floor == undefined && unit != undefined) {
            return Number(unit.entrance_level.height)
        }

        if (floor != undefined) {
            return Number(floor.height)
        }

        return 2.7

    }

    let height = get_room_height(room, floor, unit);

    return <Form onSubmit={(e) => onSubmit(e)}>
        {(floor || room.id) && <Form.Group className="mb-3">
            <Form.Label>{t("utilizationKey")}</Form.Label>
            <Form.Select id="utilization_unit" className="w-100" aria-label="" value={typeof room.utilization_unit === 'string' ? room.utilization_unit : room.utilization_unit?.id} onChange={(e) => editHandler(e)}>
                {!room?.utilization_unit && <option key={`unit-key-undefined`} value={undefined}>{t('belongsToNoAppartment')}</option>}
                {sortedUnits?.map(u => <option key={`unit-key-${u.id}`} value={u.id}>{u.name} ({t('entranceLevel')}: {u.entrance_level?.floor} - {u.entrance_level?.name})</option>)}
            </Form.Select>
        </Form.Group>}

        {(unit || room.id) && <Form.Group className="mb-3">
            <Form.Label>{t("level")}</Form.Label>
            <Form.Select className="w-100" aria-label="" id="floor" value={typeof room.floor === 'string' ? room.floor : room.floor?.id} onChange={(e) => editHandler(e)}>
                {floors?.map(f => <option key={`floor-key-${f.id}`} value={f.id}>{f.level} - {f.name}</option>)}
            </Form.Select>
        </Form.Group>}

        {('id' in room) === false && <Form.Group className="mb-3">
            <Form.Label>{t("roomTypePreselection")}</Form.Label>
            <Form.Select className="w-100" aria-label="" value={room.level} onChange={(e) => { let a = { ...room }; a["room_temperature"] = room_types[e.target.value][1]; a["name"] = room_types[e.target.value][0]; setRoom({ ...a }); }}>
                {room_types?.map((t, index) => <option key={`room-key-${index}`} value={index}>{t[0]}</option>)}
            </Form.Select>
        </Form.Group>}

        <Form.Group className="mb-3">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control id="name" required type="text" value={room['name'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("area") + " (m²)"}</Form.Label>
            <Form.Control id="area" required type="number" value={room['area'] || ''} step={0.01} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("roomTemperature")}</Form.Label>
            <Form.Control id="room_temperature" required type="number" value={room['room_temperature'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("airExchangeRate")+" (1/h)"}</Form.Label>
            <Form.Control id="air_exchange_rate" required type="number" value={room['air_exchange_rate'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("height")} (m)</Form.Label>
            <Form.Control id="height" step={0.01} required type="number" value={height} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Button variant="outline-primary" disabled={isSubmitting} className="w-100" type="submit">{(room.id === undefined) ? t("add") : t('edit')}</Button>
    </Form>
}

export default AddEditRoom;