import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../context/BuildingContext";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { faAdd, faCalculator, faCopy, faEdit, faInfo, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../components/Card";
import { Button, OverlayTrigger, Placeholder, Table, Tooltip } from "react-bootstrap";
import { usePermissions } from "../context/PermissionsContext";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import BuildingPiechart from "./BuildingPiechart";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import AddEditLevel from "./AddEditLevel";
import { Calculate } from "./CalculateDIN12831";
import InfoLevel from "./InfoLevel";
import AddEditRoom from "./AddEditRoom";
import { RoomTable } from "./BuildingRoom";
import Collapse from "../components/Collapse";
import { disableBtnIfChangelogType2, getChildrenColor } from "../util/helpers";
import DjangoPagination from "../components/DjangoPagination";

export const BuildingLevel = () => {
    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <FloorTable />
        </div>
    </MainLayout>
}

const FloorTable = () => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const client = useSwagger();
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();
    const { currentBuilding, currentVariantNode } = useBuilding();
    
    const [floors, setFloors] = useState(undefined);
    const [floor, setFloor] = useState(undefined);
    const [types, setTypes] = useState(undefined);
    const [updateRoom, setUpdateRoom] = useState(undefined);
    const [show, setShow] = useState(false);
    const [isStable, setIsStable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const mainFunctions = [
        {
            label: t("addLevel"),
            onClick: () => setShow("AddLevel"),
            key: "add_level",
            icon: faAdd,
        },
        {
            label: t("calculate"),
            onClick: () => setShow("Calculate"),
            key: "calculate",
            icon: faCalculator,
        }
    ];

    const tableStructure = [
        {
            col: <div className="d-flex" style={{ width: "16px", height: "16px" }}><FontAwesomeIcon icon={faToggleOn} size="sm" className="flex-shrink-0" /></div>,
            type: "icon",
        }, {
            col: t("name"),
            type: "label",
        }, {
            col: t("floorNumber"),
            type: "label",
        }, {
            col: t("heatingLossSum"),
            type: "label",
        }, {
            col: t("thermalPower"),
            type: "label",
        }, {
            col: t("heatedArea"),
            type: "label",
        }, {
            col: t("actions"),
            type: "buttons",
        }
    ];

    const loadFloors = useCallback(async() => {
        if (!client || !currentBuilding?.id || !currentVariantNode?.id) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].getFloors({building_uuid: currentBuilding?.id, variant_uuid: currentVariantNode?.id, page: currentPage});
        
            if (response.status >= 200 && response.status < 300) {
                let a = response.obj?.results?.sort((a, b) => {
                    return a.level < b.level ? -1 : 1;
                });
                // setFloors(a || []);
                setFloors({...response.obj, results: a || []});
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, currentVariantNode, t, currentPage]);

    const getTypes = useCallback(async () => {
        if (!client || !currentBuilding?.id || !currentVariantNode?.id) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["buildingparttypes"].buildingparttypes_list();
    
            if (response.status >= 200 && response.status < 300) {
                let responseList = response.status === 204 ? [] : response.obj;
    
                setTypes(responseList.map(item => ({
                    ...item,
                    label: item.label.toLowerCase().replace(/\s+/g, '')
                })));
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const copyLevel = useCallback(async(floor) => {
        if (!client || !currentBuilding?.id || !currentVariantNode?.id) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["building"].building_variant_floor_copy_create({ building_uuid: currentBuilding.id, floor_uuid: floor?.id, variant_uuid: currentVariantNode.id });
    
            if (response.status >= 200 && response.status < 300) {
                loadFloors();
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            if (error.status === 423) {
                addToast(t("error"), t("lockedErrorEditCopy"), "error");
            } else {
                addToast(t("error"), t("networkError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, currentBuilding, currentVariantNode]);
    
    const deleteLevel = useCallback(async() => {  
        if (!client || !currentBuilding?.id || !currentVariantNode?.id || !floor) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_variant_floor_delete_all_destroy({ building_uuid: currentBuilding.id, variant_uuid: currentVariantNode.id, floor_uuid: floor?.id });
        
            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("floorDeleted"), "success");
                if (floors.results?.length === 1 && currentPage !== 1) {
                    setCurrentPage(currentPage - 1)
                } else {
                    loadFloors();
                }
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentBuilding, currentVariantNode, floor, loadFloors, addToast, t, floors, currentPage])

    const onClose = (refresh = false) => {
        setShow(false);
        setFloor(undefined);
        if (refresh) loadFloors();
    };

    useEffect(() => {
        if (!currentBuilding || !currentVariantNode) return
        setIsStable(true)
    }, [currentBuilding, currentVariantNode]);

    useEffect(() => {
        if (!isStable || !currentVariantNode) return
        loadFloors()
    }, [isStable, currentVariantNode, currentPage])
    
    useEffect(() => {
        if (!floors || !Boolean(floors?.results?.length)) return
        getTypes();
    }, [floors])

    return (
        <>
            <Card {...{ heading: `${t('buildingParts')} ${t('inThe')} ${t('building')}: ${isStable ? currentBuilding?.name : t('loading')}`, ...(allowEdit && { mainFunctions }) }}>
                {(floors === undefined) ? (
                    <>
                        <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                            <div style={{ width: "100%", height: "500px" }}>
                                <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                                    <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                                </Placeholder>
                            </div>
                        </Card>
                        <TablePlaceholder {...{ structure: tableStructure }} />
                    </>
                ) : (floors !== undefined && !Boolean(floors?.results?.length)) ? (
                    <>
                        <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                            <div style={{ width: "100%", height: "500px" }}>
                                <p>{t('noDataAvailable')}</p>
                            </div>
                        </Card>
                        <p className="m-0">{t("noBuildingLevel")}</p>
                    </>
                ) : (
                    <>
                        <BuildingPiechart {...{ types }} />
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{(ts.type == "label") ? t(ts.col) : ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {floors?.results?.map((floor) => <FloorRow key={`floor-${floor.id}`} {...{ floor, setFloor, setShow, updateRoom, copyLevel, deleteLevel }} />)}
                            </tbody>
                        </Table>
                        <DjangoPagination {...{ total_pages: floors?.total_pages, currentPage, setCurrentPage }} />
                    </>
                )}
            </Card>

            <ContentModal {...{ show: show === "AddLevel", onHide: (boolean) => onClose(boolean), title: t("addLevel"), content: <AddEditLevel {...{ onClose: (boolean) => onClose(boolean) }} /> }} />
            <ContentModal {...{ show: show === "Calculate", onHide: (boolean) => onClose(boolean), title: t("calculateDIN12831"), content: <Calculate {...{ onClose: (boolean) => onClose(boolean) }} /> }} />
            <ContentModal {...{ show: show === "Room", onHide: (boolean) => onClose(boolean), title: t("calculate"), content: <Calculate {...{ onClose: (boolean) => onClose(boolean) }} /> }} />
            <ContentModal {...{ show: show === "Level", onHide: (boolean) => onClose(boolean), title: t("editLevel"), content: <AddEditLevel {...{ onClose: (boolean) => onClose(boolean), floor }} /> }} />
            <ContentModal {...{ show: show === "moreInfo", onHide: (boolean) => onClose(boolean), title: t("additionalInformations"), content: <InfoLevel {...{ onClose: (boolean) => onClose(boolean), floor }} /> }} />
            
            <ContentModal {...{ show: show === "AddRoom", onHide: (boolean) => onClose(boolean), title: t("addRoom"), content: <AddEditRoom {...{ onClose: () => { setShow(false); setUpdateRoom(new Date()); }, floor }} /> }} />
            <SecurityActionModal {...{ show: show === "delete_level", onHide: (boolean) => onClose(boolean), title: t("deleteLevel"), question: t("deleteLevelQuestion"), action: () => deleteLevel() }} />
        </>
    )
}

const FloorRow = ({ floor, setFloor, setShow, updateRoom, copyLevel }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    const { allowEdit } = usePermissions();

    const onClickHandler = (floor, show, type) => {
        type === 'floor' ? setFloor(floor) : copyLevel(floor)
        setShow(show)
    }

   let childrenBackgroundColor = getChildrenColor(floor)
   let changelogType2 = disableBtnIfChangelogType2(floor)

    return (
        <>
            <tr style={{ backgroundColor: childrenBackgroundColor }}>
                <td className="align-middle">
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td className="align-middle">
                    <span>{floor.name.toLocaleString(undefined, { maximumFractionDigits: 0})}</span>
                </td>
                <td className="align-middle">
                    <span>{floor.level.toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>
                </td>
                <td className="align-middle">
                    <span>{(floor.heating_load / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    <span>{(floor.thermal_power / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
                </td>
                <td className="align-middle">
                    <span>{floor.calculated_area.toLocaleString(undefined, { maximumFractionDigits: 2 })} m²</span>
                </td>
                <td className="align-middle">
                    <div className={`actions__buttonbar ${changelogType2 ? '' : 'grid__five'}`}>
                        {!changelogType2 ? (
                            <>
                                <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("addRoom")}</Tooltip>}>
                                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "AddRoom", "floor")}>
                                        <FontAwesomeIcon icon={faAdd} />
                                    </Button>
                                </OverlayTrigger>
                    
                                <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("edit")}</Tooltip>}>
                                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "Level", "floor")}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </OverlayTrigger>
                    
                                <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("copy")}</Tooltip>}>
                                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "copy_level", "copy")}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button>
                                </OverlayTrigger>
                    
                                <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("moreInfo")}</Tooltip>}>
                                    <Button variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "moreInfo", "floor")}>
                                        <FontAwesomeIcon icon={faInfo} />
                                    </Button>
                                </OverlayTrigger>
                    
                                <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("delete")}</Tooltip>}>
                                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "delete_level", "floor")}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        ) : (
                            <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("moreInfo")}</Tooltip>}>
                                <Button variant="outline-primary" className="p-10" onClick={() => onClickHandler(floor, "moreInfo", "floor")}>
                                    <FontAwesomeIcon icon={faInfo} />
                                </Button>
                            </OverlayTrigger>
                        )}
                    </div>
                </td>
            </tr>
            {display && (
                <tr>
                    <td colSpan="100%">
                        <Card {...{ heading: t('level') + ": " + floor.name, active: true, className: "height-fit" }}>
                            <RoomTable {...{ floor, updateRoom }} />
                        </Card>
                    </td>
                </tr>
            )}
        </>
    );
}

export default BuildingLevel;