import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useAuth } from "../../Context";
import { useToast } from "../../NotificationsContent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../../components/Card";
import { Form, Row } from "react-bootstrap";
import L, { Icon } from 'leaflet';
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import branchMarker from '../../assets/icons/marker-icon-2x-red.png'
import { useSwagger } from "../../context/SwaggerContext";
import DivPlaceholder from "../../components/Placeholders/DivPlaceholder";

const NOT_DRAGGABLE = 0;
const DRAGGABLE = 1;
const PATCH_AFTER_DRAG = 2;
const ABORT_DRAG = 3;

const myIcon = new Icon({
    iconUrl: branchMarker,
    iconSize: [35, 50],
    popupAnchor: [0, -25],
    iconAnchor: [15, 45],
})

const BuildingMarker = ({ draggable, setDraggable, building, coords, setCoords, updateCoords }) => {
    const [lat, setLat] = useState(Number(coords.lat));
    const [lng, setLng] = useState(Number(coords.lng));
    const markerRef = useRef(null);

    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;
            if (marker) {
                const newLatLng = marker.getLatLng();
                setLat(newLatLng.lat);
                setLng(newLatLng.lng);
                setCoords({
                    lat: newLatLng.lat,
                    lng: newLatLng.lng,
                });
            }
        }
    }), [setCoords]);

    useEffect(() => {
        if (draggable === PATCH_AFTER_DRAG) updateCoords();
        if (draggable === ABORT_DRAG) {
            setLat(building?.latitude);
            setLng(building?.longitude);
            setCoords({
                lat: building?.latitude,
                lng: building?.longitude
            })
            setDraggable(NOT_DRAGGABLE);
        }
    }, [draggable, building?.latitude, building?.longitude, setCoords, setDraggable, updateCoords])

    useEffect(() => {
        setLat(Number(coords.lat))
        setLng(Number(coords.lng))
    }, [coords])

    if (building?.latitude != null & building?.longitude != null) {
        return <Marker position={[lat, lng]} icon={myIcon}
            draggable={Boolean(draggable === DRAGGABLE)} eventHandlers={eventHandlers} ref={markerRef}
        />
    }
}

export const Map = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login, defaultTheme } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const [building, setBuilding] = useState(undefined);
    const mapRef = useRef(null);
    const { t } = useTranslation();
    const [coords, setCoords] = useState({
        lat: building?.latitude,
        lng: building?.longitude
    })

    const NOT_DRAGGABLE = 0;
    const DRAGGABLE = 1;
    const PATCH_AFTER_DRAG = 2;
    const GO_TO_GOOGLE_MAPS = 10;
    // eslint-disable-next-line no-unused-vars
    const ABORT_DRAG = 3;
    let offset;

    const [draggable, setDraggable] = useState(NOT_DRAGGABLE);

    const loadBuilding = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (response.status >= 200 && response.status <= 299) return response.json();
        //     if (response.status === 404) setBuilding({status: 404})
        //     if (response.status === 403) setBuilding({status: 403})
        //     return {};
        // })
        // .then((data) => {
        //     if (Object.keys(data).length === 0) return;
        //     setBuilding(data);
        // })
        // .catch(error => {
        //     addToast(t('error'), error, "error");
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                return req;
            };

            const response = await client.apis["building"].building_retrieve({
                building_uuid: _buildingGuid,
            });

            if (response.status >= 200 && response.status < 300) {
                if (Object.keys(response.obj).length === 0) return;
                setBuilding(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 404) {
                setBuilding({ status: 404 });
            } else if (statusCode === 403) {
                setBuilding({ status: 403 });
            } else {
                addToast(t("error"), t("responseError"), "error");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, login.Authorization, addToast, t, client]);

    let pos = [];
    [building]?.map(b => (b?.longitude != null && b?.latitude != null) ? pos.push([Number(b?.latitude), Number(b?.longitude)]) : false);
    if (pos.length > 0) {
        let min_max_pos = { minx: pos[0][0], maxx: pos[0][0], miny: pos[0][1], maxy: pos[0][1] };
        // eslint-disable-next-line array-callback-return
        pos.map(p => {
            if (p[0] < min_max_pos.minx) min_max_pos.minx = p[0];
            if (p[1] < min_max_pos.miny) min_max_pos.miny = p[1];
            if (p[0] > min_max_pos.maxx) min_max_pos.maxx = p[0];
            if (p[1] > min_max_pos.maxy) min_max_pos.maxy = p[1];
        });
        let off = offset || 0.0005;
        min_max_pos.minx -= off;
        min_max_pos.maxx += off;
        min_max_pos.miny -= off;
        min_max_pos.maxy += off;
        pos.push([min_max_pos.minx, min_max_pos.miny]);
        pos.push([min_max_pos.maxx, min_max_pos.maxy]);
    }

    const bounds = pos?.length > 0 ? L.latLngBounds(pos) : null;

    const changeCoords = useCallback((e, pos) => {
        let tmp = {...coords}
        tmp[pos] = Number(e.target.value);
        setCoords(tmp)
    }, [coords])

    const updateCoords = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${building.id}/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         'Content-Type': 'application/json'
        //     },
        //     method: 'PATCH',
        //     body: JSON.stringify({
        //         longitude: Number(coords.lng).toFixed(6),
        //         latitude: Number(coords.lat).toFixed(6)
        //     })
        // }).then(data => {
        //     if (data.status === 202) setDraggable(NOT_DRAGGABLE);
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                req.body = JSON.stringify({
                    longitude: Number(coords.lng).toFixed(6),
                    latitude: Number(coords.lat).toFixed(6),
                });
                return req;
            };

            const response = await client.apis["building"].building_partial_update({
                building_uuid: building.id,
            });

            if (response.status === 202) setDraggable(NOT_DRAGGABLE);

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [building?.id, login.Authorization, coords, client]);

    let mainFunctions = [];

    if (draggable === NOT_DRAGGABLE) {
        mainFunctions.push({
            key: DRAGGABLE,
            label: t('setMarker'),
            onClick: () => setDraggable(DRAGGABLE)
        })
    }

    if (draggable === DRAGGABLE) {
        mainFunctions.push({
            key: PATCH_AFTER_DRAG,
            label: t('unsetMarker'),
            onClick: () => setDraggable(PATCH_AFTER_DRAG)
        }, {
            key: NOT_DRAGGABLE,
            label: t('abortSetMarker'),
            onClick: () => setDraggable(NOT_DRAGGABLE)
        })
    }

    mainFunctions.push({
        key: GO_TO_GOOGLE_MAPS,
        label: t('goToGoogleMaps'),
        onClick: () => window.open(`https://www.google.com/maps/search/?api=1&query=${building?.latitude},${building?.longitude}`, "_blank")
    })

    useEffect(() => {
        setCoords({
            lat: building?.latitude,
            lng: building?.longitude
        })
    }, [building])

    useEffect(() => {
        loadBuilding();
    }, [loadBuilding])

    return <Card {...{ heading: t('map'), mainFunctions }}>
        <Row style={{height: "100%"}}>
            <div className="overflow-hidden">
                {(building !== undefined && coords.lat !== undefined && coords.lng !== undefined && bounds) ? (
                    <MapContainer ref={mapRef} zoom={1} scrollWheelZoom={true} className="map__component p-3" bounds={bounds}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url={defaultTheme  === 'light' ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" : "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}.png"}
                        />
                        <BuildingMarker {...{setDraggable, draggable, building, setBuilding, coords, setCoords, updateCoords}} />
                    </MapContainer>
                ) : (
                    <DivPlaceholder />
                )}
            </div> 
        </Row>
        {draggable === DRAGGABLE && <Row className="mt-3">
            <Form onSubmit={(e) => {e.preventDefault(); updateCoords()}}>
                <Form.Group className="mb-3">
                    <Form.Label className="text-white">{t('latitude')}</Form.Label>
                    <Form.Control type='input' value={coords?.lat} onChange={(e) => changeCoords(e, 'lat')} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="text-white">{t('longitude')}</Form.Label>
                    <Form.Control type='input' value={coords?.lng} onChange={(e) => changeCoords(e, 'lng')} />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-outline-primary" type='submit'>{t('unsetMarker')}</button>
                </div>
            </Form>
        </Row>}
    </Card>
}

export default Map;