import React from 'react'
import { Table, Placeholder } from 'react-bootstrap'

function TablePlaceholder({ structure }) {
    const rowsArray = Array.from({ length: 5 }, (_, index) => index);

    const TableRows = () => {
        return (
            <tr>
                {structure.map(td => {
                    return (
                        <td className='align-middle' key={`td-placeholder-${Math.random()}`}>
                            {(td.type === 'buttons') ? (
                                <Placeholder.Button xs={12} aria-hidden="true" style={{backgroundColor: '#fff0ce', borderColor: '#fff0ce'}} />
                            ) : (
                                <Placeholder as="p" animation="glow" className='m-0'>
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </td>
                    )
                })}
            </tr>
        )
    }

    return (
        <Table responsive>
            <thead>
                <tr>
                    {structure.map(th => {
                        return (
                            <th key={`th-placeholder-${Math.random()}`}>
                                {(th.type === 'icon') ? (
                                    <div className='d-flex' style={{width: '16px', height: '16px'}}>
                                        {th.col}
                                    </div>
                                ) : (
                                    th.col
                                )}
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {rowsArray.map(r => <TableRows key={`tr-placeholder-${Math.random()}`} />)}
            </tbody>
        </Table>
    )
}

export default TablePlaceholder