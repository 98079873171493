import { useCallback } from "react"
import "./style.css"
import { useTranslation } from "react-i18next";

export const Invoice = ({ className, plan, items, price }) => {
    const { t } = useTranslation()

    const totalAmount = useCallback(() => {
        let total = 0;
        for (let i = 0; i < items.length; i++) {
            total += items[i].appartments <= price?.tiers?.[0].up_to ? price.tiers?.[0].flat_amount : price?.price ? price?.price * items[i].appartments : price.tiers?.[1].unit_amount * items[i].appartments
        }
        return total
    }, [items, price])

    return <div className={`invoice__wrapper ${className}`}>
        <table>
            <thead>
                <tr>
                    <th>{t("building")}</th>
                    <th>ES {t("product")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("totalAmount")}</th>
                </tr>
            </thead>
            <tbody>
                {items.map(i => {
                    return <tr key={`building-key-${i.id}`}>
                        <td className="align-middle">
                            <div>
                                <span>{i.street} </span>
                                <span>{i.no}</span>
                            </div>
                            <div>
                                <span>{i.zip} </span>
                                <span>{i.city}</span>
                            </div>
                        </td>
                        <td className="align-middle">{plan}</td>
                        <td className="align-middle">{price?.tiers?.[0].flat_amount || price.price} €</td>
                        <td className="align-middle">{i.appartments}</td>
                        <td className="align-middle">{i.appartments <= price?.tiers?.[0].up_to ? price.tiers?.[0].flat_amount : price?.price ? price?.price * i.appartments : price.tiers?.[1].unit_amount * i.appartments} €</td>
                    </tr>
                })}
            </tbody>
        </table>
        <hr />
        <div className="invoice__total">
            <span>Total</span>
            <span>{totalAmount()} €</span>
        </div>
    </div>
}

export default Invoice