import { useState, useEffect, createContext, useContext, useMemo } from "react";
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Context";
import { useCallback } from "react";
import { useSwagger } from "./SwaggerContext";
import { useParams } from "react-router-dom";

const BuildingContext = createContext();

export const useBuilding = () => {
    return useContext(BuildingContext);
}

export const BuildingProvider = ({children}) => {
    const { login } = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const [currentBuilding, setCurrentBuilding] = useState(undefined);
    const [variants, setVariants] = useState(undefined);
    const [currentVariantNode, setCurrentVariantNode] = useState(undefined);
    const [variantHistoryStack, setVariantHistoryStack] = useState([]); // Stack for parent nodes
    const { addToast } = useToast();
    const { t } = useTranslation();

    const getCurrentBuilding = useCallback(async() => {
        if (!login || !client || login?.accepted === false) return; 

        if (!_buildingGuid) {
            setCurrentBuilding(undefined)
            return
        }

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login?.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_retrieve({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                if (!response.obj.hasOwnProperty('module_hydraulic_balance')) {
                    getCurrentBuilding()
                    return
                }
                setCurrentBuilding(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [login, client, _buildingGuid, addToast, t])

    const getVariants = useCallback(async() => {
        if (!login || !client || login?.accepted === false) return; 

        if (!_buildingGuid) {
            setCurrentVariantNode({})
            return
        }

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login?.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariants({building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                setVariants(response.obj || {})
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [login, client, addToast, t, _buildingGuid])

    const saveStateToLocalStorage = (node, stack) => {
        localStorage.setItem('currentNode', JSON.stringify(node));
        localStorage.setItem('historyStack', JSON.stringify(stack));
    };

    const findNodeById = (tree, id) => {
        if (tree.id === id) return tree;
        if (tree.children) {
            for (let child of tree.children) {
                const result = findNodeById(child, id);
                if (result) return result;
            }
        }
        return null;
    };

    const goToParent = () => {
        if (variantHistoryStack.length === 0) return
        const previousParent = variantHistoryStack[variantHistoryStack.length - 1];
        const updatedStack = variantHistoryStack.slice(0, -1);
        setVariantHistoryStack(updatedStack);
    
        // If moving to the root node, clear history
        if (updatedStack.length === 0) {
            saveStateToLocalStorage(previousParent, []); // Clear local storage for stack
        } else {
            saveStateToLocalStorage(previousParent, updatedStack);
        }
    
        setCurrentVariantNode(previousParent);
      };

    const openChild = (child) => {
        const updatedStack = [...variantHistoryStack, currentVariantNode];
        setVariantHistoryStack(updatedStack); // Push current node onto stack
        setCurrentVariantNode(child); // Set the clicked child as the new current node
        saveStateToLocalStorage(child, updatedStack);
    };

    useEffect(() => {
        if (!login) return 
        getVariants();
        getCurrentBuilding();
    }, [getCurrentBuilding, getVariants, login, _buildingGuid]);

    useEffect(() => {
        if (!variants) return
        const savedCurrentNode = localStorage.getItem('currentNode');
        const savedHistoryStack = localStorage.getItem('historyStack');
    
        let validCurrentNode = variants;
        let validHistoryStack = [];

        if (savedCurrentNode) {
            const parsedCurrentNode = JSON.parse(savedCurrentNode);
            const parsedHistoryStack = JSON.parse(savedHistoryStack);
            const matchedNode = findNodeById(variants, parsedCurrentNode.id);
            if (matchedNode) validCurrentNode = matchedNode;

            if (matchedNode) {
                validCurrentNode = matchedNode; // Use the matched node if it exists
            } else if (parsedHistoryStack) {
                // If the currentNode is invalid, fallback to the last parent in the history stack
                const lastParent = parsedHistoryStack[parsedHistoryStack.length - 1];
                if (lastParent) {
                    const updatedParent = findNodeById(variants, lastParent.id);
                    if (updatedParent) validCurrentNode = updatedParent; // Use the updated parent node from the tree
                }
            }
        }
      
        if (savedHistoryStack) {
            const parsedHistoryStack = JSON.parse(savedHistoryStack);
            validHistoryStack = parsedHistoryStack
                .map((node) => findNodeById(variants, node.id)) // Find valid nodes in the updated tree
                .filter((node) => node.id !== validCurrentNode.id); // Remove duplicates and invalid nodes
        }

        if (!findNodeById(variants, validCurrentNode.id)) {
            validCurrentNode = variants;
            validHistoryStack = [];
        }
      
        setCurrentVariantNode({...validCurrentNode});
        setVariantHistoryStack([...validHistoryStack]);
        saveStateToLocalStorage(validCurrentNode, validHistoryStack);
    }, [variants]);

    useEffect(() => {
        if (_buildingGuid) return
        localStorage.removeItem('currentNode');
        localStorage.removeItem('historyStack');
    }, [_buildingGuid])

    const contextValue = useMemo(() => ({
        currentBuilding,
        getVariants,
        variants,
        currentVariantNode,
        variantHistoryStack,
        goToParent,
        openChild
    }), [currentBuilding, variants, currentVariantNode, variantHistoryStack]);

    return (
        <BuildingContext.Provider value={contextValue}>
            {children}
        </BuildingContext.Provider>
    );
};