import i18next from "i18next"
import { initReactI18next } from "react-i18next"

i18next.use(initReactI18next).init({
    resources: {
        de: {
            translation: {
                author: "Verfasser",
                login: "Einloggen",
                emailAddress: "E-Mail-Adresse",
                password: "Passwort",
                stayLoggedIn: "Eingeloggt bleiben",
                forgotPassword: "Passwort vergessen?",
                loginAlerts: {
                    validEmailAddress: "Bitte geben Sie Ihre gültige E-Mail-Adresse ein, um sich anzumelden.",
                    validPassword: "Um sich anzumelden, geben Sie bitte Ihr gültiges Passwort ein.",
                    validCredentials: "Der Email oder das Passwort, das Sie angegeben haben, ist falsch. Bitte überprüfen Sie Ihre Eingabe.",
                },
                resetPassword: "Zurücksetzen Ihres Kennworts",
                resetEmail: "Wir senden Ihnen per E-Mail Anweisungen zum Zurücksetzen Ihres Passworts.",
                reset: "Zurücksetzen",
                resetAlerts: {
                    associatedEmail: "Um Ihr Passwort zurückzusetzen, geben Sie bitte eine gültige E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist.",
                    validEmail: "Die eingegebene E-Mail-Adresse ist ungültig. Wenn dies nicht der Fall ist, kontaktieren Sie uns bitte.",
                    instructionsEmail: "Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passworts.",
                    tokenExpired: "Ihr Token ist abgelaufen. Bitte besorgen Sie sich einen neuen.",
                    tokenValidation: "Der von Ihnen verwendete Token ist ungültig. Bitte verwenden Sie einen gültigen Token.",
                    passwordSecurity: "Für ein sicheres Passwort stellen Sie sicher, dass es Kleinbuchstaben, Großbuchstaben, Zahlen und Sonderzeichen enthält.",
                },
                factor: "Faktor",
                temperatureCorrection: "Temperatur-Korrekturfaktor (f1)",
                setNewPassword: "Neues Passwort festlegen",
                resetInstructions: "Um das Passwort zu ändern, müssen Sie das neue Passwort unten zweimal eingeben. Nach dem Ändern des Passwortes werden Sie ausgeloggt und müssen sich mit dem neuen Passwort erneut anmelden.",
                newPassword: "Neues Passwort",
                newPasswordAgain: "Neues Passwort wiederholen",
                confirm: "Bestätigen",
                resetSuccessfully: "Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit dem neuen Passwort anmelden.",
                privacy: "Datenschutz",
                imprint: "Impressum",
                selectOrganisation: "Wählen Sie die Organisation aus, auf die Sie zugreifen möchten",
                customerName: "Kundenname", 
                name: "Name",
                prename: "Vorname",
                lastName: "Nachname",
                street: "Straße",
                housenumber: "Hausnummer",
                zip: "Postleitzahl",
                city: "Stadt",
                previous: "Zurück",
                back: "Zurück",
                next: "Nächste",
                expertMode: "Experten-Modus",
                downloadTemplateAs: "Die Vorlage herunterladen, als:",
                addColumn: "Spalte hinzufügen",
                addRow: "Zeile hinzufügen",
                pdfExport: "Exportieren als PDF",
                selectFormExcelFile: "Wähle eine Spalte aus dem hochgeladenen Excel Datei aus:",
                addNewColumn: "Neue Spalte hinzufügen",
                columnName: "Spaltenname",
                add: "Hinzufügen",
                select: "Auswählen",
                selectAll: "Alle auswählen",
                noDaughterOrganisations: "Diese Organisation hat keine Tochterorganisationen.",
                organisations: "Organisationen",
                changeOrganisation: "Organisation wechseln",
                leaveOrganisation: "Organisation verlassen",
                deleteOrg: "Organisation löschen", 
                organisationProfile: "Organisationsprofil",
                onboarding: "Onboarding-Prozess",
                changeLanguage: "Sprache ändern",
                changeTheme: "Thema ändern",
                light: "Hell",
                dark: "Dunkel",
                german: "Deutsch",
                english: "Englisch",
                settings: "Einstellungen",
                logout: "Ausloggen",
                homepage: "Startseite",
                buildings: "Gebäude",
                overview: "Übersicht",
                devices: "Geräte",
                noEnergyReports: "Es sind keine Energieberichte vorhanden.",
                energyReports: "Energieberichte",
                totheEnergyReport: "zum Energiebericht",
                buildingLogBook: "Gebäude-Logbuch",
                usageUnits: "Nutzungseinheiten",
                floors: "Etagen",
                unitPost: "Die Nutzungseinheit wurde gespeichert.",
                unitPostError: "Es gab ein Problem beim Erstellen der Nutzungseinheit! Bitte stellen Sie sicher, dass der Name der Nutzungseinheit nicht doppelt vergeben wird.",
                unitPatch: "Herzlichen Glückwunsch, die Nutzungseinheit wurde bearbeitet.",
                unitMessage: "Zuerst eine Etage anlegen!",
                roomPost: "Der Raum wurde gespeichert.",
                roomPatch: "Der Raum wurde erfolgreich bearbeitet.",
                rooms: "Räume",
                plants: "Anlagen",
                contactPerson: "Ansprechpartner",
                editContactPerson: "Ansprechpartner wurde erfolgreich bearbeitet!",
                deleteContactPerson: "Ansprechpartner löschen",
                deleteContactPersonQuestion: "Sind Sie sicher, dass Sie diesen Ansprechpartner löschen möchten?",
                deleteContactPersonSuccess: "Ansprechpartner wurde erfolgreich gelöscht.",
                images: "Bilder",
                gallery: "Bildergalerie",
                building_lifecycle_chat:"Gebäude-Lifecycle-Chat",
                release: "Freigabe",
                buyProducts: "Produkte kaufen",
                bills: "Rechnungen",
                buildingMap: "Gebäudekarte",
                singleBuilding: "Einzelgebäude",
                buildinglist: "Gebäudeliste",
                buildinglistComplex: "Komplexe Gebäudeliste",
                markersOnly: "Nur Marker",
                consumptionMap: "Verbrauchskarte",
                heatingloadMap: "Heizlastverteilung",
                alarmMap: "Alarmkarte",
                availableSoon: "Demnächst verfügbar",
                totheOrganization: "Zur Organisation",
                totheBuilding: "Zum Gebäude",
                noBuildings: "Es gibt keine Gebäude, die aufgelistet werden müssen.",
                buildingsOverview: "Gebäudeübersicht",
                building: "Gebäude",
                inThe: "im",
                property: "Liegenschaft",
                properties: "Liegenschaften",
                buildingsWithoutProperty: "Gebäude ohne Liegenschaft",
                addProperty: "Liegenschaft hinzufügen",
                editProperty: "Liegenschaft bearbeiten",
                addBuildingWithProperty: "Gebäude zur Liegenschaft hinzufügen",
                addBuildingWithoutProperty: "Gebäude ohne Liegenschaft hinzufügen",
                addBuilding: "Gebäude hinzufügen",
                editBuilding: "Gebäude bearbeiten",
                startDesignFor: "Starten Sie Design für",
                delete: "Löschen",
                deleteProperty: "Liegenschaft löschen",
                deleteBuilding: "Gebäude löschen",
                deleteBuildingQuestion: "Sind Sie sicher, dass Sie dieses Gebäude löschen möchten?",
                enableDnD: "Aktivieren Sie Drag & Drop",
                deletePrompt: "Sind Sie sicher, dass Sie diese {{type}} löschen möchten?",
                heatingLossSum: "Gesamte Heizlast",
                thermalPower: "Verfügbare Anlagenleistung",
                possiblePower: "mögliche Leistung",
                heatedSurface: "beheizte Fläche",
                actions: "Aktionen",
                address: "Adresse",
                search: "Suchen",
                edit: "Bearbeiten",
                noEdit: "Dieses Element kann nicht bearbeitet werden.",
                propertyAlerts: {
                    add: "Die Liegenschaft wurde erfolgreich erstellt.",
                    edit: "Die Liegenschaft wurde erfolgreich bearbeitet."
                },
                responseError: "Bei der Verarbeitung Ihrer Anfrage ist ein Problem aufgetreten. Bitte versuche es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an die Webadministratoren.",
                constructionYear: "Baujahr",
                yearOfModernization: "Jahr der Modernisierung",
                close: "Schließen",
                modTypes: {
                    roof: "Dach",
                    window: "Fenster",
                    wall: "Außenwand",
                    doors: "Außentüren",
                    groundEarth: "Gegen Erdreich",
                    groundUnheated: "Gegen Unbeheizt (intern)",
                    pipe: "Rohrdämmung Heizung",
                    plant: "Austausch Heizanlage",
                },
                energyTypes: {
                    dh: "Fernwärme",
                    gas: "Erdgas",
                    gas_01: "Gas",
                    whp: "Flächenkollektor",
                    gwhp: "Geothermie",
                    awhp: "Luft/Wasser",
                    ahp: "Luft/Luft",
                    oil: "Öl",
                    other: "Andere",
                },
                controltypes: {
                    noControl: "kein Regelorgan",
                    threeValveFlow: "3-Wege-Ventil im Vorlauf",
                    threeValveReturn: "3-Wege-Ventil im Rücklauf",
                    twoValvePrimReturn: "2-Wege-Ventil im primären RL mit Bypass",
                    fourValve: "4-Wege-Ventil"
                },
                area: "Fläche",
                areas: "Flächen",
                subtractFromSurface: "Von der Oberfläche abziehen?",
                heatedArea: "beheizte Fläche (m²)",
                unheatedArea: "unbeheizte Fläche (m²)",
                avg: "Mittelwert",
                avgTempHeated: "Temperatur beheizte Fläche (°C)",
                avgTempUnheated: "Temperatur unbeheizte Fläche (°C)",
                levelCountWithoutCellar: "Etagen (ohne Keller-Etagen)",
                cellarLevels: "Keller-Etagen",
                heatedCellarLevels: "davon beheizte Keller-Etagen",
                appartmentCount: "Anzahl Wohneinheiten (WE)",
                noAssignment: "Keine Zuordnung",
                belongsToNoAppartment: "Keine Zuordnung",
                belongsToNoRoom: "Keine Zuordnung",
                avgRoomCount: "Durchschnittliche Anzahl Räume pro WE",
                roomHeight: "Raumhöhe (netto in m)",
                roomHeightWithoutUnit: "Raumhöhe",
                windowSharesHeated: "Fensteranteil beheizter Bereich (in %)",
                windowSharesUnheated: "Fensteranteil unbeheizter Bereich (in %)",
                volume: "Volumen (m³)",
                additionalVolume: "Zusätzlicher Volumen (in m³)",
                avgAreaAppartment: "Durschnittliche Fläche pro WE",
                avgAreaLevel: "Durchschnittliche Fläche pro Etage",
                buildingWidth: "Gebäudebreite (in m)",
                buildingDepth: "Gebäudetiefe (in m)",
                buildingHeight: "Gebäudehöhe (in m)",
                lastModernizations: "Letzte Modernisierungen",
                year: "Jahr",
                modernizationType: "Bauteil",
                uValue: "U-Wert",
                newUValue: "Neuer U-Wert (W pro m² und Kelvin)",
                UValueAccordingTo: "U-Wert nach",
                heatingSystem: "Heizsystem",
                statisticallyRequiredHeatingPower: "Statistisch benötigte Heizleistung in Kalenderwochen",
                statisticalConsumption: "Statistischer Verbrauch im Durchschnittsjahr",
                heatingConsumption: "Verbrauch für Wärmeerzeugung (kWh)",
                heatingPlants: "Wärmeerzeuger",
                manufacturer: "Hersteller",
                manufacturerType: "Herstellertyp",
                energyType: "Energieträger",
                energySavingPotential: "Energiesparpotenzial",

                power: "Leistung (kW)",
                heatingCircuits: "Heizkreise",
                controlType: "Regelungsart",
                countHeatingStrings: "Anzahl Stränge",
                strands:"Stränge",
                pumpManufacturer: "Pumpenhersteller",
                countRadiators: "Anzahl der Heizkörper",
                adjustableValves: "Einstellbare Heizkörperventile",
                heatPuffer: "Heizspeicher",
                volumeHeatPuffer: "Volumen Heizspeicher",
                centralHotWaterSystem: "Zentrale Warmwasserbereitung",
                volumeHotWater: "Liter Warmwasserspeicher",
                centralFreshWaterStation: "seperate Trinkwasser- / Frischwasserregelung",
                countWarmWaterStrings: "Anzahl Warmwasserstränge",
                countHotWaterTapsPerAppartment: "Anzahl Zapfstellen pro WE",
                countHotWaterTaps: "Anzahl Warmwasser-Zapfstellen",
                send: "Senden",
                energyClass: "EnergieKlasse",
                eeClasses: {
                    classAPlus: "Passivhaus",
                    classA: "3-Liter-Haus",
                    classB: "Niedrigenergiehaus",
                    classC: "GEG-Standard Neubau",
                    classD: "Stand ca. 1990 (tbd)",
                    classE: "2. WSV Stand 1982",
                    classF: "höchstens 2. WSV Stand 1982",
                    classG: "1. WSV Stand 1977",
                    classH: "Denkmalschutz oder schlecht Gedämmt"
                },
                noValuesAvailable: "Keine Werte vorhanden.",
                noValues: "keine Werte",
                toTheDetails: "Zu den Details",
                deleteImage: "Bild löschen",
                profileImage: "Profilbild",
                error: "Fehler",
                lockedErrorEditCopy: "Sie dürfen diesen Inhalt nicht bearbeiten oder kopieren. Falls erforderlich, können Sie ihn wiederherstellen.",
                lockedErrorPost: "Das Hinzufügen weiterer Elemente ist nicht gestattet. Um dies zu ermöglichen, müssen Sie das Elternelement wiederherstellen.", 
                manageProfileImage: "Profilbild verwalten",
                deletingProfileIMGSuccessfully: "Erfolg! Das Bild wurde erfolgreich von Ihrem Profil gelöscht.",
                deletingProfileIMGUnsuccessfully: "Oh-oh! Wir sind auf ein Problem gestoßen, als wir versuchten, das Bild zu löschen. Bitte versuchen Sie es später erneut oder kontaktieren Sie unser Support-Team für Hilfe.",
                uploadPhoto: "Bild hochladen",
                buildingData: "Gebäudedaten",
                information: "Informationen",
                additionalInformations: "Zusätzliche Informationen",
                additionalInformationsAdd: "Zusätzliche Informationen hinzufügen",
                additionalInformationsDelete: "Zusätzliche Informationen löschen?",
                additionalInformationsDeleteQuestion: "Sind Sie sicher, dass Sie diese Information löschen möchten?",
                deleteBuildingsImageProfile: "Profilfoto des Gebäudes löschen?",
                deleteBuildingsImageProfileQuestion: "Sind Sie sicher, dass Sie das Profilfoto des Gebäudes löschen möchten?",
                key: "Schlüssel",
                value: "Wert",
                created: "Erstellt",
                lastUpdate: "Letzte Aktualisierung",
                editDelete: "Bearbeiten / Löschen",
                loadNMoreItems: "Lade {{n}} mehr Items",
                solveEquation: "Bitte lösen Sie dieses mathematische Problem, um fortzufahren.",
                incorrectSolution: "Versuchen Sie bitte nochmal.",
                howMuch: "Wie viel ist",
                yes: "Ja",
                no: "Nein",
                map: "Karte",
                setMarker: "Marker neu setzen",
                unsetMarker: "Marker fixieren",
                abortSetMarker: "Marker setzen abbrechen",
                goToGoogleMaps: "Marker in GoogleMaps öffnen",
                latitude: "Latitude",
                longitude: "Longitude",
                description: "Beschreibung",
                heatingCurve: "Heizkurve",
                individualDiagram: "Individuelles Diagramm",
                deviceName: "Gerätename",
                deviceDescription: "Gerätebeschreibung",
                deviceActivation: "Geräteaktivierung",
                deviceReadings: "Gerätemesswerte",
                change: "Ändern",
                changeDeviceName: "Gerätenamen ändern",
                changeDeviceDescription: "Gerätebeschreibung ändern",
                assignActivationDataDevice: "Aktivierungsdaten für Gerät vergeben",
                changeDeviceReadings: "Gerätemesswerte ändern",
                noDevices: "Es gibt keine Geräte, die aufgelistet werden müssen.",
                minimalValue: "Minimalwert",
                average: "Durchschnitt",
                maximalValue: "Maximalwert",
                maximalFlowTemperature: "Maximale Vorlauftemperatur",
                minute: "Minute",
                minutely: "Minute",
                quarterhourly: "Viertelstunde",
                hourly: "Stunde",
                daily: "Tag",
                weekly: "Wöchentlich",
                cableNumber: "Kabelnummer",
                metric: "Messgröße",
                measurementPoint: "Messpunkt",
                addEditMeasurementPoint: "Messpunkt hinzufügen/ bearbeiten",
                addMeasurementPoints: "Messpunkt hinzufügen",
                deleteMeasurementPoint: "Messpunkt löschen",
                deleteMeasurementPointQuestion: "Sind Sie sicher, dass Sie diesen Messpunkt löschen möchten?",
                deleteMeasurementPointSuccessful: "Der Messpunkt wurde erfolgreich gelöscht.",
                noMeasurementPoints: "Es sind keine Messpunkte verfügbar.",
                measurementPointAlreadyExists: "Messpunkt existiert bereits.", 
                preview: "Vorschau",
                plot: "Plotten",
                aggregation: "Anhäufung",
                interval: "Intervall",
                reload: "Neu laden",
                downloadAsCSV: "Als CSV herunterladen",
                switch: "Tauschen",
                switchTo: "Wechsel zu",
                measurementName: "Messname",
                chartType: "Chart Type",
                lineChart: "Liniendiagramm",
                barChart: "Balkendiagramm",
                period: "Zeitraum",
                addLogEntry: "Log-Eintrag hinzufügen",
                buildingLogDeletedSuccessfully: "Das Gebäudelog wurde erfolgreich gelöscht.",
                hpLogDeletedSuccessfully: "Das Heizbauteil-Log wurde erfolgreich gelöscht.",
                title: "Titel",
                author: "Autor",
                updatedAt: "Zuletzt geändert",
                updated: "Zuletzt geändert",
                createdAt: "Angelegt am",
                logs: "Gebäude-Logs",
                library: "Bibliothek",
                noHP: "Es gibt keine Heizbauteile, die aufgelistet werden müssen.",
                noHPLogs: "Es gibt keine Heizbauteile-Logs, die aufgelistet werden müssen.",
                noBuildingLogs: "Es gibt keine Gebäude-Logs, die aufgelistet werden müssen.",
                noLogs: "Es gibt keine Gebäude-Logs",
                addEditLog: "Gebäude-Log hinzufügen/bearbeiten",
                hpLog: "Heizbauteil-Log",
                addEditHPLog: "Heizbauteil-Log hinzufügen/bearbeiten",
                deleteHPLog: "Heizbauteil-Log löschen?",
                deleteLog: "Gebäude-Log löschen?",
                deleteLogQuestion: "Sind Sie sicher, dass Sie diesen Gebäude-Log löschen möchten?",
                deleteHPLogQuestion: "Sind Sie sicher, dass Sie diesen Heizbauteil-Log löschen möchten?",
                copyContent: "Inhalt kopieren",
                copyContentWithoutPictures: "Inhalt ohne Bilder kopieren",
                calculationType: "Berechnungsart",
                calculationTypes: "Berechnungsarten",
                newCalculation: "Berechnung abgeschlossen",
                simpleOptimisation: "Einfache Optimierung",
                classicCalculation: "Klassische Berechnung",
                withRadiatorReplacement: "Optimierung mit Heizkörpertausch",
                calculateMaximalTemperature: "Berechnung auf eine Maximaltemperatur",
                lowTemperatureReadyness: "Vollständige Niedertemperatur-Readyness-Auslegung",
                number: "Nummer",
                calculate: "Berechnen",
                calculateNow: "Jetzt berechnen",
                calculateDIN12831: "Berechung der DIN 12831",
                entranceLevel: "Eingang in Etage",
                unit: "Einheit",
                addUnit: "Einheit hinzufügen",
                deleteUnit: "Einheit löschen",
                deleteUnitQuestion: "Sind Sie sicher, dass Sie diese Einheit löschen möchten?",
                deletedUnit: "Einheit wurde erfolgreich gelöscht.",
                calculatedArea: "Berechnete Fläche (m²)",
                heatingLoad: "Heizlast",
                heatingLoadPerFloor: "Heizlast pro Etage",
                utilizationUnits: "Nutzungseinheiten",
                noUnits: "Es gibt keine Einheiten, die aufgelistet werden müssen.",
                unitAddEdit: "Einheit hinzufügen / bearbeiten",
                addRoom: "Raum hinzufügen",
                copyUnit: "Einheit kopieren",
                noRooms: "Es gibt keine Räume, die aufgelistet werden müssen.",
                roomName: "Raumname",
                heatingLoadPerM2: "spezifische Heizlast",
                roomTemperature: "Raumtemperatur",
                airExchangeRate: "Luftwechselrate",
                partTable: "Bauteil- und Heizkörpertabelle",
                addBuildingpartTable: "Bauteil-Tabellenaufnahme",
                copyRoom: "Raum kopieren",
                deleteRoom: "Raum löschen",
                deleteRoomQuestion: "Sind Sie sicher, dass Sie diesen Raum löschen möchten?",
                deletedRoom: "Der Raum wurde erfolgreich gelöscht.",
                successulLogPostSubmit: "Erfolg! Ihr Building Log ist jetzt sicher gespeichert.",
                successulHpLogPostSubmit: "Erfolg! Ihr Heizbauteil-Log ist jetzt sicher gespeichert.",
                successulLogEditSubmit: "Ihre Änderungen am Building Log wurden erfolgreich gesichert.",
                successulHpLogEditSubmit: "Ihre Änderungen am Heizbauteil-Log wurden erfolgreich gesichert.",
                fetchingImageError: "Leider konnten wir diesmal die neueste Bildquelle nicht abrufen.",
                updatingImageSourceError: "Beim Aktualisieren der Bildquellen ist ein Fehler aufgetreten.",
                autosaveQuery: "Aktivieren Sie die Autospeicherung: Ihr Inhalt wird sicher 10 Sekunden nach der letzten Änderung gespeichert.",
                content: "Inhalt",
                save: "Speichern",
                imageUploadError: "Hoppla, wir sind auf ein kleines Problem beim Hochladen der Datei gestoßen: {{file}}",
                f1GenericTerms2017: {
                    exteriorWall: 'Innenwand gegen unbeheizt mit 1x Außenwand',
                    exteriorWallNoDoors: 'Innenwand gegen unbeheizt mit 2x Außenwänden ohne Außentüren',
                    exteriorWallWithDoors: 'Innenwand gegen unbeheizt mit 2x Außenwänden mit Außentüren',
                    exteriorWalls: 'Innenwand gegen unbeheizt mit 3x oder mehr Außenwänden; z. B. außenliegende (beheizte) Treppenhäuser',
                    floorAgainstBasementNoDoorsWindows: 'Boden gegen Kellergeschoss ohne Außentüren/Fenster',
                    floorAgainstBasementWithDoorsWindows: 'Boden gegen Kellergeschoss mit Außentüren/Fernstern',
                    atticHighAirExchangeRateNoContinuousCovering: 'Gegen Dachgeschoss mit hoher Luftwechselrate des Dachgeschosses z. B. Dächer ohne durchgängige Abdeckung (Dachziegel usw.) sowie ohne abdichtende Unterspannbahn',
                    againstUninsulatedRoofs: 'Gegen Dachgeschoss und andere ungedämmte Dächer',
                    againstInsulatedRoof: 'Gegen Dachgeschoss und gedämmten Dach',
                    interiorSpaceLowAirExchangeRate: 'Gegen Verkehrsflächen Innenliegender Raum (keine Außenwände) mit geringer Luftwechselrate ( ≤ 0,5 h-1)',
                    trafficAreasWithAirflow: 'Gegen Verkehrsflächen durchströmt (A/V) > 0.005',
                    elevatedFloorAboveCrawlSpace: 'Gegen aufgeständertem Boden (Boden über einem Kriechraum)',
                    floorAgainstEarth: 'Boden gegen Erdreich'
                },
                networkError: "Ups, wir sind auf eine Netzwerkantwort gestoßen, die etwas Aufmerksamkeit erfordert.",
                addBuildingpart: "Bauteil hinzufügen",
                editBuildingpart: "Bauteil bearbeiten",
                buildingPart: "Bauteil",
                buildingParts: "Bauteile",
                standardBuildingParts: "Varianten-Bauteile",
                buildingPartPost: "Bauteil wurde erfolgreich in der Datenbank gespeichert.",
                buildingPartPatch: "Bauteil wurde erfolgreich bearbeitet.",
                buildingPartPostError: "Fehler! Das Bauteil wurde nicht in der Datenbank gespeichert.",
                buildingPartPatchError: "Fehler! Das Bauteil wurde nicht bearbeitet.",
                material: "Material",
                structure: "Struktur",
                insulation: "Dämmung",
                glazing: "Verglasung", 
                deduction: "Abzug",
                components: "Bauteile",
                basicData: "Grunddaten",
                calculationData: "Berechnungsdaten",
                procedure: "Verfahren",
                type: "Typ",
                uValue: "U-Wert",
                length: "Länge",
                width: "Breite",
                distanceFromFloor: "Höhe über Fußboden",
                direction: "Ausrichtung",
                adjacentType: "angrenzender Typ",
                isoF2SoilDE: "(ISO...) f2 für Erdreich in DE",
                adjacentTemperature: "Angrenzende Temperatur (°C)",
                deductionArea: "Abzugsfläche von",
                thermalBridgeAddition: "Wärmebrückenaufschlag (W/m²/K)",
                transmissionLoss: "Transmissionswärmeverlust",
                typology: "Typologie",
                catalogue: "Katalog",
                individual: "Individuell",
                floor: "Boden",
                wall: "Wand",
                window: "Fenster",
                ceiling: "Decke",
                outerdoor: "Außentür",
                outerwall: "Außenwand",
                outerwallsoil: "Außenwand an Erdreich",
                groundsoil: "Boden an Erdreich",
                maxDepthGround: "max. Tiefe im Erdreich",
                innerwall: "Innenwand",
                innerdoor: "Innentür",
                innerceiling: "Innendecke",
                shutterbox: "Rollladenkasten",
                componentSurface: "Bauteilfläche",
                defaultSelectExample: "Standardauswahlbeispiel",
                temperature: "Temperatur",
                thermalBridge: "Wärmebrücke",
                radiator: "Heizkörper",
                noValves: "No valves available",
                noValves: "Keine Ventile vorhanden",
                valve: "Ventil",
                designType: "Bauart",
                radiatorvalve: "Heizkörperventil",
                pump: "Pumpe",
                pipe: "Rohr",
                hot_water_cylinder: "Warmwasserspeicher",
                heat_exchanger: "Wärmetauscher",
                buffer_storage: "Pufferspeicher",
                sensor_act: "Sensor Aktor",
                heatsource: "Wärmeerzeuger",
                other: "Sonstiges",
                sortOf: "Art",
                depth: "Tiefe",
                height: "Höhe",
                strand: "Strang",
                noStrandSelected:"kein Strang ausgewählt",
                distance: "Entfernung",
                adjustable: "Einstellbar",
                architecturalForm: "Bauform",
                diameterNominal: "Nennweite",
                size: "Größe",    
                maxSize10MB: "Die folgenden Dateien überschreiten die maximale Größe von 10 MB:",
                independent: "Unabhängig",
                panelRadiator: "Flachheizkörper",
                panelRadiatorType: "Flachheizkörper Typ",
                steelSectionalRadiator: "Stahl-Gliederradiator",
                castIronSectionalRadiator: "Gussheizkörper", 
                castingSectionalRadiator: "Guss-Gliederradiator",
                bathroomRadiator: "Badheizkörper",
                benchRadiator: "Bankheizkörper",
                pillarRadiator: "Säulenheizkörper",
                thermalBridgeSurcharge: "Wärmebrückenzuschlag",
                likeRoomArea: "1:1 wie Raumfläche",
                pipeDiameter: "Rohrdurchmesser",
                floorPost: "Ihre Etage wurde hinzugefügt.",
                floorPatch: "Die Etage wurde editiert.",
                floorNumber: "Stockwerk",
                plant: "Anlage",
                plantPost: "Ihre Anlage wurde sicher in der Datenbank gespeichert.",
                plantPatch: "Herzlichen Glückwunsch, Ihre Anlage wurde gekonnt bearbeitet.",
                ventilation: "Lüftung",
                din276Main: {
                    long: {
                        heat: "Wärmeversorgung",
                        power: "Stromversorgung",
                        ventilation: "Raumlufttechnik",
                        ventilationLoss: "Lüftungswärmeverlust",
                        water: "Abwasser-, Wasser-, Gasanlagen",
                        elevator: "Aufzugsanlagen",
                        kitchen: "Küchentechnische Anlagen",
                        laundry: "Wäscherei- und Reinigungsanlagen",
                        fireSuppression: "Feuerlöschanlagen",
                        automation: "Gebäude- und Anlagenautomation",
                        waste: "Anlagen der Abfallentsorgung",
                        outdoorArea: "Außenanlagen und Freiflächen",
                        other: "Andere"
                    },
                    short: {
                        heat: "Wärme",
                        power: "Strom",
                        ventilation: "RLT",
                        water: "Wasser",
                        elevator: "Aufzug",
                        kitchen: "Küche",
                        laundry: "Wäscherei",
                        fireSuppression: "Löschanlage",
                        automation: "Automation",
                        waste: "Abfall",
                        outdoorArea: "Außenanlage",
                        other: "Andere"
                    }
                },
                corridor: "Flur",
                livingRoom: "Wohnzimmer",
                kitchen: "Küche",
                bath: "Bad",
                bedroom: "Schlafzimmer",
                utilizationKey: "Nutzungseinheit",
                roomTypePreselection: "Vorauswahl der Zimmerkategorie",
                sector: "Bereich",
                noBuildingContacts: "Es gibt keine Gebäude-Kontakten, die aufgelistet werden müssen.",
                noContacts: "Keine Kontakte vorhanden",
                topic: "Thema",
                addContactPerson: "Ansprechpartner hinzufügen",
                telephone: "Telefon",
                telephonenumbers: "Telefonnummern",
                comment: "Kommentar",
                performancePeriod: "Leistungszeitraum",
                buildingInvoices: "Gebäuderechnungen",
                noInvoices: "Es gibt keine Baurechnungen, die aufgelistet werden müssen.",
                noInvoicesAvaible: "Es gibt keine Rechnungen, die aufgelistet werden müssen.",
                showInvoices: "Rechnungen anzeigen",
                from_ab: "ab",
                from: "vom",
                from_1: "Von",
                overlappingDates: "Der neue Datumswert überschneidet sich mit vorhandenen Datumsbereichen.",
                to: "bis",
                to_1: "Bis",
                left: "links",
                right: "rechts",
                notSpecified: "nicht angegeben",
                unknow: "Unbekannt",
                download: "Herunterladen",
                addLevel: "Etage hinzufügen",
                editLevel: "Etage bearbeiten",
                drawLevel: "Etage hinzufügen und zeichnen",
                deleteLevel: "Etage löschen",
                deleteLevelQuestion: "Sind Sie sicher, dass Sie diese Etage entfernen möchten? Bitte beachten Sie, dass die dazugehörigen Räume und Nutzungseinheiten gelöscht werden!",
                floorDeleted: "Die ausgewählte Etage wurde erfolgreich entfernt.",
                floorDeletedError: "Es gab ein Problem beim Löschen der Etage!",
                roomBook: "Raumbuch",
                noBuildingLevel: "Es gibt keine Gebäudeebenen, die aufgelistet werden müssen.",
                appartment: "Wohneinheit (WE)",
                transmissionLossNotation: "Transmission",
                adjacentTemperature: "Angrenzende T",
                addComponent: "Komponente hinzufügen",
                calculatedThermalPowerToAmbient: "Berechnete Leistung",
                calculatedVolumeFlow: "Berechneter Volumenstrom",
                designThermalPower: "Auslegungsleistung",
                spreading: "Spreizung",
                volumeWithoutUnit: "Volumen",
                room: "Raum",
                interpretation: "Auslegung",
                errorLoadingImageID: "Ups! Wir sind auf ein kleines Problem gestoßen, als wir versucht haben, das Bild mit dem Dateinamen {{filename}} zu laden. Bitte haben Sie etwas Geduld, während wir das klären.",
                buildingPictures: "Baubilder",
                imageProcessing: 'Bildverarbeitung',
                downscaling: "Ihr Bild wird optimiert: {{filename}} für ein reibungsloses Erlebnis.",
                errorOptimisedFileAccess: "Fehler beim Zugriff auf die optimierte Datei aufgetreten.",
                failedReadingFile: "Es gab Schwierigkeiten beim Lesen der Datei.",
                failedFileUpload: "Die Datei konnte nicht hochgeladen werden.",
                buildingGallery: "Gebäudegalerie",
                // buildingPlant: "Gebäudeanlage",
                plantGallery: "Anlagegalerie",
                plantData: "Anlagendaten",
                deleteDocument: "Dokument löschen",
                deleteDocumentQuestion: "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?", 
                editDocument: "Dokument bearbeiten",
                addDocument: "Dokument hinzufügen",
                noDocuments: "Es sind keine Dokumente vorhanden.",
                editedDocument: "Das Dokument wurde erfolgreich bearbeitet.", 
                postDocument: "Das Dokument wurde erfolgreich hochgeladen.", 
                document: "Dokument", 
                documents: "Dokumente",
                extractedWordsFromFile : "Extrahierte Wörter aus Datei",  
                noExtractedWordsFromFile : "Es gibt keine extrahierte Wörter aus der Datei.",
                files: "Dateien",
                file_type: "Dateityp",
                events: "Veranstaltungen",
                climateTarget: "Klimaziel für",
                climateImpact: "Klimaauswirkung",
                development: "Bezogen auf die Entwicklung",
                moderateReduction: "Moderate Senkung erzielt",
                logBook: "Logbuch",
                measurementData: "Messdaten",
                is_main_counter: "Hauptzähler",
                counter: "Zähler",
                counters: "Zähler",
                addCounter: "Zähler hinzufügen",
                addCounterSuccess: "Der Zähler wurde erfolgreich hinzugefügt!",
                editCounterSuccess: "Der Zähler wurde erfolgreich bearbeitet!",
                editCounter: "Zähler bearbeiten",
                deleteCounter: "Zähler löschen",
                deleteCounterQuestion: "Sind Sie sicher, dass Sie diesen Zähler löschen möchten?",
                deleteCounterSuccess: "Der Zähler wurde erfolgreich gelöscht.",
                noBuildingCounters: "Es gibt keine Zähler, die aufgelistet werden müssen.",
                mainCounter: "Hauptzähler",
                counterReading: "Zählerstand",
                counterReadings: "Zählerstände",
                addCounterReading: "Zählerstand hinzufügen",
                addCounterReadingSuccess: "Der Zählerstand wurde erfolgreich hinzugefügt.",
                editCounterReading: "Zählerstand bearbeiten",
                editCounterReadingSuccess: "Zählerstand wurde erfolgreich bearbeitet.",
                noCounterReading: "Es gibt keine Zählerstände, die aufgelistet werden müssen.",
                deleteCounterReading: "Zählerstand löschen",
                deleteCounterReadingQuestion: "Sind Sie sicher, dass Sie diesen Zählerstand löschen möchten?", 
                deleteCounterReadingSuccess: "Der Zählerstand wurde erfolgreich gelöscht.",
                deleteConsumption: "Verbrauch löschen",
                deleteConsumptionQuestion: "Sind Sie sicher, dass Sie diesen Verbrauch löschen möchten?",
                deleteConsumptionSuccess: "Der Verbrauch wurde erfolgreich gelöscht.",
                scheme: "Schema",
                continueWorkingQuestion: "Möchten Sie an der Stelle weiterarbeiten, an der Sie zuletzt aufgehört haben?",
                dataSuccess: "Ihre Daten wurden erfolgreich gespeichert!",
                allComponents: "Alle Bauteile",
                measuringDevice: "Messgeräte",
                wallBoxes: "Wallboxen",
                successfulPlantDelete: "Die Anlage wurde erfolgreich gelöscht.",
                deletePlant: "Anlage löschen",
                deletePlantQuestion: "Sind Sie sicher, dass Sie diese Anlage löschen möchten?",
                benefits: "Vorteile",
                yearlyPriceM2: "Preis pro m² und Jahr",
                assign: "Beauftragen",
                generateOffer: "Angebot generieren",
                contractPeriod: "Vertragslaufzeit",
                oncePrice: "Einmalpreis",
                sum: "Summe",
                infoPaper: "Infomaterial",
                buy: "Kaufen",
                addProductsBuilding: "Produkte zum Gebäude hinzufügen",
                noProducts: "Es gibt keine Produkten, die aufgelistet werden müssen.",
                years: "Jahren",
                withoutTerm: "ohne Laufzeit",
                none: "keine",
                downloadBrochure: "Infomaterial herunterladen",
                buildingSettings: "Gebäudeeinstellungen",
                anlageName: "Anlage Name",
                addPlant: "Anlage hinzufügen",
                noPlants: "Es gibt keine Anlagen, die aufgelistet werden müssen.",
                ctrlScrollZoom: "Optimieren Sie Ihr Erlebnis durch einfaches Zoomen – drücken Sie einfach STRG und scrollen Sie!",
                loading: "Laden...",
                planned_start_date: "Geplantes Startdatum",
                planned_end_date: "Geplantes Enddatum",
                date: "Datum",
                noDataAvailable: "Keine Daten verfügbar",
                scatterPlotData: "Streudiagramm von Daten",
                derivedFunction: "Abgeleitete Funktion",
                level: "Etage",
                slope: "Steigung",
                label: "Etikett",
                xAxisStart: "xAchsenstart",
                xAxisEnd: "xAchsenend",
                yAxisStart: "yAchsenstart",
                yAxisEnd: "yAchsenend",
                dataset: "Datensatz",
                from_catalog: "Aus Katalog",
                selectLabelDataset: "Wählen Sie Datensatz beschriften aus",
                submit: "Absenden",
                cookieMessage: "Um das volle Potenzial unseres Dienstes freizuschalten, gewähren Sie bitte die Erlaubnis zur Datenverarbeitung.",
                here: "Hier",
                findPrivayPolicy: "finden Sie unsere Datenschutzerklärung.",
                allow: 'Allow',
                imageFiles: "Bilddateien!",
                imageFilesText: "Um den Upload-Vorgang zu starten, wählen Sie bitte einige Bilder aus.",
                uploading: "Hochladen",
                uploadingText: "Wir laden derzeit die ausgewählten Bilder hoch. Nach Abschluss dieses Vorgangs können Sie weitere Bilder hochladen.",
                uploadedText: "Die Bilder wurden erfolgreich hochgeladen. Nachdem Sie dieses Popup geschlossen haben, werden sie in der Gebäudegalerie angezeigt.",
                selectorDnD: "Wählen Sie eine Datei aus oder ziehen Sie Ihre Bilder hierhin",
                imageDescription: "Bildbeschreibung hinzufügen",
                uploadImages: "Bilder Hochladen",
                noImagesMessage: "Es tut uns leid, aber momentan sind keine Bilder zum Anzeigen vorhanden. Sie können jedoch damit beginnen, Ihre eigenen Bilder hochzuladen.",
                noFloorRooms: "Lege bitte einen Raum an.",
                noBuildingParts: "Lege bitte ein Bauteil an.",
                noBuildingPartsWithThisType: "Es sind keine Bauteile von diesem Typ vorhanden.",
                selectBuildingPart: "Es wurde kein Bauteil ausgewählt.",
                deletedBuildingpart: "Bauteil gelöscht",
                AddBuildingPartVariant: "Standard-Bauteil zur Variante hinzufügen",
                postBuildingPartVariant: "Standart-Bauteil zur Variante hinzugefügt.",
                patchBuildingPartVariant: "Standart-Bauteil geändert",
                deletedBuildingPartVariant: "Standard-Bauteil gelöscht",
                deleteBuildingPartVariant: "Standard-Bauteil löschen",
                deleteBuildingPartVariantQuestion: "Sind Sie sicher, dass Sie dieses Standard-Bauteil löschen möchten?",
                delete_variant: {
                    existing_schemes: "Es sind noch Schemen vorhanden."
                },
                calculate_bad_request: {
                    missing_outerwall: "Eine Standardreferenz für die Außenwand fehlt",
                    missing_floor: "Eine Standardreferenz für den Boden fehlt",
                    missing_window: "Eine Standardreferenz für die Fenster fehlt",
                    no_radiator_throttle: "Ventil am Heizkörper fehlt",
                    error_calculation_found_no_config: "kein Berechnungsergebnis verfügbar",
                    missing_variant: "Ein Szenario fehlt.",
                },
                unknown:"Unbekannt",
                heatPower: "Heizleistung", 
                normHeatpower:"Norm-Heizleistung",
                realization: "Realisierungsdatum",
                actualVariant: "Aktuelle Variante",
                Max_Flow_Temperature: "Max. Vorlauftemperatur",
                max_flow_temperature: "Max. Vorlauftemperatur",
                Max_Return_Temperature: "Max. Rücklauftemperatur",
                max_return_temperature: "Max. Rücklauftemperatur",
                heating_limit: "Heizgrenze",
                exchange: "Austausch ab",
                exchange_individual_bp: "Individuelle Bauteile bei Berechnung durch Standard-Bauteile austauschen (z.B. Fenstertausch)?",
                exchange_individual_bp_short: "Individuelle Bauteile austauschen",
                edit_individual_bp: "Individuelle Bauteile bearbeiten",
                edit_all_individual_bp :"Alle individuellen Bauteile mit diesem Typ bearbeiten?",
                individual_bp: "Individuelle Bauteile",
                noIndividualBuildingParts: "Es sind keine individuellen Bauteile vorhanden.",
                noIndividualBuildingPartsType: "Es sind keine individuellen Bauteile mit diesem Typ vorhanden.",
                heatingPartCataloges: "Heizbauteil-Katalog",
                add_heatpart: "Heizbauteil hinzufügen",
                edit_heatpart: "Heizbauteil editieren",
                noHeatingParts: "Lege bitte ein Heizbauteil an.",
                HeatingPart: "Heizbauteil",
                HeatingParts: "Heizbauteile",
                unusedHP: "Hinzufügen",
                usedHP: "Verwendet",
                usedHeatingParts: "Heizbauteile des Gebäudes, welche schon im Schema verwendet worden sind.",
                notUsedHeatingParts: "Heizbauteile des Gebäudes, welche noch ins Schema hinzugefügt werden können.",
                heatingPartPatch: "Heizbauteil wurde erfolgreich bearbeitet.",
                calculated_K_v: "Kv-Wert",
                target_mass_flow: "Massenstrom",
                target_pressure: "Differenzdruck",
                target_return_temp: "Rücklauftemp.",
                configurationValue: "Einstellwert",
                radiator_throttle: "Ventil am Heizkörper",
                radiator_valve: "Ventil",
                axial: "Axial",
                passageway: "Durchgang",
                corner: "Eck",
                'angledcorner(right)': "Winkeleck, rechts",
                'angledcorner(left)': "Winkeleck, links",  
                to_calculate: "kein Ergebnis",
                performanceIndicator: "Leistungskennung",
                multiples_standard_ref_power: "Anzahl Glieder",
                multiples_standard_ref_power_meter: "Länge in Metern",
                subtypes: "Subtyp",
                distance_to_pump: "Distanz zur Pumpe",
                overbuilt: "Überbaut",
                distance_overbuilt: "Entfernung zur Überbauung in cm",
                exchange_list: "Heizkörper-Austauschliste",
                virtualgroup: "virtuelle Gruppe",
                virtual_calculation_group: "Virtuelle Gruppe",
                deductionSurfaceWithoutLoss: "Abzugsfläche ohne Verlust",
                calculation_result: "Berechnungsergebnisse",
                flow_min: "Vorlauf-Mindestemperatur",
                count: "Anzahl",
                heatpart_count: "Anzahl Heizkörper",
                resulting_count_heatparts: "Nutzbare Anzahl Heizkörper",
                year_consumption: "Jahresverbrauch",
                mass_flow: "Massenfluss",
                target_flow_temp: "Vorlauftemp.",
                flow_temperature: "Vorlauftemperatur",
                return_temperature: "Rücklauftemperatur",
                radiatorTooSmallToMaintainReturnTemperature: "Der Heizkörper ist zu klein, um die maximale Rücklauftemperatur von {{maxReturnTemperature}} °C einzuhalten.",
                upload: "Hochladen",
                copy: "Kopieren",
                moreInfo: "Details anzeigen",
                saveChanges: "Änderungen speichern",
                country: "Land",
                editPhoneNumber: "Telefonnummer bearbeiten",
                userSettings: "Benutzereinstellungen",
                organisationUsersList: "Nutzer in Organisation",
                orgSettings: "Einstellungen der Organisation",
                organisation: "Organisation",
                relationDeleted: "Die Zugehörigkeit zur Mutterorganisation wurde erfolgreich gelöst.",
                deleteRelation: "Zugehörigkeit löschen",
                deleteRelationQuestion: "Sind Sie sicher, dass Sie die Zugehörigkeit löschen möchten?", 
                organisationHasRights: "hat Rechte?",
                organisationShouldHaveRights: "Soll die Mutterorganisation Rechte über die Tochterorganisation haben?",
                deletePhoneNumber: "Telefonnummer löschen",
                phoneNumberDeleteQuestion: "Sind Sie sicher, dass Sie diese Telefonnummer löschen möchten?",
                phoneNumberDeleted: "Die ausgewählte Telefonnummer wurde erfolgreich gelöscht.",
                editAdministrator: "Administrator bearbeiten",
                deleteAdministrator: "Administrator löschen",
                administratorDeleteQuestion: "Sind Sie sicher, dass Sie diesen Administrator entfernen möchten?",
                administratorDeleted: "Der ausgewählte Administrator wurde erfolgreich entfernt.",
                leaveOrganisationQuestion: "Sind Sie sicher, dass Sie diese Organisation verlassen möchten?",
                leftOrganization: "Die Organisation wurde verlassen!",
                organisationAddress: "Adresse der Organisation",
                organisationAdministratorsList: "Liste der Administratoren der Organisation",
                paymentMethods: "Zahlungsarten",
                paymentMethod: "Zahlungsart",
                editPaymentMethod: "Zahlungsart bearbeiten",
                deletePaymentMethod: "Zahlungsart löschen?",
                paymentMethodDeleteQuestion: "Sind Sie sicher, dass Sie diesen Zahlungsart entfernen möchten?",
                paymentMethodDeleted: "Der ausgewählte Zahlungsart wurde erfolgreich entfernt.",
                authorizePayments: "Ich ermächtige SigmaHeat Gmbh, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von SigmaHeat Gmbh auf mein Konto gezogenen Lastschriften einzulösen.",
                verifyIBAN: "Bitte überprüfen Sie Ihre IBAN-Nummer.",
                subscriptions: "Abonnements",
                invoice: "Rechnung",
                invoiceNumber: "Rechnungsnummer",
                amount: "Menge",
                addTask: "Aufgabe hinzufügen",
                fileRequest: "Datei anfordern",
                fileRequestSuccess: "Die Datei wurde erfolgreich angefordert.",
                documentRequest: "Dokumentanfrage",
                documentName: "Dokumentenname",
                purpose: "Zweck",
                subsidiesCatalog: "Fördermittelkatalog",
                subsidiesCatalogCorrelatedBuilding:"Passende Fördermittel",
                subsidies: "Fördermittel",
                subsidy_from_catalog: "Fördermittel aus Katalog",
                addSubsidy: "Fördermittel hinzufügen",
                editSubsidy: "Fördermittel bearbeiten",
                deleteSubsidy: "Fördermittel löschen", 
                requestedSubsidies: "Beantragte Fördermittel",
                deleteSubsidyQuestion: "Sind Sie sicher, dass Sie dieses Fördermittel löschen möchten?",
                patchSubsidy: "Das Fördermittel wurde erfolgreich bearbeitet.",
                postSubsidy: "Das Fördermittel wurde erfolgreich erstellt.",
                noSubsidies: "Es sind keine Fördermittel vorhanden.",  
                supplier: "Dokumentenlieferant",
                required: "Erforderlich",
                noSelection: "Keine Auswahl",
                notify: "Benachrichtigen",
                requested: "Angefragt",   
                requested_funding:"Angefragte Fördermittel (in Euro)",
                costs_for_the_customer:"Eigenmittel (in Euro)",
                new_costs_for_the_customer:"Neue Eigenmittel (in Euro)",
                approved_subsidies:"Bewilligte Kosten (in Euro)",
                funding_sum:"Vorhabenskosten (in Euro)",
                funding_ratio:"Förderquote",
                cash_state:"Auszahlungsstatus", 
                subscriptionEnd: "Abo-Ende",
                application:"Antrag begonnen",
                applied:"Antrag abgegeben",
                approved:"Antrag angenommen",
                early_start:"Vorzeitiger Maßnahmenbeginn",
                rejected:"Abgelehnt",
                canceled:"Abgebrochen",
                completed:"Abgeschlossen",
                paid:"Ausgezahlt",
                partly_paid:"Teilweise augezahlt",
                not_paid:"Nicht Ausgezahlt",
                status: "Status",
                success: "Erfolg",
                pending: "Ausstehend",
                failure: "Fehlschlag",
                for_the:"für das",
                deleteOrganisation: "Organisation löschen?",
                organisationDeleteQuestion: "Sind Sie sicher, dass Sie diese Organisation entfernen möchten?",
                organisationDeleted: "Die ausgewählte Organisation wurde erfolgreich entfernt.",
                somethingWentWrong: "Sorry! Hier ist etwas schiefgelaufen!",
                errorBoundry: {
                    messageOne: "Das tut uns sehr leid, falls der Fehler erneut auftritt, kontaktieren Sie uns einfach telefonisch unter",
                    messageTwo: "oder per Mail an",
                    messageThree: "Vielen Dank für Ihr Verständnis!"
                },
                notMember: "Kein Mitglied?",
                createAccount: "Konto erstellen",
                companyName: "Firmenname",
                companyType: "Firmenart",
                alreadyMember: "Bereits Mitglied?",
                companyTypes: {
                    housing: "Wohnungsbauunternehmen / -genossenschaft",
                    business: "Unternehmen / KMU",
                    municipal: "Kommune / Kommunaler Träger",
                    private: "Privatkunde",
                    craftsman: "Handwerker"
                },
                enable: "Aktivieren",
                continue: "Fortfahren",
                verify: "Verifizieren",
                twoFA: {
                    activate2FA: "Bitte schließen Sie den 2FA-Einrichtungsprozess ab, um weiterhin auf Ihr Konto zugreifen zu können und um das höchste Sicherheitsniveau zu gewährleisten. Wenn Sie Fragen haben oder Unterstützung benötigen, steht Ihnen unser Support-Team gerne während des Einrichtungsprozesses zur Verfügung. Vielen Dank für Ihre Mitarbeit bei der Aufrechterhaltung einer sicheren Umgebung für alle Mitglieder von Organisationen.",
                    authenticator: "Um die Zwei-Faktor-Authentifizierung (2FA) zu aktivieren, scannen Sie den QR-Code mithilfe von Google Authenticator oder Microsoft Authenticator.",
                    manual: "Oder geben Sie den Code manuell ein.",
                    recoveryCodes: "Wiederherstellungs-Codes sind entscheidend, falls Sie den Zugriff auf Ihre Authenticator-App verlieren. Bewahren Sie sie sicher auf, da Sie sie benötigen, wenn Sie Ihr 2FA-Gerät verlieren. Bewahren Sie Ihre Wiederherstellungs-Codes an einem sicheren Ort auf und teilen Sie sie nicht mit anderen. Wenn Sie einen unbefugten Zugriff auf Ihr Konto vermuten, kontaktieren Sie umgehend unser Support-Team.",
                    sixDigit: "Geben Sie den 6-stelligen Code aus Ihrer Authenticator-Anwendung ein:",
                    recoveryUsage: "Haben Sie Ihr Handy verloren oder können nicht darauf zugreifen? Keine Sorge! Nutzen Sie Ihre Wiederherstellungs-Codes, um wieder Zugang zu Ihrem Konto zu erhalten. Bitte beachten Sie, dass jeder Code ein Einmalpasswort ist.",
                },
                fiveMinLogTime: "Ihre 5-minütige Anmeldesitzung ist abgelaufen. Zeit, sich erneut anzumelden!",
                buildingPOST: "Das Gebäude wurde erfolgreich erstellt.",
                buildingPATCH: "Das Gebäude wurde erfolgreich bearbeitet.",
                orgPOST: "Die Organisation wurde erfolgreich erstellt!",
                fillOTP: "Bitte fülle alle OTP-Felder aus.",
                incorrectOTP: "Hoppla! Es scheint, als hättest du den falschen OTP eingegeben. Versuche es bitte erneut.",
                fillCode: "Stelle sicher, dass du alle Felder für Wiederherstellungs-Codes ausfüllst.",
                incorrectCode: "Hoppla! Die von dir eingegebenen Wiederherstellungs-Codes stimmen nicht mit denen in unserer Datenbank überein.",
                firstTimeSetup: "Die erstmalige Einrichtung erfordert nur die OTP-Validierung.",
                deleteRowTitle: "Soll diese Zeile gelöscht werden?",
                deleteRowQuestion: "Sind Sie sicher, dass Sie diese Zeile löschen möchten?",
                twoFASuccess: "Gute Nachrichten! Deine Zwei-Faktor-Authentifizierung ist jetzt erfolgreich eingerichtet und bereit für den Einsatz!",
                passwordNoMatch: "Ups! Es sieht so aus, als ob die Passwörter nicht übereinstimmen. Könntest du sie bitte erneut eingeben?",
                passwordComplexity: "Stelle sicher, dass dein Passwort eine Zahl, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthält.",
                registrationNotPossible: "Im Moment ist eine Registrierung leider nicht möglich. Versuche es bitte später erneut. Wir danken für dein Verständnis!",
                checkout: "Bezahlen",
                setupWizard: {
                    organisation: "Starten Sie Ihre Organisation mühelos. Geben Sie Details ein, um zu beginnen.",
                    building: "Geben Sie Gebäudedetails ein. Vereinfachen Sie den Vorgang.",
                    plan: "Finden Sie den perfekten Plan. Entdecken Sie maßgeschneiderte Optionen.",
                    summary: "Schnelle Zusammenfassung Ihrer Bestellung",
                    checkout: "Sicherer Checkout. Wählen Sie die Zahlungsmethode und schließen Sie den Kauf ab."
                },
                validationError: "Die Daten haben den Validierungstest nicht bestanden! Schauen Sie bitte die markierten Zeilen an.",
                organisationValidation: {
                    street: "Um fortzufahren, geben Sie bitte die Straße der Organisation ein.",
                    no: "Geben Sie bitte die Hausnummer der Organisation ein, um fortzufahren.",
                    zip: "Um fortzufahren, geben Sie bitte die Postleitzahl der Organisation ein.",
                    city: "Geben Sie bitte die Stadt der Organisation ein, um fortzufahren.",
                    terms: "Um fortzufahren, akzeptieren Sie bitte die Nutzungsbedingungen.",
                    pA: "Um fortzufahren, akzeptieren Sie bitte die AVV.",
                    pp: "Um fortzufahren, akzeptieren Sie bitte die Datenschutzrichtlinie."
                },
                organisationSuccess: "Die Organisation wurde erfolgreich erstellt!",
                proceeding: "In bearbeitung",
                register: "Registrieren",
                registerMessage: "Danke für die Registrierung! In kurze erhalten Sie eine Email von uns mit dem Bestätigungs link für Ihr Konto.",
                registration: {
                    prename: "Um mit der Registrierung fortzufahren, geben Sie bitte Ihren Namen ein!",
                    name: "Um mit der Registrierung fortzufahren, geben Sie bitte Ihren Nachnamen ein!",
                    password: "Um den Registrierungsprozess fortzusetzen, geben Sie bitte Ihr gewünschtes Passwort ein!",
                    password2nd: "Um den Registrierungsprozess fortzusetzen, geben Sie bitte Ihr Passwort erneut ein!",
                    otp: "Um den Registrierungsprozess fortzusetzen, geben Sie bitte den OTP ein, den Sie an die angegebene E-Mail-Adresse erhalten haben."
                },
                passwordStrength: "Passwort stärke",
                applicationsSettings: "Anwendungseinstellungen",
                securitySettings: "Sicherheitseinstellungen",
                mobileNumbers: "Handynummern",
                addMobileNumber: "Telefonnummer hinzufügen",
                profileImageDelete: "Profilbild löschen?!",
                profileImageQuestionDelete: "Sind Sie sicher, dass Sie Ihren Profilbild löschen möchten?",
                twoFAOrganisations: "Organisationen mit 2FA-Pflicht",
                twoFAMandatory: "2FA ist Pflicht",
                activate2FA: "Aktivieren Sie 2FA",
                deactivate2FA: "2FA deaktivieren",
                updatingProfileFailed: "Beim Versuch, das Profil zu aktualisieren, ist ein Fehler aufgetreten. Bitte überprüfen Sie die von Ihnen eingegebenen Daten",
                buildingDeleteSuccess: "Gebäude wurde erfolgreich gelöscht!",
                propertyDeleteSuccess: "Liegenschaft wurde erfolgreich gelöscht!",
                buildingDeleteError: "Beim Löschen des Gebäudes ist ein Problem aufgetreten!",
                propertyDeleteError: "Beim Löschen der Liegenschaft ist ein Problem aufgetreten!",
                organisationDeleteError: "Beim Löschen der Organisation ist ein Problem aufgetreten! Bitte stellen Sie sicher, dass die Organisation keine weiteren Inhalte wie Gebäude, Einheiten oder Etagen enthält.",
                oldPassword: "Altes Passwort",
                changePasswordHint: "Nach dem Ändern des Passworts werden Sie aus der aktuellen Sitzung ausgeloggt.",
                changePassword: "Passwort ändern",
                profileImageLoadingError: "Beim Laden des Profilbildes ist ein Problem aufgetreten!",
                profileImageUploadError: "Es gab ein kleines Problem beim Hochladen deines Profilbildes!",
                emptyCanvasProfile: "Die Leinwand wartet gespannt auf dein Foto!",
                image: "Bild",
                selectProfileImage: "Wähle ein Foto für dein Profil aus!",
                deleteProfileImageError: "Wir konnten das Profilfoto nicht löschen. Bitte versuche es erneut.",
                profileImageUploadSuccess: "Das Profilbild wurde erfolgreich hochgeladen.",
                typeOldPassword: "Bitte geben Sie Ihr altes Passwort ein.",
                typeNewPassword: "Bitte legen Sie ein neues Passwort fest.",
                retypeNewPassword: "Bestätigen Sie Ihr neues Passwort.",
                differentPassword: "Stellen Sie sicher, dass sich Ihr neues Passwort vom alten unterscheidet.",
                retypePasswordMistake: "Eine der Eingaben für das neue Passwort scheint inkorrekt zu sein.",
                passwordChangeSuccessfully: "Das Passwort wurde erfolgreich geändert.",
                passwordChangeError: "Die Passwortänderung war nicht erfolgreich.",
                conflictNumberError: "Es können maximal fünf Telefonnummern gespeichert werden.",
                postPhoneNumberError: "Es ist ein Fehler beim Speichern Ihrer Telefonnummer aufgetreten.",
                patchPhoneNumberError: "Es ist ein Fehler beim Bearbeiten Ihrer Telefonnummer aufgetreten.",
                postPhoneNumberSuccess: "Ihre Telefonnummer wurde erfolgreich gespeichert.",
                patchPhoneNumberSuccess: "Ihre Telefonnummer wurde erfolgreich bearbeitet.",
                deletePhoneNumberError: "Ihre Telefonnummer konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.",
                userDataSuccess: "Ihre Daten wurden erfolgreich gespeichert!",
                userDataError: "Hoppla! Beim Aktualisieren Ihrer Daten ist ein Fehler aufgetreten.",
                userSettingsSuccess: "Ihre Einstellungen wurden erfolgreich gespeichert!",
                userSettingsError: "Hoppla! Beim Aktualisieren Ihrer Einstellungen ist ein Fehler aufgetreten.",
                deleteProfileImageSuccessfully: "Ihr Profilbild wurde erfolgreich aus unseren Aufzeichnungen entfernt.",
                twoFADeavtivateQuestion: "Sind Sie sicher, dass Sie die Zwei-Faktor-Authentifizierung (2FA) deaktivieren möchten?",
                twoFADeactivationError: "Hoppla! Beim Versuch, die 2FA zu deaktivieren, ist ein Fehler aufgetreten.",
                twoFADeactivationSuccessfully: "Die Zwei-Faktor-Authentifizierung (2FA) wurde erfolgreich deaktiviert.",
                excelMultipleNonUniqueNames: "Das hochgeladene Excel-Dokument enthält Spalten mit gleichen Namen wie {{cols}}. Bitte ändern Sie die Spaltennamen, um sicherzustellen, dass sie jeweils eindeutig sind.",
                orgDataChangedSuccessfully: "Die Daten der Organisation wurden erfolgreich geändert!",
                org2FATurnedON: "Die Zwei-Faktor-Authentifizierung für die Organisation wurde erfolgreich aktiviert.",
                org2FATurnedOFF: "Die Zwei-Faktor-Authentifizierung für die Organisation wurde erfolgreich deaktiviert.",
                permissions: "Berechtigungen",
                additionalInfoBuildingDeletedSuccess: "Die zusätzlichen Gebäudeinformationen wurden erfolgreich gelöscht.",
                keyExists: "Der Schlüssel existiert bereits in der Organisation.",
                buildingsWExcel: "Gebäudeinformationen über eine Excel-Datei hochladen.",
                addOBAI: "Informationen zum Organisationsgebäude hinzufügen",
                resourceType: "Ressourcentyp",
                obaiAddedSuccessfully: "Tolle Neuigkeiten! Das System hat erfolgreich den Schlüssel hinzugefügt, damit Organisationen weitere Gebäude Informationen hinzufügen können.",
                noRessourceTypeOBAI: "Der von Ihnen ausgewählte Ressourcentyp ist nicht in unserer Datenbank enthalten. Bitte wählen Sie einen aus der bereitgestellten Liste aus.",
                obaiDeletedSuccessfully: "Der Schlüssel für zusätzliche Gebäudeinformationen wurde erfolgreich aus der Datenbank der Organisationen entfernt.",
                obaiDeletedError: "Da noch weitere Informationen mit diesem spezifischen Schlüssel verknüpft sind, können wir ihn im Moment noch nicht entfernen. Bitte löschen Sie alle zusätzlichen Gebäudedetails, bevor Sie diesen Schlüssel entfernen.",
                deleteOBAIKey: "Löschen Sie den zusätzlichen Informationsschlüssel für Gebäude in Organisationen.",
                deleteOBAIKeyQuestion: "Sind Sie sicher, dass Sie diesen Schlüssel löschen möchten?",
                addPayment: "Zahlungsmethode hinzugefügen",
                paymentMethodAddedSuccessfully: "Zahlungsmethode erfolgreich hinzugefügt!",
                buildingsData: "Gebäudedaten",
                skip: "Überspringen",
                registrationAgg: "Durch die Erstellung eines Kontos erklären Sie sich damit einverstanden, unsere <0>Processing Agreement</0> zu akzeptieren.",
                registrationPP: "Durch die Erstellung eines Kontos erklären Sie sich damit einverstanden, unsere <0>Datenschutzrichtlinie</0> zu akzeptieren.",
                userIdentifiedLicence: "Benutzeridentifizierte Lizenz",
                concurrentUserLicence: "Gleichzeitige Identifikationslizenz",
                userIdentifiedLicenceManagement: "Benutzeridentifizierte Lizenzverwaltung",
                concurrentUserLicenceManagement: "Gleichzeitige Identifikationslizenzverwaltung",
                shortIntervals: {
                    monthly: "mtl.",
                    yearly: "jährl."
                },
                quantity: "Menge",
                user: "Nutzer",
                active: "Aktiv",
                activeUsers: "Aktive User",
                noUsersOnline: "Aktuell sind keine weiteren Benutzer im Gebäude aktiv.",
                inactive: "Inaktiv",
                cancel: "Abbruch",
                deleteUILTitle: "Lizenz entfernen",
                activateLicence: "Lizenz aktivieren",
                deleteUILQuestion: "Sind Sie sicher, dass Sie dieses Softwarepaket inaktiv schalten möchten?",
                activateLicenceQuestion: "Sind Sie sicher, dass Sie dieses Softwarepaket wieder aktiv schalten möchten?",
                deleteUILSuccessful: "Software-Abonnement erfolgreich inaktiviert.",
                activateLicenceSuccessful: "Software-Abonnement erfolgreich aktiviert.",
                hasLicence: "Besitzt Lizenz",
                deleteLiccenceError: "Es gab ein Problem bei der Bearbeitung Ihrer Anfrage. Bitte überprüfen Sie, ob die Lizenz keinem Benutzer zugeordnet ist.",
                assignLicence: "Lizenz zuweisen",
                revokeLicence: "Lizenz entziehen",
                grantLicenceQuestion: "Sind Sie sicher, dass Sie diese Lizenz dem Benutzer zuweisen möchten?",
                revokeLicenceQuestion: "Sind Sie sicher, dass Sie diese Lizenz dem Benutzer entziehen möchten?",
                licenceGrantSuccessfully: "Lizenz erfolgreich zugewiesen.",
                licenceRevokeSuccessfully: "Lizenz erfolgreich entzogen.",
                licenceGrantError: "Es gab einen Fehler beim Zuweisen der Lizenz.",
                licenceRevokeError: "Es gab einen Fehler beim Entziehen der Lizenz.",
                availableLicences: "verfügbare Lizenzen",
                available: "Verfügbar",
                added: "Hinzugefügt",
                addedLicenses: "hinzugefügte Lizenzen",
                moreLicensesThanAllowed: "Es wurden mehr Lizenzen vergeben, als erlaubt.",
                buyLicencesQuestion: "Möchten Sie weitere Lizenzen erwerben?",
                userAlreadyLicence: "Der Benutzer hat bereits eine Lizenz.",
                allLicencesAssigned: "Alle Lizenzen wurden zugewiesen.",
                addSWPackage: "Softwarepaket hinzufügen",
                successSWPackage: "Die Lizenz wurde erfolgreich hinzugefügt.",
                noLicences: "Keine Lizenzen verfügbar zur Anzeige.",
                cancelSubscription: "Inaktiv schalten",
                activateSubscription: "Aktiv schalten",
                paymentSuccessful: "Die Zahlung wurde erfolgreich abgeschlossen.",
                swInUse: "Die ausgewählte Software wird bereits verwendet. Um die Anzahl der Lizenzen zu ändern, verwenden Sie bitte die Bearbeitungsfunktion.",
                alreadyBuyed: "Bereits gekauft",
                choose: "Auswählen",
                tier: "Stufe",
                price: "Preis",
                netPrice: "Nettopreis",
                unitAmount: "Einzelbetrag",
                month: "Monat",
                year: "Jahr",
                priceUnit: "Preis pro Einheit",
                buildingName: "Gebäudename",
                buildingAddress: "Gebäudeadress",
                product: "Produkt",
                activeTill: "Aktiv bis",
                availableESProducts: "Verfügbare ES-Produkte",
                selectBuildingsForES: "Wählen Sie Gebäude aus, die das ES-Produkt benötigen",
                paymentSummary: "Zusammenfassung der Zahlung",
                summary:"Zusammenfassung",
                mBarperm:"mBar pro m",
                assignPaymentMethods: "Zahlungsmethode jedem Produkt zuweisen",
                changePaymentMethod: "Zahlungsmethode ändern",
                defaultPayment: "Möchten Sie die Zahlungsmethode als Standard festlegen?",
                noESProductAssigned: "Keines unserer Produkte ist dem Gebäude zugeordnet.",
                totalAmount: "Gesamtbetrag",
                waitForProccess: "Bitte warten Sie, bis der Vorgang abgeschlossen ist.",
                default: "Standard", 
                paymentMethodChanged: "Die Zahlungsmethode wurde erfolgreich geändert.",
                esProductAssigned: "Das ES-Produkt wurde erfolgreich dem Gebäude zugeordnet.",
                warningNoOfApartments: "Jedes ausgewählte Gebäude muss mehr als eine Wohnung haben. Bitte geben Sie zuerst die Anzahl der Wohnungen an.",
                WarningAreaSize: "Die Fläche ist mehr als {{factor}} x größer als die Raumfläche.",
                productBrochureTerms: "Um fortzufahren, müssen Sie die Produktinformationsbroschüre lesen und die Bedingungen akzeptieren.",
                updatingNoApartments: "Aktualisierung der Anzahl von Wohnungen.",
                organisationTermsnConditions: "Durch die Erstellung einer Organisation erklären Sie sich damit einverstanden, unsere <0>Allgemeine Geschäftsbedingungen</0> zu akzeptieren.",
                productTermsnConditions: "Hiermit bestätige ich die <0>Produktinformationen</0> gelesen zu haben und frage das Produkt bindend an.",
                userPermissionsChanged: "Die Änderungen der Benutzerberechtigungen wurden erfolgreich übernommen.",
                noPermisson: "Sie haben derzeit nicht die Berechtigung, Benutzerberechtigungen zu ändern oder einen Benutzer zu löschen.",
                noAuthorization: "Sie haben derzeit nicht die Berechtigung, die Daten abzurufen.",
                setupPaymentMethod: "Fügen Sie eine Zahlungsmethode hinzu",
                noPaymentMethods: "Aktuell sind keine Zahlungsmethoden verfügbar, die aufgelistet werden können.",
                buySoftwareWarning: "Bevor Sie einem Benutzer eine Lizenz zuweisen können, müssen Sie das Softwarepaket kaufen.",
                noPaymentMethodConfiguration: "Aktuell sind keine Zahlungsmethoden für diese Organisation eingerichtet. Bitte richten Sie eine Zahlungsmethode ein, bevor Sie ein Produkt erwerben möchten.",
                organisationsPMConfig: "Die Zahlungsmethode für die Organisation wurde erfolgreich eingerichtet.",
                shortWeekdays: {
                    monday: "Mo.",
                    tuesday: "Di.",
                    wednesday: "Mi.",
                    thursday: "Do.",
                    friday: "Fr.",
                    saturday: "Sa.",
                    sunday: "So."
                },
                shortWeekdays: {
                    monday: "Mo.",
                    tuesday: "Di.",
                    wednesday: "Mi.",
                    thursday: "Do.",
                    friday: "Fr.",
                    saturday: "Sa.",
                    sunday: "So."
                },
                shortMonths: {
                    january: "Jan.",
                    february: "Feb.",
                    march: "Mar.",
                    april: "Apr.",
                    may: "May",
                    june: "Jun.",
                    july: "Jul.",
                    august: "Aug.",
                    september: "Sep.",
                    october: "Oct.",
                    november: "Nov.",
                    december: "Dec."
                },
                countryCodes: {
                    Germany: "Deutschland",
                },
                ton: "Tonne",
                kwh_year: "kWh/(m²&Jahr)",
                kg_year: "kg/(m²&Jahr)",
                consumption: "Verbrauch",
                consumptions: "Verbräuche",
                addConsumption: "Verbrauch hinzufügen", 
                editConsumption: "Verbrauch bearbeiten",
                addConsumptionSuccess: "Der Verbauch wurde erfolgreich hinzugefügt.",
                editConsumptionSuccess: "Der Verbauch wurde erfolgreich bearbeitet.",
                noConsumption: "Es gibt derzeit keine verfügbaren Verbäuche, die angezeigt werden können.",
                consumption_kwh: "Verbrauch (kWh)",
                consumption_demand: "Verbrauch (Bedarf)",
                meterReading: "Zählerstand",
                oldValMeterReading: "Zählerstand vor Austausch",
                newValMeterReading: "neuer Zählerstand",
                meterReplacement: "Zählertausch",
                meterReplacementError: "Der neue Zählerstand bei einem Zählertausch darf den eingetragenen Zählerstand nicht überschreiten.",
                current_values: "Aktuelle Werte",
                percentValueParent: "Prozentual zum Elternelement",
                necessaryBuildings: "Notwendige Gebäude",
                goal: "Ziel",
                goalDescription: "Erreiche jetzt das 1,5-Grad-Ziel",
                reduction: "Einsparung",
                actual: "Aktuell",
                notActual: "Berechnung nicht aktuell",
                academy: "Academy",
                academyDescriptiton: "Unsere Academy spezialisiert auf effiziente Wärmetechnik bietet für alle Interessierten umfassende Kurse an!",
                goToAcademy: "Hier geht es zur Academy",
                landlordsharesco2: "Vermieteranteil CO2-Abgabe",
                landlordCosts: "Vermieter-Kosten",
                costs: "Kosten",
                co2_emissions: "CO2-Emissionen",
                co2_emissions_cost: "CO2-Emissionen Kosten / kWh",
                co2_emissions_gas: "CO2-Emissionen (Erdgas)",
                co2_savings: "CO2-Einsparung",
                noOBAIs: "Es gibt derzeit keine verfügbaren OBAIs, die angezeigt werden können.",
                heatingNet: "Heizung-Netzwerk",
                heatmap: "Heatmap",
                pvNet: "Photovoltaik-Netzwerk",
                force2FA: "2-Faktor für alle Organisations-Mitglieder erzwingen",
                checkAll: "Alles ankreuzen",
                esProducts: "ES-Produkte",
                buildingNoApartments: "Das aktuelle Gebäude hat entweder keine Wohnungen oder nur eine. Wir müssen mehr als eine Wohnung verfügbar haben.",
                buildingHasProduct: "Das Produkt wurde bereits dem Gebäude an der Adresse {{address}} zugewiesen.",
                selectBuildingForProduct: "Bitte wählen Sie die Gebäude aus, für die das Produkt hinzugefügt werden soll!",
                noPaymentMethodForOrg: "Für diese Organisation sind keine Zahlungsmethoden eingerichtet. Bitte richten Sie eine Zahlungsmethode ein, bevor Sie ein Produkt erwerben möchten!",
                buildingAssignedPM: "Die Zahlungsmethode für das Gebäude in {{address}} wurde erfolgreich zugewiesen.",
                productInformations: "Produktinformation",
                registrationPaymentConditions: "Um fortzufahren, stimmen Sie bitte den Zahlungsbedingungen zu.",
                conditions: "Nutzungsbedingungen",
                termsnConditions: "Um fortzufahren, stimmen Sie bitte den folgenden Nutzungsbedingungen zu.",
                registrationTermsnConditions: "Um ein Konto zu erstellen, bitte stimmen Sie den <0>Allgemeine Geschäftsbedingungen</0> zu.",
                registrationMarketingTerms: "Um ein Konto zu erstellen, bitte stimmen Sie unseren <0>Marketingbedingungen</0> zu.",
                organisationProcessingAgg: "Um eine neue Organisation zu erstellen, akzeptieren Sie bitte den <0>Auftragsverarbeitungsvertrag (AVV)</0> gemäß DSGVO.",
                organisationPP: "Bevor Sie mit der Erstellung einer Organisation fortfahren, stimmen Sie bitte der <0>Datenschutzrichtlinie</0> zu.",
                aIpostError: "Zusätzliche Informationen konnten momentan nicht veröffentlicht werden.",

                permission: "Genehmigung",
                httpStatus: {
                    fourzerozero: {
                        title: "Schlechte Anfrage",
                        message: "Oh nein! Es scheint, es gibt ein Problem mit der Anfrage. Überprüfen Sie Ihre Eingabe und versuchen Sie es erneut."
                    },
                    fourzeroone: {
                        title: "Unbefugter Zugriff",
                        message: "Hoppla! Es sieht so aus, als würden Sie versuchen, auf etwas zuzugreifen, für das eine ordnungsgemäße Authentifizierung erforderlich ist. Ihre aktuellen Anmeldedaten könnten fehlen oder veraltet sein. Überprüfen Sie Ihre Anmeldeinformationen erneut und stellen Sie sicher, dass Sie die richtigen Berechtigungen haben."
                    },
                    fourzerothree: {
                        title: "Zugriff verweigert",
                        message: "Hoppla! Zugriff verweigert. Möglicherweise fehlen Ihnen die erforderlichen Berechtigungen. Kontaktieren Sie den Support, wenn Sie Hilfe benötigen."
                    },
                    fourzerofour: {
                        title: "Nicht gefunden",
                        message: "Uh-oh! Die angeforderte Seite oder Ressource konnte nicht gefunden werden. Bitte überprüfen Sie die URL und versuchen Sie es erneut."
                    },
                    fourzerosix: {
                        title: "Nicht akzeptabel",
                        message: "Hoppla! Wir können den angeforderten Inhalt nicht im gewünschten Format liefern. Lassen Sie uns eine kompatible Option finden."
                    },
                    fourzeronine: {
                        title: "Konflikt",
                        message: "Houston, wir haben ein Problem! Es scheint eine Kollision mit vorhandenen Daten zu geben. Überprüfen Sie und lösen Sie das Problem, bevor Sie fortfahren."
                    },
                    fourtwonine: {
                        title: "Zu viele Anfragen",
                        message: "Ups! Es scheint, als hättest du zu viele Anfragen in kurzer Zeit gestellt. Gönn dir eine Pause von 5 Minuten und versuche es dann erneut. Wenn das Problem weiter besteht, melde dich gerne bei unserem Support-Team für Unterstützung!"
                    },
                    fivezerozero: {
                        title: "Interner Serverfehler",
                        message: "Uff! Etwas ist auf unserer Seite schiefgegangen. Unser Team kümmert sich darum. Bitte versuchen Sie es später erneut."
                    }
                },
                energy_management : "Energiemanagement",
                generate_energy_report : "Energiebericht generieren",
                create_energy_report : "Energiebericht erstellen",
                energy_audit_annually_organisation_report : "Energie-Audit jährlicher Organisationsbericht",
                energy_audit_annualy_organisation_report : "Energie-Audit jährlicher Organisationsbericht",
                energy_report : "Energiebericht",
                gas: "Gas",
                oil: "Öl",
                liquid_gas: "Flüssiggas",
                wood_pellets: "Holzpellets",
                electricity_grid_related: "Strom (netzgebunden)",
                electricity_generated_close_to_the_building_from_pv: "Strom (gebäudenah erzeugt, PV)",
                displacement_electricity_mix: "Verdrängungsstrommix",
                heating_cooling_geothermal_energy_geothermal_energy_solar_thermal_energy_ambient_heat: "Heizen/Kühlen (Geothermie, Solarthermie, Umgebungshitze)",
                geothermal_cooling_ambient_cooling: "Geothermisches Kühlen, Umgebungskühlung",
                waste_heat_from_processes: "Abwärme aus Prozessen",
                heat_chp_building_integrated_or_near_building_according_to_din_18599_9_2018_0919: "Wärme (KWK, gebäudeintegriert oder gebäudenah, gemäß DIN 18599-9:2018-09)",
                local_district_heating_from_chp_with_chp_coverage_share_of_heat_generation_of_at_least_70_percent_fuel: "Fernwärme (KWK mit mindestens 70 % Wärmeanteil)",
                local_district_heating_stadtwerke_hannover_mix_natural_gas_coal_settlement_waste: "Fernwärme (Stadtwerke Hannover, Erdgas, Kohle, Siedlungsabfälle)",
                local_district_heating_from_heating_plants_fuels_hard_coal_lignite: "Fernwärme (Heizwerke, Steinkohle, Braunkohle)",
                gaseous_or_liquid_fuels: "Gasförmige oder flüssige Brennstoffe",
                renewable_fuel: "Erneuerbare Brennstoffe",
                water: "Wasser",
                energy_audit : "Energieaudit",
                energy_simulation : "Energiesimulation",
                consumption_data : "Verbrauchsdaten",
                renewable_energy_report : "Erneuerbare-Energie-Bericht",
                energy_conservation_plans : "Energieeinsparungspläne",
                energy_savings_analysis : "Energieeinsparungsanalyse",
                energy_performance_certificates : "Energieeffizienzausweise",
                building_plans_and_technical_documents : "Gebäudepläne und technische Dokumente",
                building_plans : "Gebäudepläne",
                floor_plans : "Grundrisse",
                lighting_design : "Lichtplanung",
                electrical_layouts : "Elektropläne",
                hvac_config : "HKL-Konfiguration",
                cooling_system_diagrams : "Kühlsystem-Diagramme",
                roof_inspection_reports : "Dachinspektionsberichte",
                landscaping_plans : "Landschaftsplanungen",
                maintenance_and_inspection : "Wartung und Inspektion",
                maintenance_schedule : "Wartungsplan",
                inspection_report : "Inspektionsbericht",
                elevator_maintenance_reports : "Aufzugswartungsberichte",
                pest_control_reports : "Schädlingsbekämpfungsberichte",
                vehicle_maintenance_logs : "Fahrzeugwartungsprotokolle",
                system_log : "Systemprotokoll",
                security_log : "Sicherheitsprotokoll",
                administrative_and_legal_documents : "Verwaltungs- und Rechtsdokumente",
                contracts : "Verträge",
                lease_agreements : "Mietverträge",
                legal_documents : "Rechtsdokumente",
                procurement_documents : "Beschaffungsdokumente",
                tenant_communications : "Mieterkommunikationen",
                financial_reports : "Finanzberichte",
                reports_and_certifications : "Berichte und Zertifizierungen",
                fire_safety_report : "Brandschutzbericht",
                certifications : "Zertifizierungen",
                green_building_certifications : "Zertifizierungen für nachhaltiges Bauen",
                incident_reports : "Vorfallberichte",
                water_usage_report : "Wasserverbrauchsbericht",
                waste_management_report : "Abfallmanagementbericht",
                risk_assessment_reports : "Risikobewertungsberichte",
                air_quality_reports : "Luftqualitätsbericht",
                manuals_and_training_documents : "Handbücher und Schulungsdokumente",
                user_manuals : "Benutzerhandbücher",
                equipment_manuals : "Ausrüstungshandbücher",
                staff_training_documents : "Mitarbeiterschulungsdokumente",
                emergency_plans : "Notfallpläne",
                inventory_and_asset_management : "Inventar- und Anlagenverwaltung",
                asset_inventory : "Anlageninventar",
                cleaning_schedule : "Reinigungsplan",
                sensor_data : "Sensordaten",
                frameContract: "Rahmen Vertrag",
                frameContracts: "Rahmen Verträge",
                sign: "Unterschreiben",
                check: "Check",
                noFrameContracts: "Es gibt derzeit keine Rahmenverträge, die angezeigt werden können.",
                signFrameContract: "Bitte unterschreiben Sie den Rahmenvertrag!",
                signRequest: "Ein Rahmenvertrag wurde erstellt. Bitte unterschreiben Sie den Vertrag, bevor Sie fortfahren. Vielen Dank!",
                finish: "Fertig",
                signup: "Anmelden",
                messagePlaceholder: "Nachricht eingeben...",
                deleteMessage: "Nachricht löschen",
                deleteMessageQuestion: "Sind Sie sicher, dass Sie diese Nachricht löschen möchten?",
                deleteMessageSuccessfully: "Die Nachricht wurde erfolgreich gelöscht.",
                mailOTP: "Bitte schauen Sie in Ihrem E-Mail-Postfach nach einer Nachricht von uns. Sie enthält einen OTP-Code, den Sie für den Fortsetzen des Registrierungsprozesses benötigen.",
                toOrgInvitation: "Die Einladung wurde erfolgreich an den Benutzer mit der angegebenen E-Mail-Adresse gesendet.",
                toOrgRegistration: "Die Registration wurde erfolgreich durchgeführt.",
                registerUser: "Benutzer registrieren",
                addUserToOrg: "Benutzer zur Organisation hinzufügen",
                docSigned: "Ihr Dokument wurde erfolgreich unterzeichnet!",
                docNotSigned: "Es scheint, dass das Dokument noch nicht unterzeichnet wurde. Bitte fahren Sie fort und unterzeichnen Sie das Dokument.",
                editRoom: "Raum bearbeiten",
                errorRoomName: "Es ist wichtig, dass der Name des Raumes nicht doppelt vergeben wird.",
                invitationAccepted: "Ihr Einladung wurde erfolgreich angenommen!",
                invitationRejected: "Die Ablehnung der Einladung war erfolgreich.",
                acceptInvitation: "Einladung annehmen",
                rejectInvitation: "Einladung ablehnen",
                invitations: "Einladungen",
                energyEfficiencyClass: "EE-Klasse",
                energyFlow: "Energiefluss",
                calculation: "Berechnung",
                calculation_started:"Berechnung gestartet",
                Buildingpartcataloges: "Bauteilkataloge",
                Buildingpartcatalog: "Bauteilkatalog",
                planning: "In Planung",
                planned: "Geplant",
                in_planning: "In Planung",
                not_paid_out: "Nicht ausgezahlt",
                variants: "Szenarien",
                deviatingFromVariant: "von Szenario abweichend",
                noVariant: "keine Szenarien",
                noVariants: "Diesem Gebäude sind noch keine Szenarien zugewiesen. Klicken Sie auf die Plus-Schaltfläche in der Kopfzeile um eins hinzuzufügen.",
                noActualVariant: "Diesem Gebäude ist noch kein aktuelles Szenario zugewiesen.",
                noStandardReferences: "Diesem Gebäude sind noch keine Standardreferenzen zugewiesen. Klicken Sie auf die Plus-Schaltfläche um eine hinzuzufügen.",
                reference: "Referenz aus dem Katalog",
                noReference: "Es fehlt eine Referenz aus dem Katalog.",
                userShouldAddVariant: "Um fortzufahren, fügen Sie bitte eine Variante hinzu.",
                addVariant: "Szenario hinzufügen",
                fromVariant: "Aus Szenario",
                fiveVariant: "Pro Gebäude sind nur 5 Szenarien erlaubt!",
                postVariant: "Das Szenario wurde erfolgreich veröffentlicht!",
                patchVariant: "Das Szenario wurde erfolgreich bearbeitet!",
                deleteVariant: "Szenario löschen",
                deleteVariantQuestion: "Sind Sie sicher, dass Sie dieses Szenario löschen möchten?",
                deleteStructuredPartDataQuestion: "Sind Sie sicher, dass Sie wechseln möchten? Alle Einträge werden dabei gelöscht!",
                deletedVariantpart: "Der Szenario wurde erfolgreich gelöscht!",
                deleteVariantError: "Dieses Szenario kann nicht gelöscht werden. Bitte stellen Sie sicher, dass es keine weiteren Inhalte enthält.",
                deleteVariantNotAllowed: "Dieses Szenario kann nicht gelöscht werden.",
                deletedVariant: "Das Szenario wurde erfolgreich gelöscht!",
                conflictBpVariant: "Dieses Bauteil existiert bereits.",
                catalog: "Katalog",
                bpcatalog: "Katalog für Bauteile",
                hpcatalog: "Katalog für Heizungsteile",
                hpccomponent: "Heizungsteil Komponente",
                addEditDelete: "Hinzufügen / Bearbeiten / Löschen",
                noBPCs: "Es gibt keine Bauteilkataloge, die mit dieser Organisation verbunden sind.",
                noBPCCs: "Es existieren keine Bauteilkomponente.",
                addStandardBP: "Standard-Bauteil zu Szenario hinzufügen",
                addBPC: "Bauteilkatalog hinzufügen",
                editBPC: "Bauteilkatalog bearbeiten",
                deleteBPC: "Bauteilkatalog löschen",
                deleteErrorBPC: "Bauteilkatalog kann nicht gelöscht werden. Stellen Sie sicher, dass alle Komponenten des Bauteilkatalogs gelöscht sind.",
                deleteBPCQuestion: "Sind Sie sicher, dass Sie diesen Bauteilkatalog löschen möchten?",
                bpcDeletedSuccessfully: "Der Bauteilkatalog wurde erfolgreich aus der Organisation entfernt!",
                bpccDeletedSuccessfully: "Die Bauteilkomponente wurde erfolgreich aus dem Katalog entfernt!",
                editBPCC: "Bauteilkomponente bearbeiten",
                addBPCC: "Bauteilkomponente hinzufügen",
                deleteBPCC: "Bauteilkomponente löschen",
                deleteBPCCQuestion: "Sind Sie sicher, dass Sie diese Bauteilkomponente löschen möchten?",
                addBPV: "Gebäudeteil-Variante hinzufügen",
                editBPV: "Gebäudeteil-Variante bearbeiten",
                uTypical: "U typisch",
                individualU_Val: "Individuelle U-Werte",
                individually: "Individuell",
                valid_from: "Gültig von",
                valid_to: "Gültig bis",
                bpcAddedSuccessfully: "Bauteilkatalog wurde erfolgreich hinzugefügt!",
                bpcEditedSuccessfully: "Bauteilkatalog wurde erfolgreich bearbeitet!",
                bpccomponent: "Bauteilkomponente",
                bpccAddedSuccessfully: "Bauteilkomponente wurde erfolgreich hinzugefügt!",
                bpccEditedSuccessfully: "Bauteilkomponente wurde erfolgreich bearbeitet!",
                u_value: "U-Wert (W/m²K)",
                u_value_m2: "U-Wert m2",
                hpcDeletedSuccessfully: "Der Heizungsteilkatalog wurde erfolgreich aus der Organisation entfernt!",
                noHPCs: "Es gibt keine Heizungsteilkataloge, die mit dieser Organisation verbunden sind.",
                noHPCCs: "Es existieren keine Heizungsteikomponente.",
                addHPC: "Heizungsteilkatalog hinzufügen",
                editHPC: "Heizungsteilkatalog bearbeiten",
                deleteHPC: "Heizungsteilkatalog löschen",
                deleteErrorHPC: "Heizungsteilkatalog kann nicht gelöscht werden. Stellen Sie sicher, dass alle Komponenten des Heizungsteilkatalogs gelöscht sind.",
                deleteHPCQuestion: "Sind Sie sicher, dass Sie diesen Heizungsteilkatalog löschen möchten?",
                missingHpccomponent: "Es ist keine Heizungsteilkomponente ausgewählt!",
                hpccomponent: "Heizungsteilkomponente",
                hpccomponents: "Heizungsteilkomponenten",
                hpccDeletedSuccessfully: "Die Heizungsteilkomponente wurde erfolgreich aus dem Katalog entfernt!",
                addHPCC: "Heizungsteilkomponente hinzufügen",
                addHPCCsingle: "Einzelkomponente",
                addHPCClist: "Komponenten importieren",
                editHPCC: "Heizungsteilkomponente bearbeiten",
                deleteHPCC: "Heizungsteilkomponente löschen",
                deleteHPCCQuestion: "Sind Sie sicher, dass Sie diese Heizungsteilkomponente löschen möchten?",
                hpcAddedSuccessfully: "Heizungsteilkatalog wurde erfolgreich hinzugefügt!",
                hpccAddedSuccessfully: "Heizungsteilkomponente wurde erfolgreich hinzugefügt!",
                hpccEditedSuccessfully: "Heizungsteilkomponente wurde erfolgreich bearbeitet!",
                design_thermal_power_text: `Heizkörperspezifische Übertemperatur in Key-Value Paar mit dem Key "overtemperature" speichern!`,
                thermal_power: "Normwärmeleistung", 
                design_thermal_power: "Normwärmeleistung (75/65/20)",
                design_flow_temperature: "Vorlauftemperatur",
                design_return_temperature: "Rücklauftemperatur",
                design_room_temperature: "Raumtemperatur",
                room_temperature: "Raumtemperatur",
                theta_exponent: "Theta Exponent",
                attributes: "Attribute",
                deleteIndividualBuildingPart: "Individuelles Bauteil löschen",
                questionDeleteIndividualBuildingPart: "Sind Sie sicher, dass Sie dieses individuelle Bauteil löschen möchten?",
                deleteBuildingPart: "Gebäudeteil löschen",
                questionDeleteBuildingPart: "Sind Sie sicher, dass Sie dieses Gebäudeteil löschen möchten?",
                bpDeletedSuccessfully: "Der Gebäudeteil wurde erfolgreich gelöscht!",
                deleteHeatingPart: "Heizungsteil löschen",
                questionDeleteHeatingPart: "Sind Sie sicher, dass Sie dieses Heizungsteil löschen möchten?",
                hpDeletedSuccessfully: "Der Heizungsteil wurde erfolgreich gelöscht!",
                maxDistance: "Maximale Entfernung",
                targetPressureMBAR: "Zieldruck mBar",
                sigma_z:"Σ z",
                OffsetGeneric:"Offset Allgemein",
                info_OffsetGeneric:"Benötigt ein Heizkörperventil einen höheren Offset (Mindestdifferenzdruck), wird dieser Wert durch den höheren Wert in der Berechnung überschrieben.",
                OffsetBoilerRoom:"Offset Heizungsraum",
                strandReferenceAdded: "Strang erfolgreich hinzugefügt",
                strandReferenceEdited: "Strang erfolgreich bearbeitet",
                strandReferenceDeleted: "Strang erfolgreich gelöscht",
                noStrandReferences: "Keinen Strang gefunden",
                addStrandReference: "Strang hinzufügen",
                editStrandReference: "Strang bearbeiten",
                deleteStrandReference: "Strang löschen",
                deleteStrandReferenceQuestion: "Sind Sie sicher, dass Sie diese Strang-Referenz löschen möchten?",
                floorSNConflict: "Die angegebene Etagennummer ist bereits vergeben. Bitte wählen Sie eine andere Etagennummer, um fortzufahren!",
                thickness: "Dicke",
                free: "Frei",
                correctionFactor: "Korrekturfaktor",
                calc: "Berechnen",
                afterCalc: "nach Berechnung",

                configurationsLog: "Konfigurationsprotokoll",
                addConfigurationsLog: "Konfigurationsprotokoll hinzufügen",
                editConfigurations: "Konfigurationsprotokoll bearbeiten",
                patchConfigurationsLog: "Konfigurationsprotokoll wurde erfolgreich bearbeitet",
                postConfigurationsLog: "Konfigurationsprotokoll wurde erfolgreich veröffentlicht",
                settingValue: "Einstellwert",
                valveAuthority: "Ventilautorität",
                calcFromExternal: "Von extern berechnet?",
                deleteConfigurationsLog: "Konfigurationsprotokoll löschen",
                deleteConfigurationsLogQuestion: "Sind Sie sicher, dass Sie dieses Konfigurationsprotokoll löschen möchten?",
                deletedConfigurationsLog: "Ausgewähltes Konfigurationsprotokoll wurde erfolgreich gelöscht",
                notDeletedConfigurationsLog: "Ausgewähltes Konfigurationsprotokoll konnte nicht gelöscht werden",
                noConfigurationsLog: "Zurzeit sind keine Konfigurationsprotokolle verfügbar",
                promptLeavePageQuestion: "Sind Sie sicher, dass Sie diese Seite verlassen möchten, ohne Ihre Änderungen zu speichern?",
                heatpart: "Heizungsteil",
                heatparts: "Heizungsteile",
                heatpartsCalculated: "Berechnete Heizungsteile",
                massFlow: "Massenstrom",
                returnTemperature: "RL-Temperatur",
                differentialPressure: "Differenzdruck",
                kvValue: "Kv-Wert",
                value_MWh: "MWh-Wert",
                slopingRoofGenerator: "Schrägdach-Generator",
                roof: "Dach",
                roofType: "Dach Art",
                syncChanges: "Änderungen synchronisieren",
                pitchedRoof: "Schrägdach",
                gableRoofOnePitch: "Satteldach, 1 Schräge",
                gableRoofTwoPitches: "Satteldach, 2 Schrägen",
                hipRoofOnePitch: "Walmdach, 1 Schräge",
                hipRoofTwoPitches: "Walmdach, 2 Schrägen",
                hipRoofThreePitches: "Walmdach, 3 Schrägen",
                hipRoofFourPitches: "Walmdach, 4 Schrägen",
                kneestock: "Kniestock",
                diagonal: "Diagonale",
                dormer: "Gaube",
                dormerNo: "Anzahl von Gauben",
                completedPagesNo: "Anzahl abgeschlossener Seiten",
                distance: "Abstand",
                slanting: "Schräge",
                noofdeductionareas: "Anzahl Abzugsflächen",
                group_id: "Gruppen-ID",
                classifications: "Klassifikationen",
                noClassifications: "Es sind keine Klassifikationen anzuzeigen",
                addClassification: "Klassifizierung hinzufügen",
                addEditClassification: "Klassifizierung hinzufügen/bearbeiten",
                heatCircuit: "Heizkreis",
                hotWaterPreparation: "Warmwasseraufbereitung",
                energyConsumption: "Energieverbrauch",
                heatSource: "Wärmequelle",
                heatStorage: "Wärmespeicher",
                deleteClassification: "Klassifizierung löschen",
                deleteClassificationQuestion: "Sind Sie sicher, dass Sie diese Klassifizierung löschen möchten?",
                deleteClassificationSuccessful: "Klassifikation wurde erfolgreich gelöscht",
                deleteClassificationError: "Klassifizierung wurde nicht gelöscht",
                fixedFlowTemperature: "Fesgelegte Vorlauftemperatur",
                optimalTemperature: "Optimale Temperatur",
                calculationbasics: "Berechnung Grundlagen",
                maximumPerformance: "Maximale Leistung",
                achievableRoomTemperature: "erreichbare Raumtemperatur",
                variantCopiedSuccessfully: "Die Variante wurde erfolgreich kopiert",
                variantCopiedFailed: "Sie haben die maximale Anzahl erlaubter Varianten erreicht.",
                missingCalculation: "Berechnung fehlt",
                specificConsumption: "Spezifischer Verbrauch",
                lastcalculation: "Letzte Berechnung",
                namesource: "Namensquelle",
                productseries: "Produktserie",
                pumpTotalHead: "Pumpenförderhöhe",
                building_actions:"Maßnahmen",
                noBuildingActions:"keine Maßnahmen angelegt",
                planner_tool:"Planer-Tool",
                actual_state:"Ist-Zustand",
                building_informations:"Gebäudeinformationen",
                live_data:"Live-Daten",
                flat_panel_radiator: "Flachheizkörper",
                bathroom_radiator: "Badheizkörper",
                sectional_radiator: "Gliederheizkörper",
                convectors: "Konvektoren",
                heating_walls: "Heizwände",
                designer_radiator: "Designheizkörper",
                electric_radiator: "Elektroheizkörper",
                underfloor_convectors: "Unterflurkonvektoren",
                ceiling_convectors: "Deckenkonvektoren",
                wall_mounted_convectors: "Wandeinbaukonvektoren",
                parapet_convectors: "Brüstungskonvektoren",
                others: "Andere",

                schema_generator: "Schemen Generator",
                heat_sources_no: "Anzahl der Wärmequellen",
                accumulators_no: "Anzahl der Speicher",
                heat_exchangers_no: "Anzahl der Wärmetauscher",
                connection_type: "Verbindungstyp",
                parallel: "Parallele Verbindung",
                serial: "Serielle Verbindung",
                heat_exchanger_position: "Wärmetauscherposition: Vor dem Speicher",
                additional_heat_parts_no: "Anzahl zusätzlicher Heizkörper",
                existing_heat_parts: "Vorhandene Heizkörper verwenden",
                generate: "Generieren",
                updatedBy: "Aktualisiert von",
                createdBy: "Erstellt von",
                fromYear: "Ab Jahr",
                energyResources: "Energieressourcen",
                g_co2_kWh: "Gr. CO2 kWh",
                cent_kWh: "Cent kWh",
                from_date: "Von Datum",
                addResource: "Ressource hinzufügen",
                editResource: "Ressource bearbeiten",
                orgAddInfo: "Organisations-Schlüssel für Gebäude",
                addBCD: "Jährliche Verbrauchsdaten hinzufügen",
                related_data: "zugehöriges Datum",
                buildingConsumption: "Gebäudeverbrauch",
                resourceBeforeConsumption: "Um Ihre Verbrauchsdaten einzugeben, fügen Sie bitte zuerst eine Energiequelle hinzu.",
                energyResourceDeletedSuccessfully: "Die Energieressource wurde erfolgreich gelöscht",
                energyResourceDeleteQuestion: "Möchten Sie die Energieressource wirklich löschen?",
                energyResourceSuccess: "Die Energieressource wurde erfolreich angelegt!",
                energyResourceDelete: "Energieressource löschen",
                groupNfilteringPanel: "Gruppieren und Filtern",
                apply: "Anwenden",
                groupBy: "Gruppieren nach...",
                field: "Feld",
                operator: "Operator",
                clearGrouping: "Gruppierung aufheben",
                addFilter: "Filter hinzufügen",
                resetFilters: "Filter zurücksetzen",
                energyReport: "Energiebericht",
                reportPostedSuccessfully: "Energiebericht wurde erfolgreich erstellt.", 
                selectYear: "Wähle ein Jahr",
                introduction: "Einführung",
                energyResourceInfo: "Die CO2-Emission muss bis zum 1. Januar #### erfasst werden, damit sie als Energiequelle genutzt werden kann.",
                energyResourceTypeYearWarning: "Eine Energiequelle mit dem gleichen Typ und Jahr existiert bereits. Bitte wähle ein anderes Jahr oder einen anderen Typ.",
                addEnergyReport: "Energiebericht hinzufügen",
                oneDay: "Ein Tag",
                oneWeek: "Eine Woche",
                ninetyDays: "90 Tage",
                oneYear: "Ein Jahr",
                results: "Ergebnisse",
                set_flow_temperature: "Maximale Vorlauftemperatur einstellen",
                heating: "Heizung",
                current: "Strom",
                scenario_flow_temp_calculated_kv: "Bei Vorlauftemperatur des Szenarios und berechnetem Kv-Wert",
                offlineMode: "Offline-Modus",
                offlineModeReadmeTitle: "Wichtige Information - Bitte aufmerksam lesen",
                offlineModeReadmeText: "Dieser Assistent führt Sie durch die Schritte, um die App für die Offline-Nutzung einzurichten. Es ist wichtig zu wissen, dass Sie für die ausgewählte Gebäude exklusiv für 2 Stunden Offline-Berechtigungen haben. Wenn Sie innerhalb dieser Zeit nicht wieder online gehen, wird die Berechtigung widerrufen, und alle Benutzer in der Organisation erhalten wieder Zugriff auf die Gebäude.",
                offlineModeDownloadCatalogs: "Kataloge herunterladen",
                offlineModeDownload: "Bitte laden Sie die Kataloge herunter, die für die Offline-Nutzung benötigt werden.",
                offlineModeFinalStep: "Letzter Schritt",
                offlineModeFinalStepNotice: "Sobald Sie auf \"Offline gehen\" klicken, können Sie ausschließlich am ausgewählten Gebäude arbeiten. Wenn Sie mit der Dateneingabe fertig sind, klicken Sie einfach auf \"Online gehen\" im Gebäudedashboard, und Ihre Daten werden automatisch auf den Server hochgeladen.",
                goOffline: "Offline gehen",
                deleteVariantConflict: "Um diese Variante zu löschen, müssen Sie zuerst ihre zugehörigen Unterelemente löschen.",
                alarms: "Alarme",
                measurement: "Messung",
                threshhold: "Schwellenwert",
                alarmType: "Alarmtyp",
                lastExecuted: "Zuletzt ausgeführt",
                editAlarm: "Alarm bearbeiten",
                deleteAlarm: "Alarm löschen",
                deleteAlarmQuestion: "Sind Sie sicher, dass Sie diesen Alarm löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
                alarmDeleted: "Der Alarm wurde erfolgreich gelöscht!",
                alarmDeviceAssignInfo: "Um einen Alarm hinzuzufügen, stellen Sie bitte sicher, dass dem Gebäude ein Gerät zugewiesen ist.",
                addAlarm: "Alarm hinzufügen",
                editAlarm: "Alarm bearbeiten",
                device: "Gerät",
                noAlarms: "Es sind derzeit keine Alarme für diese Messung eingestellt.",
                underfloorheating: "Fußbodenheizung",
                configurationLimit: "Es sind nur Zahlen, Fließkommazahlen (mit . oder ,), -1 oder N erlaubt.",

            }
        },
        en: {
            translation: {
                login: "Log in",
                emailAddress: "E-Mail Address",
                password: "Password",
                stayLoggedIn: "Stay logged in",
                forgotPassword: "Forgot Password?",
                loginAlerts: {
                    validEmailAddress: "Please enter a valid email address in order to log in.",
                    validPassword: "Please enter a valid password in order to log in.",
                    validCredentials: "The email or the password you have provided is incorrect. Please verify your input.",
                },
                resetPassword: "Reset your password",
                resetEmail: "We will email you instructions to reset your password.",
                reset: "Reset",
                resetAlerts: {
                    associatedEmail: "To reset your password, please enter a valid email address associated with your account.",
                    validEmail: "The entered email address is not valid. If that may not be the issue please contact us.",
                    instructionsEmail: "You can expect to receive an email shortly containing further instructions to reset your password.",
                    tokenExpired: "Your Token has expired. Please obtain a new one.",
                    tokenValidation: "The Token you are using is not valid. Please use a valid Token.",
                    passwordSecurity: "For a secure password, ensure it includes lowercase and uppercase letters, numbers, and special characters.",
                },
                temperatureCorrection:"Temperature Correction (f1)",
                setNewPassword: "Set new password",
                resetInstructions: "To change the password, you must enter the new password twice below. After changing the password you will be logged out and have to log in again with the new password.",
                newPassword: "New password",
                newPasswordAgain: "New password again",
                confirm: "Confirm",
                resetSuccessfully: "Your password has been successfully changed. You can now proceed to log in using the new password.",
                privacy: "Privacy",
                imprint: "Imprint",
                selectOrganisation: "Select the organization you want to access",
                name: "Name",
                customerName: "Customer Name", 
                prename: "Prename",
                lastName: "Last Name",
                street: "Street",
                housenumber: "House Number",
                zip: "Postal Code",
                city: "City",
                previous: "Previous",
                back: "Back",
                next: "Next",
                expertMode: "Expert mode",
                downloadTemplateAs: "Download template as:",
                addColumn: "Add column",
                addRow: "Add row",
                pdfExport: "Export as PDF",
                selectFormExcelFile: "Select a column from the uploaded excel file:",
                addNewColumn: "Add new column",
                columnName: "Column Name",
                add: "Add",
                select: "Select",
                selectAll: "Select all",
                noDaughterOrganisations: "This organization does not have any sub-organizations.",
                organisations: "Organisations",
                changeOrganisation: "Change organisation",
                leaveOrganisation: "Leave organisation",
                deleteOrg: "Delete organisation", 
                organisationProfile: "Organisation's profile",
                onboarding: "Onboarding Process",
                changeLanguage: "Change Language",
                changeTheme: "Change Theme",
                light: "Light",
                dark: "Dark",
                german: "German",
                english: "English",
                settings: "Settings",
                logout: "Logout",
                homepage: "Homepage",
                buildings: "Buildings",
                overview: "Overview",
                devices: "Devices",
                noEnergyReports: "There are no Energy Reports available.",
                energyReports: "Energy Reports",
                totheEnergyReport: "to the Energy Report",
                buildingLogBook: "Building Log Book",
                floors: "Floors",
                usageUnits: "Usage units",
                unitPost: "Your unit part has been securely saved in the database.",
                unitPostError: "There was a problem creating the usage unit! Please make sure that the name of the usage unit is not assigned twice.",
                unitPatch: "Congratulations, your unit was skillfully edited.",
                unitMessage: "Create a floor first!",
                roomPost: "The room has been saved.",
                roomPatch: "The room has been successfully edited.",
                rooms: "Rooms",
                plants: "Plants",
                contactPerson: "Contact Person",
                editContactPerson: "Contact person has been successfully edited!",
                deleteContactPerson: "Delete Contact Person",
                deleteContactPersonQuestion: "Are you sure you want to delete this contact person?",
                deleteContactPersonSuccess: "Contact Person has been successfully deleted.",
                images: "Images",
                gallery: "Image gallery",
                building_lifecycle_chat:"Building-Lifecycle-Chat",
                release: "Release",
                buyProducts: "Buy Products",
                bills: "Bills",
                buildingMap: "Building Map",
                singleBuilding: "Single building",
                buildinglist: "Building list",
                buildinglistComplex: "Complex Building List",
                markersOnly: "Markers Only",
                consumptionMap: "Consumption Map",
                heatingloadMap: "Heating Distribution",
                alarmMap: "Alarm Map",
                totheOrganization: "To the Organization",
                totheBuilding: "To the Building",
                noBuildings: "There are no buildings to be listed.",
                buildingsOverview: "Buildings Overview",
                building: "Building",
                inThe: "in the",
                property: "Property",
                properties: "Properties",
                buildingsWithoutProperty: "Buildings Without Property",
                addProperty: "Add Property",
                editProperty: "Edit Property",
                addBuildingWithProperty: "Add Building With Property",
                addBuildingWithoutProperty: "Add Building Without Property",
                addBuilding: "Add Building",
                editBuilding: "Edit Building",
                startDesignFor: "Start Design for",
                delete: "Delete",
                deleteProperty: "Delete Property",
                deleteBuilding: "Delete Building",
                deleteBuildingQuestion: "Are you sure you want to delete this building?",
                enableDnD: "Enable Drag and Drop",
                deletePrompt: "Are you sure, that you want to delete this {{type}}?",
                heatingLossSum: "Total heating Load",
                thermalPower: "Available System Performance",
                possiblePower: "potential performance",
                heatedSurface: "Heated Surface",
                actions: "Actions",
                address: "Address",
                search: "Search",
                edit: "Edit",
                noEdit: "This element cannot be edited.",
                propertyAlerts: {
                    add: "The property was successfully created.",
                    edit: "The property was successfully edited."
                },
                responseError: "There was an issue processing your query. Please try again. If the problem persist then contact and web administrators.",
                constructionYear: "Construction Year",
                yearOfModernization: "Year of Modernization",
                close: "Close",
                modTypes: {
                    roof: "Roof",
                    window: "Window",
                    wall: "Outer Wall",
                    doors: "Exterior Doors",
                    groundEarth: "Against Earth",
                    groundUnheated: "Against Unheated (internal)",
                    pipe: "Pipe Insulation Heating",
                    plant: "Heating System Replacement",
                },
                energyTypes: {
                    dh: "District Heating",
                    gas: "Natural Gas",
                    gas_01: "gas",
                    whp: "Ground Collector",
                    gwhp: "Geothermal",
                    awhp: "Air/Water",
                    ahp: "Air/Air",
                    oil: "Oil",
                    other: "Other",
                },
                controltypes: {
                    noControl: "no regulatory body",
                    threeValveFlow: "3-way valve in the flow",
                    threeValveReturn: "3-way valve in the return line",
                    twoValvePrimReturn: "2-way valve in the primary RL with bypass",
                    fourValve: "4-way valve"
                },
                area: "Surface",
                areas: "Surfaces",
                subtractFromSurface: "Subtract from surface?",
                heatedArea: "Heated Area (m²)",
                unheatedArea: "Unheated Area (m²)",
                avg: "Average",
                avgTempHeated: "Average Temperature of Heated Area (°C)",
                avgTempUnheated: "Average Temperature of Unheated Area (°C)",
                levelCountWithoutCellar: "Number of Floors (Excluding Basement Floors)",
                cellarLevels: "Basement Floors",
                heatedCellarLevels: "Heated Basement Floors",
                appartmentCount: "Number of Residential Units (WE)",
                noAssignment: "No Assignment",
                belongsToNoAppartment: "No Assignment",
                belongsToNoRoom: "No Assignment",
                avgRoomCount: "Average Number of Rooms per Residential Unit",
                roomHeight: "Room Height (net in meters)",
                roomHeightWithoutUnit: "Room Height",
                windowSharesHeated: "Window Share in Heated Area (%)",
                windowSharesUnheated: "Window Share in Unheated Area (%)",
                volume: "Volume (m³)",
                additionalVolume: "Additional Volume (in m³)",
                avgAreaAppartment: "Average Area per Residential Unit (WE)",
                avgAreaLevel: "Average Area per Floor",
                buildingWidth: "Building Width (in meters)",
                buildingDepth: "Building Depth (in meters)",
                buildingHeight: "Building Height (in meters)",
                lastModernizations: "Last Modernizations",
                year: "Year",
                modernizationType: "Component",
                newUValue: "New U-Value (W per m² and Kelvin)",
                UValueAccordingTo: "U-Value according to",
                heatingSystem: "Heating System",
                statisticallyRequiredHeatingPower: "Statistically Required Heating Power in Calendar Week",
                statisticalConsumption: "Statistical Consumption in an Average Year",
                heatingConsumption: "Consumption for Heat Generation (kWh)",
                heatingPlants: "Heat Generators",
                manufacturer: "Manufacturer",
                manufacturerType: "Manufacturer Type",
                energyType: "Energy Source",
                energySavingPotential: "Energy Saving Potential",
                power: "Power (kW)",
                heatingCircuits: "Heating Circuits",
                controlType: "Control Type",
                strands:"Strands",
                countHeatingStrings: "Number of Strings",
                pumpManufacturer: "Pump Manufacturer",
                countRadiators: "Number of Radiators",
                adjustableValves: "Adjustable Radiator Valves",
                heatPuffer: "Heat Buffer",
                volumeHeatPuffer: "Volume of Heat Buffer",
                centralHotWaterSystem: "Central Hot Water System",
                volumeHotWater: "Liter Hot Water Tank",
                centralFreshWaterStation: "Separate Drinking Water / Fresh Water Control",
                countWarmWaterStrings: "Number of Hot Water Strings",
                countHotWaterTapsPerAppartment: "Number of Taps per Residential Unit (WE)",
                countHotWaterTaps: "Number of Hot Water Taps",
                send: "Send",
                energyClass: "Energy Class",
                eeClasses: {
                    classAPlus: "Passive House",
                    classA: "3-Liter House",
                    classB: "Low-Energy House",
                    classC: "GEG Standard New Building",
                    classD: "Approx. 1990 Standard (tbd)",
                    classE: "2nd WSV Standard 1982",
                    classF: "At Most 2nd WSV Standard 1982",
                    classG: "1st WSV Standard 1977",
                    classH: "Historical Preservation or Poorly Insulated"
                },
                noValuesAvailable: "No values available.",
                noValues: "no values",
                toTheDetails: "To the details",
                deleteImage: "Delete image",
                error: "Error",
                lockedErrorEditCopy: "You are not allowed to edit or copy this content. If necessary, you can restore it.",
                lockedErrorPost: "Adding additional elements is not permitted. To enable this, you need to restore the parent element.",
                manageProfileImage: "Managing Profile Picture",
                deletingProfileIMGSuccessfully: "Success! The image has been deleted from your profile.",
                deletingProfileIMGUnsuccessfully: "Uh-oh! We encountered a problem while trying to delete the image. Please retry later or get in touch with our support team for assistance.",
                uploadPhoto: "Upload Photo",
                buildingData: "Building Data",
                information: "Information",
                additionalInformations: "Additional Information",
                additionalInformationsAdd: "Add Additional Information",
                additionalInformationsDelete: "Delete Additional Information?",
                additionalInformationsDeleteQuestion: "Are you sure you want to delete this information entry?",
                deleteBuildingsImageProfile: "Delete Building's Profile Image?",
                deleteBuildingsImageProfileQuestion: "Are you sure you want to delete the building's profile image?",
                profileImage: "Profile Image",
                key: "Key",
                value: "Value",
                created: "Created",
                lastUpdate: "Last update",
                editDelete: "Edit / Delete",
                loadNMoreItems: "Load {{n}} more Items",
                solveEquation: "Please solve this math problem to continue.",
                incorrectSolution: "Please try again.",
                howMuch: "How much it is",
                yes: "Yes",
                no: "No",
                map: "Map",
                setMarker: "Set Marker new",
                unsetMarker: "Fix Marker",
                abortSetMarker: "Cancel Marker Setting",
                goToGoogleMaps: "Open Marker in GoogleMaps",
                latitude: "Breitengrad",
                longitude: "Längengrad",
                description: "Description",
                heatingCurve: "Heating Curve",
                individualDiagram: "Individual diagram",
                deviceName: "Device Name",
                deviceDescription: "Device Description",
                deviceActivation: "Device Activation",
                deviceReadings: "Device Readings",
                change: "Change",
                changeDeviceName: "Change Device Name",
                changeDeviceDescription: "Change Device Description",
                assignActivationDataDevice: "Assign Activation Data for Device",
                changeDeviceReadings: "Change Device Readings",
                noDevices: "There are no devices to be listed.",
                minimalValue: "Minimal Value",
                average: "Average",
                maximalValue: "Maximal Value",
                maximalFlowTemperature: "Max. Flow Temperature",
                minutely: "Minutely",
                minute: "Minute",
                quarterhourly: "Quarterhourly",
                hourly: "Hourly",
                daily: "Daily",
                weekly: "Weekly",
                cableNumber: "Cable Number",
                metric: "Metric",
                measurementPoint: "Measurement Point",
                addEditMeasurementPoint: "Add/ Edit Measurement Point",
                addMeasurementPoints: "Add Measurement Point",
                deleteMeasurementPoint: "Delete Measurement Point",
                deleteMeasurementPointQuestion: "Are you sure you want to delete this measurement point?",
                deleteMeasurementPointSuccessful: "The measurement point has been successfully deleted.",
                noMeasurementPoints: "There are no measurement points available", 
                measurementPointAlreadyExists: "Measurement point already exists.", 
                preview: "Preview",
                plot: "Plot",
                aggregation: "Aggregation",
                interval: "Interval",
                reload: "Reload",
                downloadAsCSV: "Download as CSV",
                switch: "Switch",
                switchTo: "Switch to",
                measurementName: "Measurement Name",
                chartType: "Diagramm Typ",
                lineChart: "Line Chart",
                barChart: "Bar Chart",
                period: "Period",
                addLogEntry: "Add Log Entry",
                buildingLogDeletedSuccessfully: "The Building Log was successfully deleted.",
                hpLogDeletedSuccessfully: "The Heating Part Log was successfully deleted.",
                title: "Title",
                author: "Author",
                updatedAt: "Last Updated",
                createdAt: "Created On",
                logs: "Building Logs",
                library: "Library",
                noHP: "There are no Heating Parts to be listed.",
                noHPLogs: "There are no Heating Part Logs to be listed.",
                noBuildingLogs: "There are no Building Logs to be listed.",
                noLogs: "There are no Building Logs",
                addEditLog: "Add/Edit Building Log",
                hpLog: "Heating Part Log",
                addEditHPLog: "Add/Edit Heating Part Log",
                deleteHPLog: "Delete Heating Part Log?",
                deleteLog: "Delete Building Log?",
                deleteLogQuestion: "Are you sure you want to delete this building log?",
                deleteHPLogQuestion: "Are you sure you want to delete this heating part log?",
                copyContent: "Copy Content",
                copyContentWithoutPictures: "Copy Content without Pictures",
                calculationType: "Calculation Type",
                calculationTypes: "Calculation Types",
                simpleOptimisation: "Simple Optimization",
                classicCalculation: "Classic Calculation",
                withRadiatorReplacement: "Optimization with radiator replacement",
                calculateMaximalTemperature: "Calculation to a maximum temperature",
                lowTemperatureReadyness: "Complete Low Temperature Readyness Design",
                number: "Number",
                calculate: "Calculate",
                calculateNow: "Calculate now",
                calculateDIN12831: "Calculate the DIN 12831",
                entranceLevel: "Entrance Level",
                unit: "Unit",
                addUnit: "Add Unit",
                deleteUnit: "Delete Unit",
                deleteUnitQuestion: "Are you sure you want to delete this unit?",
                deletedUnit: "The unit has been successfully deleted.",
                calculatedArea: "Calculated Area (m²)",
                heatingLoad: "Heating Load",
                unknown:"Unknown",
                heatingLoadPerFloor: "Heating Load Per Floor",
                utilizationUnits: "Utilization Units",
                noUnits: "There are no units to be listed.",
                unitAddEdit: "Unit Add / Edit",
                addRoom: "Add Room",
                copyUnit: "Copy Unit",
                noRooms: "There are no rooms to be listed.",
                roomName: "Room Name",
                heatingLoadPerM2: "Specific Heating Load",
                roomTemperature: "Room Temperature",
                airExchangeRate: "Air Exchange Rate",
                partTable: "Component and Radiator Table",
                addBuildingpartTable: "Add Component Table Entry",
                addBuildingpart: "Add Buildingpart",
                editBuildingpart: "Edit Buildingpart",
                copyRoom: "Copy Room",
                deleteRoom: "Delete Room",
                deleteRoomQuestion: "Are you sure you want to delete this room?",
                deletedRoom: "The room has been successfully deleted.",
                successulLogPostSubmit: "Success! Your Building Log is now securely saved.",
                successulHpLogPostSubmit: "Success! Your Heating Part Log is now securely saved.",
                successulLogEditSubmit: "Your changes to Building Log have been successfully preserved.",
                successulHpLogEditSubmit: "Your changes to Heating Part Log have been successfully preserved.",
                fetchingImageError: "Unfortunately, we couldn't retrieve the latest image source this time.",
                updatingImageSourceError: "Enhancing image sources encountered an issue.",
                autosaveQuery: "Experience automatic content-saving by enabling the feature: Your work will be securely saved just 10 seconds after your last edit.",
                content: "Content",
                save: "Save",
                delete_variant: {
                    existing_schemes: "Schemes are still available."
                },
                imageUploadError: "Oops, we encountered a little hiccup while uploading the file: {{file}}",
                f1GenericTerms2017: {
                    exteriorWall: 'Interior Wall Adjacent to Unheated Space with 1 Exterior Wall',
                    exteriorWallNoDoors: 'Interior Wall Adjacent to Unheated Space with 2 Exterior Walls and No Exterior Doors',
                    exteriorWallWithDoors: 'Interior Wall Adjacent to Unheated Space with 2 Exterior Walls and Exterior Doors',
                    exteriorWalls: 'Interior Wall Adjacent to Unheated Space with 3 or More Exterior Walls; e.g., Exterior Stairwells (Heated)',
                    floorAgainstBasementNoDoorsWindows: 'Floor Against Basement with No Exterior Doors/Windows',
                    floorAgainstBasementWithDoorsWindows: 'Floor Against Basement with Exterior Doors/Windows',
                    atticHighAirExchangeRateNoContinuousCovering: 'Against Attic with High Air Exchange Rate in the Attic, e.g., Roofs without Continuous Covering (Roof Tiles, etc.) and Without Sealing Underlay',
                    againstUninsulatedRoofs: 'Against Attic and Other Uninsulated Roofs',
                    againstInsulatedRoof: 'Against Attic and Insulated Roof',
                    interiorSpaceLowAirExchangeRate: 'Against Interior Space with Low Air Exchange Rate (≤ 0.5 h⁻¹), No Exterior Walls',
                    trafficAreasWithAirflow: 'Against Traffic Areas with Airflow (A/V) > 0.005',
                    elevatedFloorAboveCrawlSpace: 'Against Elevated Floor (Floor Above a Crawl Space)',
                    floorAgainstEarth: 'Floor Against Earth'
                },
                networkError: "Oops, we encountered a network response that needs some attention.",
                buildingPart: "Building Part",
                buildingParts: "Building Parts",
                standardBuildingParts: "Variant Parts",
                buildingPartPost: "Your building part has been securely saved in the database.",
                buildingPartPatch: "Congratulations, your building part was skillfully edited.",
                material: "Material",
                structure: "Structure",
                insulation: "Insulation",
                glazing: "Glazing", 
                components: "Components",
                basicData: "Basic Data",
                calculationData: "Calculation Data",
                procedure: "Procedure",
                type: "Type",
                uValue: "U-Value",
                length: "Length",
                width: "Width",
                distanceFromFloor: "Distance From Floor",
                direction: "Direction",
                adjacentType: "Adjacent type",
                isoF2SoilDE: "(ISO...) f2 for soil in DE",
                adjacentTemperatur: "Adjacent temperature (°C)",
                deductionArea: "Deduction area of",
                thermalBridgeAddition: "Thermal bridge addition (W/m²/K)",
                transmissionLoss: "Transmission Heat Loss",
                typology: "Typology",
                catalogue: "Catalogue",
                individual: "Individual",
                floor: "Floor",
                wall: "Wall",
                window: "Window",
                heatpart_count: "Count of Radiator",
                componentSurface: "Component's Surface",
                defaultSelectExample: "Default select example",
                temperature: "Temperature",
                thermalBridge: "Thermal bridge",
                radiator: "Radiator",
                noValves: "No valves available",
                valve: "Valve",
                designType: "Design Type",
                radiatorvalve: "Radiator Valve",
                pump: "Pump",
                pipe: "Pipe",
                hot_water_cylinder: "Hot Water Cylinder", 
                heat_exchanger: "Heat Exchanger", 
                buffer_storage: "Buffer Storage", 
                sensor_act: "Sensor Actor", 
                sortOf: "Sort of",
                depth: "Depth",
                height: "Height",
                strand: "Strand",
                noStrandSelected:"No Strand selected",
                distance: "Distance",
                adjustable: "Adjustable",
                architecturalForm: "Architectural Form",
                diameterNominal: "Diameter Nominal",
                size: "Size",
                maxSize10MB: "The following files exceed the maximum size of 10 MB:",
                independent: "Independent",
                panelRadiator: "Flat Panel Radiator",
                panelRadiatorType: "Flat Panel Radiator Type",
                steelSectionalRadiator: "Steel Sectional Radiator",
                castIronSectionalRadiator: "Cast Iron Sectional Radiator", 
                castingSectionalRadiator: "Casting Sectional Radiator",
                bathroomRadiator: "Bathroom Radiator",
                benchRadiator: "Bench Radiator",
                pillarRadiator: "Column Radiator",
                thermalBridgeSurcharge: "Thermal bridge surcharge",
                likeRoomArea: "1:1 like room area",
                pipeDiameter: "Pipe Diameter",
                floorPost: "Your floor part has been securely saved in the database.",
                floorPatch: "Congratulations, your floor was skillfully edited.",
                floorNumber: "Floor number",
                plant: "Plant",
                plantPost: "Your plant part has been securely saved in the database.",
                plantPatch: "Congratulations, your plant was skillfully edited.",
                ventilation: "Ventilation",
                din276Main: {
                    long: {
                        heat: "Heat Supply",
                        power: "Power Supply",
                        ventilation: "Room Air Technology",
                        ventilationLoss: "Ventilation Heat Loss",
                        water: "Sewerage, Water, Gas Systems",
                        elevator: "Elevator Systems",
                        kitchen: "Kitchen Technical Systems",
                        laundry: "Laundry and Cleaning Systems",
                        fireSuppression: "Fire Extinguishing Systems",
                        automation: "Building and Plant Automation",
                        waste: "Waste Disposal Systems",
                        outdoorArea: "Outdoor Facilities and Open Spaces",
                        other: "Other"
                    },
                    short: {
                        heat: "Heat",
                        power: "Electricity",
                        ventilation: "RLT",
                        water: "Water",
                        elevator: "Elevator",
                        kitchen: "Kitchen",
                        laundry: "Laundry",
                        fireSuppression: "Fire Suppression System",
                        automation: "Automation",
                        waste: "Waste",
                        outdoorArea: "Outdoor Facilities",
                        other: "Other"
                    }
                },
                calculate_bad_request: {
                    missing_outerwall: "A standard reference for the outer wall is missing",
                    missing_floor: "A standard reference for the floor is missing",
                    missing_window: "A standard reference for the windows is missing",
                    no_radiator_throttle: "Radiator valve is missing",
                    error_calculation_found_no_config: "No calculation result available",
                    missing_variant: "A variant is missing.",
                },
                heatPower: "heating Power",
                normHeatpower:"Standard heating power",
                max_flow_temperature: "Max. Flow Temperature",
                Max_Return_Temperature: "Max. Return Temperature",
                corridor: "Corridor",
                livingRoom: "Living Room",
                kitchen: "Kitchen",
                bath: "Bath",
                bedroom: "Bedroom",
                utilizationKey: "Usage unit",
                roomTypePreselection: "Room type preselection",
                sector: "Sector",
                noBuildingContacts: "There are no Building Contacts to be listed.",
                noContacts: "There are no Contacts to be listed",
                topic: "Topic",
                addContactPerson: "Add Contact Person",
                telephone: "Telephone",
                telephonenumbers: "Telephone numbers",
                comment: "Comment",
                performancePeriod: "Performance period",
                buildingInvoices: "Building Invoices",
                noInvoices: "There are no Building Invoices to be listed.",
                noInvoicesAvaible: "There are no invoices to be listed.",
                showInvoices: "View invoices",
                from_ab: "from",
                from: "from",
                from_1: "From",
                overlappingDates: "The new date value overlaps with existing date ranges.",
                to: "to",
                to_1: "To",
                left: "left",
                right: "right",
                notSpecified: "not specified",
                unknow: "Unknow",
                download: "Download",
                addLevel: "Add floor",
                editLevel: "Edit floor",
                drawLevel: "Add floor and draw",
                deleteLevel: "Delete floor",
                deleteLevelQuestion: "Are you sure you want to delete this floor?",
                floorDeleted: "The selected floor has been successfully removed.",
                floorDeletedError: "There was an issue deleting the floor!",
                roomBook: "Room book",
                noBuildingLevel: "There are no Building Levels to be listed.",
                appartment: "Residential unit (WE)",
                transmissionLossNotation: "Transmission",
                adjacentTemperature: "Adjacent T",
                addComponent: "Add Component",
                calculatedThermalPowerToAmbient: "Calculated power",
                calculatedVolumeFlow: "Calculated volume flow",
                designThermalPower: "Design performance",
                spreading: "Spreading",
                volumeWithoutUnit: "Volume",
                room: "Room",
                interpretation: "Interpretation",
                errorLoadingImageID: "Oops! We encountered a little hiccup when trying to load the image with the filename {{filename}}. Please bear with us while we sort this out.",
                buildingPictures: "Building Pictures",
                imageProcessing: 'Image Processing',
                downscaling: "Optimizing your image: {{filename}} for a smoother experience.",
                errorOptimisedFileAccess: "An error occurred while accessing the optimized file.",
                failedReadingFile: "Encountered difficulty reading the file.",
                failedFileUpload: "Sorry, there was an issue with uploading the file.",
                buildingGallery: "Building Gallery",
                // buildingPlant: "Building Plant",
                plantGallery: "Plant Gallery",
                plantData: "Plant Data",
                deleteDocument: "Delete Document",
                deleteDocumentQuestion: "Are you sure you want to delete this document?", 
                editDocument: "Edit Document",
                addDocument: "Add Document", 
                noDocuments: "There are no documents available.", 
                editedDocument: "The document was edited successfully.", 
                postDocument: "The document was uploaded successfully.",
                document: "Document", 
                documents: "Documents",
                extractedWordsFromFile : "Extracted Words from File",  
                noExtractedWordsFromFile : "There are no Extracted Words from File.",
                files: "Files",
                file_type: "File type",
                events: "Events",
                climateTarget: "Climate Target For",
                climateImpact: "Climate Impact",
                development: "Related to the development",
                moderateReduction: "Moderate reduction achieved",
                logBook: "Logbook",
                measurementData: "Measurement Data",
                is_main_counter: "Main Counter",
                counter: "Counter",
                counters: "Counters",
                addCounter: "Add Counter",
                addCounterSuccess: "The counter has been successfully added!",
                editCounterSuccess: "The counter has been successfully edited!",
                editCounter: "Edit Counter",
                deleteCounter: "Delete Counter",
                deleteCounterQuestion: "Are you sure you want to delete this counter?", 
                deleteCounterSuccess: "The counter has been successfully deleted.", 
                mainCounter: "Main Counter",
                noBuildingCounters: "There are no Counters to be listed.",
                counterReading: "Counter Reading",
                counterReadings: "Counter Readings",
                addCounterReading: "Add Counter Reading",
                addCounterReadingSuccess: "The counter reading has been successfully added!", 
                editCounterReading: "Edit Counter Reading",
                editCounterReadingSuccess: "The counter reading has been successfully edited.",
                noCounterReading: "There are no Counter Readings to be listed.",
                deleteCounterReading: "Delete Counter Reading",
                deleteCounterReadingQuestion: "Are you sure you want to delete this Counter Reading?",
                deleteCounterReadingSuccess: "The Counter Reading has been successfully deleted.",
                deleteConsumption: "Delete Consumption",
                deleteConsumptionQuestion: "Are you sure you want to delete this Consumption?",
                deleteConsumptionSuccess: "The Consumption has been successfully deleted.",
                scheme: "Scheme",
                continueWorkingQuestion: "Would you like to carry on from where you left off?",
                dataSuccess: "Your information has been saved successfully!",
                allComponents: "All Components",
                measuringDevice: "Measuring Device",
                wallBoxes: "Wallboxes",
                successfulPlantDelete: "The plant has been successfully deleted.",
                deletePlant: "Delete plant",
                deletePlantQuestion: "Are you sure you want to delete this plant?",
                benefits: "Benefits",
                yearlyPriceM2: "Price per m² and year",
                assign: "Assign",
                generateOffer: "Generate offer",
                contractPeriod: "Contract Period",
                oncePrice: "One-off price",
                sum: "Sum",
                infoPaper: "Information material",
                buy: "Buy",
                addProductsBuilding: "Add products to building",
                noProducts: "There are no Products to be listed.",
                years: "Years",
                withoutTerm: "without term",
                none: "none",
                downloadBrochure: "Download Brochure",
                buildingSettings: "Building Settings",
                anlageName: "Plant Name",
                addPlant: "Add Plant",
                noPlants: "There are no Plants to be listed.",
                ctrlScrollZoom: "Enhance your experience with effortless zooming – just press CTRL and scroll!",
                loading: "Loading",
                planned_start_date: "Planned start date",
                planned_end_date: "Planned end date",
                date: "Date",
                noDataAvailable: "No Data available",
                scatterPlotData: "Scatter plot of data",
                derivedFunction: "Derived function",
                level: "Level",
                slope: "Slope",
                label: "Label",
                xAxisStart: "xAxis Start",
                xAxisEnd: "xAxis End",
                yAxisStart: "yAxis Start",
                yAxisEnd: "yAxisEnd",
                selectLabelDataset: "Select Label Dataset",
                from_catalog: "From Catalog",
                submit: "Submit",
                cookieMessage: "To unlock our service's full potential, kindly grant permission for data processing.",
                here: "Here",
                findPrivayPolicy: "you can find our Privacy Policy.",
                allow: 'Erlauben',
                imageFiles: "Image Files!",
                imageFilesText: "To begin the upload process, please choose some images.",
                uploading: "Uploading",
                uploadingText: "We're currently uploading the selected images. Once this is complete, you'll be able to upload additional images.",
                uploadedText: "The images have been successfully uploaded. They will be displayed in the buildings gallery after you close this popup.",
                selectorDnD: "Select a File or Drag and Drop Your Images",
                imageDescription: "Add Image Description",
                uploadImages: "Upload images",
                noImagesMessage: "We're sorry, but currently there are no images to display. You can get started by uploading your own images.",
                noFloorRooms: "There are no Rooms to be listed.",
                noBuildingParts: "There are no Building Parts to be listed.",
                noBuildingPartsWithThisType: "There are no Building Parts of this type.",
                selectBuildingPart: "Please select a Building Part.",
                deletedBuildingpart: "Buildingpart was deleted successfully.",
                AddBuildingPartVariant: "Add standard component to variant.",
                postBuildingPartVariant: "Standard component added to the variant.",
                patchBuildingPartVariant: "Standard component changed.",
                deletedBuildingPartVariant: "Standard component deleted.",
                deleteBuildingPartVariant: "Delete Standard component",
                deleteBuildingPartVariantQuestion: "Are you sure you want to delete this standard component?",
                exchange: "Exchange from",
                edit_individual_bp: "Edit Individual Buidling Parts",
                edit_all_individual_bp :"Edit individual buidling parts with this type?",
                individual_bp: "Individual Buidling Parts",
                noIndividualBuildingParts: "No Individual Buidling Parts",
                noIndividualBuildingPartsType: "There are no individual building parts of this type available.",
                heatingPartCataloges: "Heating part Catalog",
                add_heatpart: "Add heating part",
                noHeatingParts: "There are no heating parts to be listed.",
                HeatingPart: "Heating part",
                HeatingParts: "Heating parts",
                unusedHP: "Add",
                usedHP: "Used",
                usedHeatingParts: "The heating part of the building that have already been used in the scheme.",
                notUsedHeatingParts: "The heating part of the building that can still be added to the scheme.",
                heatingPartPatch: "The heating part has been successfully edited.",
                year_consumption: "Annual consumption",
                virtualgroup: "Virtual Calculation-Group",
                virtual_calculation_group: "Virtual Calculation-Group",
                deductionSurfaceWithoutLoss: "Deduction Surface Without Loss",
                calculation_result: "Calculation results",
                flow_min: "Minimum flow temperature",
                radiator_throttle: "Thermostatic Valve",
                radiator_valve: "Thermostatic Valve",
                axial: "Axial",
                passageway: "Straight",
                corner: "Angle",
                'angledcorner(right)': "Angle, r-h connection", 
                'angledcorner(left)': "Angle, l-h connection",  
                performanceIndicator: "Performance Indicator",
                multiples_standard_ref_power: "Number of sections",
                multiples_standard_ref_power_meter: "Length in meters",
                subtypes: "Subtype",
                distance_to_pump: "Distance to the pump",
                overbuilt: "Overbuilt",
                distance_overbuilt: "Distance overbuilt in cm",
                count: "Number",
                resulting_count_heatparts: "Number of usable radiators",
                mass_flow: "Mass flow",
                target_mass_flow: "Mass flow",
                target_flow_temp: "Flow temp.",
                flow_temperature: "Flow temperature",
                return_temperature: "Return temperature",
                radiatorTooSmallToMaintainReturnTemperature: "The radiator is too small to maintain the max. return temperature of {{maxReturnTemperature}}.",
                upload: "Upload",
                copy: "Copy",
                moreInfo: "Show Details",
                saveChanges: "Save Changes",
                country: "Country",
                editPhoneNumber: "Edit Phone Number",
                userSettings: "User Settings",
                organisationUsersList: "Organisation-Users",
                orgSettings: "Organisation-Settings",
                organisation: "Organisation",
                relationDeleted: "The relationship with the parent organization has been successfully terminated.",
                deleteRelation: "Delete Relation",
                deleteRelationQuestion: "Are you sure that you want to delete this relation?", 
                organisationHasRights: "Has rights?",
                organisationShouldHaveRights: "Should the parent organization have rights over the daughter organization?",
                deletePhoneNumber: "Delete Phone Number?",
                phoneNumberDeleteQuestion: "Are you sure that you want to delete this phone number?",
                phoneNumberDeleted: "The selected phone number has been successfully removed.",
                editAdministrator: "Edit Administrator",
                deleteAdministrator: "Remove Administrator?",
                administratorDeleteQuestion: "Are you sure that you want to remove this Administrator?",
                administratorDeleted: "The selected organization was left.",
                leaveOrganisationQuestion: "Are you sure you want to leave this organization?",
                leftOrganization: "The organization has been left!",
                organisationAddress: "Organisation's Address",
                organisationAdministratorsList: "Organisation's Administrators List",
                paymentMethods: "Payment Methods",
                paymentMethod: "Payment Method",
                editPaymentMethod: "Edit Payment Method",
                deletePaymentMethod: "Delete Payment Method?",
                paymentMethodDeleteQuestion: "Are you sure that you want to remove this payment method?",
                paymentMethodDeleted: "The selected payment method has been successfully removed.",
                authorizePayments: "I authorize SigmaHeat GmbH to debit payments from my account via direct debit. At the same time, I instruct my financial institution to honor the direct debits drawn by SigmaHeat GmbH from my account.",
                verifyIBAN: "Please verify your IBAN number.",
                subscriptions: "Subscriptions",
                invoice: "Invoice",
                invoiceNumber: "Invoice number",
                amount: "Amount",
                addTask: "Add Task",
                fileRequest: "File Request",
                fileRequestSuccess: "The file was successfully requested.",
                documentRequest: "Document Request",
                documentName: "Document Name",
                purpose: "Purpose",
                subsidiesCatalog: "Subsidies Catalog",
                subsidies: "Subsidies",
                subsidy_from_catalog: "Subsidies from catalog",
                requested_funding: "Requested funding (in euros)",
                costs_for_the_customer: "Customer's own funds (in euros)",
                funding_ratio: "Funding ratio",
                approved_subsidies: "Approved costs (in euros)",
                new_costs_for_the_customer: "New customer's own funds (in euros)",
                cash_state: "Payout status",
                application: "Application",
                applied: "Applied",
                approved: "Approved",
                canceled: "Canceled",
                completed: "Completed",
                early_start: "Early Start",
                in_planning: "In Planning",
                planned: "Planned",
                rejected: "Rejected",
                editSubsidy: "Edit Subsidy",
                addSubsidy: "Add Subsidy",
                deleteSubsidy: "Delete Subsidy",  
                requestedSubsidies: "Requested Subsidies", 
                deleteSubsidyQuestion: "Are you sure that you want to remove this subsidy?", 
                patchSubsidy: "The subsidy has been successfully edited.",
                postSubsidy: "The subsidy has been successfully created.",
                noSubsidies: "There are no subsidies available.", 
                supplier: "Supplier",
                required: "Required",
                noSelection: "No selection",
                notify: "Notify",
                requested: "Requested",   
                funding_sum: "Funding amount",   
                subscriptionEnd: "Subscription End",
                status: "Status",
                success: "Success",
                pending: "Pending",
                failure: "Failure",
                for_the:"for the",
                deleteOrganisation: "Delete Organisation?",
                organisationDeleteQuestion: "Are you sure that you want to remove this organisation?",
                organisationDeleted: "The selected organisation has been successfully removed.",
                somethingWentWrong: "Sorry! Something went wrong here!",
                errorBoundry: {
                    messageOne: "We're very sorry, if the error occurs again, please contact us by phone at",
                    messageTwo: "or per E-Mail at",
                    messageThree: "Thank you for your understanding!"
                },
                notMember: "Not a member?",
                createAccount: "Create account",
                companyName: "Company Name",
                companyType: "Company Type",
                alreadyMember: "Already a member?",
                companyTypes: {
                    housing: "Housing construction company / cooperative",
                    business: "Company / SME (Small and Medium-sized Enterprise)",
                    municipal: "Municipality / Municipal Authority",
                    private: "Private customer",
                    craftsman: "Craftsman"
                },
                enable: "Enable",
                continue: "Continue",
                verify: "Verify",
                twoFA: {
                    activate2FA: "Please complete the 2FA setup process to continue accessing your account and to ensure the highest level of security. If you have any questions or need assistance, our support team is here to help you through the setup process. Thank you for your cooperation in maintaining a secure environment for all members of organisations",
                    authenticator: "To enable 2FA, scan the QR code using Google Authenticator or Microsoft Authenticator",
                    manual: "or enter the code manually",
                    recoveryCodes: "Recovery codes are vital in case you lose access to your authenticator app. Store them securely, as you will need them if you lose your 2FA device. Keep your recovery codes in a safe place and do not share them with anyone. If you suspect any unauthorized access to your account, contact our support team immediately.",
                    sixDigit: "Enter 6-digit code from your authenticator application:",
                    recoveryUsage: "Lost your phone or can't access it? No worries! Use your recovery codes to regain access to your account. Remember, each code is a one-time lifeline.",
                },
                fiveMinLogTime: "Your 5-minute login session has expired. Time to log in again!",
                buildingPOST: "The building has been successfully created.",
                buildingPATCH: "The building has been successfully edited.",
                orgPOST: "The organisation has been successfully created.",
                fillOTP: "Kindly fill in all the OTP fields.",
                incorrectOTP: "Oops! It looks like the OTP you entered is incorrect. Please give it another shot.",
                fillCode: "Make sure to fill in all the fields for recovery codes.",
                incorrectCode: "Uh-oh! The recovery codes you entered don't match the ones in our database.",
                firstTimeSetup: "Initial setup requires only OTP validation.",
                deleteRowTitle: "Delete this row?",
                deleteRowQuestion: "Are you sure you want to proceed and delete this row?",
                twoFASuccess: "Great news! Your two-factor authentication is now all set up and ready to go!",
                passwordNoMatch: "Oops! It looks like the passwords don't match. Could you please type them again?",
                passwordComplexity: "Make sure your password includes a number, a lowercase letter, an uppercase letter, and a special character.",
                registrationNotPossible: "Right now, we're unable to process registrations. Please try again later. We appreciate your patience!",
                checkout: "Checkout",
                setupWizard: {
                    organisation: "Start your organization hassle-free. Enter details to begin.",
                    building: "Input building specifics. Streamline with ease.",
                    plan: "Find the perfect plan. Explore tailored options.",
                    summary: "Your Quick Order Summary",
                    checkout: "Secure checkout. Choose method, finalize purchase."
                },
                validationError: "The data didn't meet the validation test! Take a peek at the highlighted rows.",
                organisationValidation: {
                    street: "To proceed, please provide the street address of the organization.",
                    no: "To proceed, please enter the organization's street number.",
                    zip: "To continue, enter the organization's zip code.",
                    city: "To move forward, input the organization's city.",
                    terms: "To proceed, kindly accept the terms and conditions.",
                    pA: "To proceed, kindly accept the processing aggrement.",
                    pp: "To proceed, kindly accept the privacy and policy."
                },
                organisationSuccess: "Organisation was successfully created!",
                proceeding: "Proceeding",
                register: "Register",
                registerMessage: "Thank you for registering! In a moment you will receive an email, with the confirmation link for the account.",
                registration: {
                    prename: "In order to continue the registration process please enter your name!",
                    name: "In order to continue the registration process please enter your last name!",
                    password: "In order to continue the registration process please enter your desired password!",
                    password2nd: "In order to continue the registration process please retype your password!",
                    otp: "In order to continue the registration process please enter the otp that you've recevied in the given email address"
                },
                passwordStrength: "Password strength",
                applicationsSettings: "Applications Settings",
                securitySettings: "Security Settings",
                mobileNumbers: "Mobile Numbers",
                addMobileNumber: "Add phone number",
                profileImageDelete: "Delete profile picture?!",
                profileImageQuestionDelete: "Are you sure you want to delete your profile picture?",
                twoFAOrganisations: "Organisations with 2FA Mandatory",
                twoFAMandatory: "2FA is mandatory",
                activate2FA: "Activate 2FA",
                deactivate2FA: "Deactivate 2FA",
                updatingProfileFailed: "An error occurred while trying updating the profile, please check the data that you entered",
                buildingDeleteSuccess: "Building was deleted successfully!",
                propertyDeleteSuccess: "Property was deleted successfully!",
                buildingDeleteError: "There was an issue while deleting the building!",
                propertyDeleteError: "There was an issue while deleting the property!",
                organisationDeleteError: "There was an issue deleting the organization! Please ensure that the organization does not contain any other content, such as buildings, units or floors.",
                oldPassword: "Old Password",
                changePasswordHint: "Changing your password will log you out of your current session.",
                changePassword: "Change password",
                profileImageLoadingError: "We encountered an issue while loading your profile image.",
                profileImageUploadError: "There was a hiccup while we were trying to upload your profile image!",
                emptyCanvasProfile: "The canvas is eagerly waiting for your photo!",
                image: "Image",
                selectProfileImage: "Choose a photo to represent your profile!",
                deleteProfileImageError: "We're unable to remove your profile photo right now. Please give it another shot!",
                profileImageUploadSuccess: "Your profile image has been uploaded successfully!",
                typeOldPassword: "Kindly provide your previous password.",
                typeNewPassword: "Please set a new password.",
                retypeNewPassword: "Confirm your new password.",
                differentPassword: "Ensure your new password differs from the old one.",
                retypePasswordMistake: "One of the new password entries seems incorrect.",
                passwordChangeSuccessfully: "The password has been changed successfully.",
                passwordChangeError: "The password change was unsuccessful.",
                conflictNumberError: "A maximum of five phone numbers can be stored.",
                postPhoneNumberError: "Oops! We had trouble saving your phone number.",
                patchPhoneNumberError: "Oops! We had trouble editing your phone number.",
                postPhoneNumberSuccess: "Your phone number has been successfully saved.",
                patchPhoneNumberSuccess: "Your phone number has been successfully edited.",
                deletePhoneNumberError: "We couldn't delete your phone number. Please try again later.",
                userDataSuccess: "Your information has been saved successfully!",
                userDataError: "Oops! We hit a snag while updating your information.",
                userSettingsSuccess: "Your preferences have been saved successfully!",
                userSettingsError: "Oops! We hit a snag while updating your preferences.",
                deleteProfileImageSuccessfully: "Your profile image has been successfully deleted from our records.",
                twoFADeavtivateQuestion: "Are you sure you want to turn off Two-Factor Authentication (2FA)?",
                twoFADeactivationError: "Oops! We encountered an issue while attempting to disable 2FA.",
                twoFADeactivationSuccessfully: "Two-Factor Authentication (2FA) has been successfully turned off.",
                excelMultipleNonUniqueNames: "The uploaded Excel document has columns sharing identical names like {{cols}}. To enhance clarity, please update the column names to ensure each one is unique.",
                orgDataChangedSuccessfully: "Your organization's data has been updated!",
                org2FATurnedON: "You've successfully activated 2FA for the organization.",
                org2FATurnedOFF: "2FA for the organization has been successfully turned off.",
                permissions: "Permissions",
                additionalInfoBuildingDeletedSuccess: "The extra building details have been successfully removed.",
                keyExists: "The organization already has the key you need.",
                buildingsWExcel: "Use an Excel file to upload building information.",
                addOBAI: "Add Organisation Building Information",
                resourceType: "Resource Type",
                obaiAddedSuccessfully: "Great news! The system has successfully added the key for organizations to include more building additional informations.",
                noRessourceTypeOBAI: "The resource type you've chosen isn't in our database. Kindly pick one from the list provided.",
                obaiDeletedSuccessfully: "The Building Additional Information Key has been successfully taken out from the Organisations database.",
                obaiDeletedError: "Since there's more information linked to this specific key, we can't remove it just yet. Please delete all additional building details before removing this key.",
                deleteOBAIKey: "Delete Organisations Building Additional Information Key",
                deleteOBAIKeyQuestion: "Are you sure that you want to delete this key?",
                addPayment: "Add payment method",
                paymentMethodAddedSuccessfully: "Your payment method has been successfully added!",
                buildingsData: "Buildings Data",
                skip: "Skip",
                registrationAgg: "By creating an account, you agree to accept our <0>Processing Agreement</0>.",
                registrationPP: "By creating an account, you agree to accept our <0>Privacy Policy</0>.",
                userIdentifiedLicence: "User Identified Licence",
                concurrentUserLicence: "Concurrent Identification Licence",
                userIdentifiedLicenceManagement: "User Identified Licence Management",
                concurrentUserLicenceManagement: "Concurrent Identification Licence Management",
                shortIntervals: {
                    monthly: "mo",
                    yearly: "yr"
                },
                quantity: "Quantity",
                user: "User",
                active: "Aktiv",
                activeUsers: "Active Users",
                noUsersOnline: "There are no other users online in this building.",
                inactive: "Inaktiv",
                cancel: "Cancel",
                deleteUILTitle: "Remove License",
                activateLicence: "Activate License",
                deleteUILQuestion: "Are you sure you want to cancel this subscription?",
                activateLicenceQuestion: "Are you sure you want to activate this subscription?",
                deleteUILSuccessful: "Software Subscription successfully removed from the organization.",
                activateLicenceSuccessful: "Software Subscription successfully activated.",
                hasLicence: "Has Licence",
                deleteLiccenceError: "There was an issue processing your request. Please ensure that the license is not assigned to any user.",
                assignLicence: "Assign Licence",
                revokeLicence: "Revoke Licence",
                grantLicenceQuestion: "Are you sure you want to grant this license from the user?",
                revokeLicenceQuestion: "Are you sure you want to revoke this license to the user?",
                licenceGrantSuccessfully: "License successfully assigned.",
                licenceRevokeSuccessfully: "License successfully revoked.",
                licenceGrantError: "There was an error assigning the license.",
                licenceRevokeError: "There was an error revoking the license.",
                availableLicences: "Available licences",
                addedLicenses: "Added licenses",
                available: "Available",
                added: "Added",
                moreLicensesThanAllowed: "More licenses have been assigned than allowed.",
                buyLicencesQuestion: "Would you like to purchase additional licenses?",
                userAlreadyLicence: "The user already has a license.",
                allLicencesAssigned: "Every license has been allocated.",
                addSWPackage: "Add software package",
                successSWPackage: "The license was added successfully..",
                noLicences: "No licenses available for display.",
                cancelSubscription: "Cancel subscription",
                activateSubscription: "Activate subscription",
                paymentSuccessfull: "Payment has been successfully processed.",
                from: "from",
                swInUse: "The chosen software is currently in use. To modify the number of licenses, please use the edit function.",
                alreadyBuyed: "Already Buyed",
                choose: "Choose",
                tier: "Tier",
                price: "Price",
                netPrice: "Net price",
                unitAmount: "Unit Amount",
                month: "Month",
                year: "Year",
                priceUnit: "Price per unit",
                buildingName: "Building Name",
                buildingAddress: "Building Adress",
                product: "Product",
                activeTill: "Active till",
                availableESProducts: "Browse Our ES Product Collection",
                selectBuildingsForES: "Choose Buildings Requiring ES Products",
                paymentSummary: "Payment Summary Overview",
                summary:"Summary",
                mBarperm:"mBar per m",
                assignPaymentMethods: "Match Payment Methods with Products",
                changePaymentMethod: "Change Payment Method",
                defaultPayment: "Would you like to set this payment method as the default?",
                noESProductAssigned: "None of our products are currently linked to this building.",
                totalAmount: "Total Amount",
                waitForProccess: "Kindly hang tight while the process completes.",
                default: "Default", 
                paymentMethodChanged: "The payment method has been updated successfully.",
                esProductAssigned: "The ES product has been successfully linked to the building.",
                warningNoOfApartments: "Each selected building needs to have more than one apartment. Please specify the number of apartments first.",
                WarningAreaSize: "The area is more than {{factor}} x larger than the room area.",
                productBrochureTerms: "To proceed, please review the Product Information Brochure and accept the terms.",
                updatingNoApartments: "Adjusting the number of apartments.",
                conditions: "Terms and Conditions",
                termsnConditions: "To proceed, please agree to the terms and conditions.",
                organisationTermsnConditions: "By creating an organisation, you agree to accept our <0>Terms and Conditions</0>.",
                productTermsnConditions: "I hereby confirm that I have read the <0>product information</0> and make a binding request for the product.",
                userPermissionsChanged: "The changes to user permissions were applied successfully.",
                noPermisson: "You currently don't have the authorization to modify user permissions or delete a user.",
                noAuthorization: "You currently don't have the authorization to retrieve the data.",
                setupPaymentMethod: "Set up a payment method",
                noPaymentMethods: "Currently, there are no payment methods available to display.",
                buySoftwareWarning: "To assign a license to a user, first, you'll need to purchase the software package.",
                noPaymentMethodConfiguration: "Currently, there are no payment methods configured for this organization. Kindly establish a payment method before making a product purchase.",
                organisationsPMConfig: "The organization's payment method has been successfully configured.",
                shortWeekdays: {
                    monday: "Mon",
                    tuesday: "Tue",
                    wednesday: "Wed",
                    thursday: "Thu",
                    friday: "Fri",
                    saturday: "Sat",
                    sunday: "Sun"
                },
                shortMonths: {
                    january: "Jan.",
                    february: "Feb.",
                    march: "März",
                    april: "Apr.",
                    may: "Mai",
                    june: "Jun.",
                    july: "Jul.",
                    august: "Aug.",
                    september: "Sep.",
                    october: "Okt.",
                    november: "Nov.",
                    december: "Dez."
                },
                noOBAIs: "There are currently no OBAIs available to be displayed.",
                ton: "ton",
                kwh_year: "kWh/(m²&year)",
                kg_year: "kg/(m²&year)",
                consumption: "Consumption",
                consumptions: "Consumptions",
                addConsumption: "Add Consumption",
                addConsumptionSuccess: "The consumption has been successfully posted.",
                editConsumption: "Edit Consumption",
                editConsumptionSuccess: "The consumption has been successfully edited.",
                noConsumption: "There are no Consumptions to be listed.",
                consumption_demand: "Consumption (Demand)",
                consumption_kwh: "Consumption (kWh)",
                meterReading: "Meter reading",
                oldValMeterReading: "Before replacement",
                newValMeterReading: "New meter reading",
                meterReplacement: "Meter replacement",
                meterReplacementError: "The new counter reading during a counter replacement must not exceed the recorded counter reading.",
                current_values: "Current values",
                percentValueParent: "Percentage relative to parent",
                necessaryBuildings: "Necessary Buildings",
                goal: "Goal",
                goalDescription: "Reach 1.5 Degree Goal Now",
                reduction: "Savings",
                actual: "Actual",
                notActual: "Calculation not actual",
                academy: "Academy",
                academyDescriptiton: "Our academy, specializing in efficient heating technology, offers comprehensive courses for all those interested!",
                goToAcademy: "Go to academy",
                landlordsharesco2: "Landlord's share of CO2 tax",
                landlordCosts: "Landlord costs",
                costs: "Costs",
                co2_savings: "CO2-savings",
                co2_emissions: "CO2 emissions",
                co2_emissions_cost: "CO2 emissions cost per kWh",
                co2_emissions_gas: "CO2 emissions (natural gas)",
                heatingNet: "Heating Network",
                heatmap: "Heatmap",
                pvNet: "Photovoltaic Network",
                force2FA: "Enforce 2-factor for all organization members",
                checkAll: "Check all",
                esProducts: "ES Products",
                buildingNoApartments: "The current building either has no apartments or only one. We need to have more than one apartment available.",
                buildingHasProduct: "The product has already been assigned to the building at the address {{address}}.",
                selectBuildingForProduct: "Choose the buildings where you'd like to add the product!",
                noPaymentMethodForOrg: "Payment methods haven't been configured for this organization. Please set up a payment method before making a purchase!",
                buildingAssignedPM: "The payment method has been successfully set up for the building at {{address}}.",
                productInformations: "Product Information",
                registrationPaymentConditions: "To continue, please agree to the Payment Conditions.",
                registrationTermsnConditions: "To create an account, please agree to the <0>Terms and Conditions</0>.",
                registrationMarketingTerms: "To create an account, please agree to our <0>Marketing Terms</0>.",
                organisationProcessingAgg: "To create an organisation, please accept the order <0>Processing Agreement</0> in accordance with GDPR.",
                organisationPP: "Before proceeding to create an organization, please agree to the <0>Privacy Policy</0>.",
                aIpostError: "We couldn't post additional information at the moment.",

                permission: "Permission",
                httpStatus: {
                    fourzerozero: {
                        title: "Bad Request",
                        message: "Oh no! It seems there's an issue with the request. Double-check your input and try again."
                    },
                    fourzeroone: {
                        title: "Unauthorized Access",
                        message: "Oops! It seems like you're trying to access something that requires proper authentication. Your current credentials might be missing or outdated. Double-check your login information and make sure you have the right permissions."
                    },
                    fourzerothree: {
                        title: "Forbidden",
                        message: "Whoops! Access denied. You might not have the necessary permissions. Contact support if you need assistance."
                    },
                    fourzerofour: {
                        title: "Not Found",
                        message: "Uh-oh! The requested page or resource couldn't be located. Please check the URL and try once more."
                    },
                    fourzerosix: {
                        title: "Not Acceptable",
                        message: "Oops! We can't provide the requested content in the format you want. Let's find a compatible option."
                    },
                    fourzeronine: {
                        title: "Conflict",
                        message: "Houston, we have a conflict! It seems there's a clash with existing data. Check and resolve before proceeding."
                    },
                    fourtwonine: {
                        title: "Too many requests",
                        message: "Uh-oh! It looks like you've made too many requests in a short time. Take a breather for 5 minutes and give it another go. If the issue persists, feel free to reach out to our support team for assistance!"
                    },
                    fivezerozero: {
                        title: "Internal Server Error",
                        message: "Yikes! Something went wrong on our end. Our team is on it. Please try again later."
                    }
                },
                energy_management : "Energy Management",
                generate_energy_report : "Generate Energy Report",
                create_energy_report : "Create Energy Report",
                energy_audit_annually_organisation_report : "Energy Audit Annual Organization Report",
                energy_audit_annualy_organisation_report : "Energy Audit Annual Organization Report",
                energy_report : "Energy Report",
                gas: "Gas",
                oil: "Oil",
                liquid_gas: "Liquid Gas",
                wood_pellets: "Wood Pellets",
                electricity_grid_related: "Electricity (Grid Related)",
                electricity_generated_close_to_the_building_from_pv: "Electricity (Generated Close to the Building from PV)",
                displacement_electricity_mix: "Displacement Electricity Mix",
                heating_cooling_geothermal_energy_geothermal_energy_solar_thermal_energy_ambient_heat: "Heating/Cooling (Geothermal Energy, Solar Thermal Energy, Ambient Heat)",
                geothermal_cooling_ambient_cooling: "Geothermal Cooling, Ambient Cooling",
                waste_heat_from_processes: "Waste Heat from Processes",
                heat_chp_building_integrated_or_near_building_according_to_din_18599_9_2018_0919: "Heat (CHP, Building-integrated or Near Building, According to DIN 18599-9:2018-09)",
                local_district_heating_from_chp_with_chp_coverage_share_of_heat_generation_of_at_least_70_percent_fuel: "Local District Heating from CHP with CHP Coverage Share of Heat Generation of at Least 70% Fuel",
                local_district_heating_stadtwerke_hannover_mix_natural_gas_coal_settlement_waste: "Local District Heating (Stadtwerke Hannover Mix, Natural Gas, Coal, Settlement Waste)",
                local_district_heating_from_heating_plants_fuels_hard_coal_lignite: "Local District Heating from Heating Plants, Fuels: Hard Coal, Lignite",
                gaseous_or_liquid_fuels: "Gaseous or Liquid Fuels",
                renewable_fuel: "Renewable Fuel",
                water: "Water",
                energy_audit : "Energy Audit",
                energy_simulation : "Energy Simulation",
                consumption_data : "Consumption Data",
                renewable_energy_report : "Renewable Energy Report",
                energy_conservation_plans : "Energy Conservation Plans",
                energy_savings_analysis : "Energy Savings Analysis",
                energy_performance_certificates : "Energy Performance Certificates",
                building_plans_and_technical_documents : "Building Plans and Technical Documents",
                building_plans : "Building Plans",
                floor_plans : "Floor Plans",
                lighting_design : "Lighting Design",
                electrical_layouts : "Electrical Layouts",
                hvac_config : "HVAC Configuration",
                cooling_system_diagrams : "Cooling System Diagrams",
                roof_inspection_reports : "Roof Inspection Reports",
                landscaping_plans : "Landscaping Plans",
                maintenance_and_inspection : "Maintenance and Inspection",
                maintenance_schedule : "Maintenance Schedule",
                inspection_report : "Inspection Report",
                elevator_maintenance_reports : "Elevator Maintenance Reports",
                pest_control_reports : "Pest Control Reports",
                vehicle_maintenance_logs : "Vehicle Maintenance Logs",
                system_log : "System Log",
                security_log : "Security Log",
                administrative_and_legal_documents : "Administrative and Legal Documents",
                contracts : "Contracts",
                lease_agreements : "Lease Agreements",
                legal_documents : "Legal Documents",
                procurement_documents : "Procurement Documents",
                tenant_communications : "Tenant Communications",
                financial_reports : "Financial Reports",
                reports_and_certifications : "Reports and Certifications",
                fire_safety_report : "Fire Safety Report",
                certifications : "Certifications",
                green_building_certifications : "Green Building Certifications",
                incident_reports : "Incident Reports",
                water_usage_report : "Water Usage Report",
                waste_management_report : "Waste Management Report",
                risk_assessment_reports : "Risk Assessment Reports",
                air_quality_reports : "Air Quality Report",
                manuals_and_training_documents : "Manuals and Training Documents",
                user_manuals : "User Manuals",
                equipment_manuals : "Equipment Manuals",
                staff_training_documents : "Staff Training Documents",
                emergency_plans : "Emergency Plans",
                inventory_and_asset_management : "Inventory and Asset Management",
                asset_inventory : "Asset Inventory",
                cleaning_schedule : "Cleaning Schedule",
                sensor_data : "Sensor Data",
                frameContract: "Frame Contract",
                frameContracts: "Frame Contracts",
                sign: "Sign",
                check: "Check",
                noFrameContracts: "There are currently no frame contracts available for display.",
                signFrameContract: "Please go ahead and sign the long-term agreement!",
                signRequest: "We've prepared a framework agreement for you. To proceed, kindly sign the agreement. Thank you!",
                finish: "Finish",
                signup: "Sign up",
                messagePlaceholder: "Enter message...",
                deleteMessage: "Delete message",
                deleteMessageQuestion: "Are you sure that you want to delete this message?",
                deleteMessageSuccessfully: "The message was successfully deleted.",
                mailOTP: "Please look in your email inbox for a message from us. It contains an OTP code that you'll need to proceed with the registration process.",
                toOrgInvitation: "The invitation was sent successfully to the user with the provided email address.",
                toOrgRegistration: "The registration was successfully sent to the user at the provided email address.",
                registerUser: "Register User",
                addUserToOrg: "Add User to the Organisation",
                docSigned: "Your document has been successfully signed!",
                docNotSigned: "Looks like the document hasn't been signed yet. Please proceed to sign it.",
                editRoom: "Edit Room",
                errorRoomName: "It is important that the room name is not assigned twice.",
                invitationAccepted: "Your invitation has been successfully accepted!",
                invitationRejected: "The rejection of the invitation was successful.",
                acceptInvitation: "Accept Invitation",
                rejectInvitation: "Reject Invitation",
                invitations: "Invitations",
                energyEfficiencyClass: "EE-Class",
                energyFlow: "Energy Flow",
                calculation: "Calculation",
                planning: "In planning",
                not_paid: "Not paid",
                partly_paid:"Partly paid",
                not_paid_out: "Not paid out",
                variants: "Variants",
                deviatingFromVariant: "deviating from variant",
                noVariant: "No Variant",
                noVariants: "This building doesn't have any assigned variations yet. Click the plus button in the header to add one.",
                noActualVariant: "This building doesn't have any actual variation yet.",
                noStandardReferences: "No standard references have yet been assigned to this building. Click on the plus button to add one.",
                reference: "Reference from catalog",
                addVariant: "Add Variant",
                fromVariant: "From Variant",
                fiveVariant: "You can only add up to 5 variations per building!",
                postVariant: "Your variation has been successfully posted!",
                patchVariant: "The variation has been successfully edited!",
                deleteVariant: "Delete Variant",
                deleteVariantQuestion: "Are you sure that you want to delete this variant?",
                deleteStructuredPartDataQuestion: "Are you sure you want to switch? All entries will be deleted!",
                deletedVariantpart: "The variant has been successfully deleted!",
                deleteVariantError: "The variant cannot be deleted. Please ensure that the variant does not contain any further content.",
                deleteVariantNotAllowed: "The variant cannot be deleted.",
                deletedVariant: "The variant has been successfully deleted!",
                conflictBpVariant: "This building part component already exists.",
                noReference: "A reference from the catalog is missing.",
                userShouldAddVariant: "To proceed, please add a variant.",
                catalog: "Catalog",
                bpcatalog: "Building Parts Catalog",
                hpcatalog: "Heating Parts Catalog",
                missingHpccomponent: "No Heating Part Component is selected!”",
                hpccomponent: "Heating Part Component",
                addEditDelete: "Add / Edit / Delete",
                noBPCs: "There aren't any Building Part Catalog Related to this Organisation.",
                noBPCCs: "There is no Building Part Component.",
                addStandardBP: "Add standard Building Part to variant",
                addBPC: "Add Building Part Catalog",
                editBPC: "Edit Building Part Catalog",
                deleteBPC: "Delete Building Part Catalog",
                deleteErrorBPC: "The Building Part Catalog cannot be deleted. Please ensure that all components of the Building Part Catalog have been deleted.",
                deleteBPCQuestion: "Are you sure that you want to delete this Building Part Catalog?",
                bpcDeletedSuccessfully: "The Building Part Catalog was successfully removed from the Organisation!",
                bpccDeletedSuccessfully: "The Building Part Component was successfully removed from Catalog!",
                editBPCC: "Edit Building Part Component",
                addBPCC: "Add Building Part Component",
                deleteBPCC: "Delete Building Part Component",
                deleteBPCCQuestion: "Are you sure that you want to delete this Building Part Component?",
                addBPV: "Add Building Part Variant",
                editBPV: "Edit Building Part Variant",
                uTypical: "U typical",
                individualU_Val: "Individual U-values",
                individually: "Individually",
                valid_from: "Valid From",
                valid_to: "Valid To",
                bpcAddedSuccessfully: "Building Part Catalog is added successfully!",
                bpcEditedSuccessfully: "Building Part Catalog is edited successfully!",
                bpccomponent: "Building Part Component",
                bpccAddedSuccessfully: "Building Part Component is added successfully!",
                bpccEditedSuccessfully: "Building Part Component is edited successfully!",
                u_value: "U Value (W/m²K)",
                u_value_m2: "U Value m2",
                hpcDeletedSuccessfully: "The Heating Part Catalog was successfully removed from the Organisation!",
                noHPCs: "There aren't any Heating Part Catalog related to this Organisation.",
                noHPCCs: "There is no Heating Part Component.",
                addHPC: "Add Heating Part Catalog",
                editHPC: "Edit Heating Part Catalog",
                deleteHPC: "Delete Heating Part Catalog",
                deleteErrorHPC: "The Heating Part Catalog cannot be deleted. Please ensure that all components of the Heating Part Catalog have been deleted.",
                deleteHPCQuestion: "Are you sure that you want to delete this Heating Part Catalog?",
                hpccomponent: "Heating Part Component",
                hpccomponents: "Heating Part Components",
                hpccDeletedSuccessfully: "The Heating Part Component was successfully removed from Catalog!",
                addHPCC: "Add Heating Part Component",
                addHPCCsingle: "Single Component",
                addHPCClist: "Import Components",
                editHPCC: "Edit Heating Part Component",
                deleteHPCC: "Delete Heating Part Component",
                deleteHPCCQuestion: "Are you sure that you want to delete this Heating Part Component?",
                hpcAddedSuccessfully: "Heating Part Catalog is added successfully!",
                hpccAddedSuccessfully: "Heating Part Component is added successfully!",
                hpccEditedSuccessfully: "Heating Part Component is edited successfully!",
                thermal_power: "Thermal Power", 
                design_thermal_power_text: `Store the radiator-specific overtemperature in a key-value pair with the key "overtemperature"!`,
                design_thermal_power: "Design Thermal Power (75/65/20)",
                design_flow_temperature: "Design Flow Temperature",
                design_return_temperature: "Design Return Temperature",
                design_room_temperature: "Resign Room Temperature",
                room_temperature: "Room Temperature",
                theta_exponent: "Theta Exponent",
                attributes: "Attributes",
                deleteIndividualBuildingPart: "Delete Individual Building Part",
                questionDeleteIndividualBuildingPart: "Are you sure that you want to delete this individual Building Part?",
                deleteBuildingPart: "Delete Building part",
                questionDeleteBuildingPart: "Are you sure that you want to delete this Building Part?",
                bpDeletedSuccessfully: "The Building Part was deleted successfully!",
                deleteHeatingPart: "Delete heating part",
                questionDeleteHeatingPart: "Are you sure that you want to delete this heating part?",
                hpDeletedSuccessfully: "The heating part was deleted successfully!",
                maxDistance: "Max Distance",
                targetPressureMBAR: "Target Pressure mBar",
                sigma_z:"Σ z",
                OffsetGeneric:"Offset Generic",
                info_OffsetGeneric:"If a radiator valve requires a higher offset (minimum differential pressure), this value is overwritten by the higher value in the calculation.",
                OffsetBoilerRoom:"Offset Boiler Room",
                strandReferenceAdded: "Strand Reference added successfully",
                strandReferenceEdited: "Strand Reference edited successfully",
                strandReferenceDeleted: "Strand Reference deleted successfully",
                noStrandReferences: "No Strand References found",
                addStrandReference: "Add Strand Reference",
                editStrandReference: "Edit Strand Reference",
                deleteStrandReference: "Delete Strand Reference",
                deleteStrandReferenceQuestion: "Are you sure that you want to delete this Strand Reference",
                floorSNConflict: "It looks like that floor number is already taken. Please choose a different floor number to continue!",
                thickness: "Thickness",
                free: "Free",
                floor: "Floor",
                wall: "Wall",
                window: "Window",
                ceiling: "Ceiling",
                outerdoor: "Outerdoor",
                outerwall: "Outerwall",
                outerwallsoil: "Outerwall on soil",
                groundsoil: "Floor on soil",
                maxDepthGround: "max. depth in the ground",
                innerwall: "Innerwall",
                innerdoor: "Innerdoor",
                innerceiling: "Innerceiling",
                shutterbox: "Roller Shutter Box",
                correctionFactor: "Correction factor",
                calc: "Calculate",
                afterCalc: "after calculation",

                configurationsLog: "Configurations Log",
                addConfigurationsLog: "Add Configuration Log",
                editConfigurations: "Edit Configuration Log",
                patchConfigurationsLog: "Configuration Log was edited successfully",
                postConfigurationsLog: "Configuration Log was posted successfully",
                settingValue: "Setting Value",
                valveAuthority: "Valve Authority",
                calcFromExternal: "Calculated From External?",
                deleteConfigurationsLog: "Delete Configuration Log",
                deleteConfigurationsLogQuestion: "Are you sure that you want to delete this Configuration Log?",
                deletedConfigurationsLog: "Seleted Configuration Log was deleted successfully",
                notDeletedConfigurationsLog: "Seleted Configuration Log could not be deleted",
                noConfigurationsLog: "There aren't any Configurations Log presentable at this time",
                promptLeavePageQuestion: "Are you sure you want to leave this page without saving your changes?",
                heatpart: "Heatpart",
                heatparts: "Heatparts",
                heatpartsCalculated: "Calculated Heatparts",
                massFlow: "Mass Flow",
                returnTemperature: "Return Temperature",
                differentialPressure: "Differential Pressure",
                kvValue: "Kv-Value",
                value_MWh: "MWh-Value",
                slopingRoofGenerator: "Sloping Roof Generator",
                roof: "Roof",
                roofType: "Roof Types",
                syncChanges: "Synchronise Changes",
                pitchedRoof: "Pitched Roof",
                gableRoofOnePitch: "Gable Roof One Pitch",
                gableRoofTwoPitches: "Gable Roof Two Pitches",
                hipRoofOnePitch: "Hip Roof One Pitch",
                hipRoofTwoPitches: "Hip Roof Two Pitches",
                hipRoofThreePitches: "Hip Roof Three Pitches",
                hipRoofFourPitches: "Hip Roof Four Pitches",
                kneestock: "Kneestock",
                diagonal: "Diagonal",
                dormer: "Dormer",
                dormerNo: "Number of Roof Dormer",
                completedPagesNo: "Number of Completed Pages",
                distance: "Distance",
                slanting: "Slanting",
                noofdeductionareas: "No of deduction areas",
                group_id: "Group-ID",
                classifications: "Classifications",
                noClassifications: "There are no classifications to be shown",
                addClassification: "Add Classification",
                addEditClassification: "Add/Edit Classification",
                heatCircuit: "Heat Circuit",
                hotWaterPreparation: "Hot Water Preparation",
                energyConsumption: "Energy Consumption",
                other: "Other",
                heatsource: "Heat Source",
                heatSource: "Heat Source",
                heatStorage: "Heat Storage",
                deleteClassification: "Delete classification",
                deleteClassificationQuestion: "Are you sure you want to delete this classification?",
                deleteClassificationSuccessful: "Classification was deleted successfully",
                deleteClassificationError: "Classification was not deleted",
                fixedFlowTemperature: "Fixed Flow Temperature",
                optimalTemperature: "Festgelegte Temperature",
                calculationbasics: "Calculation Basics",
                maximumPerformance: "Maximum Performance",
                achievableRoomTemperature: "Achievable Room Temperature",
                variantCopiedSuccessfully: "The variant has been successfully copied",
                variantCopiedFailed: "You've reached the maximum number of allowed variants.",
                missingCalculation: "Missing Calculation",
                specificConsumption: "Specific Consumption",
                lastcalculation: "Last Calculation",
                namesource: "Name Source",
                productseries: "Product Series",
                pumpTotalHead: "Pump total head",
                building_actions:"Building Actions",
                noBuildingActions: "No actions created",
                flat_panel_radiator: "Flat Panel Radiator",
                bathroom_radiator: "Bathroom Radiator",
                sectional_radiator: "Sectional Radiator",
                convectors: "Convectors",
                heating_walls: "Heating Walls",
                designer_radiator: "Designer Radiator",
                electric_radiator: "Electric Radiator",
                underfloor_convectors: "Underfloor Convectors",
                ceiling_convectors: "Ceiling Convectors",
                wall_mounted_convectors: "Wall Mounted Convectors",
                parapet_convectors: "Parapet Convectors",
                others: "others",

                schema_generator: "Schema Creator",
                heat_sources_no: "Number of Heat Sources",
                accumulators_no: "Number of Accumulators",
                heat_exchangers_no: "Number of Heat Exchangers",
                connection_type: "Type of Connections",
                parallel: "Parallel Connection",
                serial: "Serial Connection",
                heat_exchanger_position: "Heat Exchanger Position: Before Accumulator",
                additional_heat_parts_no: "Number of Additional Heat Components",
                existing_heat_parts: "Use Existing Heat Components",
                generate: "Generate",
                updatedBy: "Updated By",
                createdBy: "Created By",
                fromYear: "From Year",
                energyResources: "Energy Resources",
                g_co2_kWh: "Gr. CO2 kWh",
                cent_kWh: "Cent kWh",
                from_date: "From Date",
                addResource: "Add Resource",
                editResource: "Edit Resource",
                orgAddInfo: "Organisation Additional Information",
                addBCD: "Add Annual Consumption Data",
                related_data: "Related Date",
                buildingConsumption: "Building Consumption",
                resourceBeforeConsumption: "To enter your consumption data, please start by adding an energy resource first.",
                energyResourceDeletedSuccessfully: "Energy Resource was successfully deleted",
                energyResourceDeleteQuestion: "Are you sure that you want to delete the energy resource?",
                energyResourceSuccess: "The energy resource was successfully created!",
                energyResourceDelete: "Delete Energy Resource",
                groupNfilteringPanel: "Group & Filtering Panel",
                apply: "Apply",
                groupBy: "Group by...",
                field: "Field",
                operator: "Operator",
                clearGrouping: "Clear Grouping",
                addFilter: "Add Filter",
                resetFilters: "Reset Filters",
                energyReport: "Energy Report",
                reportPostedSuccessfully: "Energy report has been successfully created.", 
                selectYear: "Select a year",
                introduction: "Introduction",
                energyResourceInfo: "The CO2 emission needs to be recorded by January 1st, ####, in order for it to be used as an energy resource.",
                energyResourceTypeYearWarning: "An energy resource with the same type and year already exists. Please choose a different year or type.",
                addEnergyReport: "Add Energy Report",
                oneDay: "One Day",
                oneWeek: "One Week",
                ninetyDays: "90 Days",
                oneYear: "One Year",
                results: "Results",
                set_flow_temperature: "Set Max Flow Temperature",
                heating: "Heating",
                current: "Current",
                scenario_flow_temp_calculated_kv: "At the flow temperature of the scenario and the calculated Kv value",
                offlineMode: "Offline Mode",
                offlineModeReadmeTitle: "Important Information - Please Read Carefully",
                offlineModeReadmeText: "This wizard will guide you through the steps to set up the app for offline use. It is important to note that you will have exclusive offline privileges for the selected building for 2 hours. If you do not come back online within this time, the privilege will be revoked, and all users in the organization will regain access to the building.",
                offlineModeDownloadCatalogs: "Download Catalogs",
                offlineModeDownload: "Please download the catalogs required for offline use.",
                offlineModeFinalStep: "Final Step",
                offlineModeFinalStepNotice: "Once you click \"Go Offline,\" you will be able to work exclusively on the selected building. When you're finished entering your data, simply click \"Go Online\" on the building dashboard, and your data will automatically be uploaded to the server.",
                goOffline: "Go Offline",
                deleteVariantConflict: "To delete this variant, you must first delete its associated children.",
                alarms: "Alarms",
                measurement: "Measurement",
                threshhold: "Threshhold",
                alarmType: "Alarm Type",
                lastExecuted: "Last Executed",
                editAlarm: "Edit Alarm",
                deleteAlarm: "Delete Alarm",
                deleteAlarmQuestion: "Are you sure you want to delete this alarm? This action can't be undone.",
                alarmDeleted: "The alarm has been deleted successfully!",
                alarmDeviceAssignInfo: "To add an alarm, please make sure a device is assigned to the building.",
                addAlarm: "Add Alarm",
                editAlarm: "Edit Alarm",
                device: "Device",
                noAlarms: "No alarms are currently set for this measurement.",
                underfloorheating: "Underfloor Heating",
                configurationLimit: "Only numbers, floats (with . or ,), -1, or N are allowed.",

            }
        }
    },
    lng: localStorage.getItem("Profile")?.settings?.language_code || "en",
});

export default i18next;