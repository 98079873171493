

export default class NotificationWebsocketWorker {

    constructor(login, callback_function_message, setReceivedNotifications, setUnreadNotifications) {
        
        this.url = process.env.WEBSOCKET;
        this.socket = null;
        this.token = login?.Authorization?.replace("Bearer ", "");
        this.user = login.id;
        this.callback_function_toast = callback_function_message;
        this.setReceivedNotifications = setReceivedNotifications;
        this.setUnreadNotifications = setUnreadNotifications;

        if(this.token && this.url)this.connect()
    }
  
    connect() {
      this.user_socket = new WebSocket(this.url+'/?token='+this.token);

      // Event-Listener: Wenn die Verbindung geöffnet wird
      this.user_socket.onopen = () => {
        console.log('WebSocket connection opened.');
      };
  
      // Event-Listener: Wenn eine Nachricht empfangen wird
      this.user_socket.onmessage = (event) => {
        try {

            let parsedData = JSON.parse(event.data);
            if (typeof parsedData === "object" && parsedData !== null) {

                if (Object.keys(parsedData).includes("message")) {
                  this.setReceivedNotifications(prevState => [
                    ...prevState,
                    {...parsedData, date: new Date().toISOString()}
                  ])

                  this.setUnreadNotifications(prevState => {
                    // Prüfe, ob die Nachricht mit der gleichen ID bereits existiert
                    const exists = prevState.some(notification => notification.message === parsedData.id);
                    if (exists) {
                      return prevState;
                    }
                    return [
                      ...prevState,
                      { ...parsedData, read: false, user: this.user, message: parsedData.id }
                    ];
                  });
                  this.callback_function_message && this.callback_function_message(parsedData)
                }
            }
          } catch (error) {
            console.log("Received non-dict message:", event.data);
            console.log("error", error)
          }
      };
  
      // Event-Listener: Wenn ein Fehler auftritt
      this.user_socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      // Event-Listener: Wenn die Verbindung geschlossen wird
      this.user_socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };

    }
      
    // Ungelesene Nachrichten auf "gelesen" setzen
    async readMessage(message, onSentCallback) {
      await this.waitForSocketOpen();
      try {
        this.user_socket.send(JSON.stringify({ "type": "chat.read", "message_uuids": message }));

        if (onSentCallback) {
          onSentCallback();
        }
      } catch (error) {
        console.error('Error message:', error);
      }
    }
    
    waitForSocketOpen() {
      return new Promise((resolve, reject) => {
        if (this.user_socket && this.user_socket.readyState === WebSocket.OPEN) {
          resolve(); 
        } else {
          const onOpenHandler = () => {
            this.user_socket.removeEventListener('open', onOpenHandler);
              resolve(); 
          };
          this.user_socket.addEventListener('open', onOpenHandler);
        }
      });
    }
  
    // Schließe die WebSocket-Verbindung
    disconnect() {
      if (this.socket) {
        this.user_socket.close();
      }
    }
  }
  